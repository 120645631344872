import React from 'react';

const TransParentLoader = () => {
  return (
    <div style={{backgroundColor:"rgba(0,0,0,0.3)" , zIndex:2000}} className="flex fixed w-screen left-0 top-0  justify-center items-center h-screen">
      <div className="loader text-yellow-600 w-1 h-1 rounded-full  animate-spin" style={{
        boxShadow: `
          calc(1 * 22px) calc(0 * 22px) 0 0,
          calc(0.707 * 22px) calc(0.707 * 22px) 0 1px,
          calc(0 * 22px) calc(1 * 22px) 0 2px,
          calc(-0.707 * 22px) calc(0.707 * 22px) 0 3px,
          calc(-1 * 22px) calc(0 * 22px) 0 4px,
          calc(-0.707 * 22px) calc(-0.707 * 22px) 0 5px,
          calc(0 * 22px) calc(-1 * 22px) 0 6px
        `,
        animation: 'l27 1s infinite steps(8)',
      }} />
      <style jsx>{`
        @keyframes l27 {
          100% {
            transform: rotate(1turn);
          }
        }
      `}</style>
    </div>
  );
};

export default TransParentLoader;
