import Drawer from "../../global_layouts/Drawer/Drawer";


const DetailsMarket = (props) => {
  const { isDrawerOpen, setIsDrawerOpen } = props
  const isOpen = isDrawerOpen.detailsMarketDrawer;

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setIsDrawerOpen({ detailsMarketDrawer: false })}
      direction="right"
      size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
      overlay={true}
    >
      <div className="text-white">

        <h2 className="text-2xl font-bold">Deatils MArket</h2>
        <p>Deatils MArket Drawer Api pending.</p>
      </div>
    </Drawer>


  );

};

export default DetailsMarket;
