import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FaRegFileExcel } from 'react-icons/fa6';
import { FaFilePdf } from 'react-icons/fa';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const DownloadFileExcel = ({ headers, data, fileName }) => {
  const getStatus = (dataKey, value) => {
    if (dataKey === 'status') {
      return value === 1 ? 'Active' : 'Inactive';
    } 
    // else if (dataKey === 'betStatus') {
    //   return value ? 'Active' : 'Inactive';
    // }
    return value; // Return value as is if not status or betStatus
  };

  const handleDownload = () => {
    const worksheetData = [];
    
    data.forEach(item => {
      const rowData = {};
      headers.forEach(header => {
        rowData[header.dataKey] = header.dataKey === 'status' 
         ? getStatus(header.dataKey, item[header.dataKey])
          : item[header.dataKey];
      });
      worksheetData.push(rowData);
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(file, `${fileName}.xlsx`);
  };

  return (
    <button className='text-sm shadow-md shadow-indigo-700/40 bg-red-600 text-white py-1.5 px-2 inline-flex items-center rounded-sm font-semibold uppercase' type='button' onClick={handleDownload}>
      <span className='flex justify-center items-center gap-1'>
        <FaRegFileExcel />Excel
      </span> 
    </button>
  );
};


export const DownloadFilePdf = ({ headers, data, fileName }) => {
  const getStatus = (dataKey, value) => {
    if (dataKey === 'status') {
      return value === 1 ? 'Active' : 'Inactive';
    } 
    // else if (dataKey === 'betStatus') {
    //   return value ? 'Active' : 'Inactive'; 
    // }
    return value; // Return value as is if not status or betStatus
  };

  const handleDownload = () => {
    if (!data || data.length === 0) {
      console.error('No data available to generate the PDF');
      return;
    }

    const doc = new jsPDF();

    // Use autoTable to create the table in the PDF
    doc.autoTable({
      head: [headers.map(col => col.header)],
      body: data.map(item => headers.map(col => getStatus(col.dataKey, item[col.dataKey]))),
      startY: 10,
      styles: {
        cellHeight: 'wrap',
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 8
      },
      headStyles: {
        fillColor: false,
        textColor: [0, 0, 0],
      }
    });

    // Save the PDF with the specified file name
    doc.save(`${fileName}.pdf`);
  };

  return (
    <button onClick={handleDownload} className="text-sm shadow-md shadow-indigo-700/40 bg-red-600 text-white  py-1.5 px-2 inline-flex items-center rounded-sm font-semibold uppercase">
      <FaFilePdf />
      <span className="ml-1 px-1">PDF</span>
    </button>
  );
};



// export const DownloadFileExcel = ({ data, fileName }) => {

//   const handleDownload = () => {

//     const filteredData = data.map(item => ({
//       username: item.username,
//       creditReference: item.creditReference,
//       balance: item.coins,
//       clientpl: item.coins,
//       exposure: item.exposure,
//       availablebalance: "0",
//       ust: item.status,
//       bst: item.betStatus,
//       exposureLimit: item.exposureLimit,
//       default: "0",
//       userType: item.userType,
//     }));
// //  if you need all data so replace the data in filteredData
//     const worksheet = XLSX.utils.json_to_sheet(filteredData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
//     saveAs(file, `${fileName}.xlsx`);
//   };
//   return (
//     <button className=' text-sm shadow-md shadow-indigo-700/40 bg-green-900 text-white  py-1.5 px-2 inline-flex items-center rounded-sm font-semibold uppercase' type='button' onClick={handleDownload}>
//       <span className='flex justify-center items-center gap-1'> <FaRegFileExcel />Excel </span> 
//       </button>
//   );
// };



// export const DownloadFilePdf = ({ data, fileName }) => {
//   const handleDownload = () => {
//     if (!data || data.length === 0) {
//       console.error('No data available to generate the PDF');
//       return;
//     }

//     const doc = new jsPDF();

//     // Define the columns we want to display 
//     const columns = [
//       { header: 'Username', dataKey: 'username' },
//       { header: 'creditReference', dataKey: 'creditReference' },
//       { header: 'Balance', dataKey: 'balance' },
//       { header: 'Client (P/L)', dataKey: 'clientpl' },
//       { header: 'exposure', dataKey: 'exposure' },
//       { header: 'Available Balance', dataKey: 'availablebalance' },
//       { header: 'U St', dataKey: 'ust' },
//       { header: 'B St', dataKey: 'bst' },
//       { header: 'exposureLimit', dataKey: 'exposureLimit' },
//       { header: 'Default %', dataKey: 'default' },
//       { header: 'userType', dataKey: 'userType' },
//     ];

//     // Filter the data to only include the specified fields
//     const filteredData = data.map(item => ({
//       username: item.username,
//       creditReference: item.creditReference,
//       balance: item.coins,
//       clientpl: item.coins,
//       exposure: item.exposure,
//       availablebalance: "0",
//       ust: "active",
//       bst: "active",
//       exposureLimit: item.exposureLimit,
//       default: "0",
//       userType: item.userType,
//     }));

//     // Use autoTable to create the table in the PDF
//     doc.autoTable({
//       head: [columns.map(col => col.header)],
//       body: filteredData.map(item => columns.map(col => item[col.dataKey])),
     
//       startY: 10,
//       styles: {
//         // cellWidth: 'wrap',
//         cellHeight: 'wrap',
//         lineColor: [0, 0, 0], // black border color
//         lineWidth: 0.1, // border width in mm
//         fontSize: 8
//       },
//       headStyles: {
//         fillColor: false, // Remove background color for the table header
//         textColor: [0, 0, 0], // Set text color for the table header
//         // lineWidth: 0 // Remove border for the table header
//       }
//     });

//     // Save the PDF with the specified file name
//     doc.save(`${fileName}.pdf`);
//   };

//   return (
//     <button onClick={handleDownload} className="text-sm shadow-md shadow-indigo-700/40 bg-red-600 text-white  py-1.5 px-2 inline-flex items-center rounded-sm font-semibold uppercase">
//       <FaFilePdf />
//       <span className="ml-1 px-1">PDF</span>
//     </button>
//   );
// };

// export const DownloadFilePdf = ({ headers, data, fileName }) => {
//   const handleDownload = () => {
//     if (!data || data.length === 0) {
//       console.error('No data available to generate the PDF');
//       return;
//     }

//     const doc = new jsPDF();

//     // Define the columns we want to display 
//     const columns = [
//       { header: 'Username', dataKey: 'username' },
//       { header: 'creditReference', dataKey: 'creditReference' },
//       { header: 'Balance', dataKey: 'balance' },
//       { header: 'Client (P/L)', dataKey: 'clientpl' },
//       { header: 'exposure', dataKey: 'exposure' },
//       { header: 'Available Balance', dataKey: 'availablebalance' },
//       { header: 'U St', dataKey: 'ust' },
//       { header: 'B St', dataKey: 'bst' },
//       { header: 'exposureLimit', dataKey: 'exposureLimit' },
//       { header: 'Default %', dataKey: 'default' },
//       { header: 'userType', dataKey: 'userType' },
//     ];

//     // Filter the data to only include the specified fields
//     const filteredData = data.map(item => ({
//       username: item.username,
//       creditReference: item.creditReference,
//       balance: item.coins,
//       clientpl: item.coins,
//       exposure: item.exposure,
//       availablebalance: "0",
//       ust: "active",
//       bst: "active",
//       exposureLimit: item.exposureLimit,
//       default: "0",
//       userType: item.userType,
//     }));

//     // Use autoTable to create the table in the PDF
//     doc.autoTable({
//       head: [headers],
//       body: data.map(item => headers.map(header => item[header.toLowerCase().replace(/\s+/g, '')])),
//       startY: 10,
//       styles: {
//         // cellWidth: 'wrap',
//         cellHeight: 'wrap',
//         lineColor: [0, 0, 0], // black border color
//         lineWidth: 0.1, // border width in mm
//         fontSize: 8
//       },
//       headStyles: {
//         fillColor: false, // Remove background color for the table header
//         textColor: [0, 0, 0], // Set text color for the table header
//         // lineWidth: 0 // Remove border for the table header
//       }
//     });

//     // Save the PDF with the specified file name
//     doc.save(`${fileName}.pdf`);
//   };

//   return (
//     <button onClick={handleDownload} className="text-sm shadow-md shadow-indigo-700/40 bg-red-600 text-white  py-1.5 px-2 inline-flex items-center rounded-sm font-semibold uppercase">
//       <FaFilePdf />
//       <span className="ml-1 px-1">PDF</span>
//     </button>
//   );
// };


