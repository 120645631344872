import Card from "../../global_layouts/Card/Card";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";

const MyBets = () => {
    return (
        <GlobalLayout>
            <Card>

                My Bets Pages Created
            </Card>

        </GlobalLayout>
    )
}

export default MyBets;