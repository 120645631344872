import React, { useEffect, useState } from "react";
import { Card, Table, Button } from "antd";
// import BackButton from "../../Hoc/BackButton";
import { useDispatch, useSelector } from "react-redux";
// import Loader from "../../../../components/loader";
import { Link } from "react-router-dom";
import { getMatkaList } from "../../../redux/_reducers/_user_reducers";
import Loader from "../../../component/loader/Loader";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import Triangle from "../../../global_layouts/Triangle/Triangle";
// import { getMatkaList } from "../../../../appRedux/actions/User";

const Basic = () => {
  const [matkaList, setMatkaList] = useState([]);

  const dispatch = useDispatch();

  const { loading, getMatkaListData } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getMatkaList());
  }, [dispatch]);

  useEffect(() => {
    if (getMatkaListData) {
      const sortedSessions = [...getMatkaListData].sort(
        (a, b) => a.priority - b.priority
      );
      const filteredData = sortedSessions.map((item, index) => ({
        key: `${item._id}-${index}`,
        isDeclare: item.isDeclare,
        isDeleted: item.isDeleted,
        _id: item._id,
        name: item.name,
        shortName: item.shortName,
        sportsId: item.sportsId,
        matkaEventId: item.matkaEventId,
        maxStake: item.maxStake,
        minStake: item.minStake,
        betStatus: item.betStatus ? "Active" : "InActive",
        matkaStatus: item.matkaStatus,
        openTime: item.openTime,
        closeTime: item.closeTime,
        resultTime: item.resultTime,
        priority: item.priority,
        createdAt: item.createdAt,
      }));
      setMatkaList(filteredData);
    }
  }, [getMatkaListData]);

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    return obj;
  };
  const columns = [
    {
      title: "Name",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "name",
      render: renderContent,
    },
    {
      title: "Bet Status",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "betStatus",
      render: renderContent,
    },
    {
      title: "Result Time",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "resultTime",
      render: renderContent,
    },
    {
      title: "Open Time",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "openTime",
      render: renderContent,
    },
    {
      title: "Close Time",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "closeTime",
      render: renderContent,
    },
    {
      title: "Action",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "action",
      render: (text, record) => (
        <div className="flex gx-justify-end">
          <Button className="bg-[#60a5fa] flex items-center justify-center text-white hover:!text-white hover:!bg-[#60a5fa] font-bold px-4 py-1" type="primary">
            <Link className="leading-bold hover:!text-white uppercase text-[12px]" to={`/admin/view-matka/${record?.matkaEventId}`}>
              View
            </Link>
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      {loading ? (
        <Loader loaderTrue={true} />
      ) : (
        <GlobalLayout>
          <Card className="gx-card">
            {/* <div className="gx-bg-grey gx-px-5 gx-pt-2 gx-bg-flex gx-align-items-center">
              <span className="gx-fs-2xl gx-font-weight-normal gx-text-white gx-align-items-center gx-text-capitalize">{`Matka Inplay`}</span>
            </div> */}
            <div className="bg-red-600 flex justify-between items-center mb-4">
            <div className="text-white bg-red-700 relative font-bold px-4 py-2">
              {" "}
              {`Matka Inplay`}
              <Triangle direction="down" size={10} color="#B91C1C" />
            </div>
            <button
              onClick={() => window.location.reload()}
              className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
            >
              {" "}
              Refresh
            </button>
          </div>

            <div>
              <Table
                className="table-responsive uppercase"
                columns={columns}
                dataSource={matkaList}
                bordered
                pagination={false}
                size="small"
              />
            </div>
          </Card>
        </GlobalLayout>
      )}
    </>
  );
};

export default Basic;
