// import { NavLink } from 'react-router-dom'; // or use your preferred routing library
// import { sidebarMenu } from './sidebarMenu';
// import { useState } from 'react';



// const Sidebar = () => {
//   const [activeSubMenu, setActiveSubMenu] = useState(null);

//   const handleSubMenuToggle = (title) => {
//     setActiveSubMenu((prev) => (prev === title ? null : title));
//   };
//   const sortedMenuItems = sidebarMenu.filter(item => item.visible).sort((a, b) => (a.orderBy - b.orderBy));

//   return (
//     <div>
//       <aside className="main-sidebar sidebar-dark-primary ">
//         <a href="/" className="brand-link">
//           <img
//             src="/assest/images/logo.png"
//             alt="Logo"
//             className="px-3 h-9 w-52"
//           />
//         </a>
//         <div className="sidebar">
//           <nav className="navbar-expand navbar-dark">
//             <ul
//               className="nav nav-pills nav-sidebar flex-column py-1"
//             >
//              {sortedMenuItems.map((item, index) => (
//         <li className={`nav-item border-bottom ${activeSubMenu === item.title ? 'menu-is-opening menu-open' : null}`} key={index}>
//           <NavLink 
//             to={item.path} 
//             className={({ isActive }) => `nav-link sidebar-hover`}
//             onClick={() => item.submenu && handleSubMenuToggle(item.title)} 
//           >
//             <i className={`nav-icon py-1 ${item.icon}`} />
//             <p className='px-3 text-md font-semi-bold'>
//               {item.title}
//               {item.submenu && <i className="fas fa-angle-left right" />}
//             </p>
//           </NavLink>

//           {item.submenu && activeSubMenu === item.title && ( 
//             <ul className='nav'
//             >
//               {item.submenu.filter(subItem => subItem.visible).sort((a, b) => a.orderBy - b.orderBy)
//                 .map((subItem, subIndex) => (
//                   <li className={`nav-item border-bottom `} key={subIndex}>
//                     <NavLink 
//                       to={subItem.path} 
//                       className={({ isActive }) => `nav-link sidebar-hover ${isActive ? 'active' : ''}`}
//                     >
//                       <i className={`far fa-circle nav-icon`} />
//                       <p>{subItem.title}</p>
//                     </NavLink>
//                   </li>
//                 ))}
//             </ul>
//           )}
//         </li>))}
//             </ul>
//           </nav>
//         </div>
//       </aside>
//     </div>
//   );
// };

// export default Sidebar;

// nav-item menu-is-opening menu-open








import { NavLink } from 'react-router-dom';
import useSidebarMenu from './sidebarMenu';
import { useState, useEffect } from 'react';

const Sidebar = () => {
  const sidebarMenu = useSidebarMenu();
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubMenuToggle = (title) => {
    setActiveSubMenu((prev) => (prev === title ? null : title));

  };

  const closeSidebarOnMobile = () => {
    if (isMobile) {
      const sidebar = document.querySelector('.sidebar-open');
      if (sidebar) {
        sidebar.classList.remove('sidebar-open');
        sidebar.classList.add('sidebar-closed');
        sidebar.classList.add('sidebar-collapse');
      }
    }
  };

  const sortedMenuItems = sidebarMenu.filter(item => item.visible).sort((a, b) => (a.orderBy - b.orderBy));

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary sidebar-closed">
        <a href="/" className="brand-link">
          <img
            src="/assest/images/logo.png"
            alt="Logo"
            className="px-3 h-9 w-52"
          />
        </a>
        <div className="sidebar">
          <nav className="navbar-expand navbar-dark">
            <ul className="nav nav-pills nav-sidebar flex-column py-1">
              {sortedMenuItems.map((item, index) => (
                <li className={`nav-item border-bottom ${activeSubMenu === item.title ? 'menu-is-opening menu-open' : null}`} key={index}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => `nav-link sidebar-hover`}
                    onClick={() => {
                      item.submenu ? handleSubMenuToggle(item.title) : closeSidebarOnMobile(); // Close sidebar on mobile after menu click
                    }}
                  >
                    <i className={`nav-icon text-md py-1 ${item.icon}`} />
                    <p className='px-3 text-md font-semi-bold'>
                  
                      {item.title}
                      {item.submenu && <i className="fas fa-angle-left right" />}
                    </p>
                  </NavLink>

                  {item.submenu && activeSubMenu === item.title && (
                    <ul className='nav'>
                      {item.submenu.filter(subItem => subItem.visible).sort((a, b) => a.orderBy - b.orderBy)
                        .map((subItem, subIndex) => (
                          <li className={`nav-item border-bottom`} key={subIndex}>
                            <NavLink
                              to={subItem.path}
                              className={({ isActive }) => `nav-link sidebar-hover ${isActive ? 'active' : ''}`}
                              onClick={closeSidebarOnMobile}
                            >
                              <i className={`${subItem?.icon} nav-icon text-md`}/>                      
                              <p className='capitalize'>
                                {/* {subItem.title} */}
                              {subItem.title === 'superagent' ? 'Super Master' : subItem.title === 'agent' ? "Master" : subItem.title === 'client' ? "User" : subItem.title === 'master' ? "Master Admin" : subItem.title}

                              </p>
                            </NavLink>
                          </li>
                        ))}
                          {/* <li className={`nav-item border-bottom`} >
                            <NavLink
                              
                              className={({ isActive }) => `nav-link sidebar-hover `}
                              onClick={()=>{closeSidebarOnMobile();handleSubMenuToggle(item.title) }}
                            >
                              <i class="fa fa-times nav-icon text-md"/>                      
                              <p>close</p>
                            </NavLink>
                          </li>
                       */}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
