import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { reportServices } from "../_services/_report_services";

const initialState = {
  
};


export const reportSelfStatement = createAsyncThunk(
  "reports/selfStatementCrick",
  async (reportData, { rejectWithValue }) => {
    try {
      const reportsselfStatementData = await reportServices.reportSelfStatement(reportData);
      return reportsselfStatementData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const reportBlockMarket = createAsyncThunk(
  "reports/blockMarket",
  async (reportData, { rejectWithValue }) => {
    try {
      const reportsBlockMarketData = await reportServices.reportBlockMarket(reportData);
      return reportsBlockMarketData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const reportMyCase = createAsyncThunk(
    "reports/myCash",
    async (reportData, { rejectWithValue }) => {
      try {
        const reportsMyCaseData = await reportServices.reportMyCase(reportData);
        return reportsMyCaseData;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const reportUserProfitLoss = createAsyncThunk(
    "reports/userProfitLoss",
    async (reportData, { rejectWithValue }) => {
      try {
        const reportsMyCaseData = await reportServices.reportUserProfitLoss(reportData);
        return reportsMyCaseData;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const reportUserGroupByBetsForEventId = createAsyncThunk(
    "reports/groupByBetsForEventId",
    async (reportData, { rejectWithValue }) => {
      try {
        const reportsMyCaseData = await reportServices.reportUserGroupByBetsForEventId(reportData);
        return reportsMyCaseData;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const getDepositWithdrwalList = createAsyncThunk(
    "reports/getDepositWithdrwalList",
    async (reportData, { rejectWithValue }) => {
      try {
        const response = await reportServices.depositWithdrwalList(reportData);
        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  export const getWebsiteView = createAsyncThunk(
    "reports/getWebsiteView",
    async (reportData, { rejectWithValue }) => {
      try {
        const response = await reportServices.websiteView(reportData);
        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  export const getapproveDisapproveReq = createAsyncThunk(
    "reports/getapproveDisapproveReq",
    async (reportData, { rejectWithValue }) => {
      try {
        const response = await reportServices.approveDisapprove(reportData);
        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

  export const reportsUserWiseBlockMarketList = createAsyncThunk(
    "reports/reportsUserWiseBlockMarketList",
    async (reportData, { rejectWithValue }) => {
      try {
        const response = await reportServices.reportsUserWiseBlockMarketList(reportData);
        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );


  export const depositWithdrawTotal = createAsyncThunk(
    "/daman/depositWithdrawTotal",
    async (reportData, { rejectWithValue }) => {
      try {
        const response = await reportServices.depositWithdrawTotal(reportData);
        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );



const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(reportSelfStatement.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportSelfStatement.fulfilled, (state, action) => {
        state.loading = false;
        state.reportSelfStatementData = action.payload;
      })
      .addCase(reportSelfStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // report myCase

      .addCase(reportMyCase.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportMyCase.fulfilled, (state, action) => {
        state.loading = false;
        state.reportMyCaseData = action.payload;
      })
      .addCase(reportMyCase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // report profit loss data

      .addCase(reportUserProfitLoss.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportUserProfitLoss.fulfilled, (state, action) => {
        state.loading = false;
        state.reportUserProfitLossData = action.payload;
      })
      .addCase(reportUserProfitLoss.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDepositWithdrwalList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepositWithdrwalList.fulfilled, (state, action) => {
        state.loading = false;
        state.depositWithdrwalListData = action.payload;
      })
      .addCase(getDepositWithdrwalList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getWebsiteView.pending, (state) => {
        state.loadingwebview = true;
      })
      .addCase(getWebsiteView.fulfilled, (state, action) => {
        state.loadingwebview = false;
        state.websiteViewData = action.payload;
      })
      .addCase(getWebsiteView.rejected, (state, action) => {
        state.loadingwebview = false;
        state.error = action.payload;
      })
      .addCase(getapproveDisapproveReq.pending, (state) => {
        state.loading = true;
      })
      .addCase(getapproveDisapproveReq.fulfilled, (state, action) => {
        state.loading = false;
        state.approveDisapproveData = action.payload;
      })
      .addCase(getapproveDisapproveReq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // report match block

      .addCase(reportBlockMarket.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportBlockMarket.fulfilled, (state, action) => {
        state.loading = false;
        state.reportBlockMarketData = action.payload;
      })
      .addCase(reportBlockMarket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // report reportsUserWiseBlockMarketList

      .addCase(reportsUserWiseBlockMarketList.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportsUserWiseBlockMarketList.fulfilled, (state, action) => {
        state.loading = false;
        state.reportsUserWiseBlockMarketListData = action.payload;
      })
      .addCase(reportsUserWiseBlockMarketList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // report deposit withdrwal Total

      .addCase(depositWithdrawTotal.pending, (state) => {
        state.loading = true;
      })
      .addCase(depositWithdrawTotal.fulfilled, (state, action) => {
        state.loading = false;
        state.depositWithdrawTotalData = action.payload;
      })
      .addCase(depositWithdrawTotal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // report user group by event id

      .addCase(reportUserGroupByBetsForEventId.pending, (state) => {
        state.loading = true;
      })
      .addCase(reportUserGroupByBetsForEventId.fulfilled, (state, action) => {
        state.loading = false;
        state.reportUserGroupByBetsForEventIdData = action.payload;
      })
      .addCase(reportUserGroupByBetsForEventId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
  },
});



export default reportSlice.reducer;
