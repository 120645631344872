import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/_reducers/_auth_reducers";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { login_loading } = useSelector((state) => state.authentication)

  useEffect(() => {
    const login = localStorage.getItem("token");
    if (login) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset the error message
    setErrorMessage("");

    // Validate inputs
    if (!username) {
      setErrorMessage("Please enter username.");
      return;
    }

    if (!password) {
      setErrorMessage("Please enter password.");
      return;
    }

    const reqData = {
      username: username,
      password: password,
      isClient: false,
      host: window.location.host
    };

    dispatch(login(reqData)).then((response) => {
      if (response && response?.payload?.userinfo) {
        setUsername("");
        setPassword("");
        navigate("/admin/dashboard");
      } else {
        // Handle other possible errors from login
        setErrorMessage("Login failed. Please check your credentials.");
      }
    });
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center h-dvh"
      style={{
        backgroundImage:
          "url('/assest/images/login-bg.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="p-4 shadow w-100"
        style={{
          maxWidth: "400px",

          backgroundColor: "rgba(0,0,0, 0.7)",
        }}
      >
        <div className="flex justify-center mb-2">
          <img
            src="/assest/images/logo.png"
            alt="Cric Sports News"
            style={{ maxWidth: "200px" }}
          />
        </div>

        <div style={{ height: "15px" }} className="mb-2 text-danger text-center" >
          {errorMessage ? errorMessage : ""}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label font-weight-normal text-white text-[12px]">
              USER NAME
            </label>
            <input
              type="text"
              style={{ color: "black" }}
              className="form-control bg-white  text-[14px]"
              id="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label font-weight-normal text-white text-[12px] text-uppercase">
              PASSWORD
            </label>
            <input
              style={{ color: "black" }}
              type="password"
              className="form-control bg-white  text-[14px]"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label className="form-check-label font-weight-normal text-[14px] text-white" htmlFor="rememberMe">
              Remember me
            </label>
          </div>
          <button style={{ backgroundColor: "#116c50" }} class="btn py-2 text-light w-100 font-weight-bold" type="submit" >
            {!login_loading ? (
              <span>LOGIN</span>
            ) : (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">

                </span><span> LOGGING IN</span></>
            )}

          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
