export const gameList = [


    {
        key: '1',
        gameName: 'AmarAkbarAnthony',
        gameId: 3056
    },
    {
        key: '2',
        gameName: 'Live Teen Patti One Day',
        gameId: 3031
    },
    {
        key: '3',
        gameName: 'DragonTiger',
        gameId: 3035
    },
    {
        key: '4',
        gameName: 'DragonTiger T20',
        
        gameId: 3059
    },
    {
        key: '5',
        gameName: 'luck7b',
        gameId: 3032
    },
    {
        key: '6',
        gameName: 'TeenpattiT20',
        gameId: 3030
    },
    {
        key: '7',
        gameName: 'Warli Matka',
        gameId: 3054
    },
]