import { Autocomplete, Button, Menu, MenuItem, TextField } from "@mui/material";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import Switch from "../../global_layouts/Switch/Switch";
import { RiUser3Fill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { getUserList } from "../../redux/_reducers/_user_reducers";
import { useDispatch, useSelector } from "react-redux";
import TableNoDataFound from "../../global_layouts/TableNoDataFound/TableNoDataFound";

function ClosedAccounts() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch()
  const { userListData } = useSelector(
    (state) => state.user
  );
  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);

  };

  const handleClose = () => {
    setAnchorEl(null);
;
  };

  const fetchUserList = () => {
    let reqData = {
  
      // isDeleted: true,
      status: 0
    };

    dispatch(getUserList(reqData));
  };

  useEffect(()=>{
fetchUserList()
  },[])


  return (
    <GlobalLayout>
        <div className="flex lg:flex-row flex-col my-1 justify-between">
          <div className="flex items-center justify-start gap-2">
            <RiUser3Fill size={25} className=" text-gray-500" />
            <div className="text-lg font-bold capitalize text-gray-500">
              Closed Acoount
            </div>
          </div>
          {/* <div className="flex text-nowrap flex-wrap gap-2">
            <Button variant="contained" color="error">
              + Add Direct User
            </Button>
           
          </div> */}
        </div>
        <div className="mt-3">
           <div className="grid grid-cols-2 tex-xs md:text-sm sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 my-1">
        <TextField
          className="w-full"
          id="outlined-basic"
          label="Search"
          variant="outlined"
          size="small"
        />

        <Autocomplete
          disablePortal
          options={["YES", "NO"]}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="User Locked" />
          )}
        />
       
        <div className="flex gap-2 ">
          <Button className="w-full " variant="contained">
            Search
          </Button>
          <Button className="w-full" variant="outlined">
            Refresh
          </Button>
        </div>
      </div> 
        </div>
      <div className="my-3">
        <div className="max-w-100 overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="bg-gray-100">
              <tr className="text-uppercase">
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">ID</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">User Name</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Liability</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Balance</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Profit Loss</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Freechips</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Casino Locked</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Betting Locked</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">User Locked</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Closed Account</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Created At</th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>

              {userListData?.list ? userListData?.list?.map((user) => (
                <tr key={user.id} className="bg-white hover:bg-gray-100 text-sm font-light">
                  <td className="px-2 py-2 border border-gray-300 font-semibold">{user.username}</td>
                  <td className="px-2 py-2 border border-gray-300 font-semibold">{user.name}</td>
                  <td className={`px-2 py-2 border border-gray-300 font-semibold ${user.exposure < 0 ? "text-red-800" : "text-green-800"}`}>
                    {(Math.floor(Number(user.exposure) * 100) / 100).toFixed(2)}
                  </td>
                  <td className="px-2 py-2 border border-gray-300 font-semibold">
                    {(Math.floor(Number(user.coins) * 100) / 100).toFixed(2)}
                  </td>
                  <td className={`px-2 py-2 border border-gray-300 font-semibold ${user.totalProfitLoss < 0 ? "text-red-800" : "text-green-800"}`}>
                    {(Math.floor(Number(user.totalProfitLoss) * 100) / 100).toFixed(2)}
                  </td>
                  <td className="px-2 py-2 border border-gray-300 font-semibold">
                    {(Math.floor(Number(user.coins) * 100) / 100).toFixed(2)}
                  </td>
                  <td className="px-2 py-2 border border-gray-300 font-semibold">
                 
                    <Switch   activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                         
                          // text="Self Lock" 
                          checked={user.casinoStatus} size="small" />
                    {/* <Switch      activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          text="Praent Lock" checked={user.casinoStatus} size="small" /> */}
                 
                  </td>
                  <td className="px-2 py-2 border border-gray-300 font-semibold">
                 
                    <Switch   activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                        
                          // text="Self Lock" 
                          checked={user.betStatus} size="small" />
                    {/* <Switch      activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          text="Praent Lock" checked={user.betStatus} size="small" /> */}
                   
                  </td>
                  <td className="px-2 py-2 border border-gray-300 font-semibold">
               
                    <Switch  activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                         
                          // text="Self Lock" 
                          checked={user.status} size="small" />
                    {/* <Switch      activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          text="Praent Lock" checked={user.status} size="small" />
                   */}
                  </td>
                  <td className="px-2 py-2 border border-gray-300 font-semibold">
                 
                    <Switch  activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                         
                          // text="Self Lock" 
                          checked={user.isDeleted} size="small" />
                    {/* <Switch      activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          text="Praent Lock" checked={user.isDeleted} size="small" />
                   */}
                  </td>
                  <td className="px-2 py-2 border text-nowrap border-gray-300 font-semibold">
                    {user.createdAt}
                  </td>
                  <td className="px-2 py-2 border border-gray-300 font-semibold">
                  <button
                          onClick={(event) => handleClick(event, user)}
                          className="text-white bg-[#2ecc71] font-medium text-nowrap rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                          type="button"
                        >
                          View More{" "}
                          <svg
                            className="w-2.5 h-2.5 ms-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          className="border"
                        >
                   
                          <MenuItem onClick={handleClose}>Edit User</MenuItem>
                          <MenuItem onClick={handleClose}>Change Password</MenuItem>
                          <MenuItem onClick={handleClose}>Chips Deposit / Withdrawal</MenuItem>
                          {/* <MenuItem onClick={handleClose}>Setting</MenuItem> */}
                          <MenuItem onClick={handleClose}>Acccount Information</MenuItem>
                   
                          {/* <MenuItem onClick={handleClose}>Delete A/c</MenuItem> */}
                        </Menu>
                  </td>
                 
                </tr>
              ))
              :
              <TableNoDataFound size={12} />
              }
            </tbody>
          </table>
        </div>
      </div>
    </GlobalLayout>
  );
}

export default ClosedAccounts;

