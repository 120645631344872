import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { httpPatch, httpPost, httpPostFormData } from "../../_helpers/http";
// import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import { toast } from 'react-toastify';
// import ToggleButton from './component/ToggleButton';
import { SketchPicker } from 'react-color';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import { httpPost, httpPostFormData } from '../../config/http2';
import GlobalLayout from '../../global_layouts/GlobalLayout/GlobalLayout';
import { domainName } from '../../constents/global';
import ToggleButton from '../../component/toggleButton/ToggleButton';
import { FaTrophy } from 'react-icons/fa';
// import MultipleFileUpload from './component/MultipleFileUpload';


class AccountUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldsWebsiteUpdate: {},
            sportsSetting: {},
            themeSetting: {},
            socialMedia: {},
            fieldsWebsiteError: {},
            commissionTypeOption: "",
            userMetaDetails: {},
            parentUserDetails: {},
            matchDetails: {},
            socketPerm: "",
            socketUrl: "",
            scoreIframe: "",
            tvUrl: "",
            tvId: "",
            matchDetailsForSocket: {},
            scoreHeight: "130",
            inplayMatchList: {},
            scoreScreen: false,
            isTvScreen: false,
            cacheUrl: "",
            matchScoreDetails: {},
            isTvScreen: false,
            positionData: {},
            fancyData: {},
            fancyBetList: {},
            oddBetList: {},
            subownerList: {},
            agentList: {},
            displayBackGround: false,
            displayLightTheme: false,
            displayDarkTheme: false,
            selectedImage: null,
            banners: [],
            account: {},
            upi: {},
            barcode: {},
            paytm: {},
            phonePay: {},
            googlePay: {},
            bhimUpi: {},
            updateWebsiteResData: {}
        }
        this.modules = {
            toolbar: [
                [{ font: [] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "image"],
                [{ list: "ordered" }, { list: "bullet" }],
                // [{ align: [] }],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                [{ color: [] }, { background: [] }],
                ["clean"]
            ],

        };

        this.formats = [
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "align",
            "color",
            "link",
            "image",
            "imageBlot",
            "background"
        ];
    }

    componentDidMount() {
        // this.getUserDetails();
        this.getMatchDataByMarketIdWise();
        // this.getAgentList();

        // this.updateAccountSetting()



    }

    // updateAccountSetting = async () => {
    //     let updateWebsiteRes = await httpPost('website/getBankDetailsByUserId');
    //     if (updateWebsiteRes?.data) {

    //         this.setState({ updateWebsiteResData: updateWebsiteRes?.data })
    //     }
    // }





    // getMatchDataByMarketIdWise = async () => {

    //     let reqData = {
    //         "subownerId": JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.userId
    //     }
    //     // test
    //     this.setState({ isFetch: true })
    //     let websiteDetails = await httpPost("website/getBankDetailsByUserId");
    //     if (websiteDetails?.data) {
    //         // this.setState({ fieldsWebsiteUpdate: { ...websiteDetails.data, ...this.state.fieldsWebsiteUpdate } })
    //         // this.setState({
    //         //     banners: !websiteDetails.data.banner ? [{ name: "", priority: "", image: "" }] : websiteDetails.data.banner,
    //         // });
    //         // this.setState({
    //         //     sportsSetting: !websiteDetails.data.sportsSetting ? this.state.sportsSetting : websiteDetails.data.sportsSetting,
    //         // });
    //         // this.setState({
    //         //     themeSetting: !websiteDetails.data.themeSetting ? this.state.themeSetting : websiteDetails.data.themeSetting,
    //         // });
    //         // this.setState({
    //         //     socialMedia: !websiteDetails.data.socialMedia ? this.state.socialMedia : websiteDetails.data.socialMedia,
    //         // });
    //         this.setState({
    //             account: !websiteDetails.data.account ? this.state.account : websiteDetails.data.account,
    //         });
    //         // this.setState({
    //         //     barcode: !websiteDetails.data.barcode ? this.state.barcode : websiteDetails.data.barcode,
    //         // });
    //     }

    //     if (websiteDetails.data && websiteDetails.data.upi) {
    //         this.setState({
    //             paytm: !websiteDetails.data.upi.paytm ? this.state.paytm : websiteDetails.data.upi.paytm
    //         });
    //         this.setState({
    //             phonePay: !websiteDetails.data.upi.phonePay ? this.state.phonePay : websiteDetails.data.upi.phonePay,
    //         });
    //         this.setState({
    //             googlePay: !websiteDetails.data.upi.googlePay ? this.state.googlePay : websiteDetails.data.upi.googlePay,
    //         });
    //         this.setState({
    //             bhimUpi: !websiteDetails.data.upi.bhimUpi ? this.state.bhimUpi : websiteDetails.data.upi.bhimUpi,
    //         });
    //     }
    //     this.setState({ isFetch: false })
    // }
    getMatchDataByMarketIdWise = async () => {

        let reqData = {
            "subownerId": JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.userId
        }
        // test
        this.setState({ isFetch: true })
        let websiteDetails = await httpPost("website/getBankDetailsByUserId");
        if (websiteDetails?.data) {
            // this.setState({ fieldsWebsiteUpdate: { ...websiteDetails.data, ...this.state.fieldsWebsiteUpdate } })
            // this.setState({
            //     banners: !websiteDetails.data.banner ? [{ name: "", priority: "", image: "" }] : websiteDetails.data.banner,
            // });
            // this.setState({
            //     sportsSetting: !websiteDetails.data.sportsSetting ? this.state.sportsSetting : websiteDetails.data.sportsSetting,
            // });
            // this.setState({
            //     themeSetting: !websiteDetails.data.themeSetting ? this.state.themeSetting : websiteDetails.data.themeSetting,
            // });
            // this.setState({
            //     socialMedia: !websiteDetails.data.socialMedia ? this.state.socialMedia : websiteDetails.data.socialMedia,
            // });
            this.setState({
                account: !websiteDetails?.data?.account ? this?.state?.account : websiteDetails?.data?.account,
            });
            // this.setState({
            //     barcode: !websiteDetails.data.barcode ? this.state.barcode : websiteDetails.data.barcode,
            // });
        }

        if (websiteDetails?.data && websiteDetails?.data?.upi) {
            this.setState({
                paytm: !websiteDetails?.data?.upi?.paytm ? this?.state?.paytm : websiteDetails?.data?.upi?.paytm
            });
            this.setState({
                phonePay: !websiteDetails?.data?.upi?.phonePay ? this?.state?.phonePay : websiteDetails?.data?.upi?.phonePay,
            });
            this.setState({
                googlePay: !websiteDetails?.data?.upi?.googlePay ? this?.state?.googlePay : websiteDetails?.data?.upi?.googlePay,
            });
            this.setState({
                bhimUpi: !websiteDetails?.data?.upi?.bhimUpi ? this?.state?.bhimUpi : websiteDetails?.data?.upi?.bhimUpi,
            });
        }
        this.setState({ isFetch: false })
    }

    getAgentList = async () => {
        let reqData = {
            "specific": {
                username: 1,
                userId: 1,
                name: 1
            },
            "status": 1,
            "parentUserType": "subowner",
            "parentId": JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.userId,
            "downlineUserType": "agent"
        }
        let agentList = await httpPost('user/userList', reqData);

        if (agentList) {
            this.setState({ agentList: agentList && agentList?.data && agentList?.data?.list ? agentList?.data?.list : {} });
        }
    }

    handleInput = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        fieldsWebsiteUpdate[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ fieldsWebsiteUpdate, fieldsWebsiteError });
    }

    handleInputSocialMedia = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let socialMedia = this.state.socialMedia;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        socialMedia[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ socialMedia, fieldsWebsiteError });
    }

    handleInputPaytm = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let paytm = this.state.paytm;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        paytm[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ paytm, fieldsWebsiteError });
    }

    handleInputPhonePay = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let phonePay = this.state.phonePay;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        phonePay[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ phonePay, fieldsWebsiteError });
    }

    handleInputGooglePay = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let googlePay = this.state.googlePay;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        googlePay[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ googlePay, fieldsWebsiteError });
    }

    handleInputBhimUpi = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let bhimUpi = this.state.bhimUpi;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        bhimUpi[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ bhimUpi, fieldsWebsiteError });
    }

    handleInputBarcode = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let barcode = this.state.barcode;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        barcode[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ barcode, fieldsWebsiteError });
    }

    handleInputAccountDetails = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let account = this.state.account;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        account[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ account, fieldsWebsiteError });
    }

    handleInputThemeSetting = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let themeSetting = this.state.themeSetting;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        themeSetting[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ themeSetting, fieldsWebsiteError });
    }

    handleChangeToggle = (e) => {
        e.preventDefault();
        let { id, value } = e.target;
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        fieldsWebsiteUpdate[id] = !fieldsWebsiteUpdate[id]
        this.setState({ fieldsWebsiteUpdate })
    }

    handleChangeSportsSettingToggle = (e) => {
        e.preventDefault();
        let { id, value } = e.target;
        let sportsSetting = this.state.sportsSetting;
        sportsSetting[id] = !sportsSetting[id]
        this.setState({ sportsSetting })
    }

    handleChangeDeleted = () => {
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        fieldsWebsiteUpdate["isDeleted"] = !fieldsWebsiteUpdate["isDeleted"]
        this.setState({ fieldsWebsiteUpdate })
    }

    handleSelectAgent = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        fieldsWebsiteUpdate[name] = value;
        fieldsWebsiteError[""] = "";
        this.setState({ fieldsWebsiteUpdate, fieldsWebsiteError });
    }

    handleUpdateSubmit = async () => {
        if (this.handleValidationWebsiteUpdate()) {
            let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
            let reqData = {
                // "subownerId": JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.userId,
                // "domainName": fieldsWebsiteUpdate.domainName,
                // "domainUrl": fieldsWebsiteUpdate.domainUrl,
                // "userNotification": fieldsWebsiteUpdate.userNotification,
                // "clientNotification": fieldsWebsiteUpdate.clientNotification,
                // // "logo": fieldsWebsiteUpdate.logo,
                // // "favicon": fieldsWebsiteUpdate.favicon,
                // // "title": fieldsWebsiteUpdate.title,
                // "whatsappNumber": fieldsWebsiteUpdate.whatsappNumber,
                // "helplineNumber": fieldsWebsiteUpdate.helplineNumber,
                // // "assignSubownerId": fieldsWebsiteUpdate.assignSubownerId,
                // "assignAgentId": fieldsWebsiteUpdate.assignAgentId,
                // "registerBonus": fieldsWebsiteUpdate.registerBonus,
                // "status": fieldsWebsiteUpdate.status,
                // "isRegister": fieldsWebsiteUpdate.isRegister,
                // "isAffilation": fieldsWebsiteUpdate.isAffilation,
                // "talkTo": fieldsWebsiteUpdate.talkTo,
                // "isDeleted": fieldsWebsiteUpdate.isDeleted,
                // "aboutUs": fieldsWebsiteUpdate.aboutUs,
                // "contactUs": fieldsWebsiteUpdate.contactUs,
                // "themeSetting": this.state.themeSetting,
                // "sportsSetting": this.state.sportsSetting,
                // "socialMedia": this.state.socialMedia,
                // "apiKey": {
                //     "talkTo": fieldsWebsiteUpdate.talkTo,
                // },
                // "banner": this.state.banners,
                "account": this.state.account,
                // "barcode": this.state.barcode,
                "upi": {
                    "paytm": this.state.paytm,
                    "googlePay": this.state.googlePay,
                    "phonePay": this.state.phonePay,
                    "bhimUpi": this.state.bhimUpi,
                },
            }
            this.setState({ isFetch: true })
            let updateWebsiteRes = await httpPost('website/updateAccountDetails', reqData);
            if (updateWebsiteRes) {
                toast.success(updateWebsiteRes?.message);
                // window.location.reload()
            }
            this.setState({ isFetch: false })
        }
    }
    handleValidationWebsiteUpdate = () => {
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        let errorsWebsiteUpdate = {};
        let formIsValid = true;

        // //title
        // if (!fieldsWebsiteUpdate["title"] || fieldsWebsiteUpdate["title"] === "") {
        //     formIsValid = false;
        //     errorsWebsiteUpdate["title"] = "Cannot be empty";
        // }

        this.setState({ errorsWebsiteUpdate: errorsWebsiteUpdate });
        return formIsValid;
    }

    handleClickLightTheme = () => {
        this.setState({ displayLightTheme: !this.state.displayLightTheme });
    };
    handleClickDarkTheme = () => {
        this.setState({ displayDarkTheme: !this.state.displayDarkTheme });
    };
    handleClickBackGround = () => {
        this.setState({ displayBackGround: !this.state.displayBackGround });
    };

    handleClose = () => {
        this.setState({
            displayLightTheme: false,
            displayBackGround: false,
            displayDarkTheme: false
        });
    };

    // handleChangeLightTheme = (color) => {
    //     let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
    //     let fieldsWebsiteError = this.state.fieldsWebsiteError;
    //     fieldsWebsiteUpdate.themeSetting["lightThemeColor"] = color.hex;
    //     fieldsWebsiteError["lightThemeColor"] = "";
    //     this.setState({ fieldsWebsiteUpdate, fieldsWebsiteError });
    // };

    handleChangeLightTheme = (color) => {
        let themeSetting = this.state.themeSetting;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        themeSetting["lightThemeColor"] = color.hex;
        fieldsWebsiteError["lightThemeColor"] = "";
        this.setState({ themeSetting, fieldsWebsiteError });
    };

    handleChangeDarkTheme = (color) => {
        let themeSetting = this.state.themeSetting;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        themeSetting["darkThemeColor"] = color.hex;
        fieldsWebsiteError["darkThemeColor"] = "";
        this.setState({ themeSetting, fieldsWebsiteError });
    };

    handleChangeBackGround = (color) => {
        let themeSetting = this.state.themeSetting;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        themeSetting["backgroundColor"] = color.hex;
        fieldsWebsiteError["backgroundColor"] = "";
        this.setState({ themeSetting, fieldsWebsiteError });
    };


    rteChangeAboutUsUpdate = (content, delta, source, editor) => {
        let themeSetting = this.state.themeSetting;
        themeSetting["aboutUs"] = editor.getHTML();
        this.setState({ themeSetting });
    };

    rteChangeAboutUsTextUpdate = (e) => {
        e.preventDefault();
        let { value } = e.target;
        let themeSetting = this.state.themeSetting;
        themeSetting["aboutUs"] = value;
        this.setState({ themeSetting });
    };

    rteChangeContactUsUpdate = (content, delta, source, editor) => {
        let themeSetting = this.state.themeSetting;
        themeSetting["contactUs"] = editor.getHTML();
        this.setState({ themeSetting });
    };

    rteChangeContactUsTextUpdate = (e) => {
        e.preventDefault();
        let { value } = e.target;
        let themeSetting = this.state.themeSetting;
        themeSetting["contactUs"] = value;
        this.setState({ themeSetting });
    };

    handleFile = async (e) => {
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                fieldsWebsiteUpdate[name] = fileData.data.imageName;
                this.setState({ fieldsWebsiteUpdate });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedBanners = [...this.state.banners];
        updatedBanners[index][name] = value;

        this.setState({
            banners: updatedBanners,
        });
    };

    handleAddBanner = () => {
        this.setState((prevState) => ({
            banners: [...prevState.banners, { name: '', priority: '', image: null }],
        }));
    };

    handleClearBanner = () => {
        this.setState(() => ({
            banners: [{ name: '', priority: '', image: null }],
        }));
    };

    handleRemoveBanner = (index) => {
        this.setState((prevState) => {
            const updatedBanners = [...prevState.banners];
            updatedBanners.splice(index, 1);
            return { banners: updatedBanners };
        });
    };

    handleImageChange = async (e, index) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const updatedBanners = this.state.banners;
                updatedBanners[index].image = `${this.state.fieldsWebsiteUpdate.imgBaseUrl}/${fileData.data.imageName}`;
                this.setState({ banners: updatedBanners, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handleBarcodeImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const barcode = this.state.barcode;
                barcode.image = `${this.state.fieldsWebsiteUpdate.imgBaseUrl}/${fileData.data.imageName}`;
                this.setState({ barcode: barcode, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handlePaytmImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const paytm = this.state.paytm;
               
                // /${fileData.data.imageName}
                paytm.image = `${fileData?.data?.imageUrl}`;
                this.setState({ paytm: paytm, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handlePhonePayImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const phonePay = this.state.phonePay;
                phonePay.image = `${fileData?.data?.imageUrl}`;
                this.setState({ phonePay: phonePay, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handleGooglePayImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const googlePay = this.state.googlePay;
                googlePay.image = `${fileData?.data?.imageUrl}`;
                this.setState({ googlePay: googlePay, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handleBhimUpiImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const bhimUpi = this.state.bhimUpi;
                bhimUpi.image = `${fileData?.data?.imageUrl}`;
                this.setState({ bhimUpi: bhimUpi, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    render() {



        let { fieldsWebsiteUpdate, fieldsWebsiteError, isFetch, agentList, banners, themeSetting, sportsSetting, socialMedia, account, barcode, paytm, phonePay, googlePay, bhimUpi, updateWebsiteResData } = this.state;

        return (

            <GlobalLayout icons={<FaTrophy />} title="Account Setting">


                <div className="flex flex-col flex-1 overflow-y-auto h-full">
                    <main className="relative flex-1">
                        <div className=" lg:px-1 sm:p-5 w-full">
                            <div className="mx-auto max-w-screen-3xl md:py-3 px-2">
                                <section className="flex-col col-span-6 bg-white">
                                    {/* <PageTitleBar title={`Update Website`} history={this.props.history} /> */}
                                    <div className='w-full py-2 px-3'>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left whitespace-nowrap capitalize">Account Holder Name :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="acHolderName"
                                                        value={account && account["acHolderName"] ? account["acHolderName"] : ""}
                                                        onChange={this.handleInputAccountDetails} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["acHolderName"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["acHolderName"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Account No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="accountNumber"
                                                        value={account && account["accountNumber"] ? account["accountNumber"] : ""}
                                                        onChange={this.handleInputAccountDetails} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["accountNumber"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["accountNumber"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Branch Name :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="branchName"
                                                        value={account && account["branchName"] ? account["branchName"] : ""}
                                                        onChange={this.handleInputAccountDetails} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["branchName"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["branchName"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">IFSC Code :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="ifscCode"
                                                        value={account && account["ifscCode"] ? account["ifscCode"] : ""}
                                                        onChange={this.handleInputAccountDetails} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["ifscCode"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["ifscCode"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-3xl text-black/80 capitalize leading-4'>UPI</h2>
                                        </div>
                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-xl text-center text-black/80 capitalize leading-4'>Paytm UPI</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handlePaytmImageChange(event)}
                                                    />
                                                   
                                                 {paytm && paytm["image"] && (<img src={paytm && paytm["image"]} alt='barcode' className='h-24 w-24' />)}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">UPI Id :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="upiId"
                                                        value={paytm && paytm["upiId"] ? paytm["upiId"] : ""}
                                                        onChange={this.handleInputPaytm} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["upiId"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["upiId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Mobile No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="mobNo"
                                                        value={paytm && paytm["mobNo"] ? paytm["mobNo"] : ""}
                                                        onChange={this.handleInputPaytm} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["mobNo"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["mobNo"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-xl text-center text-black/80 capitalize leading-4'>PhonePay UPI</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handlePhonePayImageChange(event)}
                                                    />
                                                    {phonePay && phonePay["image"] && (<img src={phonePay && phonePay["image"]} alt='barcode' className='h-24 w-24' />)}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">UPI Id :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="upiId"
                                                        value={phonePay && phonePay["upiId"] ? phonePay["upiId"] : ""}
                                                        onChange={this.handleInputPhonePay} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["upiId"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["upiId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Mobile No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="mobNo"
                                                        value={phonePay && phonePay["mobNo"] ? phonePay["mobNo"] : ""}
                                                        onChange={this.handleInputPhonePay} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["mobNo"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["mobNo"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-xl text-center text-black/80 capitalize leading-4'>GooglePay UPI</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handleGooglePayImageChange(event)}
                                                    />
                                                    {googlePay && googlePay["image"] && (<img src={googlePay && googlePay["image"]} alt='barcode' className='h-24 w-24' />)}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">UPI Id :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="upiId"
                                                        value={googlePay && googlePay["upiId"] ? googlePay["upiId"] : ""}
                                                        onChange={this.handleInputGooglePay} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["upiId"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["upiId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Mobile No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="mobNo"
                                                        value={googlePay && googlePay["mobNo"] ? googlePay["mobNo"] : ""}
                                                        onChange={this.handleInputGooglePay} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["mobNo"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["mobNo"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-xl text-center text-black/80 capitalize leading-4'>Bhim UPI</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handleBhimUpiImageChange(event)}
                                                    />
                                                    {bhimUpi && bhimUpi["image"] && (<img src={bhimUpi && bhimUpi["image"]} alt='barcode' className='h-24 w-24' />)}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">UPI Id :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="upiId"
                                                        value={bhimUpi && bhimUpi["upiId"] ? bhimUpi["upiId"] : ""}
                                                        onChange={this.handleInputBhimUpi} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["upiId"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["upiId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Mobile No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="mobNo"
                                                        value={bhimUpi && bhimUpi["mobNo"] ? bhimUpi["mobNo"] : ""}
                                                        onChange={this.handleInputBhimUpi} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["mobNo"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["mobNo"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex justify-end pt-3 pb-4 space-x-5'>
                                            <button className="rounded-none cursor-pointer btn btn-type bg-green-500 text-white font-bold" type="button" onClick={this.handleUpdateSubmit}>Submit</button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </div>
            </GlobalLayout>
        );
    }
}
function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}
export default connect(mapStateToProps)(AccountUpdate);
