import { useDispatch, useSelector } from "react-redux";
import { domainName } from "../../../constents/global";
import Drawer from "../../../global_layouts/Drawer/Drawer";
import { useEffect, useState } from "react";
import { userUpdate } from "../../../redux/_reducers/_user_reducers";


const UserListChangePassword = (props) => {
  const [userInfo, setUserInfo] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const { isDrawerOpen, setIsDrawerOpen, propsData } = props
  const isOpen = isDrawerOpen.changePasswordDrawer;
  const dispatch = useDispatch()
  const { updateUserList } = useSelector((state) => state.user);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const changePassword = (e) => {
    e.preventDefault();
    const {newPassword, confirmPassword } = userInfo;
    let validationErrors = { oldPassword: '', newPassword: '', confirmPassword: '' };
    let hasError = false;
    if (!confirmPassword) {
      validationErrors.confirmPassword = 'Please confirm new password.';
      hasError = true;
    } else if (newPassword !== confirmPassword) {
      validationErrors.confirmPassword = 'New password and confirm password do not match.';
      hasError = true;
    }

    if (hasError) {
      setErrors(validationErrors);
      return; // Do not submit if there are errors
    }

    let reqData = {
      password: newPassword,
      isTransaction: true,
      userId: props?.propsData?.chgPassProps?.userId,
    };

    dispatch(userUpdate(reqData));

  };
  useEffect(() => {
    if (updateUserList === true) {
      setIsDrawerOpen({ changePasswordDrawer: false })
      setUserInfo({newPassword: '', confirmPassword: '' });

    }
  }, [updateUserList]);
  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setIsDrawerOpen({ changePasswordDrawer: false })}
      direction="right"
      size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
      overlay={true}>
      <div class=" bg-gradient-to-t from-[#27a6c3] to-[#05537e] p-2 border-b-1.5 border-black">

        <div class="flex justify-center uppercase text-sm font-bold items-center w-100  text-white ">
          <div class="info-box-number">{`Change Password for [${propsData.chgPassProps.username}]`}</div>
        </div>
      </div>

      <form className="p-2.5" onSubmit={changePassword}>
        <div className="grid gap-6 mb-2 md:grid-cols-2">
          <div>
            <label htmlFor="newPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">new password</label>
            <input type="text" id="newPassword" name="newPassword" value={userInfo.newPassword} onChange={handleChange} className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 bg-gray-300" placeholder="new password" />
            {errors.newPassword && <span className="text-red-500 text-sm font-medium">{errors.newPassword}</span>}
          </div>
          <div>
            <label htmlFor="confirmPassowrd" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">confirm password</label>
            <input type="text" id="confirmPassowrd" name="confirmPassword" className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 bg-gray-300" placeholder="confirm passowrd" value={userInfo.confirmPassword} onChange={handleChange} />
            {errors.confirmPassword && <span className="text-red-500 text-sm font-medium">{errors.confirmPassword}</span>}
          </div>
        </div>
        <div className="flex items-start mt-3  w-full " >
          <button type="submit" className="text-white uppercase  bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none  font-normal rounded-sm text-md w-100 sm:w-auto px-5 py-1.5 text-center ">update</button>
        </div>
      </form>

    </Drawer>
  );

};

export default UserListChangePassword;
