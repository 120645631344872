import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './CustomModal.module.css';

const CustomModal = ({
  isOpen,
  onClose,
  children,
  header,
  footer,
  position = { horizontal: 'center', vertical: 'center' },
  size = { width: '50%', height: 'auto' },
}) => {

  useEffect(() => {

    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  if (!isOpen) return null;

  const horizontalClass = position.horizontal === 'left' ? styles.left :
    position.horizontal === 'right' ? styles.right : styles.centerHorizontal;

  const verticalClass = position.vertical === 'top' ? styles.top :
    position.vertical === 'bottom' ? styles.bottom : styles.centerVertical;

  const modalPositionClass = `${horizontalClass} ${verticalClass}`;

  return ReactDOM.createPortal(
    <div className={` ${styles.overlay} ${modalPositionClass} px-2`} onClick={onClose}>
      <div
        className={`${styles.container} ${styles.customSize} `}
        style={{ width: size.width, height: size.height !== 'auto' ? size.height : 'auto' }}
        onClick={(e) => e.stopPropagation()}
      >
        {header && <div>{header}</div>}
        <div className="w-full h-full overflow-auto px-2">
          {children}
        </div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>,
    document.body
  );
};

export default CustomModal;
