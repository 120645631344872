

const RangePickerComponent = ({ startDate, endDate, handleStartDateChange, handleEndDateChange }) => {
  return (
    <div className="flex items-center md:space-y-0 px-2 gap-3 ">
      <div className="flex flex-col ">
        {/* <label className="text-sm font-medium text-gray-600">Start Date</label> */}
        <input
          type="date"
          value={startDate}
          onChange={(e) => handleStartDateChange(e.target.value)}
          className="px-3 py-2 text-sm border md:w-64  border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
      </div>
      <div className="flex flex-col">
        {/* <label className="text-sm font-medium text-gray-600">End Date</label> */}
        <input
          type="date"
          value={endDate}
          onChange={(e) => handleEndDateChange(e.target.value)}
          min={startDate || undefined} 
          className="px-3 py-2  text-sm border md:w-64  border-gray-300 rounded-md text-gray-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
      </div>
    </div>
  );
};

export default RangePickerComponent;
