import { FaTrophy } from "react-icons/fa";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSportMatchBetList } from "../../redux/_reducers/_sports_reducers";
import { useParams } from "react-router-dom";
import { decrypt } from "../../config/Encryption";

const FancyHistory = () => {
    const [sportBetListData, setSportBetListData] = useState({ fancyBets: [] })
    const { sportBetList, loading } = useSelector(state => state.sport);
    const dispatch = useDispatch()
    // const { marketIdEnc } = useParams()
    // const marketId = decrypt(marketIdEnc);

    useEffect(() => {
        fetchBetList()
    }, [])
    useEffect(() => {
        if (sportBetList) {
            setSportBetListData({
                fancyBets: sportBetList?.fancyBetData
            })
        }

    }, [sportBetList]);
    const fetchBetList = () => {
        let reqData = {
            fancyBet: true,
            marketId: "1.234222899",
        }
        dispatch(getSportMatchBetList(reqData));
    };

    return (
        <GlobalLayout icons={<FaTrophy />} title="Fancy">
            <table class="min-w-full bg-white border border-gray-300">

                <thead class="bg-gray-100">
                    <tr className="text-uppercase">
                        <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">Match Name</th>
                        <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">Name</th>
                        <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">Result</th>
                        <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">Actions</th>
                    </tr>
                </thead>

                <tbody>


                    {sportBetListData && sportBetListData?.fancyBets?.length > 0 ? (
                        sportBetListData?.fancyBets.map((bet) => (
                            <tr key={bet._id} className="hover:bg-gray-100">
                                <td className="border border-gray-300 px-4 py-2">{bet?.userInfo?.clientName}</td>
                                <td className="border border-gray-300 px-4 py-2">
                                    <div><b>{bet?.sessionName}</b></div>
                                    <span>{bet?.createdAt}</span>
                                </td>
                                <td className="border border-gray-300 px-4 py-2">{bet?.decisionRun}</td>
                                <td className="border border-gray-300 px-4 py-2 space-x-2 relative">
                                   
                                        <button className="px-2 py-1 bg-red-500 text-white">Roll Back</button>
                                        <button className="px-2 py-1 bg-green-400 text-white">Position</button>
                       
                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} className="border border-gray-300 text-center">
                                No Fancy Bet List
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </GlobalLayout>
    )
}

export default FancyHistory;