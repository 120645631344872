import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getapproveDisapproveReq,
  getDepositWithdrwalList,
  getWebsiteView,
} from "../../redux/_reducers/_report_reducers";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import RangePickerComponent from "../../global_layouts/RangePickerComponent/RangePickerComponent";
import moment from "moment";
import Card from "../../global_layouts/Card/Card";
import Loader from "../../component/loader/Loader";
import TransParentLoader from "../../component/loader/TransparentLoader";
import { useNotification } from "../../global_layouts/CustomNotification/CustomNotification";
import { BsCashCoin } from "react-icons/bs";
import axios from "axios";
import { saveAs } from 'file-saver';
import { useParams } from "react-router-dom";
import { decrypt } from "../../config/Encryption";



const formatDate = (date) => date.toISOString().split("T")[0];
const today = new Date();

function Withdrawal() {

  const {  statusFnc, dateFnc } = useParams();
  const paramStatus = decrypt(statusFnc);
  const paramDate = decrypt(dateFnc);
  const [startDate, setStartDate] = useState(paramDate || formatDate(new Date(today.setDate(today.getDate() - 7))));
  const [endDate, setEndDate] = useState(paramDate || formatDate(new Date()));
  const [status, setStatus] = useState(paramStatus || "pending");
  const [modalVisible, setModalVisible] = useState(false); // Modal visibility state
  const [cancelData, setCancelData] = useState(null); // Data to be canceled
  const [remark, setRemark] = useState("");
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { depositWithdrwalListData, loadingwebview, websiteViewData, loading } =
    useSelector((state) => state.report);
  const notify = useNotification();

  const imgRef = useRef();

  const handleDownload = async (imageUrl) => {  
      if (!imageUrl) {
          console.warn('Please provide the image URL.');
          return;
      }

      try {
          const response = await axios({
              url: imageUrl,
              method: 'GET',
              responseType: 'blob',
          });

          const fileName = imageUrl.split('/').pop();
          saveAs(response.data, fileName);

          console.log(`Image downloaded successfully: ${fileName}`);
      } catch (error) {
          console.error('Error downloading the image:', error.message);
         
      }
  };


  useEffect(() => {
    let reqData = {
      status: status,
      toDate: moment(endDate).format("YYYY-MM-DD"),
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      reqType: "withdraw",
    };
    completeSportData(reqData);
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    let reqData = {
      status: status,
      toDate: moment(endDate).format("YYYY-MM-DD"),
      fromDate: moment(date).format("YYYY-MM-DD"),
      reqType: "withdraw",
    };
    completeSportData(reqData);

    if (new Date(date) > new Date(endDate)) {
      setEndDate("");
    }
  };
  const handleEndDateChange = async (date) => {
    setEndDate(date);
    let reqData = {
      status: status,
      toDate: moment(date).format("YYYY-MM-DD"),
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      reqType: "withdraw",
    };
    completeSportData(reqData);
  };
  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setStatus(selectedValue);
    let reqData = {
      status: selectedValue,
      toDate: moment(endDate).format("YYYY-MM-DD"),
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      reqType: "withdraw",
    };
    completeSportData(reqData);
  };
  const completeSportData = async (reqData) => {
    try {
      dispatch(getDepositWithdrwalList(reqData));
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  const handalAccept = (data) => {
    let reqData = {
      reqId: data?._id,
      status: "accept",
      depositType: "automatic",
    };
    dispatch(getapproveDisapproveReq(reqData)).then((data) => {
      if (!data.error) {
        // Only execute this if there's no error
        const reqData = {
          status: status,
          toDate: moment(endDate).format("YYYY-MM-DD"),
          fromDate: moment(startDate).format("YYYY-MM-DD"),
          reqType: "withdraw",
        };
        completeSportData(reqData);

        setModalVisible(false);
      }
    });
  };
  const handalViewWebsite = (data) => {
    setViewModalOpen(true);
    let reqData = {
      reqId: data?._id,
    };
    dispatch(getWebsiteView(reqData));
  };
  const handleCancelClick = (data) => {
    setCancelData(data);
    setModalVisible(true);
    setRemark("");
  };
  const handleModalOk = () => {
    if (!remark.trim()) {
      notify("Please write a remark.", "error");
      return;
    }

    let reqData = {
      reqId: cancelData?._id,
      status: "cancel",
      depositType: "automatic",
      remark: remark,
      canceledRemark: remark,
    };
    dispatch(getapproveDisapproveReq(reqData)).then((data) => {
      if (!data.error) {
        // Only execute this if there's no error
        const reqData = {
          status: status,
          toDate: moment(endDate).format("YYYY-MM-DD"),
          fromDate: moment(startDate).format("YYYY-MM-DD"),
          reqType: "withdraw",
        };

        // Assuming completeSportData is a function that handles the request data
        completeSportData(reqData);

        // Close the modal
        setModalVisible(false);
      }
    });
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };
  return loading ? (
    <Loader loaderTrue={true} />
  ) : (
    <GlobalLayout icons={<i class="fa-solid fa-sack-dollar"></i>} title="Withdrwal">
      <>
        {/* modal--1 */}
        <div
          style={{ zIndex: 2500 }}
          className={`fixed inset-0 bg-black bg-opacity-50  flex justify-center items-center ${viewModalOpen ? "block" : "hidden"
            }`}
          onClick={() => setViewModalOpen(false)} // Close on click outside
        >
          <div
            className="bg-white w-full h-[60%] overflow-auto max-w-2xl  rounded-lg shadow-lg relative px-3 py-2 mx-1"
            onClick={(e) => e.stopPropagation()} // Prevent close when clicking inside modal
          >
            <div className="text-right">
              <span
                onClick={() => setViewModalOpen(false)}
                className="cursor-pointer bg-red-700 px-3 py-1 text-white text-lg font-bold rounded-full"
              >
                &times;
              </span>
            </div>

            <h2 className="text-2xl font-bold mb-6">Transaction Details</h2>
            {!loadingwebview ? (
              websiteViewData?.accountDetails || websiteViewData?.reqData ? (
                <div className="space-y-2">
                  <div className="grid grid-cols-1 sm:grid-cols-2 space-y-2 md:space-x-2 space-x-0">
                    {/* Account Details */}
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div><strong>Name: </strong> </div>
                      <div> {websiteViewData?.accountDetails?.name}
                      </div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div> <strong>Username: </strong> </div>
                    <div>   {websiteViewData?.accountDetails?.username}
                    </div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div> <strong>Account Holder Name: </strong>
                    </div>  <div> {websiteViewData?.accountDetails?.accountHolder}
                    </div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div>  <strong>Account Number: </strong>  </div>
                    <div>  {websiteViewData?.accountDetails?.accountNumber}
                    </div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div>   <strong>Bank Name: </strong>  </div>
                    <div>{websiteViewData?.accountDetails?.bankName}
                    </div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div>  <strong>Branch Name: </strong>  </div>
                    <div>   {websiteViewData?.accountDetails?.branchName}
                    </div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div>  <strong>IFSC Code: </strong></div>
                    <div>  {websiteViewData?.accountDetails?.ifscCode}
                    </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 space-y-2 md:space-x-2 space-x-0">
                    {/* Request Details */}
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div><strong>Amount: </strong></div>
                    <div>{websiteViewData?.reqData?.amount}</div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div> <strong>Status: </strong></div>
                    <div>  {websiteViewData?.reqData?.status}</div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div> <strong>Request Type: </strong></div>
                    <div> {websiteViewData?.reqData?.reqType}</div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div> <strong>Remark: </strong></div>
                    <div> {websiteViewData?.reqData?.remark}</div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div>  <strong>Cancel Remark: </strong></div>
                    <div>  {websiteViewData?.reqData?.canceledRemark}</div>
                    </div>
                    <div className="border px-2 py-0.5 rounded flex justify-between">
                    <div> <strong>Date: </strong></div>
                    <div> {moment(websiteViewData?.reqData?.createdAt).format("YYYY-MM-DD hh:mm:ss A")}</div>
                    </div>
                  </div>


                  {/* Screenshot */}
                  {websiteViewData?.reqData?.screenShotImg && (
                    <>
                      <div className="text-lg font-bold text-center mt-4 w-full">Screen Shot Images</div>
                      <div className="flex justify-center my-4">
                        <a
                          href={websiteViewData?.reqData?.screenShotImg}
                          download
                        >
                          <img
                            ref={imgRef}
                            src={websiteViewData?.reqData?.screenShotImg}
                            alt="Screenshot"
                            className=" object-cover"
                          />
                        </a>


                      </div>
                      <button

                        onClick={() => handleDownload(websiteViewData?.reqData?.screenShotImg)}
                        className="bg-green-600 py-1 w-full flex justify-center font-bold text-white text-center mt-4"
                      >
                        Download
                      </button>
                    </>
                  )}

                  {/* Close Button */}
                  {/* <div className="flex justify-end mt-6">
                    <button
                      onClick={() => setViewModalOpen(false)}
                      className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
                    >
                      Close
                    </button>
                  </div> */}
                </div>
              ) : (
                <div>No data available</div>
              )
            ) : (
              <TransParentLoader loaderTrue={true} />
            )}
          </div>
        </div>
        {/* modal-2 */}
        <div
          style={{ zIndex: 2500 }}
          className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ${modalVisible ? "block" : "hidden"
            }`}
          onClick={() => setViewModalOpen(false)} // Close on click outside
        >
          <div
            className="bg-white w-full  max-w-2xl rounded-lg shadow-lg relative px-3 py-2"
            onClick={(e) => e.stopPropagation()} // Prevent close when clicking inside modal
          >
            {/* Modal Header */}
            <div className="text-xl font-semibold text-center mb-4">
              Remarks
            </div>

            {/* Modal Body with Input */}
            <div className="mb-4">
              <input
                type="text"
                placeholder="Enter your remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Modal Footer with Submit and Cancel Buttons */}
            <div className="flex justify-end mt-4">
              <span
                className="mx-1 px-2 py-2 text-white bg-blue-500 cursor-pointer rounded-lg hover:bg-blue-600 transition"
                onClick={handleModalOk}
              >
                Submit
              </span>
              <span
                className="mx-1 px-2 py-2 text-white bg-red-500 cursor-pointer rounded-lg hover:bg-red-600 transition"
                onClick={handleModalCancel}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
        <div className="overflow-auto border rounded-lg shadow-lg py-2 bg-[#3F3F46]">
          <div className="flex flex-col md:flex-row items-center md:gap-4 gap-2 ">
            <RangePickerComponent
              startDate={startDate}
              endDate={endDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
            />
            <div className="md:w-64 w-80">
              {/* <label
          htmlFor="status"
          className="block text-gray-700 text-sm font-semibold mb-2"
        >
          Status
        </label> */}
              <select
                id="status"
                value={status}
                onChange={handleStatusChange}
                className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">All</option>
                <option value="accept">Approve</option>
                <option value="pending">Pending</option>
                <option value="cancel">Cancel</option>
              </select>
            </div>
          </div>
        </div>



        <Card className="overflow-auto">
          <table className="table table-sm table-bordered">
            <thead>
              <tr className="bg-zinc-700 text-white">
                <th className="text-nowrap">Date & Time</th>
                <th className="text-nowrap">Client</th>
                <th className="text-nowrap">Amount</th>
                <th className="text-nowrap">RequestType</th>
                <th className="text-nowrap">Status</th>
                <th className="text-nowrap">Action</th>
              </tr>
            </thead>

            <tbody>
              {depositWithdrwalListData &&
                depositWithdrwalListData.length > 0 ? (
                depositWithdrwalListData.map((data, index) => (
                  <tr key={index} className="uppercase">
                    <td className="text-nowrap">
                      {moment(data?.createdAt).format("DD-MM-YYYY hh:mm A")}
                    </td>
                    <td className="text-nowrap">
                      {`${data?.username} (${data?.name})`}
                    </td>
                    <td className="text-nowrap">{data?.amount || "-"}</td>
                    <td className="text-nowrap">{data?.reqType || "-"}</td>

                    <td
                      className={`text-nowrap ${data?.status === "pending"
                          ? "text-orange-500"
                          : data?.status === "accept"
                            ? "text-green-500"
                            : data?.status === "cancel"
                              ? "text-red-500"
                              : "text-black"
                        }`}
                    >
                      {data?.status}
                    </td>
                    <td className="text-nowrap">
                      <div className="flex gap-2">
                        <span
                          className="py-1 px-2 bg-blue-500 text-white cursor-pointer"
                          onClick={() => handalViewWebsite(data)}
                        >
                          View
                        </span>
                        <span
                          className="py-1 px-2 bg-green-500 text-white cursor-pointer"
                          onClick={() => {
                            if (data.status === "pending") {
                              handalAccept(data);
                            }
                          }}
                        >
                          {data.status === "pending" ? "Accept" : "Approved"}
                        </span>
                        <span
                          className="py-1 px-2 bg-red-500 text-white cursor-pointer"
                          onClick={() => {
                            if (data.status === "pending") {
                              handleCancelClick(data);
                            }
                          }}
                        >
                          {data.status === "pending" ? "Cancel" : "Approved"}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={6}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </Card>
      </>
    </GlobalLayout>
  );
}

export default Withdrawal;
