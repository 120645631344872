import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userServices } from '../_services/_user_services ';

const initialState = {

};



export const getUserBalance = createAsyncThunk(
  "user/getUserBalance",
  async (_, { rejectWithValue }) => {
    try {
      const userBalance = await userServices.userBalance();
      return userBalance;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userCreate = createAsyncThunk(
  "user/create",
  async (userData, { rejectWithValue }) => {
    try {
      const getCreateUserData = await userServices.userCreate(userData);
      return getCreateUserData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userUpdate = createAsyncThunk(
  "user/userUpdate",
  async (userData, { rejectWithValue }) => {
    try {
      const userUpdateData = await userServices.userUpdate(userData);
      return userUpdateData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userLenaDena = createAsyncThunk(
  "user/lenaDena",
  async (userData, { rejectWithValue }) => {
    try {
      const userLenaDenaData = await userServices.userLenaDena(userData);
      return userLenaDenaData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userLedger = createAsyncThunk(
  "user/userLedger",
  async (userData, { rejectWithValue }) => {
    try {
      const userLedgerData = await userServices.userLedger(userData);
      return userLedgerData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const userLedgerCreditDebit = createAsyncThunk(
  "user/ledgerCreditDebit",
  async (userData, { rejectWithValue }) => {
    try {
      const userLedgerCreditDebitData = await userServices.userLedgerCreditDebit(userData);

      return userLedgerCreditDebitData;
      
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUserList = createAsyncThunk(
  "user/getUserList",
  async (reqData, { rejectWithValue }) => {
    try {
      const userLedgerCreditDebitData = await userServices.userList(reqData);
      return userLedgerCreditDebitData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCoinsUpdate = createAsyncThunk(
  "user/updateCoins",
  async (userData, { rejectWithValue }) => {
    try {
      const coinUpdate = await userServices.getCoinsUpdate(userData);
      return coinUpdate;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getUserStatement = createAsyncThunk(
  "user/userStatement",
  async (userData, { rejectWithValue }) => {
    try {
      const getuserStatementData = await userServices.getuserStatement(userData);
      return getuserStatementData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getuserSearchReport = createAsyncThunk(
  "user/getuserSearchReport",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getUserSearchResult(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const userLedgerList = createAsyncThunk(
  "user/userLedgerList",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.userLedgerList(userData);
      console.log(result, "yyyyyyyrrrrrrrrrrrrrr");
      
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getCasinoDayWise = createAsyncThunk(
  "user/getCasinoDayWise",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getCasinoDayWise(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getDiamondCasinoReport = createAsyncThunk(
  "user/getDiamondCasinoReport",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getDiamondCasinoReport(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getcasinoReportByUser = createAsyncThunk(
  "user/getcasinoReportByUser",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getcasinoReportByUser(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const casinoDiamondBetList = createAsyncThunk(
  "user/casinoDiamondBetList",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.casinoDiamondBetList(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const casinoRoundWise = createAsyncThunk(
  "user/casinoRoundWise",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.casinoRoundWise(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getDiamondCasinoByEventId = createAsyncThunk(
  "user/getDiamondCasinoByEventId",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getDiamondCasinoByEventId(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getProfitLossPos = createAsyncThunk(
  "user/getProfitLossPos",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getProfitLossPos(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getMatkaList = createAsyncThunk(
  "user/getMatkaList",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getMatkaList(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getMatkaBetList = createAsyncThunk(
  "user/getMatkaBetList",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getMatkaBetList(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDomainList = createAsyncThunk(
  "user/domainList",
  async (userData, { rejectWithValue }) => {
    try {
      const result = await userServices.getDomainList(userData);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);










const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUpdateUserListFalse(state) {
      state.updateUserList = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserBalance.pending, (state) => {
        state.loading = true;
        state.refrecLoading = true;
      })
      .addCase(getUserBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.refrecLoading = false;
        state.userBalance = action.payload;
      })
      .addCase(getUserBalance.rejected, (state, action) => {
        state.loading = false;
        state.refrecLoading = true;
        state.error = action.payload;
      })
      // user update reducers data
      .addCase(userUpdate.pending, (state) => {
        state.userUpdateLoading = true;
        state.updateUserList = false
      })
      .addCase(userUpdate.fulfilled, (state, action) => {
        state.userUpdateLoading = false;
        state.userUpdateData = action.payload;
        state.updateUserList = true
      })
      .addCase(userUpdate.rejected, (state, action) => {
        state.userUpdateLoading = false;
        state.userUpdateError = action.payload;
        state.updateUserList = false
      })

      // user lena dena reducer data

      .addCase(userLenaDena.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLenaDena.fulfilled, (state, action) => {
        state.loading = false;
        state.userLenaDenaData = action.payload;
      })
      .addCase(userLenaDena.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // user ledger Data

      .addCase(userLedger.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLedger.fulfilled, (state, action) => {
        state.loading = false;
        state.userLedgerData = action.payload;
      })
      .addCase(userLedger.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // user ledger credit bedit data

      .addCase(userLedgerCreditDebit.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLedgerCreditDebit.fulfilled, (state, action) => {
        state.loading = false;
        state.userLedgerCreditDebitData = action.payload;
      })
      .addCase(userLedgerCreditDebit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // user Account Statement
      .addCase(getUserList.pending, (state) => {
        state.userListLoading = true;
      
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userListLoading = false;
        state.userListData = action.payload;
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.userListLoading = false;
        state.error = action.payload;
      })

      .addCase(getUserStatement.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserStatement.fulfilled, (state, action) => {
        state.loading = false;
        state.userStatementData = action.payload;
      })
      .addCase(getUserStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // user coins update

      .addCase(getCoinsUpdate.pending, (state) => {
        state.loading = true;
        state.updateUserList = false
      })
      .addCase(getCoinsUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.coinUpdateData = action.payload;
        state.updateUserList = true
      })
      .addCase(getCoinsUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.updateUserList = false
      })
      .addCase(getuserSearchReport.pending, (state) => {
        state.loading = true;
        state.srearchUserList = false
      })
      .addCase(getuserSearchReport.fulfilled, (state, action) => {
        state.loading = false;
        state.userSearchList = action.payload;
        state.srearchUserList = true
      })
      .addCase(getuserSearchReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.srearchUserList = false
      })
      .addCase(userLedgerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLedgerList.fulfilled, (state, action) => {
        state.loading = false;
        state.userLedgerListData = action.payload;
      })
      .addCase(userLedgerList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCasinoDayWise.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCasinoDayWise.fulfilled, (state, action) => {
        state.loading = false;
        state.casinoDayWise = action.payload;
      })
      .addCase(getCasinoDayWise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDiamondCasinoReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDiamondCasinoReport.fulfilled, (state, action) => {
        state.loading = false;
        state.diamondCasinoReport = action.payload;
      })
      .addCase(getDiamondCasinoReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getcasinoReportByUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getcasinoReportByUser.fulfilled, (state, action) => {
        state.loading = false;
        state.casinoReportListbyUser = action.payload;
      })
      .addCase(getcasinoReportByUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(casinoDiamondBetList.pending, (state) => {
        state.loading = true;
      })
      .addCase(casinoDiamondBetList.fulfilled, (state, action) => {
        state.loading = false;
        state.casinoBetList = action.payload;
      })
      .addCase(casinoDiamondBetList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(casinoRoundWise.pending, (state) => {
        state.loading = true;
      })
      .addCase(casinoRoundWise.fulfilled, (state, action) => {
        state.loading = false;
        state.casinoRoundWiseData = action.payload;
      })
      .addCase(casinoRoundWise.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDiamondCasinoByEventId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDiamondCasinoByEventId.fulfilled, (state, action) => {
        state.loading = false;
        state.diamondcasinobyeventid = action.payload;
      })
      .addCase(getDiamondCasinoByEventId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProfitLossPos.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfitLossPos.fulfilled, (state, action) => {
        state.loading = false;
        state.getcasinoProfitLossList = action.payload;
      })
      .addCase(getProfitLossPos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMatkaList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMatkaList.fulfilled, (state, action) => {
        state.loading = false;
        state.getMatkaListData = action.payload;
      })
      .addCase(getMatkaList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMatkaBetList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMatkaBetList.fulfilled, (state, action) => {
        state.loading = false;
        state.matkaBetListReport = action.payload;
      })
      .addCase(getMatkaBetList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(userCreate.pending, (state) => {
        state.loading = true;
        state.updateUserList = false
      })
      .addCase(userCreate.fulfilled, (state, action) => {
        state.loading = false;
        state.userCreateData = action.payload;
        state.updateUserList = true
      })
      .addCase(userCreate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.updateUserList = false
      })

      .addCase(getDomainList.pending, (state) => {
        state.loading = true;
        // state.updateUserList = false
      })
      .addCase(getDomainList.fulfilled, (state, action) => {
        state.loading = false;
        state.getDomainListData = action.payload;
        // state.updateUserList = true
      })
      .addCase(getDomainList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        // state.updateUserList = false
      })
  },
});


export const {  setUpdateUserListFalse } = userSlice.actions;
export default userSlice.reducer;



