import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getUserList,
  setUpdateUserListFalse,
  userUpdate,
} from "../../redux/_reducers/_user_reducers";
import {
  domainName,
  getOneUplineUserData,
  getUplineUserData,
  getUserTypeInfo,
  userTypeInfo,
} from "../../constents/global";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import {
  Autocomplete,
  Button,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { RiUser3Fill } from "react-icons/ri";
import TablePagination from "../../global_layouts/Pagination/Pagination";
import TableNoDataFound from "../../global_layouts/TableNoDataFound/TableNoDataFound";
import Triangle from "../../global_layouts/Triangle/Triangle";
import DepositWidrawal from "../../component/UserList/depositWidrawal/DepositWidrawal";
import { decrypt, encrypt } from "../../config/Encryption";
import { FaFileAlt } from "react-icons/fa";
import UserListChangePassword from "../../component/UserList/userListChangePassword/UserListChangePassword";
import AccountSatement from "../../component/UserList/accountStatement/AccountStatement";
import Switch from "../../global_layouts/Switch/Switch";
import Loader from "../../component/loader/Loader";
import moment from "moment";
import ADDUSER from "../../component/UserList/addUser/addUser";
import TransParentLoader from "../../component/loader/TransparentLoader";
import { useNotification } from "../../global_layouts/CustomNotification/CustomNotification";
import ProfitLoss from "../../component/UserList/profitLoss/profitLoss";
import EDITUSER from "../../component/UserList/editUser/EditUser";
import { DownloadFileExcel } from "../../component/downloadManangment/DowloadFiles";
import ExposerModal from "../../component/exposerModal/ExposerModal";
import CreditRefrence from "../../component/creditRefernce/CreditRefrence";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";


function UserList() {
  const [isDrawerOpens, setIsDrawerOpens] = useState({
    depositWidralDrawer: false,
    accountStatementDrawer: false,
    changePasswordDrawer: false,
    addNewUserDrawer: false,
    editUserDrawer: false,
    profitLossDrawer: false
  });
  const [isExposerOpens, setIsExposerOpens] = useState(false);
  const [exposerData, setExposerData] = useState({});

  const [propsData, setPropsData] = useState({
    accProps: "",
    chgPassProps: "",
    dipWidProps: "",
    addUserProps: "",
    editUserProps: '',
    profitLossProps: "",
  });
  const [totalItems, setTotalItems] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isCreditData, setIsCreditData] = useState(false)
  const [credit, setCredit] = useState({})



  const notify = useNotification();
  const { sidebarTypeEnc, userTypeEnc, downlinePriorityEnc, userIdEnc } = useParams();
  const userType = decrypt(userTypeEnc);
  const userId = decrypt(userIdEnc);
  const downlinePriority = decrypt(downlinePriorityEnc);
  const sidebarType = decrypt(sidebarTypeEnc);
  const dispatch = useDispatch();
  const { userListData, userListLoading, updateUserList, userUpdateLoading, userUpdateData, userUpdateError } = useSelector((state) => state.user);

  const userInfo = JSON.parse(
    localStorage.getItem(`user_info_${domainName}`)
  )?.data;
  const pageSize = 25;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchUserList = () => {
    let reqData = {
      sortData: { createdAt: 1 },
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
      status: "both",
      parentUserType: "",
      parentId: "",
      downlineUserType: userType,
    };

    if (Number(userId) > 0 && Number(userId) < 10) {
      reqData.parentUserType = getUplineUserData(userType)?.userType;
    } else {
      reqData.parentUserType = getOneUplineUserData(userType)?.userType;
      reqData.parentId = userId;
    }

    dispatch(getUserList(reqData)).then(() => {
      if (updateUserList) {
        dispatch(setUpdateUserListFalse());
      }
    });
  };

  useEffect(() => {
    fetchUserList();
  }, [userType, userId, currentPage, dispatch]);

  useEffect(() => {
    if (updateUserList) {

      fetchUserList();
    }
  }, [updateUserList]);


  useEffect(() => {
    if (userListData) {
      setTotalItems(userListData?.total);
    }
  }, [userListData]);
  useEffect(() => {
    if (userUpdateData) {
      notify(userUpdateData.message, 'success');
    }
    if (userUpdateError) {
      notify(userUpdateData.message, 'error');
    }
  }, [userUpdateData, userUpdateError]);


  const handleClick = (event, user) => {


    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handalChipDeposit = (data) => {
    setPropsData({
      dipWidProps: data,
    });

    setIsDrawerOpens({
      depositWidralDrawer: true,
      accountStatementDrawer: false,
      changePasswordDrawer: false,
      addNewUserDrawer: false,
      profitLossDrawer: false,
      editUserDrawer: false,
    });
    setAnchorEl(null);
    setSelectedUser(null);
  };
  const handalAddNewUser = (data) => {
    setPropsData({
      addUserProps: data,
    });
    setIsDrawerOpens({
      addNewUserDrawer: true,
      depositWidralDrawer: false,
      accountStatementDrawer: false,
      changePasswordDrawer: false,
      profitLossDrawer: false,
      editUserDrawer: false,

    });
    setAnchorEl(null);
    setSelectedUser(null);
  };
  const handalEditUser = (data) => {

    setPropsData({
      editUserProps: data,
    });

    setIsDrawerOpens({
      addNewUserDrawer: false,
      depositWidralDrawer: false,
      accountStatementDrawer: false,
      changePasswordDrawer: false,
      profitLossDrawer: false,
      editUserDrawer: true,
    });

    setAnchorEl(null);
    setSelectedUser(null);
  };
  const handalAccountStatement = (data) => {
    setPropsData({
      accProps: data,
    });
    setIsDrawerOpens({
      accountStatementDrawer: true,
      depositWidralDrawer: false,
      changePasswordDrawer: false,
      addNewUserDrawer: false,
      profitLossDrawer: false,
      editUserDrawer: false,
    });
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handalUserChangePassword = (data) => {
    setPropsData({
      chgPassProps: data,
    });
    setIsDrawerOpens({
      changePasswordDrawer: true,
      depositWidralDrawer: false,
      accountStatementDrawer: false,
      addNewUserDrawer: false,
      profitLossDrawer: false,
      editUserDrawer: false,
    });
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handalProfitLoss = (data) => {
    setPropsData({
      profitLossProps: data,
    });
    setIsDrawerOpens({
      profitLossDrawer: true,
      addNewUserDrawer: false,
      depositWidralDrawer: false,
      accountStatementDrawer: false,
      changePasswordDrawer: false,
      editUserDrawer: false,

    });
    setAnchorEl(null);
    setSelectedUser(null);
  };






  const handleUserStatus = async (data) => {
    let reqData = {
      "userId": data.userId,
      "status": data.status === 1 ? 0 : data.status === 0 ? 1 : null,
    };

    await dispatch(userUpdate(reqData));

  };
  const handleBetBlock = async (data) => {
    let reqData = {
      "userId": data.userId,
      "betStatus": !data.betStatus
    };

    await dispatch(userUpdate(reqData));
  };

  const handleCasinoBlock = async (data) => {
    let reqData = {
      "userId": data.userId,
      "casinoStatus": !data.casinoStatus
    };
    await dispatch(userUpdate(reqData));

  };

  const handalExposerModal = (data) => {

    setIsExposerOpens(true)
    setExposerData(data?.userId)
  }



  const headers = [
    { header: 'Name', dataKey: 'name' },
    { header: 'Balance', dataKey: 'coins' },
    { header: 'Client (P/L)', dataKey: 'totalProfitLoss' },
    { header: 'Exposure', dataKey: 'exposure' },
    { header: 'userType', dataKey: 'userType' },
  ];

  const currentUser = userTypeInfo.find(user => user.userType === "client");

  const handleCreditModal = (data) => {
    setIsCreditData(!isCreditData)
    setCredit(data)
  }

  return userListLoading ? (
    <Loader loaderTrue={true} />
  ) : (
    <>
      {userUpdateLoading && <TransParentLoader />}
      <GlobalLayout>

        <div className="  flex divide-x divide-gray-500 w-full overflow-x-auto overflow-y-hidden">
          <div className="flex w-100 mb-3 mt-3 bg-red-500 divide-x divide-gray-70">
            {userTypeInfo.filter((ele) => ele.priority < (userInfo.userPriority && Number(getUserTypeInfo(sidebarType)?.priority) + 1)).map((item, index) => (
              <div
                key={index}
                className={`text-white  ${Number(item.priority) === Number(downlinePriority)
                  ? "bg-red-700 "
                  : "bg-red-500"
                  }  capitalize flex items-center gap-1 relative text-sm font-semibold px-3 py-2`}
              >
                <a
                  href={`/admin/user-list/${encrypt(sidebarType)}/${encrypt(
                    item.userType
                  )}/${encrypt(item.priority)}/${encrypt(item.priority)}`}
                  className="hover:text-white"
                >
                  <span className={``}>{item.userType}</span>
                  {Number(item.priority) === Number(downlinePriority) && (
                    <Triangle direction="down" size={10} color="#B91C1C" />
                  )}
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="flex lg:flex-row flex-col my-1 justify-between">
          <div className="flex items-center justify-start gap-2">
            <RiUser3Fill size={25} className=" text-gray-500" />
            <div className="text-lg font-bold capitalize text-gray-500">
              {sidebarType === "downline" ? userType : sidebarType} List
            </div>
          </div>
          <div className="flex text-nowrap flex-wrap gap-2">
          {userInfo?.userType === 'owner' && userType === 'subowner' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>}

          {userInfo?.userType === 'subowner' && userType === 'superadmin' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>}


            {userInfo?.userType === 'superadmin' && userType === 'admin' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>}

            {userInfo?.userType === 'admin' && userType === 'subadmin' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>}
            {userInfo?.userType === 'subadmin' && userType === 'master' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>}
            {userInfo?.userType === 'master' && userType === 'superagent' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>}
            {userInfo?.userType === 'superagent' && userType === 'agent' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>}
            {userInfo?.userType === 'agent' && userType === 'client' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>}
            {/* {sidebarType !== "downline" && userType === 'agent' && <Button variant="contained" color="error" onClick={() => handalAddNewUser(userInfo)}>
              + Add {sidebarType}
            </Button>} */}
            

            {/* <Button variant="contained" color="error">
              +Add
            </Button> */}
            {/* <Button variant="contained" color="success" onClick={() => handalChipDeposit(userInfo)}>
              A/C chips [{userInfo?.userType}]
            </Button> */}
            {/* <Button variant="contained" color="error"> */}
            {/* <FaFileAlt /> Export */}
            <DownloadFileExcel headers={headers} data={userListData?.list} fileName={`clientlist`} />
            {/* </Button> */}
          </div>
        </div>

        {/* <div className="max-w-100 overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="bg-gray-100">
              <tr className="text-uppercase">
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                  Super admin Balance
                </th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                  Credit Limit
                </th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                  Available Balance
                </th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                  Profit Loss
                </th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                  Liability
                </th>
                <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                  Balance
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white hover:bg-gray-100 text-sm font-light">
                <td className="px-2 py-2 border border-gray-300 font-semibold">
                  165465465465464
                </td>
                <td className="px-2 py-2 border border-gray-300 font-semibold">
                  5165651615651651651
                </td>
                <td className="px-2 py-2 border border-gray-300 font-semibold">
                  65465649895
                </td>
                <td className="px-2 py-2 border border-gray-300 font-semibold">
                  898987987879
                </td>
                <td className="px-2 py-2 border border-gray-300 font-semibold">
                  8978987454
                </td>
                <td className="px-2 py-2 border border-gray-300 font-semibold">
                  5654684649654
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}
        {/* <div className="grid grid-cols-2 tex-xs md:text-sm sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 my-1">
        <TextField
          className="w-full"
          id="outlined-basic"
          label="Search"
          variant="outlined"
          size="small"
        />

        <Autocomplete
          disablePortal
          options={["YES", "NO"]}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="User Locked" />
          )}
        />
        <Autocomplete
          disablePortal
          options={["YES", "NO"]}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Betting Locked" />
          )}
        />
        <Autocomplete
          disablePortal
          options={["YES", "NO"]}
          size="small"
          renderInput={(params) => (
            <TextField {...params} label="Closed Account" />
          )}
        />
        <div className="flex gap-2 ">
          <Button className="w-full " variant="contained">
            Search
          </Button>
          <Button className="w-full" variant="outlined">
            Refresh
          </Button>
        </div>
      </div> */}
        <div className="my-3">
          <div className="max-w-100 overflow-x-auto">
            <table className="min-w-full bg-white border  border-gray-300">
              <thead className="bg-gray-100">
                <tr className="text-uppercase">
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    ID
                  </th>
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    User Name
                  </th>
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    Liability
                  </th>
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    Balance
                  </th>
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    Profit Loss
                  </th>
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    C/D
                  </th>
                  {/* <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
            Total Settled Amount
          </th> */}
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    Casino Locked
                  </th>
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    Betting Locked
                  </th>
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    User Locked
                  </th>
                  {/* <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    Closed Account
                  </th> */}
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    Created At
                  </th>
                  <th className="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-semibold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {userListData && userListData?.list?.length > 0 ? (
                  userListData?.list?.map((user) => (
                    <tr
                      key={user.userId}
                      className="bg-white hover:bg-gray-100 text-sm font-light"
                    >
                      <td className="px-2 py-2 border border-gray-300 font-semibold">
                        {user.username}
                      </td>

                      <td
                        className={`px-2 ${user?.userPriority > 1 && user?.userPriority < 9
                          ? "text-blue-700 cursor-pointer"
                          : "text-black"
                          } py-2 border border-gray-300 font-semibold `}
                        onClick={() => {
                          if (user?.userPriority > 1 && user?.userPriority < 9) {
                            const downline = encrypt("downline");
                            const userType = encrypt(
                              userTypeInfo.find(
                                (ele) =>
                                  ele.priority ==
                                  Number(
                                    getUserTypeInfo(user.userType).priority
                                  ) -
                                  1
                              ).userType
                            );
                            const userId = encrypt(user.userId);
                            const userPriority = encrypt(
                              Number(user.userPriority) - 1
                            );

                            window.location.href = `/admin/user-list/${encrypt(
                              "downline"
                            )}/${userType}/${userId}/${userPriority}`;
                          }
                        }}
                      >
                        {user.name}
                      </td>
                      <td
                        className={`px-2 ${user.exposure < 0 ? "text-red-800" : "text-green-800"
                          } py-2 border border-gray-300 font-semibold`}

                        onClick={() => handalExposerModal(user)}
                      >
                        {(Math.floor(Number(user.exposure) * 100) / 100).toFixed(2)}

                      </td>
                      <td className="px-2 py-2 border border-gray-300 font-semibold">

                        {(Math.floor(Number(user.coins) * 100) / 100).toFixed(2)}
                      </td>
                      <td
                        className={`px-2 ${user.totalProfitLoss < 0
                          ? "text-red-800"
                          : "text-green-800"
                          } py-2 border border-gray-300 font-semibold`}
                      >
                        {(Math.floor(Number(user.totalProfitLoss) * 100) / 100).toFixed(2)}

                      </td>
                      {user?.userType !== 'client' ? (
                        <td className="px-2 py-2 border border-gray-300 font-semibold flex justify-between items-center">


                          {(Math.floor(Number(user.creditReference) * 100) / 100).toFixed(2)}

                          <RiMoneyRupeeCircleFill size={34} color="green" onClick={() => handleCreditModal(user)} />



                        </td>
                      ) : <td className="px-2 py-2 border border-gray-300 font-semibold ">
                        {(Math.floor(Number(user.creditReference) * 100) / 100).toFixed(2)}
                      </td>}

                      <td className="px-2 py-2 border border-gray-300 font-semibold">
                        <Switch
                          isActive={user.casinoStatus}
                          onToggle={() => handleCasinoBlock(user)}
                          activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          size="small"
                        // text="Self Lock"
                        />
                        {/* {sidebarType === "downline" &&
                          <Switch
                            isActive={user.casinoStatus}
                            onToggle={() => handleCasinoBlock(user)}
                            activeClass="shadow-lg"
                            inactiveClass="opacity-75"
                            activeColor="bg-blue-500"
                            inactiveColor="bg-gray-500"
                            size="small"
                            text="Parent Lock"
                          />
                        } */}

                        {/* <FormControlLabel
                  control={
                    <Switch
                      checked={user.betStatus}
                      color="primary"
                      size="small"
                      id={`custom-switch-${user.userId}`}
                    />
                  }
                  label={user.betStatus ? "Active" : "Inactive"}
                /> */}
                      </td>
                      <td className="px-2 py-2 border border-gray-300 font-semibold">
                        <Switch
                          isActive={user.betStatus}
                          onToggle={() => handleBetBlock(user)}
                          activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          size="small"
                        // text="Self Lock"
                        />
                        {/* {sidebarType === "downline" && <Switch
                          isActive={user.betStatus}
                          onToggle={() => handleBetBlock(user)}
                          activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          text="Praent Lock"
                          size="small"
                        />} */}
                      </td>
                      <td className="px-2 py-2 border border-gray-300 font-semibold">
                        <Switch
                          isActive={user.status}
                          onToggle={() => handleUserStatus(user)}
                          activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          size="small"
                        // text="Self Lock"
                        />
                        {/* {sidebarType === "downline" &&
                          <Switch
                            isActive={user.status}
                            onToggle={() => handleUserStatus(user)}
                            activeClass="shadow-lg"
                            inactiveClass="opacity-75"
                            activeColor="bg-blue-500"
                            inactiveColor="bg-gray-500"
                            size="small"
                            text="Parent Lock"
                          />
                        } */}
                      </td>
                      {/* <td className="px-2 py-2 border border-gray-300 font-semibold"> */}
                      {/* <Switch
                          isActive={user.isDeleted}
                          // onToggle={toggleSwitch}
                          activeClass="shadow-lg"
                          inactiveClass="opacity-75"
                          activeColor="bg-blue-500"
                          inactiveColor="bg-gray-500"
                          size="small"
                          // text="Self Lock"
                        /> */}
                      {/* {sidebarType === "downline" &&
                          <Switch
                            isActive={user.isDeleted}
                            // onToggle={toggleSwitch}
                            activeClass="shadow-lg"
                            inactiveClass="opacity-75"
                            activeColor="bg-blue-500"
                            inactiveColor="bg-gray-500"
                            size="small"
                            text="Parent Lock"
                          />
                        } */}
                      {/* </td> */}
                      <td className="px-2 py-2 border text-nowrap border-gray-300 font-semibold">

                        {moment(user.createdAt).format("DD-MM-YY hh:mm:ss A")}
                      </td>
                      <td className="px-2 py-2 border border-gray-300 font-semibold">
                        <button
                          onClick={(event) => handleClick(event, user)}
                          className="text-white bg-[#2ecc71] font-medium text-nowrap rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                          type="button"
                        >
                          View More{" "}
                          <svg
                            className="w-2.5 h-2.5 ms-2"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl && selectedUser === user)}
                          onClose={handleClose}
                          className="border uppercase text-xs"
                        >





                          {selectedUser?.userType !== 'client' && <MenuItem className="text-xs" onClick={() => handalAddNewUser(user)}>
                            Add User
                          </MenuItem>}


                          {/* <MenuItem className="text-xs" onClick={() => handalAddNewUser(user)}>Add User</MenuItem> */}

                          {selectedUser?.userType !== 'client' && <MenuItem className="text-xs" onClick={() => {
                            if (user?.userPriority > 1 && user?.userPriority < 9) {
                              const downline = encrypt("downline");
                              const userType = encrypt(
                                userTypeInfo.find(
                                  (ele) =>
                                    ele.priority ==
                                    Number(
                                      getUserTypeInfo(user.userType).priority
                                    ) -
                                    1
                                ).userType
                              );
                              const userId = encrypt(user.userId);
                              const userPriority = encrypt(
                                Number(user.userPriority) - 1
                              );

                              window.location.href = `/admin/user-list/${encrypt(
                                "downline"
                              )}/${userType}/${userId}/${userPriority}`;
                            }
                          }}>{" "}View Child</MenuItem>}
                          <MenuItem className="text-xs" onClick={() => handalEditUser(user)}>Edit User</MenuItem>
                          <MenuItem className="text-xs" onClick={() => handalUserChangePassword(user)}>Change Password</MenuItem>
                          <MenuItem className="text-xs" onClick={() => handalChipDeposit(user)}>Chips D/W</MenuItem>
                          {/* <MenuItem className="text-xs" onClick={handleClose}>Setting</MenuItem> */}
                          <MenuItem className="text-xs" onClick={() => handalAccountStatement(user)}>A/c Statement</MenuItem>
                          <MenuItem className="text-xs" onClick={() => handalProfitLoss(user)}>Profit Loss</MenuItem>
                          {/* <MenuItem className="text-xs" onClick={handleClose}>Delete A/c</MenuItem> */}
                        </Menu>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableNoDataFound size={12} />
                )}
              </tbody>
            </table>
          </div>

          <TablePagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            position="right"
            shape="rounded"
            color="primary"
          />
        </div>





        {isDrawerOpens.depositWidralDrawer && (
          <DepositWidrawal
            isDrawerOpen={isDrawerOpens}
            setIsDrawerOpen={setIsDrawerOpens}
            propsData={propsData}
          />
        )}
        {isDrawerOpens.changePasswordDrawer && (
          <UserListChangePassword
            isDrawerOpen={isDrawerOpens}
            setIsDrawerOpen={setIsDrawerOpens}
            propsData={propsData}
          />
        )}
        {isDrawerOpens.accountStatementDrawer && (
          <AccountSatement
            isDrawerOpen={isDrawerOpens}
            setIsDrawerOpen={setIsDrawerOpens}
            propsData={propsData}
          />
        )}
        {isDrawerOpens.addNewUserDrawer && (
          <ADDUSER
            isDrawerOpen={isDrawerOpens}
            setIsDrawerOpen={setIsDrawerOpens}
            propsData={propsData}
          />
        )}
        {isDrawerOpens.editUserDrawer && (

          <EDITUSER
            isDrawerOpen={isDrawerOpens}
            setIsDrawerOpen={setIsDrawerOpens}
            propsData={propsData}
          />
        )}

        {isDrawerOpens.profitLossDrawer && (
          <ProfitLoss
            isDrawerOpen={isDrawerOpens}
            setIsDrawerOpen={setIsDrawerOpens}
            propsData={propsData}
          />
        )}

        {isExposerOpens && (
          <ExposerModal isExposerOpens={isExposerOpens} setIsExposerOpens={setIsExposerOpens} exposerData={exposerData} />
        )}
      </GlobalLayout>


      {isCreditData && (


        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center " onClick={() => setIsCreditData(false)}>
          <CreditRefrence data={credit} setIsCreditData={setIsCreditData} />
        </div>
      )}
    </>
  );
}

export default UserList;
