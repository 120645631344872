import React from "react";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import Card from "../../global_layouts/Card/Card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLedger, userLenaDena } from "../../redux/_reducers/_user_reducers";
import {
    reportMyCase,
    reportSelfStatement,
} from "../../redux/_reducers/_report_reducers";
import { domainName } from "../../constents/global";
import Loader from "../../component/loader/Loader";
import { useParams } from "react-router-dom";
import SettlementModal from "./SettlementModal";
import HistoryModal from "./HistoryModal";
import { decrypt, encrypt } from "../../config/Encryption";

const Settlement = () => {
    const [lenaDenaData, setLenaDenaData] = useState({
        totalLenaBalance: 0,
        totalDenaBalance: 0,
        lenaUser: [],
        denaUser: [],
        clearUser: [],
    });
    const [selfStatementList, setSelfStatementList] = useState([]);
    const [reportMyCaseList, setReportMyCaseListList] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    const [paymentType, setPaymentType] = useState('');
    const [fieldsUser, setFieldsUser] = useState(null);

    const dispatch = useDispatch();

    const { userTypeEnc, userIdEnc } = useParams()
    const userType = decrypt(userTypeEnc)
    const userId = decrypt(userIdEnc)



    const userData = localStorage.getItem(`user_info_${domainName}`)
        ? JSON.parse(localStorage.getItem(`user_info_${domainName}`))
        : null;
    const { reportMyCaseData, reportSelfStatementData } = useSelector((state) => state.report);
    const { userLenaDenaData, loading, userLedgerData } = useSelector((state) => state.user);

    useEffect(() => {
        fetchResponseData();
    }, []);

    useEffect(() => {
        getUserlenaDena();
        getSelfStatmentData();
        getMyCash();
    }, [dispatch, userLenaDenaData, reportMyCaseData, reportSelfStatementData]);

    const fetchResponseData = async () => {
        let reqLenaDena = {
            userId: userId ? userId : userData.data.userId,
            isDirect: true,
        };
        dispatch(userLenaDena(reqLenaDena));
        let reqReportSelf = {
            userId: userId ? userId : userData.data.userId,
            userType: userType ? userType : userData.data.userType,
        };
        dispatch(reportSelfStatement(reqReportSelf));
        let reqReportMyCase = {
            creatorId: userId ? userId : userData.data.userId,
        };
        dispatch(reportMyCase(reqReportMyCase));
    };

    const getUserlenaDena = () => {
        let totalLenaBalanceList = 0;
        let totalDenaBalanceList = 0;
        let lenaUserList = [];
        let denaUserList = [];
        let clearUserList = [];

        if (userLenaDenaData) {
            userLenaDenaData.forEach((userData) => {
                const modifiedUserData = { ...userData };

                if (modifiedUserData.userType !== "client") {
                    modifiedUserData.balance = -1 * modifiedUserData.balance;
                }
                if (modifiedUserData.balance > 0) {
                    modifiedUserData.ledgerType = "lena";
                    lenaUserList.push(modifiedUserData);
                    totalLenaBalanceList += modifiedUserData.balance;
                } else if (modifiedUserData.balance < 0) {
                    modifiedUserData.ledgerType = "dena";
                    denaUserList.push(modifiedUserData);
                    totalDenaBalanceList += modifiedUserData.balance;
                } else {
                    clearUserList.push(modifiedUserData);
                }
            });
        }

        setLenaDenaData({
            totalLenaBalance: totalLenaBalanceList,
            totalDenaBalance: totalDenaBalanceList,
            lenaUser: lenaUserList,
            denaUser: denaUserList,
            clearUser: clearUserList,
        });
    };

    const getSelfStatmentData = () => {
        console.log(reportSelfStatementData, "reportSelfStatementData");

        if (reportSelfStatementData) {
            setSelfStatementList(reportSelfStatementData);
        }
    };

    const getMyCash = () => {
        if (reportMyCaseData) {
            setReportMyCaseListList(reportMyCaseData);
        }
    };

    const settlementModal = (data) => {
        setIsModalOpen(true);
        setModalData(data.element);
        setPaymentType(data.type);
        setFieldsUser(data.element);
    };

    const myCashHistoryModal = (value) => {
        setIsHistoryModalOpen(true);
        const reqData = {
            downlineUserId: userId ? userId : userData.data.userId,
            ledgerType: "settle",
            isDirect: value.isDirect,
        };
        dispatch(userLedger(reqData));
    };

    const myHistoryModal = (value) => {
        setIsHistoryModalOpen(true);
        const reqData = {
            downlineUserId: value.element.userId,
            ledgerType: "settle",
            isDirect: value.isDirect,
        };
        dispatch(userLedger(reqData));
    };



    return loading ? (
        <Loader loaderTrue={loading} />
    ) : (
        <GlobalLayout>
            <div class="">
                <div class="py-2.5 rounded-sm  bg-gradient-to-t from-[#0c0e5f] to-[#0277b7]">
                    <div class="justify-between items-center w-100 lg:px-4 px-2 text-white">
                        <div class="info-box-number text-lg font-bold capitalize">
                            settlements
                        </div>
                    </div>
                </div>
            </div>
            <Card className="overflow-auto">
                <div className="row ">
                    <div className="col-md-6">
                        <div className="card">
                            <table className="table table-bordered mb-0 table-sm ">
                                <thead className="bg-gray-800 text-white">
                                    <tr>
                                        <th>Name</th>
                                        <th>Settle Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lenaDenaData && lenaDenaData.lenaUser.length > 0
                                        ? lenaDenaData.lenaUser?.map((element, index) => (
                                            <tr key={index}>
                                               
                                                {element.userType === "client" ? (
                                                    <td className="text-sm font-medium uppercase">
                                                        {element.username} [{element.name}]
                                                    </td>
                                                ) : (
                                                    <td
                                                        className="text-sm font-medium uppercase"

                                                    >
                                                        <a href={`/admin/settalment/${encrypt(element.userType)}/${encrypt(element.userId)}/${encrypt(element.username)}`} className="">
                                                            {element.username} [{element.name}]{" "}
                                                        </a>
                                                    </td>
                                                )}
                                                <td
                                                    className={`text-sm font-medium uppercase ${element.balance > 0
                                                        ? "text-green-600"
                                                        : "text-red-600"
                                                        }`}
                                                >
                                                    {Number.parseFloat(
                                                        Math.abs(element.balance)
                                                    ).toFixed(2)}
                                                </td>
                                                <td className="space-x-2">
                                                    <button
                                                        className="bg-[#22a7f0] text-white px-2 py-1 text-xs font-bold uppercase"
                                                        type="button"
                                                        onClick={() => settlementModal({ element: element, type: "lena" })}
                                                    >
                                                        Settlement
                                                    </button>
                                                    <button
                                                        className="bg-[#2ecc71] text-white px-2 py-1 text-xs font-bold uppercase"
                                                        type="button"
                                                        onClick={() => myHistoryModal({ element: element, isDirect: false })}
                                                    >
                                                        History
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                        : null}






                                    {selfStatementList.marketProfitLossWithoutComm > 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="info">Market PL</div></td>
                                            <td className={`${selfStatementList.marketProfitLossWithoutComm > 0 ? "txtgreen" : "txtred"}  text-end `}>
                                                {((Number.parseFloat(selfStatementList.marketProfitLossWithoutComm)).toFixed(2))}</td>
                                            <td className='text-center'></td>
                                        </tr> : null}
                                    {selfStatementList.userOddsCommProfitLoss > 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="info">Market Commission</div></td>
                                            <td className={`${selfStatementList.userOddsCommProfitLoss > 0 ? "txtgreen" : "txtred"}  text-end `}> {((Number.parseFloat(selfStatementList.userOddsCommProfitLoss || 0)).toFixed(2))}</td>
                                            <td className='text-center'></td>
                                        </tr> : null}
                                    {selfStatementList.userSessionCommProfitLoss > 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="warning">Session Commission</div></td>
                                            <td className={`${selfStatementList.userSessionCommProfitLoss > 0 ? "txtgreen" : "txtred"}  text-end `}>{((Number.parseFloat(selfStatementList.userSessionCommProfitLoss)).toFixed(2))}</td>
                                            <td className='text-center'></td>
                                        </tr> : null}
                                    {selfStatementList.userLedgerAmt > 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="orange">Settlement (Parent)</div></td>
                                            <td className={`${selfStatementList.userLedgerAmt > 0 ? "txtgreen" : "txtred"}  text-end `}>
                                                {((Number.parseFloat(selfStatementList.userLedgerAmt)).toFixed(2))}</td>
                                            <td className='text-center'></td>
                                        </tr> : null}

                                    {selfStatementList.cashSettlement > 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="success">Cash</div></td>
                                            <td className={`${selfStatementList.cashSettlement > 0 ? "txtgreen" : "txtred"}  text-end `}>
                                                {((Number.parseFloat((selfStatementList && selfStatementList.cashSettlement ? selfStatementList.cashSettlement : "0.00"))).toFixed(2))}
                                            </td>
                                        </tr> : null}


                                    {/* {selfStatementList && selfStatementList.length > 0
                                        ? selfStatementList.map((element, index) => (
                                            <React.Fragment key={index}>
                                                {element.userLedgerAmt > 0 && (
                                                    <tr>

                                                        <td colSpan={2} className="text-sm font-medium uppercase text-center">Up-Line P/L</td>
                                                        <td className={element.userLedgerAmt > 0 ? "text-green-600" : "text-red-600"}>
                                                            {Number.parseFloat(
                                                                element.userLedgerAmt
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td>-</td>
                                                    </tr>
                                                )}

                                                <tr>

                                                    <td colSpan={2} className="text-sm font-medium uppercase text-center">My Comm.</td>
                                                    <td className={element.userOddsComm + element.userSessionComm > 0 ? "text-green-600 text-sm font-medium uppercase" : "text-red-600 text-sm font-medium uppercase"}
                                                    >
                                                        {Number.parseFloat(
                                                            Math.abs(
                                                                (element.userOddsComm +
                                                                    element.userSessionComm) *
                                                                ((100 -
                                                                    (element?.shareData?.userMatchShare ||
                                                                        0)) /
                                                                    100)
                                                            )
                                                        ).toFixed(2)}
                                                    </td>
                                                    <td>-</td>
                                                </tr>

                                                {element.userNetProfitLoss < 0 && (
                                                    <tr>

                                                        <td colSpan={2} className="text-sm font-medium uppercase text-center">My P/L</td>
                                                        <td
                                                            className={
                                                                element.userNetProfitLoss < 0
                                                                    ? "text-red-600 text-sm font-medium uppercase"
                                                                    : "text-green-600 text-sm font-medium uppercase"
                                                            }
                                                        >
                                                            {Number.parseFloat(
                                                                Math.abs(element.userNetProfitLoss)
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td>-</td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))
                                        : null} */}

                                    {reportMyCaseList && reportMyCaseList.amount > 0 ?
                                        <tr className="">

                                            <td colSpan={1} className="text-sm font-medium uppercase text-center">My Cash</td>
                                            <td className='text-sm font-medium uppercase text-green-600'>   {Number.parseFloat(Math.abs(reportMyCaseList && reportMyCaseList.amount ? reportMyCaseList.amount : "0.00")).toFixed(2)} </td>
                                            <td>
                                                <button onClick={() => myCashHistoryModal({ isDirect: true })} className="bg-[#2ecc71] text-xs font-bold text-white py-1 px-1  uppercase" type="button" >
                                                    History
                                                </button>
                                            </td>
                                        </tr> : null
                                    }
                                </tbody>

                                <tfoot className="bg-green-800 text-white">
                                    <tr>
                                        <td colSpan={2} className="text-sm font-medium uppercase text-center"> TOTAL </td>
                                        <td colSpan={2} className="text-sm font-medium uppercase text-start">{Number.parseFloat(Math.abs(lenaDenaData.totalLenaBalance)).toFixed(2)}</td>

                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <table className="table table-bordered mb-0 table-sm">
                                <thead className="bg-gray-800 text-white">
                                    <tr>
                                        <th>Name</th>
                                        <th>Settle Amount</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lenaDenaData && lenaDenaData.denaUser.length > 0 ? lenaDenaData.denaUser.map((element, index) =>
                                        <tr className=" ">
                                            {/* <td><span className=''>
                                                {index + 1}
                                            </span></td> */}
                                            {element.userType === "client" ?
                                                <td className="text-sm font-medium uppercase">{element.username} [{element.name}]</td> :
                                                <td className="text-sm font-medium uppercase">
                                                    <a href={`/admin/settalment/${encrypt(element.userType)}/${encrypt(element.userId)}/${encrypt(element.username)}`} className="">
                                                        {element.username} [{element.name}]{" "}
                                                    </a>
                                                </td>
                                            }
                                            <td className={`text-sm font-medium whitespace-nowrap ${element.balance > 0 ? "text-green-600" : "text-red-600"}`}>{Number.parseFloat(Math.abs(element.balance)).toFixed(2)}</td>
                                            <td className='space-x-2'>
                                                <button onClick={() => settlementModal({ element: element, type: "dena" })} className="bg-[#22a7f0] text-white px-2 py-1 text-xs font-bold uppercase" type="button" >
                                                    Settlement
                                                </button>
                                                <button onClick={() => myHistoryModal({ element: element, isDirect: false })} className="bg-[#2ecc71] text-white px-2 py-1 text-xs font-bold uppercase" type="button" >
                                                    History
                                                </button>
                                            </td>
                                        </tr>) : null}

                                    {selfStatementList.marketProfitLossWithoutComm <= 0 ?
                                        <tr className='tablebodyTr'>
                                            
                                            <td className='text-start'><div className="info">Market PL</div></td>
                                            <td className={`${selfStatementList.marketProfitLossWithoutComm > 0 ? "txtgreen" : "txtred"}  text-end `}>
                                                {((Number.parseFloat(selfStatementList.marketProfitLossWithoutComm)).toFixed(2))}</td>
                                            <td className='text-center'></td>
                                        </tr> : null}
                                    {selfStatementList.userOddsCommProfitLoss <= 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="info">Market Commission</div></td>
                                            <td className={`${selfStatementList.userOddsCommProfitLoss > 0 ? "txtgreen" : "txtred"}  text-end `}> {((Number.parseFloat(selfStatementList.userOddsCommProfitLoss || 0)).toFixed(2))}</td>
                                            <td className='text-center'></td>
                                        </tr> : null}
                                    {selfStatementList.userSessionCommProfitLoss <= 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="warning">Session Commission</div></td>
                                            <td className={`${selfStatementList.userSessionCommProfitLoss > 0 ? "txtgreen" : "txtred"}  text-end `}>{((Number.parseFloat(selfStatementList.userSessionCommProfitLoss || 0)).toFixed(2))}</td>
                                            <td className='text-center'></td>
                                        </tr> : null}
                                    {selfStatementList.userLedgerAmt <= 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="orange">Settlement (Parent)</div></td>
                                            <td className={`${selfStatementList.userLedgerAmt > 0 ? "txtgreen" : "txtred"}  text-end `}>
                                                {((Number.parseFloat(selfStatementList.userLedgerAmt)).toFixed(2))}</td>
                                            <td className='text-center'></td>
                                        </tr> : null}


                                    {selfStatementList.cashSettlement <= 0 ?
                                        <tr className='tablebodyTr'>
                                            <td className='text-start'><div className="success">Cash</div></td>
                                            <td className={`${selfStatementList.cashSettlement < 0 ? "txtgreen" : "txtred"}  text-end `}>  {((Number.parseFloat((selfStatementList && selfStatementList.cashSettlement ? selfStatementList.cashSettlement : "0.00"))).toFixed(2))} </td>
                                            <td className='text-center'></td>
                                        </tr> : null}





                                    {/* {selfStatementList && selfStatementList.length > 0 ? selfStatementList.map((element, index) =>
                                        <React.Fragment key={index}>
                                            {element.userLedgerAmt < 0 ?
                                                <tr className="">

                                                    <td colSpan={2} className="text-sm font-medium uppercase text-center">Up-Line P/L</td>
                                                    <td className={` ${element.userLedgerAmt > 0 ? "text-green-600" : "text-red-600"}`}>
                                                        {element.userLedgerAmt < 0 ? Number.parseFloat(element.userLedgerAmt).toFixed(2) : 0}
                                                    </td>
                                                    <td>-</td>
                                                </tr>
                                                : null
                                            }
                                            {element.userNetProfitLoss > 0 ?
                                                <tr className="">

                                                    <td colSpan={2} className="text-sm font-medium uppercase text-center">My P/L	</td>
                                                    <td className={`${element.userNetProfitLoss * (-1) > 0 ? "text-green-600" : "text-red-600"}    `}>
                                                        {element.userNetProfitLoss > 0 ? Number.parseFloat(-1 * element.userNetProfitLoss).toFixed(2) : 0}</td>
                                                    <td> -

                                                    </td>
                                                </tr> : null}
                                        </React.Fragment>) : null

                                    } */}
                                    {reportMyCaseList && reportMyCaseList.amount <= 0 ?
                                        <tr className="">
                                            <td colSpan={1} className="text-sm font-medium uppercase text-center">My Cash</td>
                                            <td className='text-sm font-medium uppercase text-red-600'>{Number.parseFloat(Math.abs(reportMyCaseList && reportMyCaseList.amount ? reportMyCaseList.amount : "0.00")).toFixed(2)}</td>
                                            <td>
                                                <button onClick={() => myCashHistoryModal({ isDirect: true })} className="bg-[#2ecc71] text-white px-2 py-1 text-xs font-bold uppercase" type="button" >
                                                    History
                                                </button>
                                            </td>
                                        </tr> : null
                                    }

                                </tbody>
                                <tfoot className="bg-red-800 text-white">
                                    <tr>
                                        <td colSpan={1} className="text-sm font-medium uppercase text-center"> TOTAL </td>
                                        <td colSpan={2} className="text-sm font-medium uppercase text-start">{Number.parseFloat(Math.abs(lenaDenaData.totalDenaBalance)).toFixed(2)}</td>

                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                    </div>
                </div>
                {isModalOpen && (
                    <SettlementModal
                        setIsModalOpen={setIsModalOpen}
                        isModalOpen={isModalOpen}
                        modalData={modalData}
                        setModalData={setModalData}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        fieldsUser={fieldsUser}
                        setFieldsUser={setFieldsUser}
                        getMyCash={getMyCash}
                    />
                )}

                {isHistoryModalOpen && (
                    <HistoryModal
                        isHistoryModalOpen={isHistoryModalOpen}
                        setIsHistoryModalOpen={setIsHistoryModalOpen}
                        userLedgerData={userLedgerData}
                    />
                )}
            </Card>
        </GlobalLayout>
    );
};

export default Settlement;
