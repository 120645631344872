import { apiCall } from "../../config/Http";

// reports/selfStatementCrick
async function reportSelfStatement(data) {
  try {
    const response = await apiCall("POST", "reports/selfStatementCrick", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}

async function reportMyCase(data) {
  try {
    const response = await apiCall("POST", "reports/myCash", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}

async function reportUserProfitLoss(data) {
  try {
    // userProfitLoss
    const response = await apiCall("POST", "reports/profitLossForAllExch", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}

async function reportUserGroupByBetsForEventId(data) {
  try {
    // userProfitLoss
    const response = await apiCall("POST", "reports/groupByBetsForEventId", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}


async function depositWithdrwalList(data) {
  try {
    const response = await apiCall("POST", "website/getDepositWithdrawList", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}
async function websiteView(data) {
  try {
    const response = await apiCall("POST", "website/view", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}
async function approveDisapprove(data) {
  try {
    const response = await apiCall("POST", "website/approveDisapproveReq", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}

async function reportBlockMarket(data) {
  try {
    const response = await apiCall("POST", "reports/blockMarket", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}

async function reportsUserWiseBlockMarketList(data) {
  try {
    const response = await apiCall("POST", "reports/userWiseBlockMarketList", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}


async function depositWithdrawTotal(data) {
  try {
    const response = await apiCall("POST", "daman/depositWithdrawTotal", data);
    if (response && response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error("report", error);
    return Promise.reject(error);
  }
}




export const reportServices = {
  reportSelfStatement,
  reportMyCase,
  reportUserProfitLoss,
  depositWithdrwalList,
  websiteView,
  approveDisapprove,
  reportBlockMarket,
  reportsUserWiseBlockMarketList,
  depositWithdrawTotal,
  reportUserGroupByBetsForEventId

};