import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sportsServices } from "../_services/_sports_services";


const initialState = {

};



export const getSportMatchList = createAsyncThunk(
  "sports/getSportMatchList",
  async (reqData, { rejectWithValue }) => {
    try {
      const sportMatchList = await sportsServices.sportMatchList(reqData);
      return sportMatchList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSportMatchdetailsByMarketId = createAsyncThunk(
  "sports/getSportMatchdetailsByMarketId",
  async (reqData, { rejectWithValue }) => {
    try {
      const sportMatchList = await sportsServices.sportMatchDetails(reqData);
      return sportMatchList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getSportMatchOddsPosition = createAsyncThunk(
  "sports/getSportMatchOddsPosition",
  async (reqData, { rejectWithValue }) => {
    try {
      const sportsPositionData = await sportsServices.sportMatchOddsPosition(reqData);
      return sportsPositionData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getSportMatchBetList = createAsyncThunk(
  "sports/getSportMatchBetList",
  async (reqData, { rejectWithValue }) => {
    try {
      const sportsPositionData = await sportsServices.sportMatchBetList(reqData);
      return sportsPositionData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getCompletedSportList = createAsyncThunk(
  "decision/completeSportList",
  async (reqData, { rejectWithValue }) => {
    try {
      const completedSportListData = await sportsServices.getCompletedSportList(reqData);
      return completedSportListData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);




const userSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {
    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      
      .addCase(getSportMatchList.pending, (state) => {
        state.loading = true;
        state.sportmatchlist = [];
      })
      .addCase(getSportMatchList.fulfilled, (state, action) => {
        state.loading = false;
        state.sportmatchlist = action.payload;
      })
      .addCase(getSportMatchList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSportMatchdetailsByMarketId.pending, (state) => {
        state.loading = true;
        state.matchDetailsByMarketId = null
      })
      .addCase(getSportMatchdetailsByMarketId.fulfilled, (state, action) => {
        state.loading = false;
        state.matchDetailsByMarketId = action.payload;
      })
      .addCase(getSportMatchdetailsByMarketId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSportMatchOddsPosition.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSportMatchOddsPosition.fulfilled, (state, action) => {
        state.loading = false;
        state.oddsPosition = action.payload;
      })
      .addCase(getSportMatchOddsPosition.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSportMatchBetList.pending, (state) => {
        state.loading = true;
        state.sportBetList=null
      })
      .addCase(getSportMatchBetList.fulfilled, (state, action) => {
        state.loading = false;
        state.sportBetList = action.payload;
      })
      .addCase(getSportMatchBetList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // completed sport Match List

      .addCase(getCompletedSportList.pending, (state) => {
        state.loading = true;
        state.sportBetList=null
      })
      .addCase(getCompletedSportList.fulfilled, (state, action) => {
        state.loading = false;
        state.getCompletedSportListData = action.payload;
      })
      .addCase(getCompletedSportList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});



export default userSlice.reducer;
