import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, Card, DatePicker, Select, Table, Menu, Spin, Dropdown, Popconfirm, message } from 'antd';
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getuserSearchReport, userLedgerCreditDebit, userLedgerList } from "../../redux/_reducers/_user_reducers";
import { httpPost } from "../../config/http2";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import Triangle from "../../global_layouts/Triangle/Triangle";
import { decrypt } from "../../config/Encryption";
import { MdDeleteForever } from "react-icons/md";


const { Option } = Select;
const CashTransaction = () => {
  const dispatch = useDispatch();
  // const { usertype, userName, userIds, name } = useParams();

  const { userTypeEnc, userName, userIds, name } = useParams();
  const usertype = decrypt(userTypeEnc);



  const [form] = Form.useForm();
  const [userLists, setUserLists] = useState([]);
  const [userLadger, setLadger] = useState([]);
  const [calAmount, setCalAmount] = useState(0);
  const [creditAmount, setCreditAmount] = useState({});
  const [debitAmount, setDebitAmount] = useState({});
  const [selectedUserId, setSelectedUserId] = useState({});
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showRow, setShowRow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [payloadData, setPayloadData] = useState({
    client: '',
    collection: 'CASH A/C',
    date: moment(),
    amount: '',
    paymentType: "dena",
    remark: '',
    ledgerType: ''
  });
  const userData = JSON.parse(localStorage.getItem('user_id'));
  const { userListItems, userLedgerListData, userSearchList, loading, succesApi } = useSelector((state) => state.user);
  const [selectedClient, setSelectedClient] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  // useEffect(() => {
  //   dispatch(userLedgerClear());

  //   setPayloadData({
  //     client: '',
  //     collection: '',
  //     date: moment(),
  //     amount: '',
  //     paymentType: "dena",
  //     remark: '',
  //   });
  //   setShowRow(false);
  //   setUserLists([]);
  //   setLadger([])
  //   setCalAmount(0);
  //   setCreditAmount({});
  //   setDebitAmount({});
  //   // getUserListFun();
  //   if (userName) {
  //     handleChange2(userName);
  //   }
  // }, [userData?.data?.userType, usertype]);

  useEffect(() => {
    //////////////////////////////////////// dispatch(userLedgerClear());

    setPayloadData({
      client: '',
      collection: '',
      date: moment(),
      amount: '',
      paymentType: "dena",
      remark: '',
    });
    setShowRow(false);
    setUserLists([]);
    setLadger([])
    setCalAmount(0);
    setCreditAmount({});
    setDebitAmount({});
    // getUserListFun();
    if (userIds) {
      handleChange(userIds);
    }
  }, [usertype, userIds]);

  useEffect(() => {
    if (userSearchList) {
      setFilteredOptions(userSearchList);
    }
  }, [userSearchList]);


  useEffect(() => {
    if (succesApi) {
      let reqDataUserledger = {
        downlineUserId: selectedUserId.userId,
      };

      dispatch(userLedgerList(reqDataUserledger));
    }

  }, [succesApi]);

  useEffect(() => {

    if (userLedgerListData) {
      setFilteredOptions([])
      const { calAmount, creditAmount, debitAmount, ledgerData } = userLedgerListData;

      const filteredData = ledgerData?.filter(item => {

        if (!payloadData.ledgerType) {
          return true;
        }

        return item.ledgerType === payloadData.ledgerType;
      })?.map((item, index) => ({
        key: `${item.userId}-${index}`,
        userID: `${item.userId}`,
        createdAt: item.createdAt,
        eventName: item.eventName,
        balance: item.balance,
        debit: item.amount > 0 ? item.amount : 0,
        credit: item.amount < 0 ? item.amount : 0,
        ledgerType: item.ledgerType,
        ledgerId: item.ledgerId,
        remark: item.remark,
        userType: item.userType,



      }));

      const totalDebit = filteredData.reduce((acc, item) => acc + item.debit, 0);
      const totalCredit = filteredData.reduce((acc, item) => acc + item.credit, 0);
      const totalBalance = filteredData.reduce((acc, item) => acc + item.balance, 0);
      const totalBalancefilter = filteredData.reduce((acc, item) => acc + item.debit - item.credit, 0);



      setLadger(filteredData);
      // setCalAmount(calAmount);
      // setCreditAmount(creditAmount);
      // setDebitAmount(debitAmount);
      if (!payloadData.ledgerType) {
        // Display totals for "All"
        setCalAmount(calAmount);
        setCreditAmount(creditAmount);
        setDebitAmount(debitAmount);



      } else {
        // Display filtered totals
        setCalAmount(calAmount);
        setCreditAmount(totalCredit);
        setDebitAmount(totalDebit);
      }



    }
  }, [userLedgerListData, payloadData.ledgerType]);



  // useEffect(() => {

  //   if (userLedgerListData) {
  //     setFilteredOptions([])
  //     const { calAmount, creditAmount, debitAmount, ledgerData } = userLedgerListData;
  //     const filteredData = ledgerData.map((item, index) => ({
  //       key: `${item.userId}-${index}`,
  //       userID: `${item.userId}`,
  //       createdAt: item.createdAt,
  //       eventName: item.eventName,
  //       balance: item.balance,
  //       debit: item.amount > 0 ? item.amount : 0,
  //       credit: item.amount < 0 ? item.amount : 0,
  //       ledgerType: item.ledgerType,
  //       ledgerId: item.ledgerId,
  //       remark: item.remark,
  //       userType: item.userType,
  //     }));
  //     setLadger(filteredData);
  //     setCalAmount(calAmount);
  //     setCreditAmount(creditAmount);
  //     setDebitAmount(debitAmount);

  //   }
  // }, [userLedgerListData]);



  const getUserListFun = async () => {
    let reqData = {
      sortData: { createdAt: 1 },
      keyWord: '',
      pageNo: 1,
      size: 20,
      status: 'both',
      parentUserType: userData?.data.userType,
      parentId: '',
      downlineUserType: usertype,
    };
    // await dispatch(getUserList(reqData));
    setLadger([]);
    setCalAmount(0);
    setCreditAmount({});
    setDebitAmount({});
    setShowRow(false);
  };

  const handleInputChange = (key, value) => {

    setPayloadData(prevState => ({
      ...prevState,
      [key]: value
    }));
    // let reqData = {
    //   downlineUserId: selectedUserId.userId ? selectedUserId.userId : userIds,
    //   ledgerType: value

    // };

    //  dispatch(userLedgerList(reqData));
  };

  const handleInputChange2 = (key, value) => {

    setPayloadData(prevState => ({
      ...prevState,
      [key]: value
    }));
    let reqData = {
      downlineUserId: selectedUserId.userId ? selectedUserId.userId : userIds,
      ledgerType: value

    };

    dispatch(userLedgerList(reqData));
  };



  const onFinish = (values) => {
    let amount = null;
    if (usertype === "client") {
      amount = payloadData.paymentType === "dena" ? payloadData.amount * -1 : payloadData.amount;
    } else {
      amount = payloadData.paymentType === "dena" ? payloadData.amount : payloadData.amount * -1;
    }

    let reqData = {
      date: moment(payloadData.date).format("DD-MM-YYYY"),
      remark: payloadData.remark,
      amount: amount,
      paymentType: payloadData.paymentType,
      downlineUserId: selectedUserId.userId,
      downlineUserType: usertype,
      // collection: payloadData.collection,
    };


    if (payloadData.ledgerType !== "all") {
      reqData.ledgerType = payloadData.ledgerType;
    }

    dispatch(userLedgerCreditDebit(reqData))
      .then(response => {
        if (!response?.error) {


          let reqDataUserledger = {
            downlineUserId: selectedUserId.userId,
          };

          dispatch(userLedgerList(reqDataUserledger));

          console.log("ggggggggggggggg");
        }

      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });


    // dispatch(userLedgerCreditDebit(reqData))
    form.resetFields(['amount', 'remark', 'ledgerType', 'date', 'paymentType', 'collection']);
  };


  const onChangeDate = (date, dateString) => {
    handleInputChange('date', dateString);
  };

  const handleChange = async (value) => {
    form.resetFields(['amount', 'remark', 'ledgerType', 'date', 'paymentType', 'collection']);
    setSelectedClient(value);
    setSelectedUserId({ userId: value, userType: value.userType })
    setPayloadData(prevState => ({
      ...prevState,
      client: value.username,
    }));
    let reqData = {
      downlineUserId: value ? value : userIds,

    };

    await dispatch(userLedgerList(reqData));
    await setShowRow(true);
    //////////////////////////////////////////////// await dispatch(getuserSearchClear())
  };





  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    return obj;
  };

  const handleChange2 = async (value) => {
    setSelectedClient(value);
    const redData = {
      userType: usertype,
      searchValue: value,
      cashTransaction: true
    };
    // if (value.length > 3) {
    // dispatch(getuserSearchReport(redData));
    const resData = await httpPost("user/userSearch", redData);
    // console.log("resData",resData.data[0]);
    handleChange(resData?.data[0])
    // }
    // handleChange()
  };










  const showPopconfirm = (record) => {
    setCurrentRecord(record);
    setOpen(true);
  };


  const deleteLedger = async (row) => {

    let reqData = {
      "downlineUserId": row?.userID,
      "ledgerId": row?.ledgerId
    }
    let userLedger = await httpPost('user/deleteUserLedger', reqData);
    if (userLedger) {
      message.success(userLedger?.message);
      let reqData = {
        downlineUserId: row?.userID,
      };

      await dispatch(userLedgerList(reqData));
    }
  }




  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setOpen(false);
  };



  const columns = [

    {
      title: '#',
      dataIndex: 'id',
      render: (value, row) => {
        const oneHourAgo = moment().subtract(24, 'hours');
        if (row?.ledgerType === "settle" && moment(row?.createdAt).isAfter(oneHourAgo)) {
          return (
            <>
              <span
                className="bg-primary text-red-600"
                onClick={() => deleteLedger(row)}
              >
                <MdDeleteForever size={30} color="red" />
              </span>
            </>
          );
        }
        return null;
      },
    },

    {
      title: 'Date',
      dataIndex: 'createdAt',
      // render: (createdAt) => <span className="text-nowrap">{moment(createdAt).utcOffset("+05:30").format("DD-MM-YYYY")}</span>,
      render: (createdAt) => <span className="text-nowrap">{(moment(createdAt)).format('DD MMM hh:mm:ss A')}</span>,
      // moment(item?.createdAt).format('DD MMM hh:mm:ss A')
    },
    {
      title: 'Collection Name',
      dataIndex: 'eventName',
      render: (value, row) => <span className="text-nowrap">{row.eventName}</span>,
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      render: (value, row) => (
        Number.parseFloat(Math.abs(value)).toFixed(2)
      ),
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      render: (value) => (
        Number.parseFloat(Math.abs(value)).toFixed(2)
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (value, row) => {
        const balanceLabel = usertype === 'client'
          ? (row.balance > 0 ? " - Dena" : " - Lena")
          : (row.balance > 0 ? " - Lena" : " - Dena");

        return (
          <span className='text-nowrap'>
            {Number.parseFloat(Math.abs(row.balance)).toFixed(2)}{balanceLabel}
          </span>
        );
      },
    },

    //   {
    //     title: 'Balance',
    //     dataIndex: 'balance',
    //     render: (value, row) => 

    //     {usertype === 'client' ?
    //     <span className='text-nowrap'>{row.balance > 0 ? Number.parseFloat(Math.abs(row.balance)).toFixed(2) + " - Dena" : Number.parseFloat(Math.abs(row.balance)).toFixed(2) + " - Lena"}</span>
    //   :<span className='text-nowrap'>{row.balance > 0 ? Number.parseFloat(Math.abs(row.balance)).toFixed(2) + " - Lena" : Number.parseFloat(Math.abs(row.balance)).toFixed(2) + " - Dena"}</span>}
    // },

    {
      title: 'Payment Type',
      dataIndex: 'ledgerType',
      render: (value, row) => <span>{row.ledgerType === 'settle' ? row.remark : row.ledgerType}</span>,
    },
    // {
    //   title: 'Remark',
    //   dataIndex: 'remark',
    //   render: (value, row) => <span className="text-nowrap">{row?.remark}</span>,
    // },
    {
      title: 'Done By',
      dataIndex: ``,
      render: () => <span>{usertype}</span>
    },
  ];

  const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backdropFilter: 'blur(10px)'
  };



  const handleChange1 = (value) => {
    setSelectedClient(value);
    const redData = {
      userType: usertype,
      searchValue: value,
      cashTransaction: true
    };
    if (value.length > 2) {
      dispatch(getuserSearchReport(redData));
    }

  };
  const onSearch = (value) => {

    setSelectedClient(value);
    const redData = {
      userType: usertype,
      searchValue: value,
      cashTransaction: true
    };
    if (value.length > 2) {
      dispatch(getuserSearchReport(redData));
      form.resetFields(['ledgerType']);
    }

    // console.log('search:', value);
  };

  // const filterOption = (input, option) => {
  //   if (typeof option?.children == 'string') {
  //     return option.children.toLowerCase().includes(input.toLowerCase());
  //   }
  //   return false; // Default to false if children is not a string
  // };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <GlobalLayout>
      {loading
        && <div style={{
          backgroundColor: 'rgba(255,255,255, 0.5)',
          zIndex: 10
        }} className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
          <Spin size="mideum" style={styles} />
          <span className="text-primary" ></span>
        </div>}
      <div className="bg-red-600 flex justify-between items-center ">
        <div className="text-white bg-red-700 relative font-bold px-4 py-2"> Cash Transaction<Triangle direction="down" size={10} color="#B91C1C" /></div>
        <button onClick={() => window.location.reload()} className="bg-green-500 text-white py-1 mr-1 px-3 rounded" > Refresh</button>
      </div>

      <Card className="mt-4">
        <div className="px-2 pt-3 flex">
          <Form
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            // onFinish={onFinish}

            defaultValue={{
              client: payloadData.client,
              collection: 'cash_selected',
              // date: moment(payloadData.date).format("DD-MM-YYYY"),
              date: moment(payloadData.date),
              amount: payloadData.amount,
              paymentType: payloadData.paymentType,
              remark: payloadData.remark,
            }}


          >
            {/* <Row gutter={20} className="">
                  <Col lg={8} md={8} sm={12} xs={24} className="px-5">
                    <Form.Item
                      label={usertype}
                      name="client"
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder="Select a person"
                        onChange={(e) => handleChange1(e.target.value)}
                        value={selectedClient}
                      />
                      {filteredOptions.map(item => (
                        <li className="py-1" key={item.key} value={item.key} onClick={() => handleChange(item)}>
                          {`${item.username} (${item.username})`}
                        </li>
                      ))}
                    </Form.Item>
                  </Col>
                </Row> */}


            <Row gutter={20} className="">
              {/* <Col lg={8} md={8} sm={12} xs={24} className="px-5">
                    <Form.Item label={usertype} name="client" rules={[{ required: true }]}>
                      <Select
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={value => { handleInputChange('client', value); handleChange(value); }}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {userLists.map(item => (
                          <Select.Option key={item.key} value={item.key}>{item.username} ({item.username})</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col> */}
              <Col lg={8} md={8} sm={12} xs={24} className="">
                <Form.Item
                  style={{ position: "relative" }}
                  label={
                    <span style={{ textTransform: 'uppercase' }}>
                      {usertype === 'subadmin' ? "mini admin" : usertype}
                    </span>
                  }
                  name="Client"

                  rules={[{ required: true }]}
                  labelCol={{ flex: '0 0 130px' }}  // Adjust this width as needed
                  wrapperCol={{ flex: '1' }}
                >
                  <Select
                    showSearch
                    placeholder={`${userName ? `${userName} (${name})` : 'Select User'}`}
                    optionFilterProp="children"
                    onChange={handleChange}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    className='w-full'
                  >

                    {filteredOptions && filteredOptions.length > 0 ? filteredOptions.map(user => (
                      <Option key={user.userId} value={user.userId} label={`${user.username} ${user.name} `}>
                        {user.username} ({user.name})
                      </Option>
                    )) : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} className="">
                <Form.Item
                  label="COLLECTION" name="collection" labelCol={{ flex: '0 0 130px' }}  // Adjust this width as needed
                  wrapperCol={{ flex: '1' }} rules={[{ required: true }]}>
                  <Select getPopupContainer={trigger => trigger.parentElement} defaultValue="CASH A/C">
                    <Select.Option value="cash_selected" >CASH A/C</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} className="">
                <Form.Item label="DATE" name="date" labelCol={{ flex: '0 0 130px' }}  // Adjust this width as needed
                  wrapperCol={{ flex: '1' }} rules={[{ required: true }]}>
                  <DatePicker className="mb-3 w-full border-redius0"
                    defaultValue={payloadData?.date}
                    onChange={onChangeDate} />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} className="">
                {/* <Form.Item label="Amount"  name="amount" rules={[{type:"number", required: true }]}>
                      <Input
                        // type="number"
                        type="number"
                        placeholder="Amount"
                        className="border-redius0"
                        onChange={e => handleInputChange('amount', e.target.value)}
                      />
                    </Form.Item> */}
                <Form.Item label="AMOUNT" name="amount" labelCol={{ flex: '0 0 130px' }}  // Adjust this width as needed
                  wrapperCol={{ flex: '1' }} rules={[{ required: true }]}>
                  <Input
                    type="number"
                    placeholder="Amount"
                    className="border-redius0"
                    onChange={e => handleInputChange('amount', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} className="">
                <Form.Item label="PAYMENT TYPE" name="paymentType" labelCol={{ flex: '0 0 130px' }}  // Adjust this width as needed
                  wrapperCol={{ flex: '1' }} rules={[{ required: true }]}>
                  <Select
                    showSearch
                    placeholder="Select PaymentType"
                    optionFilterProp="children"
                    onChange={value => handleInputChange('paymentType', value)}
                    // getPopupContainer={trigger => trigger.parentElement}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option value="dena">PAYMENT-DIYA</Select.Option>
                    <Select.Option value="lena">PAYMENT-LIYA</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24} className="">
                <Form.Item label="REMARK" name="remark" labelCol={{ flex: '0 0 130px' }}  // Adjust this width as needed
                  wrapperCol={{ flex: '1' }} rules={[{ required: true }]}>
                  <Input
                    placeholder="Remark"
                    className="border-redius0"
                    onChange={e => handleInputChange('remark', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={8} md={8} sm={12} xs={24}>
                <Form.Item label="LEDGER TYPE" name="ledgerType" labelCol={{ flex: '0 0 140px' }}  // Adjust this width as needed
                  wrapperCol={{ flex: '1' }}
                // rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder="All"
                    optionFilterProp="children"
                    onChange={value => handleInputChange2('ledgerType', value)}
                    getPopupContainer={trigger => trigger.parentElement}
                    className="w-full"
                    style={{ width: '100%', maxWidth: '100%' }}
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="diamondCasino">Diamond Casino</Select.Option>
                    <Select.Option value="internationalCasino">International Casino</Select.Option>
                    <Select.Option value="settle">Settle</Select.Option>
                    <Select.Option value="cricket">Cricket</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button classNames='bg-[#60a5fa] flex items-center justify-center text-white hover:!text-white hover:!bg-[#60a5fa] font-bold px-4 py-1' type="primary" onClick={() => onFinish()}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>

      {showRow && <>
        {/* {loading ? <Loader loaderTrue={true} /> : */}


        <Card className="w-full">
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12} className="pt-3 " >
              <div className="fs-xl px-1    text-red">
                Dena: {usertype === "client" ? Number.parseFloat(Math.abs(creditAmount ? creditAmount : 0)).toFixed(2) : Number.parseFloat(Math.abs(debitAmount ? debitAmount : 0)).toFixed(2)}
              </div>
            </Col>
            <Col xl={6} lg={6} md={12} sm={12} xs={12} className="pt-3   ">
              <div className="fs-xl   text-green">
                Lena: {usertype === "client" ? Number.parseFloat(Math.abs(debitAmount ? debitAmount : 0)).toFixed(2) : Number.parseFloat(Math.abs(creditAmount ? creditAmount : 0)).toFixed(2)}
              </div>
            </Col>

            {usertype === 'client' ?
              <Col xl={6} lg={6} md={22} sm={22} xs={22} className="pt-3 px-3 flex justify-center">
                <span className={`fs-xl  px-3  text-black ${-1 * calAmount > 0 ? "text-green" : "text-red"} `}>
                  Balance: {Number.parseFloat(Math.abs(calAmount ? calAmount : 0)).toFixed(2)}
                  ({-1 * calAmount > 0 ? "Lena" : "Dena"})
                </span>
              </Col> :
              <Col xl={6} lg={6} md={22} sm={22} xs={22} className="pt-3 px-3 flex justify-center">
                <span className={`fs-xl  px-3 ${calAmount > 0 ? "text-green" : "text-red"} `}>
                  Balance: {Number.parseFloat(Math.abs(calAmount ? calAmount : 0)).toFixed(2)} ({calAmount > 0 ? "Lena" : "Dena"})
                </span>
              </Col>

            }














            <Col xl={6} lg={6} md={22} sm={22} xs={22} className="pt-3 px-3  ">
              <Link to={`/components/lenedena/deleted-lenedena/${selectedUserId.userId}/${selectedUserId.userType}`} className='px-3'>
                <Button className="fs-xl  text-white bg-primary">Deleted</Button></Link>
            </Col>
          </Row>

          <Table rowKey={record => record?.key} className="table-responsive mt-3" columns={columns} dataSource={userLadger} bordered pagination={false} size="small"

            rowClassName={(row, index) => index % 2 !== 0 && row.ledgerType != 'settle' ? 'bg-light-grey' : row.ledgerType == 'settle' ? 'bg-yellow' : ''}
          />
        </Card>
        {/* } */}
      </>



      }
    </GlobalLayout>
  );
};

export default CashTransaction;


