import { Controller, useForm } from "react-hook-form";
import Drawer from "../../../global_layouts/Drawer/Drawer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IoIosArrowDown } from "react-icons/io";
import { getOneDownlineUserData } from "../../../constents/global";
import { useDispatch, useSelector } from "react-redux";
import { getDomainList, getUserBalance, userCreate } from "../../../redux/_reducers/_user_reducers";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { apiCall } from "../../../config/Http";

// import {
//   getUserList
// } from "../../../redux/_reducers/_user_reducers";

const ADDUSER = (props) => {
  const [userDetails, setUserDetails] = useState({});

  const { isDrawerOpen, setIsDrawerOpen, propsData } = props;
  const isOpen = isDrawerOpen.addNewUserDrawer;
  const { addUserProps } = propsData;
  const userType = getOneDownlineUserData(addUserProps?.userType);


  const dispatch = useDispatch();

  const { updateUserList, getDomainListData } = useSelector((state) => state.user);




  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control
  } = useForm();

  const onSubmit = async (values) => {


    const updatedFieldsUser = {
      password: values.password,
      mobile: values.contact,
      username: values.username,
      name: values.name,
      userMatchShare: values.userMatchShare,
      userCasinoShare: values.userCasinoShare,
      matkaShare: values.userMatkaShare,
      matchCommission: values.userMatchCommission,
      sessionCommission: values.userSessionCommission,
      intCasinoExpoLimit: values.intCasinoExpoLimit,
      intCasinoShare: values.intCasinoShare,
      intCasinoMultiply: values.intCasinoMultiply,
      intCasinoStatus: values.intCasinoStatus,
      commissionType: values.commissionType,
      commissionChangeType: values.commissionChangeType,
      casinoCommission: values.userCasinoCommission,
      matkaCommission: values.userMatkaCommission,
      coins: values.coins,
      reference: values.remark,
      commChangeType: values.commChangeType,
      maxCommValue: values.maxCommValue,
      casinoStatus: values.casinoStatus,
      selectDomain: values.selectDomain?.map(domain => domain.value),
      matkaStatus: values.matkaStatus,
    };

    const userCreateFun = {
      parentId: addUserProps?.userId || null,
      password: updatedFieldsUser.password,
      mobile: updatedFieldsUser.mobile,
      name: updatedFieldsUser.name,
      username: updatedFieldsUser.username,
      userType: userType?.userType,
      status: 1,
      matchShare:
        userType === "client"
          ? addUserProps?.userMatchShare
          : updatedFieldsUser.userMatchShare,
      casinoShare:
        userType === "client"
          ? addUserProps?.userCasinoShare
          : updatedFieldsUser.userCasinoShare,
      casinoStatus:
        userType === "client" ? updatedFieldsUser.casinoStatus : true,
      matkaShare:
        userType === "client"
          ? addUserProps?.userMatkaShare
          : updatedFieldsUser.matkaShare,

      matchCommission: updatedFieldsUser.commissionType === "NoCommission"
          ? 0
          : updatedFieldsUser.matchCommission,
      sessionCommission:
        updatedFieldsUser.commissionChangeType === "NoCommission"
          ? 0
          : updatedFieldsUser.sessionCommission,
      matkaCommission:
        updatedFieldsUser.commissionChangeType === "NoCommission"
          ? 0
          : updatedFieldsUser.matkaCommission,

      intCasinoExpoLimit: updatedFieldsUser.intCasinoStatus
        ? updatedFieldsUser.intCasinoExpoLimit
        : 0,
      intCasinoShare: updatedFieldsUser.intCasinoShare
        ? updatedFieldsUser.intCasinoShare
        : 0,
      intCasinoMultiply:
        updatedFieldsUser.intCasinoStatus && addUserProps?.userPriority > 7
          ? updatedFieldsUser.intCasinoMultiply
          : addUserProps?.intCasinoMultiply,
      intCasinoStatus:
        userType === "client"
          ? true
          : updatedFieldsUser.intCasinoStatus
            ? updatedFieldsUser.intCasinoStatus
            : false,
      // commissionType: updatedFieldsUser.commissionType,
      commissionType: "BetByBet",

      

      casinoCommission: updatedFieldsUser.casinoCommission,
      coins: updatedFieldsUser.coins,
      reference: updatedFieldsUser.reference,
      creditReference: 0,
      commChangeType: updatedFieldsUser.commissionChangeType,
      maxCommValue:
        updatedFieldsUser.commissionChangeType === "change"
          ? updatedFieldsUser.maxCommValue
          : 0,
      betStatus: true,
      matchStatus: true,
      matkaStatus: updatedFieldsUser?.matkaStatus,
      transactionPassword: "1122",
      allowedDomains: userType?.userType === "subowner" ? updatedFieldsUser.selectDomain : null,
    };

    
    await dispatch(userCreate(userCreateFun))
    reset();
  };

  useEffect(() => {
    if (updateUserList === true) {
      setIsDrawerOpen({ addNewUserDrawer: false });
    }

    dispatch(getDomainList())
    dispatch(getUserBalance())



    const getUserDetails = async () => {
      let reqData = {
        userId: addUserProps?.userId
      };
      try {
        let response = await apiCall("POST", "user/userDetails", reqData);
        if (response) {
          setUserDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getUserDetails()

  }, [updateUserList]);



  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setIsDrawerOpen({ addNewUserDrawer: false })}
      direction="right"
      size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
      overlay={true}
    >

      <div className="h-[95vh] pt-2 pb-20 md:pb-6">
        <div className="text-white text-[16px] m-1 font-bold border-b border-b-[#665d49] pb-2">
          Add User For {addUserProps?.name} [ {addUserProps?.username} ]
        </div>
        <div className="px-3 py-2 h-full overflow-y-auto">

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-x-4 mt-2">
              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Parent Username:
                </label>
                <input
                  className="w-full h-[34px] px-[12px] py-[6px] text-[14px]"
                  value={addUserProps?.username}
                  disabled
                  {...register("userId")}
                />
              </div>
              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  User Name:
                </label>
                <input
                  className={`w-full h-[34px] px-[12px]  py-[6px] text-[14px] ${errors.username ? "border-red-500" : ""
                    }`}
                  type="text"
                  placeholder="User Name"
                  {...register("username", {
                    required: "User Name is required",
                  })}
                />
                {errors.username && (
                  <span className="text-red-500 text-sm">
                    {errors.username.message}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-4 mt-2">

              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Name:
                </label>
                <input
                  className={`w-full h-[34px] px-[12px] py-[6px]  text-[14px] ${errors.name ? "border-red-500" : ""
                    }`}
                  type="text"
                  placeholder="Name"
                  {...register("name", {
                    required: "Name is required",
                  })}
                />
                {errors.name && (
                  <span className="text-red-500 text-sm">
                    {errors.name.message}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-4 mt-4">
              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Contact No:
                </label>
                <input
                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${errors.contact ? "border-red-500" : ""
                    }`}
                  type="number"
                  placeholder="Contact Number"
                  {...register("contact", {
                    required: "Contact Number is required",
                    // pattern: {
                    //   value: /^[0-9]{10}$/,
                    //   message: "Enter a valid 10-digit contact number",
                    // },
                  })}
                />
                {errors.contact && (
                  <span className="text-red-500 text-sm">
                    {errors.contact.message}
                  </span>
                )}
              </div>

              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Coins{" "}
                  <span className="text-red-500 text-sm">
                    [{addUserProps?.coins ? addUserProps?.coins : addUserProps?.balance}]
                  </span>
                  :
                </label>
                <input
                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${errors.coins ? "border-red-500" : ""
                    }`}
                  type="number"
                  placeholder="Coins"
                  {...register("coins", {
                    required: "Coins are required",
                    max: {
                      value: addUserProps?.coins,
                      message: `Coins cannot be more than ${addUserProps?.coins}`,
                    },
                  })}
                />
                {errors.coins && (
                  <span className="text-red-500 text-sm">
                    {errors.coins.message}
                  </span>
                )}
              </div>
            </div>



            <div className="grid grid-cols-2 gap-x-4 mt-4">
              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Password:
                </label>
                <input
                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${errors.password ? "border-red-500" : ""
                    }`}
                  type="password"
                  placeholder="Password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                {errors.password && (
                  <span className="text-red-500 text-sm">
                    {errors.password.message}
                  </span>
                )}
              </div>

              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Confirm Password:
                </label>
                <input
                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${errors.confirmPassword ? "border-red-500" : ""
                    }`}
                  type="password"
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === getValues("password") ||
                      "Passwords do not match",
                  })}
                />
                {errors.confirmPassword && (
                  <span className="text-red-500 text-sm">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
            </div>



            {userType?.userType !== "subowner" ? null :
              <div className="mt-4">
                {userType?.userType !== 'subowner' ? null : (
                  <div className="mt-4">
                    <label className="text-[13px] font-bold text-white mb-[5px]">Select Domain:</label>
                    <Controller
                      name="selectDomain"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isMulti
                          options={getDomainListData?.map(domain => ({
                            value: domain.domainName,
                            label: domain.domainName,
                          }))}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />

                  </div>
                )}



              </div>}

            <div className="mt-4">
              <label className="text-[13px] font-bold text-white mb-[5px]">
                Remark:
              </label>
              <textarea
                className="w-full px-[5px] py-[6px]"
                placeholder="Remark"
                {...register("remark")}
              />
            </div>

            <div className="text-[13px] font-bold text-white mb-[5px] border-b border-b-[#665d49] pb-2 mt-4">
              Set commission setting
            </div>

            <div>
              <div
                style={{ backgroundImage: "linear-gradient(#05537e, #27a6c3)" }}
                className="text-white mt-3 uppercase font-bold h-[38px] flex items-center justify-center text-[14px] bg-[#27a6c3]"
              >
                Sports Settings
              </div>


            </div>
            <div>
              <Accordion className="rounded-0" defaultExpanded>
                <AccordionSummary
                  sx={{
                    margin: 0,
                    height: "25px",
                    padding: "10px",
                    borderBottom: "1px solid #e4e4e4",
                    borderRadius: "0",
                    marginTop: "0px"
                  }}
                  className="!rounded-0"
                  expandIcon={
                    <IoIosArrowDown size={14} className="text-[#337ab7]" />
                  }
                  id="panel1-header"
                >
                  <div className=" flex items-center gap-1">
                    <div className="text-[14px] font-semibold text-[#23527c] uppercase">
                      cricket
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-3 gap-3">
                    <div>
                      <label className="text-[13px] font-bold text-black">
                        Match <br /> Share{" "}
                        <span className="text-red-700">
                          [{addUserProps?.userMatchShare ? addUserProps?.userMatchShare : addUserProps?.matchShare}]
                        </span>
                      </label>
                      <input
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px]"
                        type="number"
                        placeholder="Match Share"
                        {...register("userMatchShare", {
                          required: "Match share is required",
                          max: {
                            value: addUserProps?.userMatchShare ? addUserProps?.userMatchShare : addUserProps?.matchShare,
                            message: `Value cannot exceed ${addUserProps?.userMatchShare ? addUserProps?.userMatchShare : addUserProps?.matchShare}`,
                          },
                        })}
                      />
                      {errors.userMatchShare && (
                        <span className="text-red-500 text-sm">
                          {errors.userMatchShare.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label className="text-[13px] font-bold text-black">
                        Match <br /> Commission{" "}
                        <span className="text-red-700">
                          [{addUserProps?.userMatchCommission ? addUserProps?.userMatchCommission : addUserProps?.matchCommission}]
                        </span>
                      </label>
                      <input
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px]"
                        type="number"
                        placeholder="Match Commission"
                        {...register("userMatchCommission", {
                          required: "Match Commission is required",
                          max: {
                            value: addUserProps?.userMatchCommission ? addUserProps?.userMatchCommission : addUserProps?.matchCommission,
                            message: `Value cannot exceed ${addUserProps?.userMatchCommission ? addUserProps?.userMatchCommission : addUserProps?.matchCommission}`,
                          },
                        })}
                      />
                      {errors.userMatchCommission && (
                        <span className="text-red-500 text-sm">
                          {errors.userMatchCommission.message}
                        </span>
                      )}
                    </div>
                    <div>
                      <label className="text-[13px] font-bold text-black">
                        Session <br /> Commission{" "}
                        <span className="text-red-700">
                          [{addUserProps?.userSessionCommission ? addUserProps?.userSessionCommission : addUserProps?.sessionCommission}]
                        </span>
                      </label>
                      <input
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px]"
                        type="number"
                        placeholder="Session Commission"
                        {...register("userSessionCommission", {
                          required: "Session Commission is required",
                          max: {
                            value: addUserProps?.userSessionCommission ? addUserProps?.userSessionCommission : addUserProps?.sessionCommission,
                            message: `Value cannot exceed ${addUserProps?.userSessionCommission ? addUserProps?.userSessionCommission : addUserProps?.sessionCommission}`,
                          },
                        })}
                      />
                      {errors.userSessionCommission && (
                        <span className="text-red-500 text-sm">
                          {errors.userSessionCommission.message}
                        </span>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div>
              <Accordion className="rounded-0" defaultExpanded>
                <AccordionSummary
                  sx={{
                    margin: 0,
                    height: "25px",
                    padding: "10px",
                    borderBottom: "1px solid #e4e4e4",
                    borderRadius: "0",
                  }}
                  className="!rounded-0"
                  aria-controls="panel1-content"
                  expandIcon={
                    <IoIosArrowDown size={14} className="text-[#337ab7]" />
                  }
                  id="panel1-header"
                >
                  <div className="flex items-center gap-1">
                    <div className="text-[14px] font-semibold text-[#23527c] uppercase">
                      Casino
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        casino share{" "}
                        <span className="text-red-700">
                          [{addUserProps?.userCasinoShare ? addUserProps?.userCasinoShare : addUserProps?.casinoShare}]
                        </span>
                      </label>
                      <input
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder="Casino Share"
                        {...register("userCasinoShare", {
                          required: "Casino share is required",
                          max: {
                            value: addUserProps?.userCasinoShare ? addUserProps?.userCasinoShare : addUserProps?.casinoShare,
                            message: `Maximum value is ${addUserProps?.userCasinoShare ? addUserProps?.userCasinoShare : addUserProps?.casinoShare}`,
                          },
                        })}
                      />
                      {errors.userCasinoShare && (
                        <p className="text-red-500 text-sm">
                          {errors.userCasinoShare.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        casino Commission{" "}
                        <span className="text-red-700">
                          [{addUserProps?.userCasinoCommission ? addUserProps?.userCasinoCommission : addUserProps?.casinoCommission}]
                        </span>
                      </label>
                      <input
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder="Casino Commission"
                        {...register("userCasinoCommission", {
                          required: "Casino commission is required",
                          max: {
                            value: addUserProps?.userCasinoCommission ? addUserProps?.userCasinoCommission : addUserProps?.casinoCommission,
                            message: `Maximum value is ${addUserProps?.userCasinoCommission ? addUserProps?.userCasinoCommission : addUserProps?.casinoCommission}`,
                          },
                        })}
                      />
                      {errors.userCasinoCommission && (
                        <p className="text-red-500 text-sm">
                          {errors.userCasinoCommission.message}
                        </p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div>
              <Accordion className="rounded-0" defaultExpanded>
                <AccordionSummary
                  sx={{
                    margin: 0,
                    height: "25px",
                    padding: "10px",
                    borderBottom: "1px solid #e4e4e4",
                    borderRadius: "0",
                  }}
                  className="!rounded-0"
                  aria-controls="panel2-content"
                  expandIcon={
                    <IoIosArrowDown size={14} className="text-[#337ab7]" />
                  }
                  id="panel2-header"
                >
                  <div className="flex items-center gap-1">
                    <div className="text-[14px] font-semibold text-[#23527c] uppercase">
                      International Casino
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      id="togBtn"
                      {...register("intCasinoStatus", { required: false })}
                      defaultChecked={addUserProps?.intCasinoStatus}
                    />
                    <div className="slider round">
                      <span className="on">ON</span>
                      <span className="off">OFF</span>
                    </div>
                  </label>

                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        International Casino share{" "}
                        <span className="text-red-700">
                          [{addUserProps?.intCasinoShare ? addUserProps?.intCasinoShare : userDetails?.intCasinoShare}]
                        </span>
                      </label>
                      <input
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder="Int Casino Share"
                        {...register("intCasinoShare", {
                          required: "International Casino share is required",
                          max: {
                            value: addUserProps?.intCasinoShare,
                            message: `Maximum value is ${addUserProps?.intCasinoShare}`,
                          },
                        })}
                      />
                      {errors.intCasinoShare && (
                        <p className="text-red-500 text-sm">
                          {errors.intCasinoShare.message}
                        </p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div>
              <Accordion className="rounded-0" defaultExpanded>
                <AccordionSummary
                  sx={{
                    margin: 0,
                    height: "25px",
                    padding: "10px",
                    borderBottom: "1px solid #e4e4e4",
                    borderRadius: "0",
                  }}
                  className="!rounded-0"
                  aria-controls="panel3-content"
                  expandIcon={
                    <IoIosArrowDown size={14} className="text-[#337ab7]" />
                  }
                  id="panel3-header"
                >
                  <div className="flex items-center gap-1">
                    <div className="text-[14px] font-semibold text-[#23527c] uppercase">
                      Matka
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      id="togBtn"
                      {...register("matkaStatus", { required: false })}
                      defaultChecked={addUserProps?.matkaStatus}
                    />
                    <div className="slider round">
                      <span className="on">ON</span>
                      <span className="off">OFF</span>
                    </div>
                  </label>

                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        Matka share{" "}

                        <span className="text-red-700">
                          [{addUserProps?.userMatkaShare ? addUserProps?.userMatkaShare : userDetails?.userMatkaShare}]
                        </span>
                      </label>
                      <input
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder="Matka share"
                        {...register("userMatkaShare", {
                          required: "Matka share is required",
                          max: {
                            value: addUserProps?.userMatkaShare,
                            message: `Maximum value is ${addUserProps?.userMatkaShare}`,
                          },
                        })}
                      />
                      {errors.userMatkaShare && (
                        <p className="text-red-500 text-sm">
                          {errors.userMatkaShare.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        Matka Commission{" "}
                        <span className="text-red-700">
                          [{addUserProps?.userMatkaCommission ? addUserProps?.userMatkaCommission : userDetails?.userMatkaCommission}]
                        </span>
                      </label>
                      <input
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder=" Matka Commission"
                        {...register("userMatkaCommission", {
                          required: "Matka commission is required",
                          max: {
                            value: addUserProps?.userMatkaCommission,
                            message: `Maximum value is ${addUserProps?.userMatkaCommission}`,
                          },
                        })}
                      />
                      {errors.userMatkaCommission && (
                        <p className="text-red-500 text-sm">
                          {errors.userMatkaCommission.message}
                        </p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="flex justify-end mt-8">
              <button
                type="button"
                className="bg-[#3f5c61] text-white py-[6px] px-4 text-[13px] mr-2"
                onClick={() => setIsDrawerOpen({ addNewUserDrawer: false })}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#006e92] text-white py-[6px] px-4 text-[13px]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Drawer>
  );
};

export default ADDUSER;
