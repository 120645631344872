import { apiCall } from "../../config/Http";


async function sportMatchList(data) {
  try {
      const response = await apiCall("POST", "sports/matchList", data);
      if (response) {
          return response;
      }
  } catch (error) {
    console.error("Sport Match List", error);
    return Promise.reject(error);
  }
}
async function sportMatchDetails(data) {
  try {
      const response = await apiCall("POST", "sports/sportByMarketId", data);
      if (response && response?.data) {
          return response.data;
      }
  } catch (error) {
    console.error("Sport Match Details", error);
    return Promise.reject(error);
  }
}
async function sportMatchOddsPosition(data) {
  try {
      const response = await apiCall("POST", "sports/getOddsPosition", data);
      if (response && response?.data) {
          return response.data;
      }
  } catch (error) {
    console.error("Sport Match Position", error);
    return Promise.reject(error);
  }
}
async function sportMatchBetList(data) {
  try {
      const response = await apiCall("POST", "sports/betsList", data);
      if (response && response?.data) {
          return response.data;
      }
  } catch (error) {
    console.error("Sport Match  BetList", error);
    return Promise.reject(error);
  }
}

async function getCompletedSportList(data) {
  try {
      const response = await apiCall("POST", "decision/completeSportList", data);
      if (response && response?.data) {
          return response?.data;
      }
  } catch (error) {
    console.error("user completeSportList respons error", error);
    return Promise.reject(error);
  }
}






export const sportsServices = {

  sportMatchList,
  sportMatchDetails,
  sportMatchOddsPosition,
  sportMatchBetList,
  getCompletedSportList

};