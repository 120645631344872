import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Header from "../component/header/Header";
import Sidebar from "../component/sidebar/Sidebar";
import Dashboard from "../pages/dashboard/Dashboard";
import Roles from "../pages/roles/Roles";
import { useEffect } from "react";
import { getUserBalance } from "../redux/_reducers/_user_reducers";
import { useDispatch } from "react-redux";
import MatchDetails from "../pages/details/MatchDeatils";
import Inplay from "../pages/inplay/Inplay";
import Settlement from "../pages/settlement/Settlement";
import AccountStatment from "../pages/accountStatment/AccountStatment";
import UserList from "../pages/userList/UserList";
import MatchesHistroy from "../pages/matchesHistory/MatchesHistroy";
import MatchHistoryBet from "../pages/matchHistoryBet/MatchHistoryBet";
import { BalanceProvider } from "../component/balanceContext/BalanceContext";
import FancyHistory from "../pages/fancyHistory/FancyHistory";
import ProfitLoss from "../pages/profitLoss/ProfitLoss";
import MyBets from "../pages/myBets/MyBets";
import ClosedAccounts from "../pages/closedAccounts/ClosedAccounts";
import ManageMarket from "../pages/manageMarket/ManageMarket";
import Deposit from "../pages/depositWithdrawal/Deposit";
import Withdrawal from "../pages/depositWithdrawal/Withdrawal";
import EditWebsite from "../pages/websiteSetting/EditWebsite";
import CashTransaction from "../pages/CashTransaction/CashTransaction";
import InplayCasino from "../pages/casino/InplayCasino";
import CompleteCasino from "../pages/casino/CompletedCasino";
import DiamondCasinoDetails from "../pages/casino/DiamondCasinoDetails/index";
import InternetionalCasinoList from "../pages/casino/InternetionalCasino";
import CasinoInplayView from "../pages/casino/CasinoViewInplay";
import InplayCasinoDetails from "../pages/casino/InplayCasinoDetails";
import CasinoBetListData from "../pages/casino/CasinoBets";
import CasinoViewDetails from "../pages/casino/ViewCasino";
import PlusminusCasinoDetails from "../pages/casino/PlusminusCasinoDetails";
import Demo from "../pages/casino/Demo/Demo";
import InternetionalCasinoBet from "../pages/casino/internetionalCasinoBet/InternetionalCasinoBet";
import InplayMatka from "../pages/Matka/InplayMatka";
import MatkaView from "../pages/Matka/MatkaView/Basic";
import FinisedManageMarket from "../pages/FinisedManageMarket/FinisedManageMarket";
import AccountUpdate from "../pages/AccountSettings/AccountUpdate";
import TotalWithdrwal from "../pages/reports/totalwithdrwal/TotalWithdrwal";
import AllTotalWithdrawal from "../pages/depositWithdrawal/AllTotalWithdrawal";
import EventMatchHistoryBet from "../pages/eventMatchHistoryBet/EventMatchHistoryBet";





function Layout() {
  const navigate = useNavigate();
  

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      localStorage.clear()
      navigate("/login");
    }
  }, [navigate]);
    
    const dispatch = useDispatch();
    useEffect(()=>{
   dispatch(getUserBalance())
  
  
    },[])
  return (
    <BalanceProvider>
      <Header />
     
     <Routes>
        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/viewmatch-details/:marketIdEnc?/:eventIdEnc?" element={<MatchDetails/>} />
        <Route path="/inplay-matche" element={<Inplay/>} />
        <Route path="/settalment/:userTypeEnc?/:userIdEnc?/:userNameEnc?" element={<Settlement/>} />
        <Route path="/account-statement/:userId?" element={<AccountStatment/>} />
        <Route path="/user-list/:sidebarTypeEnc/:userTypeEnc?/:userIdEnc?/:downlinePriorityEnc" element={<UserList />} />
        <Route path="/matches-history" element={<MatchesHistroy />} />
        <Route path="/manage-market" element={<ManageMarket />} />
        <Route path="/complete-manage-market" element={<FinisedManageMarket />} />
        <Route path="/matches-bet-history/:userNameEnc?" element={<MatchHistoryBet/>} />

        <Route path="/event-matches-bet-history/:marketIdEnc?/:selectionIdEnc?/:oddsTypeEnc?/:userIdEnc?/:userTypeEnc?" element={<EventMatchHistoryBet/>} />
        <Route path="/fancy-history/:marketIdEnc?" element={<FancyHistory/>} />
        <Route path="/profit-loss/:userIdEnc?/:userTypeEnc?" element={<ProfitLoss/>} />
        <Route path="/myBets" element={<MyBets/>} />
        <Route path="/closed-accounts" element={<ClosedAccounts/>} />
        <Route path="/deposit/:dateFnc?/:statusFnc?" element={<Deposit/>} />
        <Route path="/withdraw/:dateFnc?/:statusFnc?" element={<Withdrawal/>} />
        <Route path="/alltotalwithdraw/:dateFnc?/:statusFnc?" element={<AllTotalWithdrawal/>} />
        <Route path="/website-setting" element={<EditWebsite/>} />
        <Route path="/account-update-setting" element={<AccountUpdate />} />
        <Route path="/cash-transaction/:userTypeEnc?" element={<CashTransaction/>} />
        <Route path="/inplaycasino" element={<InplayCasino/>} />
        <Route path="/completedcasino" element={<CompleteCasino/>} />
        <Route path="/diamond-casino-details" element={<DiamondCasinoDetails/>} />
        <Route path="/internetionalcasinodeatils/:fromDate?/:toDate?" element={<InternetionalCasinoList/>} />
        <Route path="/casinoinplayview/:eventId?/:gameName?" element={<CasinoInplayView/>} />
        <Route path="/inplaycasinodetails/:name?/:id?/:payloadDate?" element={<InplayCasinoDetails/>} />
        <Route path="/casinobets/:gameId?" element={<CasinoBetListData/>} />
        <Route path="/casinoviewdetails/:eventId?/:date?" element={<CasinoViewDetails/>} />
        <Route path="/plusminuscasinodeatils-data/:date?" element={<PlusminusCasinoDetails />} />
        <Route path="/Demo/:_id?" element={<Demo />} />
        <Route path="/internetionalcasino-bet/:_id?/:fromDate?/:toDate?" element={<InternetionalCasinoBet />} />
        <Route path="/inplay-matka" element={<InplayMatka />} />
        <Route path="/view-matka/:eventIdMatka?" element={<MatkaView />} />
        <Route path="/total-deposit-withdrwal" element={<TotalWithdrwal />} />

        
      </Routes>

      <Sidebar />
  
      </BalanceProvider>
  );
}

export default Layout;



