import React, {Component} from "react";
import { Col, Row} from "antd";


import NavigationTop from "./NavigationTop";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";


class DiamondCasinoDetails extends Component {

  render() {
    return (

      <Row justify={"center"}>
          <Col xs={24}>
          <NavigationTop/>
          </Col>
      </Row>
    );
  }
}

export default DiamondCasinoDetails;
