import { apiCall } from "../../config/Http";
import { domainName } from "../../constents/global";


async function login(data) {
  try {
      const user = await apiCall("POST", "user/login", data);
      
      if (user) {
      
          localStorage.setItem(`user_info_${domainName}`, JSON.stringify(user));
          localStorage.setItem("token",JSON.stringify(user?.token))
          return { userinfo: user };
      }
  } catch (error) {
      console.error("Login error:", error);
      return Promise.reject(error);
  }
}

function logout() {
  alert('1')
  localStorage.clear();
  window.location.href("/login")
}


export const authServices = {
  login,
  logout,
};