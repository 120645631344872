import React from "react";
import { Row, Col } from "antd";
import Basic from "./Basic";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";


const CasinoBetListData = () => {
  return (
    <GlobalLayout>
      <Row justify={"center"}>
      <Col xl={22}>
        <Basic />
      </Col>
    </Row>
    </GlobalLayout>
  );
};

export default CasinoBetListData;