/* eslint-disable react-hooks/exhaustive-deps */
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  getSportMatchBetList,
  getSportMatchdetailsByMarketId,
  getSportMatchOddsPosition,
} from "../../redux/_reducers/_sports_reducers";
import Loader from "../../component/loader/Loader";
import { VscTriangleRight } from "react-icons/vsc";
import useSocketCacheManager from "./socketCacheService";
import BlinkingComponent from "./BlinkingComponent";
import moment from "moment/moment";
import { HiRefresh } from "react-icons/hi";
import CustomModal from "../../global_layouts/Modal/CustomModal";
import { decrypt } from "../../config/Encryption";
import MatchBookModal from "../../component/matchBook/MatchBookModal";

const MatchDetails = () => {
  const { marketIdEnc, eventIdEnc } = useParams();
  const marketId = decrypt(marketIdEnc)
  const eventId = decrypt(eventIdEnc)
  const dispatch = useDispatch();
  const [matchScoreData, setMatchScoreData] = useState({
    dataByMarketId: [],
    dataByEventId: [],
  });
  const [isBookModal, setIsBookModal] = useState(false);

  const [bookmaker, setBookmaker] = useState({});
  const [toss, setToss] = useState({});
  const [bookmakerTeamData, setBookmakerTeamData] = useState([]);
  const [matchOdds, setMatchOdds] = useState({});
  const [tied, setTied] = useState({});
  const [completed, setCompleted] = useState({});




  const [modaleData, setModaleData] = useState(null);
  const [coinData, setCoinData] = useState({
    minMax: { max: null, min: null },
    session: { max: null, min: null },
    isTie: { max: null, min: null },
    isToss: { max: null, min: null },
  });
  const [fancyBetList, setFancyBetList] = useState([]);
  const [oddsBetList, setOddsBetList] = useState([]);
  const { matchDetailsByMarketId, loading, oddsPosition, sportBetList } =
    useSelector((state) => state.sport);
  const pollingIntervalRef = useRef(null);

  ///////////////////////////------------------Getting initial Data ----------------///////////////////////////////
  useEffect(() => {
    dispatch(getSportMatchdetailsByMarketId({ marketId }));
    dispatch(getSportMatchOddsPosition({ marketId: marketId }));
    let reqData = {
      isDeclare: false,
      isDeleted: false,
      oddsBet: true,
      pageNo: 1,
      size: 20,
      fancyBet: true,
      marketId: marketId,
    }
    dispatch(getSportMatchBetList(reqData));
    return () => {
      disconnectSocket();
      clearInterval(pollingIntervalRef.current);
    };
  }, [marketId]);


  ///////////////////////////////------------------Checking Visibility State and handling code--------------------/////////////////////////////
  const { connectSocket, disconnectSocket, callCache, handleVisibilityChange } =
    useSocketCacheManager(
      matchDetailsByMarketId,
      setMatchScoreData,
      eventId,
      marketId
    );
  useEffect(() => {
    if (matchDetailsByMarketId) {
      if (matchDetailsByMarketId.socketPerm) {
        connectSocket(matchDetailsByMarketId.socketUrl);
      } else {
        callCache(
          matchDetailsByMarketId.cacheUrl,
          matchDetailsByMarketId.otherMarketCacheUrl
        );
      }
    }

    return () => {
      disconnectSocket();
      clearInterval(pollingIntervalRef.current);
    };
  }, [matchDetailsByMarketId]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [matchDetailsByMarketId]);

  /////////////////////------------------*max min value ------------------/////////////////////////////
  useEffect(() => {
    const maxCoinData = matchDetailsByMarketId?.maxMinCoins
      ? JSON.parse(matchDetailsByMarketId?.maxMinCoins)
      : {
        maximum_match_bet: null,
        minimum_match_bet: null,
        maximum_session_bet: null,
        minimum_session_bet: null,
        maximum_tie_coins: null,
        maximum_toss_coins: null,
        maximum_matchOdds_coins: null,
      };

    setCoinData({
      minMaxCoins: {
        max: maxCoinData.maximum_match_bet,
        min: maxCoinData.minimum_match_bet,
      },
      sessionCoin: {
        max: maxCoinData.maximum_session_bet,
        min: maxCoinData.minimum_session_bet,
      },
      isTieCoin: {
        max:
          maxCoinData.maximum_tie_coins > 0
            ? maxCoinData.maximum_tie_coins
            : maxCoinData.maximum_match_bet,
        min: maxCoinData.minimum_match_bet,
      },
      isTossCoin: {
        max:
          maxCoinData.maximum_toss_coins > 0
            ? maxCoinData.maximum_toss_coins
            : maxCoinData.maximum_match_bet,
        min: maxCoinData.minimum_match_bet,
      },
      isMatchCoin: {
        max:
          maxCoinData.maximum_matchOdds_coins > 0
            ? maxCoinData.maximum_matchOdds_coins
            : maxCoinData.maximum_match_bet,
        min: maxCoinData.minimum_match_bet,
      },
    });



    if (matchDetailsByMarketId) {
      let BookmakerData = {};
      let tossOdds = {};
      let bookmakerTeamDataBook = matchDetailsByMarketId?.teamData
      let bookmakerTeamData = JSON.parse(bookmakerTeamDataBook);

      bookmakerTeamData?.forEach((data) => {
        BookmakerData[data.bookmakerSelectionId] = data.runner_name;
        tossOdds[data.bookmakerSelectionId * 10000] = data.runner_name;
      });
      setBookmaker(BookmakerData);
      setToss(tossOdds);
      setBookmakerTeamData(bookmakerTeamData);

      let marketListBook = matchDetailsByMarketId?.marketList;

      let matchOddsTemp = {};
      let tiedOddsTemp = {};
      let completedOddsTemp = {};

      if (marketListBook && marketListBook.length > 0) {
        marketListBook.forEach((market) => {
          let oddsType = market.marketType;
          let selectionData = market.selectionIdData;

          // Handling different odds types
          if (oddsType === "Match Odds") {
            selectionData.forEach((selection) => {
              matchOddsTemp[selection.selectionId] = selection.runnerName;
            });
          }

          if (oddsType === "Tied Match") {
            selectionData.forEach((selection) => {
              tiedOddsTemp[selection.selectionId] = selection.runnerName;
            });
          }

          if (oddsType === "Completed Match") {
            selectionData.forEach((selection) => {
              completedOddsTemp[selection.selectionId] = selection.runnerName;
            });
          }
        });

        // Set the state for odds data
        setMatchOdds(matchOddsTemp);
        setTied(tiedOddsTemp);
        setCompleted(completedOddsTemp);
      }

    }




  }, [matchDetailsByMarketId]);
  ////////////////////////////////-------------------every 10 sec betlist calling -----------------------///////////
  const fetchBetList = () => {
    let reqData = {
      isDeclare: false,
      isDeleted: false,
      oddsBet: true,
      fancyBet: true,
      marketId: marketId,
      pageNo: 1,
      size: 20,
    }
    dispatch(getSportMatchBetList(reqData));
  };
  const fetchOddsPosition = () => {
    dispatch(getSportMatchOddsPosition({ marketId: marketId }));
  };

  useEffect(() => {
    const betListInterval = setInterval(fetchBetList, 10000);
    const oddsPositionInterval = setInterval(fetchOddsPosition, 5000);
    return () => {
      clearInterval(betListInterval);
      clearInterval(oddsPositionInterval);
    };
  }, [marketId]);

  //////////////////////////--------------------- betlist management --------------///////////////////
  useEffect(() => {
    if (sportBetList) {
      if (sportBetList?.fancyBetData) {
        setFancyBetList(sportBetList?.fancyBetData);
      }
      if (sportBetList?.oddsBetData) {
        setOddsBetList(sportBetList?.oddsBetData);
      }
    }
  }, [sportBetList]);

  //////////////////////-----------calculate profit loss for betList ---------------------///////////////
  function calculateProfitLoss(element) {
    let profit = element.profit
      ? Number.parseFloat(Math.abs(element.profit)).toFixed(2)
      : "0.00"; // Default to '0.00' as a string

    let loss = element.loss
      ? Number.parseFloat(Math.abs(element.loss)).toFixed(2)
      : "0.00"; // Default to '0.00' as a string

    if (element.type === "L") {
      profit = element.positionInfo[element.selectionId];
      loss = (-1 * element.amount).toFixed(2);
    }

    if (element.type === "K" || element.type === "k") {
      profit = element.amount.toFixed(2);
      loss = (-1 * (element.amount * element.odds)).toFixed(2);
    }

    return {
      profit,
      loss,
    };
  }

  ////////////////////////---------------- function to fetch Type ----------------------/////////////////////////
  function getTypeDescription(type) {
    switch (type.toLowerCase()) {
      case "n":
        return "NO";
      case "y":
        return "Yes";
      case "l":
        return "Lagai";
      case "k":
        return "Khaai";
      default:
        return "Unknown"; // fallback if type doesn't match
    }
  }

  const handleMinMaxOddsModal1 = (element) => {
    try {
      setIsBookModal(prevState => !prevState);
      setModaleData(element);
    } catch (error) {
      console.error("Error in isBookModal:", error);
    }
  };

  return (
    <GlobalLayout>
      <div class="py-1 rounded mb-2.5   bg-gradient-to-t from-[#0c0e5f] to-[#0277b7]">
        <div class=" flex justify-between items-center w-100 lg:px-4 px-2 text-white">
          <div class="info-box-number">
            <span>
              <i class="fa-solid fa-arrow-left px-2"></i>
              Back
            </span>
            <br />
            {matchDetailsByMarketId?.matchName}
          </div>
          <div class="info-box-number">{matchDetailsByMarketId?.matchDate}</div>
        </div>
      </div>

      <div className="row uppercase ">

        {/* -------------------------------------------------- Top left section Data ---------------------------------------------------- */}
        <div className="col-md-6">
          {/* -------------------------------------------------- Bookmaker Data ---------------------------------------------------- */}
          {matchDetailsByMarketId?.isBookmaker && (
            <div className="card">
              {matchScoreData?.dataByMarketId?.team_data &&
                matchScoreData?.dataByMarketId?.team_data.length > 0 && (
                  <table className="mb-0 border md:text-sm text-xs font-semibold border-gray-200">
                    <thead>
                      <tr className="bg-zinc-800 text-white border-b border-gray-200">
                        <th className="flex p-2 justify-between items-start lg:items-center border-r border-gray-200">
                          <div>
                            <div className="font-bold ">Bookmaker</div>
                            <div className="font-bold ">
                              BET :{" "}
                              {coinData && coinData.minMax && coinData.minMax.min
                                ? coinData.minMax.min
                                : "0"}{" "}
                              -
                              {coinData && coinData.minMax && coinData.minMax.max
                                ? coinData.minMax.max
                                : "0"}{" "}
                            </div>
                          </div>
                          <div className="flex gap-1 flex-wrap  md:w-1/2 w-fit justify-end items-center">
                            {/* <div className="inline-flex items-center cursor-pointer">
                            <span className="ml-3 mr-1  font-medium text-white">
                              Bet Lock
                            </span>
                            <label class="switch mb-0">
                              <input type="checkbox" id="togBtn" />
                              <div class="slider round">
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                              </div>
                            </label>
                          </div> */}
                            <button onClick={() => handleMinMaxOddsModal1({ type: "bookmaker" })} className="ml-1 ms:ml-3 bg-[#22a7f0] hover:bg-blue-500 text-[13px] text-white font-bold  px-[16px] py-[2px]  rounded btn btn-xs md:btm-sm">
                              Book
                            </button>
                          </div>
                        </th>
                        <th className="w-[18%] text-center border-r border-gray-200">
                          Back
                        </th>
                        <th className="w-[18%] text-center">Lay</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchScoreData?.dataByMarketId?.team_data.map(
                        (data, index) => {
                          return (
                            <tr className="bg-white  border-b border-gray-200">
                              <td className="py-1 border-r pl-2 border-gray-200">
                                <div className="font-bold">{data?.team_name}</div>
                                <div
                                  className={` ${(oddsPosition?.find(
                                    (ele) => ele._id == data.selectionid
                                  )?.Position
                                    ? -1 *
                                    oddsPosition?.find(
                                      (ele) => ele._id == data.selectionid
                                    )?.Position
                                    : 0) >= 0
                                    ? "text-sky-500"
                                    : "text-rose-500"
                                    }  flex gap-1 justify-start items-center`}
                                >
                                  {" "}
                                  <VscTriangleRight />{" "}
                                  {(oddsPosition?.find(
                                    (ele) => ele._id == data.selectionid
                                  )?.Position
                                    ? -1 *
                                    oddsPosition?.find(
                                      (ele) => ele._id == data.selectionid
                                    )?.Position
                                    : 0).toFixed(2)}
                                </div>
                              </td>
                              <td className="py-1 text-center font-bold relative text-sm  border-r border-gray-200">
                                <BlinkingComponent
                                  price={
                                    data?.lgaai && data.lgaai !== 0
                                      ? (Number(data?.lgaai) * 100).toFixed(0)
                                      : "--"
                                  }
                                  size={null}
                                  color={"bg-sky-300"}
                                />
                                {/* {data?.lgaai && data.lgaai !== 0
                                ? (Number(data?.lgaai) * 100).toFixed(0)
                                : "--"} */}
                              </td>
                              <td className="py-1 text-center relative font-bold  text-sm  border-r ">
                                <BlinkingComponent
                                  price={
                                    data?.khaai
                                      ? (Number(data?.khaai) * 100).toFixed(0)
                                      : "--"
                                  }
                                  size={null}
                                  color={"bg-rose-300"}
                                />
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                )}
            </div>

          )}
          {/* -------------------------------------------------- Match odds Data ---------------------------------------------------- */}
          {matchDetailsByMarketId?.isMatchOdds && (
            <div className="card">
              {matchScoreData?.dataByEventId && matchScoreData?.dataByEventId?.find((ele) => ele.marketType === "Match Odds") && matchScoreData?.dataByEventId?.find((ele) => ele.marketType === "Match Odds")?.runners.length > 0 && (
                <table className="mb-0 border md:text-sm text-xs font-semibold border-gray-200">
                  <thead>
                    <tr className="bg-zinc-800 text-white border-b border-gray-200">
                      <th className="flex p-2 justify-between items-start lg:items-center border-r border-gray-200">
                        <div>
                          <div className="font-bold ">match odds</div>
                          <div className="font-bold ">
                            BET :{" "}
                            {coinData && coinData.is && coinData.is.min
                              ? coinData.is.min
                              : "0"}{" "}
                            -
                            {coinData && coinData.is && coinData.is.max
                              ? coinData.is.max
                              : "0"}
                          </div>
                        </div>
                        <div className="flex gap-1 md:w-1/2 w-fit justify-end flex-wrap items-center">
                          <div className="inline-flex items-center cursor-pointer">
                            {/* <span className="ml-3 mr-1  font-medium text-white">
                              Bet Lock
                            </span> */}

                            {/* <label class="switch mb-0">
                              <input type="checkbox" id="togBtn" />
                              <div class="slider round">
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                              </div>
                            </label> */}
                            {/* <input
                              type="checkbox"
                              defaultChecked
                              className="sr-only peer"
                            />
                            <div className="relative w-11 h-6 bg-gray-300 rounded-full peer-focus:outline-none   dark:bg-gray-700 peer-checked:bg-green-600 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-gray-200 after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-200 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div> */}
                          </div>
                          <button onClick={() => handleMinMaxOddsModal1({ type: "matchOdds" })} className="ml-1 ms:ml-3 bg-[#22a7f0] hover:bg-blue-500 text-[13px] text-white font-bold  px-[16px] py-[2px]  rounded btn btn-xs md:btm-sm">
                            Book
                          </button>
                        </div>
                      </th>
                      <th className="w-[18%] text-center border-r border-gray-200">
                        Back
                      </th>
                      <th className="w-[18%] text-center">Lay</th>
                    </tr>
                  </thead>

                  <tbody>
                    {matchScoreData?.dataByEventId
                      .find((ele) => ele.marketType === "Match Odds")
                      ?.runners.map((data, index) => {
                        return (
                          <tr className="bg-white border-b border-gray-200">
                            <td className="py-1 border-r pl-2 border-gray-200">
                              <div className="font-bold">
                                {data?.selectionName}
                              </div>
                              <div className={` ${(oddsPosition?.find((ele) => ele._id == data.selectionId)?.Position ? -1 * oddsPosition?.find((ele) => ele._id == data.selectionId
                              )?.Position
                                : 0) >= 0
                                ? "text-sky-500"
                                : "text-rose-500"
                                }  flex gap-1 justify-start items-center`}
                              >
                                {" "}
                                <VscTriangleRight />{" "}
                                {(oddsPosition?.find((ele) => ele._id == data.selectionId)?.Position ? -1 * oddsPosition?.find((ele) => ele._id == data.selectionId)?.Position : 0).toFixed(2)}

                              </div>
                            </td>
                            <td className="py-1 text-center font-bold relative  text-sm  border-r border-gray-200">
                              <BlinkingComponent
                                price={
                                  data?.ex &&
                                    data.ex.availableToBack &&
                                    data.ex.availableToBack.length > 0
                                    ? data.ex.availableToBack[0].price
                                    : "--"
                                }
                                size={
                                  data?.ex &&
                                    data.ex.availableToBack &&
                                    data.ex.availableToBack.length > 0
                                    ? data.ex.availableToBack[0].size
                                    : "--"
                                }
                                color={"bg-sky-300"}
                              />
                              {/* <div className="bg-sky-300 py-1">
                             <div>
                                {" "}
                                {data?.ex &&
                                data.ex.availableToBack &&
                                data.ex.availableToBack.length > 0
                                  ? data.ex.availableToBack[0].price
                                  : "--"}
                              </div>
                              <div className="text-xs font-medium">
                                {" "}
                                {data?.ex &&
                                data.ex.availableToBack &&
                                data.ex.availableToBack.length > 0
                                  ? data.ex.availableToBack[0].size
                                  : "--"}
                              </div>
                             </div> */}
                            </td>
                            <td className="py-1 text-center font-bold relative text-sm  border-r ">
                              <BlinkingComponent
                                price={
                                  data?.ex &&
                                    data.ex.availableToLay &&
                                    data.ex.availableToLay.length > 0
                                    ? data.ex.availableToLay[0]?.price
                                    : "--"
                                }
                                size={
                                  data?.ex &&
                                    data.ex.availableToLay &&
                                    data.ex.availableToLay.length > 0
                                    ? data.ex.availableToLay[0]?.size
                                    : "--"
                                }
                                color={"bg-rose-300"}
                              />
                              {/* <div className="bg-rose-300 py-1 ">
                              <div>
                                {" "}
                                {data?.ex &&
                                data.ex.availableToLay &&
                                data.ex.availableToLay.length > 0
                                  ? data.ex.availableToLay[0]?.price
                                  : "--"}
                              </div>
                              <div className="text-xs font-medium">
                                {" "}
                                {data?.ex &&
                                data.ex.availableToLay &&
                                data.ex.availableToLay.length > 0
                                  ? data.ex.availableToLay[0]?.size
                                  : "--"}
                              </div>
                              </div> */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          )}


          {/* ------------------------------------------------------ Line market Odds add karna hai ------------------------------------------------------ */}

          {matchDetailsByMarketId?.isLineMarketOdds && (
            <div className="card">
              {matchScoreData?.dataByEventId && (
                // Filter out the unwanted markets
                matchScoreData?.dataByEventId
                  .filter((ele) => !["Match Odds", "Tied Match", "Completed Match", "To Win the Toss"].includes(ele.marketType)).map((marketData) => {

                    if (marketData?.runners.length > 0) {
                      return (
                        <table className="mb-0 border md:text-sm text-xs font-semibold border-gray-200" key={marketData.marketId}>
                          <thead>
                            <tr className="bg-zinc-800 text-white border-b border-gray-200">
                              <th className="flex p-2 justify-between items-start lg:items-center border-r border-gray-200">
                                <div>
                                  <div className="font-bold ">{marketData.marketType}</div>
                                  <div className="font-bold">
                                    BET :{" "}
                                    {coinData && coinData.is && coinData.is.min
                                      ? coinData.is.min
                                      : "0"}{" "}
                                    -
                                    {coinData && coinData.is && coinData.is.max
                                      ? coinData.is.max
                                      : "0"}
                                  </div>
                                </div>
                              </th>
                              <th className="w-[18%] text-center border-r border-gray-200">Back</th>
                              <th className="w-[18%] text-center">Lay</th>
                            </tr>
                          </thead>

                          <tbody>
                            {marketData?.runners.map((data) => {
                              return (
                                <tr className="bg-white border-b border-gray-200" key={data.selectionId}>
                                  <td className="py-1 border-r pl-2 border-gray-200">
                                    <div className="font-bold">{data?.selectionName}</div>
                                    <div
                                      className={`${(oddsPosition?.find(
                                        (ele) => ele._id === data.selectionId
                                      )?.Position
                                        ? -1 *
                                        oddsPosition?.find(
                                          (ele) => ele._id === data.selectionId
                                        )?.Position
                                        : 0) >= 0
                                        ? "text-sky-500"
                                        : "text-rose-500"
                                        }  flex gap-1 justify-start items-center`}
                                    >
                                      <VscTriangleRight />
                                      {(
                                        oddsPosition?.find(
                                          (ele) => ele._id === data.selectionId
                                        )?.Position
                                          ? -1 *
                                          oddsPosition?.find(
                                            (ele) => ele._id === data.selectionId
                                          )?.Position
                                          : 0
                                      ).toFixed(2)}
                                    </div>
                                  </td>
                                  <td className="py-1 text-center font-bold relative text-sm border-r border-gray-200">
                                    <BlinkingComponent
                                      price={
                                        data?.ex?.availableToBack?.[0]?.price || "--"
                                      }
                                      size={
                                        data?.ex?.availableToBack?.[0]?.size || "--"
                                      }
                                      color={"bg-sky-300"}
                                    />
                                  </td>
                                  <td className="py-1 text-center font-bold relative text-sm border-r">
                                    <BlinkingComponent
                                      price={
                                        data?.ex?.availableToLay?.[0]?.price || "--"
                                      }
                                      size={
                                        data?.ex?.availableToLay?.[0]?.size || "--"
                                      }
                                      color={"bg-rose-300"}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      );
                    }
                    return null;
                  })
              )}
            </div>)}




          {/* -------------------------------------------------- Tied Match Data ---------------------------------------------------- */}
          {matchDetailsByMarketId?.isTieOdds && (
            <div className="card">
              {matchScoreData?.dataByEventId &&
                matchScoreData?.dataByEventId.find(
                  (ele) => ele.marketType === "Tied Match"
                ) &&
                matchScoreData?.dataByEventId.find(
                  (ele) => ele.marketType === "Match Odds"
                )?.runners.length < 3 &&
                matchScoreData?.dataByEventId.find(
                  (ele) => ele.marketType === "Tied Match"
                )?.runners.length > 0 && (
                  <table className="mb-0 border md:text-sm text-xs font-semibold border-gray-200">
                    <thead>
                      <tr className="bg-zinc-800 text-white border-b border-gray-200">
                        <th className="flex p-2 justify-between items-start lg:items-center border-r border-gray-200">
                          <div>
                            <div className="font-bold ">Tied odds</div>
                            <div className="font-bold">
                              BET :{" "}
                              {coinData &&
                                coinData.isTieCoin &&
                                coinData.isTieCoin.min
                                ? coinData.isTieCoin.min
                                : "0"}{" "}
                              -
                              {coinData &&
                                coinData.isTieCoin &&
                                coinData.isTieCoin.max
                                ? coinData.isTieCoin.max
                                : "0"}
                            </div>
                          </div>
                          <div className="lg:flex block md:w-1/2 w-fit justify-between items-center">
                            <div className="inline-flex items-center cursor-pointer">
                              {/* <span className="ml-3 mr-1 font-medium text-white">
                              Bet Lock
                            </span>

                            <label class="switch mb-0">
                              <input type="checkbox" id="togBtn" />
                              <div class="slider round">
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                              </div>
                            </label> */}
                              {/* <input
                              type="checkbox"
                              defaultChecked
                              className="sr-only peer"
                            />
                            <div className="relative w-11 h-6 bg-gray-300 rounded-full peer-focus:outline-none dark:bg-gray-700 peer-checked:bg-green-600 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-gray-200 after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-200 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div> */}
                            </div>
                           
                            <button onClick={() => handleMinMaxOddsModal1({ type: "tiedMatch" })} className="ml-1 ms:ml-3 bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-1 md:px-3 rounded btn btn-xs md:btm-sm">
                              Book
                            </button>
                          </div>
                        </th>
                        <th className="w-[18%] text-center border-r border-gray-200">
                          Back
                        </th>
                        <th className="w-[18%] text-center">Lay</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchScoreData?.dataByEventId
                        .find((ele) => ele.marketType === "Tied Match")
                        ?.runners.map((data, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b border-gray-200"
                          >
                            <td className="py-1 border-r px-2 border-gray-200">
                              <div className="font-bold">
                                {data?.selectionName}
                              </div>
                              <div
                                className={` ${(oddsPosition?.find(
                                  (ele) => ele._id == data.selectionId
                                )?.Position
                                  ? -1 *
                                  oddsPosition?.find(
                                    (ele) => ele._id == data.selectionId
                                  )?.Position
                                  : 0) >= 0
                                  ? "text-sky-500"
                                  : "text-rose-500"
                                  }  flex gap-1 justify-start items-center`}
                              >
                                {" "}
                                <VscTriangleRight />{" "}
                                {(oddsPosition?.find(
                                  (ele) => ele._id == data.selectionId
                                )?.Position
                                  ? -1 *
                                  oddsPosition?.find(
                                    (ele) => ele._id == data.selectionId
                                  )?.Position
                                  : 0).toFixed(2)}
                              </div>
                            </td>
                            <td className="py-1 text-center font-bold relative text-sm   border-r ">
                              <BlinkingComponent
                                price={
                                  data?.ex?.availableToBack?.length > 0
                                    ? data.ex.availableToBack[0].price
                                    : "--"
                                }
                                size={
                                  data?.ex?.availableToBack?.length > 0
                                    ? data.ex.availableToBack[0].size
                                    : "--"
                                }
                                color={"bg-sky-300"}
                              />
                              {/* <div>
                              {data?.ex?.availableToBack?.length > 0
                                ? data.ex.availableToBack[0].price
                                : "--"}
                            </div>
                            <div className="text-xs font-medium">
                              {data?.ex?.availableToBack?.length > 0
                                ? data.ex.availableToBack[0].size
                                : "--"}
                            </div> */}
                            </td>
                            <td className="py-1 text-center font-bold  text-sm relative  border-r border-gray-200">
                              <BlinkingComponent
                                price={
                                  data?.ex?.availableToLay?.length > 0
                                    ? data.ex.availableToLay[0]?.price
                                    : "--"
                                }
                                size={
                                  data?.ex?.availableToLay?.length > 0
                                    ? data.ex.availableToLay[0]?.size
                                    : "--"
                                }
                                color={"bg-rose-300"}
                              />

                              {/* <div>
                              {data?.ex?.availableToLay?.length > 0
                                ? data.ex.availableToLay[0]?.price
                                : "--"}
                            </div>
                            <div className="text-xs font-medium">
                              {data?.ex?.availableToLay?.length > 0
                                ? data.ex.availableToLay[0]?.size
                                : "--"}
                            </div> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
            </div>
          )}

        </div>
        {/* -------------------------------------------------- Top right section Data ---------------------------------------------------- */}
        <div className="col-md-6">
          {/* -------------------------------------------------- Fancy Data ---------------------------------------------------- */}
          {matchDetailsByMarketId?.isFancy && (
            <div className="card">
              {matchScoreData?.dataByMarketId?.session &&
                matchScoreData?.dataByMarketId?.session?.length > 0 && (
                  <table className="mb-0 border md:text-sm text-xs font-semibold border-gray-200">
                    <thead>
                      <tr className="bg-zinc-800 text-white border-b border-gray-200">
                        <th className="flex justify-between  p-2 items-start lg:items-center border-r border-gray-200">
                          <div>
                            <div className="font-bold ">Fancy</div>
                            <div className="font-bold ">
                              BET :{" "}
                              {coinData &&
                                coinData.sessionCoin &&
                                coinData.sessionCoin.min
                                ? coinData.sessionCoin.min
                                : "0"}{" "}
                              -
                              {coinData &&
                                coinData.sessionCoin &&
                                coinData.sessionCoin.max
                                ? coinData.sessionCoin.max
                                : "0"}
                            </div>
                          </div>
                          <div className="flex gap-1 md:w-1/2 w-fit justify-end flex-wrap items-center">
                            {/* <div className="inline-flex items-center cursor-pointer">
                          <span className="ml-3 mr-1  font-medium text-white">
                            Bet Lock
                          </span>

                          <label class="switch mb-0">
                            <input type="checkbox" id="togBtn" />
                            <div class="slider round">
                              <span class="on">ON</span>
                              <span class="off">OFF</span>
                            </div>
                          </label>
                        </div> */}
                            {/* <button onClick={() => handleMinMaxOddsModal1({ type: "tiedMatch" })} className="ml-1 ms:ml-3 bg-[#22a7f0] hover:bg-blue-500 text-[13px] text-white font-bold  px-[16px] py-[2px]  rounded btn btn-xs md:btm-sm">
                              Book
                            </button> */}
                          </div>
                        </th>
                        <th className="w-[18%] text-center border-r border-gray-180">
                          No
                        </th>
                        <th className="w-[18%] text-center">Yes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matchScoreData?.dataByMarketId?.session &&
                        matchScoreData?.dataByMarketId?.session?.length > 0 &&
                        matchScoreData?.dataByMarketId?.session.map(
                          (data, index) => {
                            return (
                              <tr className="bg-white border-b border-gray-200">
                                <td className="py-1 border-r  pl-2 border-gray-200">
                                  <div className="font-bold">
                                    {data?.session_name}
                                  </div>
                                  <div className="font-semibold  text-[10px] ">
                                    session Limit : {data?.max}
                                  </div>
                                </td>
                                <td className="py-1 text-center relative font-bold  text-sm  border-r border-gray-200">
                                  <BlinkingComponent
                                    price={
                                      data?.runsNo && data.runsNo !== 0
                                        ? data?.runsNo
                                        : "--"
                                    }
                                    size={
                                      data?.oddsNo && data.oddsNo !== 0
                                        ? (Number(data?.oddsNo) * 100).toFixed(0)
                                        : "--"
                                    }
                                    color={"bg-rose-300"}
                                  />
                                </td>
                                <td className="py-1 text-center relative font-bold  text-sm ">
                                  <BlinkingComponent
                                    price={
                                      data?.runsYes && data.runsYes !== 0
                                        ? data?.runsYes
                                        : "--"
                                    }
                                    size={
                                      data?.oddsYes && data.oddsYes !== 0
                                        ? (Number(data?.oddsYes) * 100).toFixed(0)
                                        : "--"
                                    }
                                    color={"bg-sky-300"}
                                  />
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                )}
            </div>
          )}
        </div>
      </div>

      {/* -------------------------------------------------- Bottom section Data ---------------------------------------------------- */}

      <div className="row ">
        {/* -------------------------------------------------- Bottom left section Data ---------------------------------------------------- */}
        <div className="col-md-6">
          {/* -------------------------------------------------- odds bet list table  Data ---------------------------------------------------- */}

          <div className="flex md:text-lg text-sm  justify-between items-center p-2">
            <div className="font-bold text-gray-700 ">
              Betfair Running Market Bets
            </div>
            <div className="flex items-center">
              <HiRefresh onClick={() => { fetchBetList() }} className="w-6 h-6 text-white bg-black rounded-full p-1 flex-shrink-0" />
              <div className="ml-3 text-black font-semibold  bg-black rounded py-1 px-2">
                Total Bets: {oddsBetList ? oddsBetList.length : 0}
              </div>
            </div>
          </div>

          <div className="card w-full">
            <div className="table-responsive overflow-x-auto">
              <table className="min-w-full text-sm border-collapse border uppercase font-semibold border-gray-200 mb-4">
                <thead>
                  <tr className="bg-zinc-800 text-white border-b border-gray-200">
                    <th className="px-2 text-left border border-gray-200">sn.</th>
                    <th className="px-2 text-left border border-gray-200">
                      Runner
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      User
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Odds
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Stake
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Profit
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Loss
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {oddsBetList.map((bet, index) => (
                    <tr
                      className={`border-b ${getTypeDescription(bet.type) === "NO" ||
                        getTypeDescription(bet.type) === "Khaai"
                        ? "bg-rose-300"
                        : "bg-sky-300"
                        } border-gray-200 text-nowrap py-1.5`}
                      key={bet._id}
                    >
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {index + 1}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {bet.teamName}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {bet.userInfo.clientName} (
                        {bet.userInfo && bet.userInfo.clientCode
                          ? bet.userInfo.clientCode
                          : ""}
                        )
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {Number.parseFloat(100 * bet.odds).toFixed(2)}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {bet.amount}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {Number.parseFloat(
                          Math.abs(calculateProfitLoss(bet).profit)
                        ).toFixed(2)}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {Number.parseFloat(
                          Math.abs(calculateProfitLoss(bet).loss)
                        ).toFixed(2)}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {bet.createdAt
                          ? moment(bet.createdAt)
                            .utcOffset("+05:30")
                            .format("DD MMM hh:mm:ss A")
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* -------------------------------------------------- Bottom right section Data ---------------------------------------------------- */}
        <div className="col-md-6">
          {/* -------------------------------------------------- fancy list table  Data ---------------------------------------------------- */}
          <div className="flex md:text-lg text-sm justify-between items-center p-2">
            <div className="font-bold text-gray-700 ">Fancy Bets</div>
            <div className="flex items-center">
              <HiRefresh onClick={() => { fetchBetList() }} className="w-6 h-6 text-white bg-black rounded-full p-1 flex-shrink-0" />
              <div className="ml-3 text-black font-semibold  bg-black rounded py-1 px-2">
                Total Bets: {fancyBetList ? fancyBetList.length : 0}
              </div>
            </div>
          </div>
          <div className="card w-full overflow-auto">
            <div className="tableresponsive">
              <table className="min-w-full text-sm border-collapse border uppercase font-semibold border-gray-200 mb-4">
                <thead>
                  <tr className="bg-zinc-800 text-white border-b border-gray-200">
                    <th className="px-2 text-left border border-gray-200">sn.</th>
                    <th className="px-2 text-left border border-gray-200">
                      Runner
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      User
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Odds
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Run
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Stake
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Profit
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Loss
                    </th>
                    <th className="px-2 text-left border border-gray-200">
                      Date
                    </th>
                    {/* <th className="px-2 text-left border border-gray-200">Refresh</th> */}
                  </tr>
                </thead>
                <tbody>
                  {fancyBetList.map((bet, index) => (
                    <tr
                      className={`border-b ${getTypeDescription(bet.type) === "NO" ||
                        getTypeDescription(bet.type) === "Khaai"
                        ? "bg-rose-300"
                        : "bg-sky-300"
                        } border-gray-200 text-nowrap py-1.5`}
                      key={bet._id}
                    >
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {index + 1}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {bet.sessionName}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {bet.userInfo.clientName} (
                        {bet.userInfo && bet.userInfo.clientCode
                          ? bet.userInfo.clientCode
                          : ""}
                        )
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {Number.parseFloat(100 * bet.odds).toFixed(2)}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {Number.parseFloat(bet.run).toFixed(2)}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {bet.amount}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {Number.parseFloat(
                          Math.abs(calculateProfitLoss(bet).profit)
                        ).toFixed(2)}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {Number.parseFloat(
                          Math.abs(calculateProfitLoss(bet).loss)
                        ).toFixed(2)}
                      </td>
                      <td className="py-1 px-2 border border-gray-200 text-nowrap text-[14px] font-[400]">
                        {bet.createdAt
                          ? moment(bet.createdAt)
                            .utcOffset("+05:30")
                            .format("DD MMM hh:mm:ss A")
                          : ""}
                      </td>
                      {/* <td className="py-1 px-2 border border-gray-200">
            -
          </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {isBookModal && <MatchBookModal
          minMaxOddsModal1={isBookModal}
          // handleMinMaxOddsModalClose={handleMinMaxOddsModalClose}
          modaleData={modaleData}
          marketId={marketId}
          setIsBookModal={setIsBookModal}
          odds={modaleData.type === "matchOdds" ? matchOdds :
            modaleData.type === "bookmaker" ? bookmaker :
              modaleData.type === "tiedMatch" ? tied :
                modaleData.type === "completedMatch" ? completed :
                  modaleData.type === "toss" ? toss :
                    bookmaker}

        />}
      </div>
    </GlobalLayout>)

};
export default MatchDetails;
