
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { depositWithdrawTotal } from "../../../redux/_reducers/_report_reducers";
import moment from "moment";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import Loader from "../../../component/loader/Loader";
import Card from "../../../global_layouts/Card/Card";
import RangePickerComponent from "../../../global_layouts/RangePickerComponent/RangePickerComponent";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { encrypt } from "../../../config/Encryption";
import { FaLink } from "react-icons/fa";




const formatDate = (date) => date.toISOString().split("T")[0];
const today = new Date();

function TotalWithdrwal() {
  // const [startDate, setStartDate] = useState(
  //   formatDate(new Date(today.setDate(today.getDate() - 7)))
  // );
  const [startDate, setStartDate] = useState(formatDate(new Date()));
  const [endDate, setEndDate] = useState(formatDate(new Date()));
  const [totals, setTotals] = useState({
    totalDeposit: 0,
    totalFakeDeposit: 0,
    totalWithdraw: 0,
    pendingWithdraw: 0,
    pendingDeposit: 0,
    totalBalance: 0,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { depositWithdrawTotalData, loading } = useSelector((state) => state.report);

  useEffect(() => {
    let reqData = {
      toDate: moment(endDate).format("YYYY-MM-DD"),
      fromDate: moment(startDate).format("YYYY-MM-DD"),
    };
    completeSportData(reqData);
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // let reqData = {
    //   status: status,
    //   toDate: moment(endDate).format("YYYY-MM-DD"),
    //   fromDate: moment(date).format("YYYY-MM-DD"),
    //   reqType: "deposit",
    // };
    // completeSportData(reqData);

    if (new Date(date) > new Date(endDate)) {
      setEndDate("");
    }
  };

  const handleEndDateChange = async (date) => {
    setEndDate(date);
    // let reqData = {
    //   toDate: moment(date).format("YYYY-MM-DD"),
    //   fromDate: moment(startDate).format("YYYY-MM-DD"),
    // };
    // completeSportData(reqData);
  };

  useEffect(() => {
    if (depositWithdrawTotalData && depositWithdrawTotalData.length > 0) {
      let totalDeposit = 0;
      let totalFakeDeposit = 0;
      let totalWithdraw = 0;
      let pendingWithdraw = 0;
      let pendingDeposit = 0;
      let totalBalance = 0;

      depositWithdrawTotalData.forEach((data) => {
        totalDeposit += data.totalDeposit || 0;
        totalFakeDeposit += data.totalFakeDeposit || 0;
        totalWithdraw += data.totalWithdraw || 0;
        pendingWithdraw += data.pendingWithdraw || 0;
        pendingDeposit += data.pendingDeposit || 0;
        totalBalance += data.totalDeposit - data.totalWithdraw || 0;
      });

      setTotals({
        totalDeposit,
        totalFakeDeposit,
        totalWithdraw,
        pendingWithdraw,
        pendingDeposit,
        totalBalance,
      });
    }
  }, [depositWithdrawTotalData]);






  const handelSubmit = () => {
    let reqData = {
      toDate: moment(endDate).format("YYYY-MM-DD"),
      fromDate: moment(startDate).format("YYYY-MM-DD"),
    };
    completeSportData(reqData);
  }



  const completeSportData = async (reqData) => {
    try {
      dispatch(depositWithdrawTotal(reqData))
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };



  return loading ? (
    <Loader loaderTrue={true} />
  ) : (
    <GlobalLayout icons={<i class="fa-solid fa-sack-dollar"></i>} title="Total Deposit Withdraw">
      <>

        <div className="overflow-auto border rounded-lg shadow-lg py-2 bg-[#3F3F46] md:flex">
          <div className="flex flex-row md:flex-row md:gap-4 gap-2  ">
            <RangePickerComponent
              startDate={startDate}
              endDate={endDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
            />

          </div>


          <div className="px-2">

            <button className="px-3 py-2 bg-green-800  text-white rounded md:mt-0 mt-2" onClick={() => handelSubmit()}>Submit</button>
          </div>


        </div>


        <Card className="overflow-auto">



          <table className="table table-sm table-bordered">
            <thead>
              <tr className="bg-zinc-700 text-white">
                <th className="text-nowrap">Date</th>
                <th className="text-nowrap">Total Deposit</th>
                <th className="text-nowrap">Total Withdraw</th>
                <th className="text-nowrap">Balance</th>
                <th className="text-nowrap">Pending Deposit</th>
                <th className="text-nowrap">Pending Withdraw</th>
                <th className="text-nowrap">Total FakeDeposit</th>
              </tr>
            </thead>

            <thead>
              {depositWithdrawTotalData &&
                depositWithdrawTotalData?.length > 0 && (
                  <tr className="uppercase ">
                    <td className="text-nowrap md:text-lg  font-bold">Total
                      <br />
                      <span className="text-xs">
                        {moment(startDate).format("DD-MM-YYYY")} To {moment(endDate).format("DD-MM-YYYY")}
                      </span>
                    </td>
                    <td className="text-nowrap text-md font-bold text-green-700">{totals.totalDeposit}</td>
                    <td className="text-nowrap text-md font-bold text-red-700">{totals.totalWithdraw}</td>
                    <td className={`text-nowrap text-md font-bold ${totals.totalBalance < 0 ? 'text-red-600' : "text-green-700"} `}>{totals.totalBalance}</td>
                    <td className="text-nowrap text-md font-bold text-green-700">{totals.pendingDeposit}</td>
                    <td className="text-nowrap text-md font-bold text-black">{totals.pendingWithdraw}</td>
                    <td className="text-nowrap text-md font-bold text-black-700">{totals.totalFakeDeposit}</td>
                  </tr>)}
            </thead>

            <tbody>
              {depositWithdrawTotalData &&
                depositWithdrawTotalData.length > 0 ? (
                depositWithdrawTotalData.map((data, index) => (
                  <tr key={index} className="uppercase">
                    <td className="text-nowrap">{moment(data?._id?.date).format("DD-MM-YYYY")}</td>
                    <td className="text-nowrap text-green-700 " >
                      <span className="flex justify-between">
                        {data?.totalDeposit}
                        {data?.totalDeposit !== 0 ? <FaLink color="blue" onClick={data?.totalDeposit !== 0 ? () => navigate(`/admin/deposit/${encrypt(moment(data?._id?.date).format("YYYY-MM-DD"))}/${encrypt("accept")}`) : undefined} /> : ''}
                      </span>
                    </td>
                    <td className="text-nowrap text-red-700 " >
                      <span className="flex justify-between">
                        {data?.totalWithdraw}
                        {data?.totalWithdraw !== 0 ? <FaLink color="blue" onClick={data?.totalWithdraw !== 0 ? () => navigate(`/admin/withdraw/${encrypt(moment(data?._id?.date).format("YYYY-MM-DD"))}/${encrypt("accept")}`) : undefined} /> : ''}
                      </span>
                    </td>
                    <td className={`text-nowrap ${data?.totalDeposit - data?.totalWithdraw < 0 ? 'text-red-600' : 'text-green-600'}`}>
                      
                      <span className="flex justify-between">
                      {data?.totalDeposit - data?.totalWithdraw}
                      {data?.totalDeposit - data?.totalWithdraw !== 0 ? <FaLink color="blue" onClick={data?.totalDeposit - data?.totalWithdraw !== 0 ? () => navigate(`/admin/alltotalwithdraw/${encrypt(moment(data?._id?.date).format("YYYY-MM-DD"))}/${encrypt("all")}`) : undefined} /> : ''}
                      </span>
                      
                      </td>
                    <td className="text-nowrap " >
                      <span className="flex justify-between">
                        {data?.pendingDeposit}
                        {data?.pendingDeposit !== 0 ? <FaLink color="blue" onClick={data?.pendingDeposit !== 0 ? () => navigate(`/admin/deposit/${encrypt(moment(data?._id?.date).format("YYYY-MM-DD"))}/${encrypt("pending")}`) : undefined} /> : ''}
                      </span>
                    </td>
                    <td className="text-nowrap " >
                      <span className="flex justify-between">
                        {data?.pendingWithdraw}
                        {data?.pendingWithdraw !== 0 ? <FaLink color="blue" onClick={data?.pendingWithdraw !== 0 ? () => navigate(`/admin/withdraw/${encrypt(moment(data?._id?.date).format("YYYY-MM-DD"))}/${encrypt("pending")}`) : undefined} /> : ''}
                      </span>
                    </td>
                    <td className="text-nowrap " >

                      <span className="flex justify-between">
                        {data?.totalFakeDeposit}
                        {data?.totalFakeDeposit !== 0 ? <FaLink color="blue" onClick={data?.totalFakeDeposit !== 0 ? () => navigate(`/admin/deposit/${encrypt(moment(data?._id?.date).format("YYYY-MM-DD"))}/${encrypt("cancel")}`) : undefined} /> : ''}
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={6}>No Data Found</td>
                </tr>
              )}
            </tbody>



          </table>
        </Card>
      </>
    </GlobalLayout>
  );
}

export default TotalWithdrwal;