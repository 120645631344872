import { useDispatch, useSelector } from "react-redux";
import Drawer from "../../../global_layouts/Drawer/Drawer";
import { reportUserProfitLoss } from "../../../redux/_reducers/_report_reducers";
import { useEffect, useState } from "react";
import { encrypt } from "../../../config/Encryption";
import moment from "moment";

const ProfitLoss = (props) => {
    const [profitLossData, setProfitLossData] = useState([])

    useEffect(() => {
        getProfitLoss()
    }, [])
    const dispatch = useDispatch();
    const { reportUserProfitLossData, loading } = useSelector(state => state.report);

    const { isDrawerOpen, setIsDrawerOpen, propsData } = props;
    const isOpen = isDrawerOpen.profitLossDrawer;

    useEffect(() => {
        if (reportUserProfitLossData) {
            const filterProfitLossData = reportUserProfitLossData.map(item => ({
                eventName: item.eventName,
                marketId: item.marketId,
                userLedgerAmt: item.userLedgerAmt,
                userNetProfitLoss: item.userNetProfitLoss,
                userOddsComm: item.userOddsComm
            }));
            setProfitLossData(filterProfitLossData)
        }

    }, [reportUserProfitLossData])


    const getProfitLoss = () => {
        let reqData = {
            // "fromDate": moment().format("YYYY-MM-DD"),
            // "toDate": moment().format("YYYY-MM-DD"),
            "profitLossType": "all",
            "status": 1,
            "userId": propsData.profitLossProps.userId,
            "userType": propsData.profitLossProps.userType

        }
        dispatch(reportUserProfitLoss(reqData))
    }


    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => setIsDrawerOpen({ profitLossDrawer: false })}
            direction="right"
            size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
            overlay={true}

        >
            <div class=" bg-gradient-to-t from-[#27a6c3] to-[#05537e] p-2 border-b-1.5 border-black">
                <div class="flex justify-center uppercase text-sm font-bold items-center w-100  text-white ">
                    <div class="info-box-number">{`Profit Loss [${propsData.profitLossProps.username}]`}</div>
                </div>
            </div>


            <div className="p-2 h-[600px] overflow-auto">
                <div className="overflow-auto">
                    <table className="table table-bordered table-sm w-full">
                        <thead>
                            <tr className='bg-gray-800 text-white'>
                                <th className='w-1/2 px-2'>Event Name</th>
                                <th className='w-1/4  px-2'>Amount</th>
                                <th className='w-1/4  px-2'>Total</th>
                            </tr>
                        </thead>
                        <tbody className='text-sm text-black bg-white'>
                            {profitLossData?.length > 0 ? (
                                profitLossData.slice(0, 7).map((res, index) => (
                                    <tr key={index}>
                                        <td className='px-2'>{res.eventName}</td>
                                        <td className='px-2'>{(-1 * res.userNetProfitLoss).toFixed(2)} </td>
                                        <td className='px-2'>{(-1 * res.userNetProfitLoss).toFixed(2)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center">No Profit Loss available</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
                <div className="flex items-start mt-3 px-2 w-full " >
                    <a href={`/admin/profit-loss/${encrypt(propsData.profitLossProps.userId)}/${encrypt(propsData.profitLossProps.userType)}`} className="w-full">
                        <button type="submit" className="text-white uppercase  bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none  font-normal rounded-sm text-md w-100 sm:w-auto px-5 py-1.5 text-center ">View More</button>
                    </a>

                </div>
            </div>


        </Drawer>
    );
};

export default ProfitLoss;
