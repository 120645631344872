import React, { useEffect, useState } from "react";
import { Button, Card, Table, Tag } from "antd";
import moment from "moment";
// import BackButton from "../../Hoc/BackButton";
import { useDispatch, useSelector } from "react-redux";
// import { casinoRoundWise } from "../../../../appRedux/actions/User";
// import Loader from "../../../../components/loader";
import { Link, useParams } from "react-router-dom";
import { casinoRoundWise } from "../../../redux/_reducers/_user_reducers";
import Loader from "../../../component/loader/Loader";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import Triangle from "../../../global_layouts/Triangle/Triangle";

const Basic = () => {
  const { name, id, payloadDate } = useParams();
  const [matchLedger, setMatchLedger] = useState([]);
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);

  const dispatch = useDispatch();

  const { casinoRoundWiseData, loader } = useSelector((state) => state.user);

  useEffect(() => {
    casinoRoundWiseList();
  }, []);

  const casinoRoundWiseList = async () => {
    let reqData = {
      eventId: id,
      fromDate: payloadDate,
      toDate: payloadDate,
    };
    dispatch(casinoRoundWise(reqData));
  };

  useEffect(() => {
    if (casinoRoundWiseData) {
      const filteredData = casinoRoundWiseData?.map((item, index) => ({
        key: `${index + 1}`,
        createdAt: item.createdAt,
        gameId: item._id,
        profitLoss: -1 * item.profitLoss,
        // gameName: item?.gameName
      }));
      let total = 0;
      casinoRoundWiseData?.forEach((item) => {
        total += -1 * item.profitLoss;
      });
      setTotalProfitLoss(total);
      setMatchLedger(filteredData);
    }
  }, [casinoRoundWiseData]);

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };

    return obj;
  };

  const columns = [
    {
      title: " S no.",
      dataIndex: "key",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          paddingInline:'10px',
          paddingBlock:'8px',

          color: "white",
        },
      }),
    },
    {
      title: "Game ID",
      dataIndex: "gameId",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          paddingInline:'10px',
          paddingBlock:'8px',

          color: "white",
        },
      }),
    },
    {
      title: " Started AT",
      dataIndex: "date",
      render: (createdAt) => (
        <span className="gx-text-nowrap">
          {" "}
          {moment(createdAt).format("DD MMM hh:mm A")}
        </span>
      ),
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          paddingInline:'10px',
          paddingBlock:'8px',

          color: "white",
        },
      }),
    },
    // {
    //     title: 'Winner',
    //     dataIndex: 'action',
    //     render: renderContent,

    // },
    {
      title: "Plus/Minus",
      dataIndex: "profitLoss",
      render: (value, row) => (
        <span
          className={`${
            row.profitLoss > 0 ? "gx-text-green-0" : "gx-text-red"
          }`}
        >
          {row.profitLoss}
        </span>
      ),
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          paddingInline:'10px',
          paddingBlock:'8px',

          color: "white",
        },
      }),
    },

    {
      title: "Action",
      dataIndex: "action",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          paddingInline:'10px',
          paddingBlock:'8px',

          color: "white",
        },
      }),
      render: (value, row) => (
        <Link to={`/admin/casinobets/${row.gameId}`} >
          <Button size="small" className="px-4 py-1 leading-0 bg-blue-400 !border-0 text-white hover:!bg-blue-400 font-bold">
            Show Bets
          </Button>
        </Link>
      ),
    },
  ];

  // Define the data source

  return (
    <>
      {loader ? (
        <Loader props={loader} />
      ) : (
        <GlobalLayout>
          <Card className="gx-card capitalize">
            <div className="bg-red-600 flex justify-between items-center mb-3">
              <div className="text-white bg-red-700 relative font-bold px-4 py-2">
                {`${name}`}
                <Triangle direction="down" size={10} color="#B91C1C" />
              </div>
              <button
                onClick={() => window.location.reload()}
                className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
              >
                Refresh
              </button>
            </div>

            <div className="flex px-5 py-1 justify-center gap-3">
              <div className="text-2xl">
                Total :{" "}
                <span
                  className={`${
                    totalProfitLoss > 0 ? "text-green-800" : "text-red-800"
                  }`}
                >
                  {" "}
                  {totalProfitLoss ? totalProfitLoss.toFixed(2) : "0.00"}
                </span>
              </div>
            </div>
            <div>
              <Table
                className="table-responsive"
                columns={columns}
                dataSource={matchLedger}
                bordered
                pagination={false}
                size="small"
                rowClassName={(record, index) =>
                    index % 2 === 0 ? "bg-gray-100 " : ""
                  }
              />
            </div>
          </Card>
        </GlobalLayout>
      )}
    </>
  );
};

export default Basic;
