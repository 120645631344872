


import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import moment from "moment";
import { httpPost } from "../../config/http2";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import { FaTrophy } from "react-icons/fa";
import { decrypt } from "../../config/Encryption";
import { useParams } from "react-router-dom";

const EventMatchHistoryBet = (props) => {
  const [offset, setOffset] = useState(0);
  const [fieldsUser, setFieldsUser] = useState({});
  const [errorsUser, setErrorsUser] = useState({});
  const [errorType, setErrorType] = useState("");
  const [userBetHistory, setUserBetHistory] = useState([]);
  const [minStake, setMinStake] = useState("");
  const [maxStake, setMaxStake] = useState("");
  const [sportByMarketId, setSportByMarketId] = useState("");

  const { userIdEnc, userTypeEnc, marketIdEnc, selectionIdEnc, oddsTypeEnc } = useParams();
  const userId = decrypt(userIdEnc);
  const userType = decrypt(userTypeEnc);
  const marketId = decrypt(marketIdEnc);
  const selectionId = decrypt(selectionIdEnc);
  const oddsType = decrypt(oddsTypeEnc);


    useEffect(() => {
      getBetList();
    }, []);

  
    const getBetList = async () => {
      let reqData = getRequestData();
      let betList = await httpPost("sports/betsList", reqData);
      setUserBetHistory(betList.data);
    };

    const getRequestData = () => {
      let reqData = {
        isDeleted: 0,
        marketId: marketId,
        downlineUserType: userType,
        downlineUserId: userId,
        // casinoBet: false,
      };

      if (oddsType === "fancyOdds") {
        reqData.selectionId = selectionId !== "" ? selectionId : 0;
        reqData.fancyBet = true;
      } else {
        reqData.oddsType = oddsType;
        reqData.oddsBet = true;
      }

      if (marketId === "1") {
        reqData = {
          isDeleted: 0,
          fancyBet: true,
          oddsBet: true,
          casinoBet: false,
          downlineUserType: userType,
          downlineUserId: userId,
        };
      }

      return reqData;
    };
console.log(userBetHistory, "userBetHistoryuserBetHistory");

    let { fancyBetData, oddsBetData, casinoBetData } = userBetHistory;
    let finalobject = [];

    // Process fancyBetData
    fancyBetData &&
      fancyBetData.forEach((fancyData) => {
        let profitLoss = 0;
        if (fancyData.isDeclare) {
          if (
            (fancyData.type === "N" && fancyData.decisionRun >= fancyData.run) ||
            (fancyData.type === "Y" && fancyData.decisionRun < fancyData.run)
          ) {
            profitLoss = fancyData.loss;
          } else if (
            (fancyData.type === "N" && fancyData.decisionRun < fancyData.run) ||
            (fancyData.type === "Y" && fancyData.decisionRun >= fancyData.run)
          ) {
            profitLoss = fancyData.profit;
          }
        }
        fancyData.profitLoss = profitLoss;
        finalobject.push({
          userInfo: fancyData.userInfo,
          description: fancyData.sessionName,
          selection: fancyData.oddsType,
          stack: parseFloat(fancyData.amount),
          odds: parseFloat(fancyData.odds),
          run: fancyData.run,
          Type: "Fancy Odds",
          createdAt: fancyData.createdAt,
          profit: parseFloat(fancyData.profit),
          type: fancyData.type,
          isDeclare: fancyData.isDeclare,
          ip: fancyData.ip,
          decisionRun: fancyData.decisionRun,
          profitLoss: profitLoss,
          oddsType: fancyData.oddsType,
        });
      });

    // Process oddsBetData
    oddsBetData &&
      oddsBetData.forEach((oddsData) => {
        finalobject.push({
          userInfo: oddsData.userInfo,
          description: oddsData.teamName,
          selection: oddsData.oddsType,
          stack: parseFloat(oddsData.amount),
          odds: parseFloat(oddsData.odds),
          Type: "Match Odds",
          createdAt: oddsData.createdAt,
          profit: parseFloat(oddsData.profit),
          type: oddsData.type,
          decisionRun: oddsData.wonTeamName,
          isDeclare: oddsData.isDeclare,
          ip: oddsData.ip,
          run: "",
          oddsType: oddsData.oddsType,
          profitLoss: parseFloat(oddsData.positionInfo[oddsData.decisionSelectionId]),
        });
      });

    // Process casinoBetData
    casinoBetData &&
      casinoBetData.forEach((casinoData) => {
        finalobject.push({
          userInfo: casinoData.userInfo,
          description: casinoData.teamName,
          selection: casinoData.oddsType,
          stack: parseFloat(casinoData.amount),
          odds: parseFloat(casinoData.odds),
          Type: "Match Odds",
          createdAt: casinoData.createdAt,
          profit: parseFloat(casinoData.profit),
          type: casinoData.type,
          isDeclare: casinoData.isDeclare,
          ip: casinoData.ip,
          run: "",
          oddsType: casinoData.oddsType,
          profitLoss: 0, // Adjust as per your calculation logic
        });
      });

    let totalAmount = finalobject.reduce((acc, data) => acc + data.stack, 0);
    let totalProfitLoss = finalobject.reduce((acc, data) => acc + data.profitLoss, 0);

  return (

    <GlobalLayout icons={<FaTrophy />} title="Bets Histroy">
      <div className="overflow-x-auto pb-5">
            <table className="table table-hover">
              <thead className="">
                <tr className="bg-gray-800 text-white">
                  <th scope="col">No</th>
                  <th scope="col">User</th>
                  <th scope="col">Runner</th>
                  <th scope="col">Rate</th>
                  <th scope="col">Stake</th>
                  {oddsType === "fancyOdds" ? (
                    <th scope="col">Desc Run</th>
                  ) : (
                    <th scope="col">Won Team</th>
                  )}
                  <th scope="col">Total</th>
                  <th scope="col">Date/Time</th>
                  <th scope="col">IP </th>
                </tr>
              </thead>
              <tbody className="text-black">
                {finalobject && finalobject.length > 0
                  ? finalobject.map((element, index) => (
                      <tr key={index} className={element &&(element.type === "K" ? "bg-[#EBB2B4]" : element.type === "L" ? "bg-sky-500" : element.type === "Y" ? "bg-sky-500" : "bg-[#EBB2B4]")}>
                        <td >{index + 1}</td>
                        <td> {element.userInfo?.clientCode} </td>
                        <td> {element.description}</td>
                        <td className="">
                          {element && element.run
                            ? `${element.run} / ${parseFloat(
                                element.odds * 100
                              ).toFixed(2).replace(/\.?0+$/, "")}`
                            : (
                                <>
                                  {element.oddsType === "matchOdds"
                                    ? (
                                        <>
                                          {parseFloat(
                                            Number(element.odds) + 1
                                          ).toFixed(3).replace(/\.?0+$/, "")}
                                        </>
                                      )
                                    : element.oddsType === "bookmaker" ||
                                      element.oddsType === "toss"
                                    ? (
                                        <>
                                          {parseFloat(element.odds * 100)
                                            .toFixed(3)
                                            .replace(/\.?0+$/, "")}
                                        </>
                                      )
                                    : (
                                        <>
                                          {parseFloat(element.odds)
                                            .toFixed(3)
                                            .replace(/\.?0+$/, "")}
                                        </>
                                      )}
                                </>
                              )}
                        </td>
                        <td>{element.stack}</td>
                        <td>{element.decisionRun}</td>
                        <td >{element.profitLoss.toFixed(2).replace(/\.?0+$/, "")}</td>
                        <td >{element && element.createdAt ? moment(element.createdAt)
                                .utcOffset("+05:30")
                                .format("DD/MM/YYYY hh:mm:ss A")
                            : ""}
                        </td>
                        <td> {element && element.ip ? element.ip : "0"}</td>
                      </tr>
                    ))
                  : null}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><b>Total</b></td>
                  <td><b>{parseFloat(totalAmount).toFixed(2).replace(/\.?0+$/, "")}</b></td>
                  <td></td>
                  <td className={totalProfitLoss > 0 ? 'text-green-600' : totalProfitLoss < 0 ? 'text-red-600' : 'text-black' }><b>{parseFloat(totalProfitLoss).toFixed(2).replace(/\.?0+$/, "")}</b></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
    </GlobalLayout>
  );
};

export default EventMatchHistoryBet;

