import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { httpPatch, httpPost, httpPostFormData } from "../../_helpers/http";
// import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import { toast } from 'react-toastify';
// import ToggleButton from './component/ToggleButton';
import { SketchPicker } from 'react-color';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import { httpPost, httpPostFormData } from '../../config/http2';
import GlobalLayout from '../../global_layouts/GlobalLayout/GlobalLayout';
import { domainName } from '../../constents/global';
import ToggleButton from '../../component/toggleButton/ToggleButton';
import { FaTrophy } from 'react-icons/fa';
// import MultipleFileUpload from './component/MultipleFileUpload';


class EditWebsite extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldsWebsiteUpdate: {},
            sportsSetting: {},
            themeSetting: {},
            socialMedia: {},
            fieldsWebsiteError: {},
            commissionTypeOption: "",
            userMetaDetails: {},
            parentUserDetails: {},
            matchDetails: {},
            socketPerm: "",
            socketUrl: "",
            scoreIframe: "",
            tvUrl: "",
            tvId: "",
            matchDetailsForSocket: {},
            scoreHeight: "130",
            inplayMatchList: {},
            scoreScreen: false,
            isTvScreen: false,
            cacheUrl: "",
            matchScoreDetails: {},
            isTvScreen: false,
            positionData: {},
            fancyData: {},
            fancyBetList: {},
            oddBetList: {},
            subownerList: {},
            agentList: {},
            displayBackGround: false,
            displayLightTheme: false,
            displayDarkTheme: false,
            selectedImage: null,
            banners: [],
            account: {},
            upi: {},
            barcode: {},
            paytm: {},
            phonePay: {},
            googlePay: {},
            bhimUpi: {},
        }
        this.modules = {
            toolbar: [
                [{ font: [] }],
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "image"],
                [{ list: "ordered" }, { list: "bullet" }],
                // [{ align: [] }],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                [{ color: [] }, { background: [] }],
                ["clean"]
            ],

        };

        this.formats = [
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "align",
            "color",
            "link",
            "image",
            "imageBlot",
            "background"
        ];
    }

    componentDidMount() {
        // this.getUserDetails();
        this.getMatchDataByMarketIdWise();
        this.getAgentList();
    }
    getMatchDataByMarketIdWise = async () => {

        let reqData = {
            "subownerId": JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.userId
        }
// test
        this.setState({ isFetch: true })
        let websiteDetails = await httpPost("website/getDomainSetting", reqData);
        if (websiteDetails?.data) {
            this.setState({ fieldsWebsiteUpdate: { ...websiteDetails.data, ...this.state.fieldsWebsiteUpdate } })
            this.setState({
                banners: !websiteDetails.data.banner ? [{ name: "", priority: "", image: "" }] : websiteDetails.data.banner,
            });
            this.setState({
                sportsSetting: !websiteDetails.data.sportsSetting ? this.state.sportsSetting : websiteDetails.data.sportsSetting,
            });
            this.setState({
                themeSetting: !websiteDetails.data.themeSetting ? this.state.themeSetting : websiteDetails.data.themeSetting,
            });
            this.setState({
                socialMedia: !websiteDetails.data.socialMedia ? this.state.socialMedia : websiteDetails.data.socialMedia,
            });
            this.setState({
                account: !websiteDetails.data.account ? this.state.account : websiteDetails.data.account,
            });
            this.setState({
                barcode: !websiteDetails.data.barcode ? this.state.barcode : websiteDetails.data.barcode,
            });
        }

        if (websiteDetails.data && websiteDetails.data.upi) {
            this.setState({
                paytm: !websiteDetails.data.upi.paytm ? this.state.paytm : websiteDetails.data.upi.paytm
            });
            this.setState({
                phonePay: !websiteDetails.data.upi.phonePay ? this.state.phonePay : websiteDetails.data.upi.phonePay,
            });
            this.setState({
                googlePay: !websiteDetails.data.upi.googlePay ? this.state.googlePay : websiteDetails.data.upi.googlePay,
            });
            this.setState({
                bhimUpi: !websiteDetails.data.upi.bhimUpi ? this.state.bhimUpi : websiteDetails.data.upi.bhimUpi,
            });
        }
        this.setState({ isFetch: false })
    }

    getAgentList = async () => {
        let reqData = {
            "specific": {
                username: 1,
                userId: 1,
                name: 1
            },
            "status": 1,
            "parentUserType": "subowner",
            "parentId": JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.userId,
            "downlineUserType": "agent"
        }
        let agentList = await httpPost('user/userList', reqData);
       
        if (agentList) {
            this.setState({ agentList: agentList && agentList?.data && agentList?.data?.list ? agentList?.data?.list : {} });
        }
    }

    handleInput = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        fieldsWebsiteUpdate[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ fieldsWebsiteUpdate, fieldsWebsiteError });
    }

    handleInputSocialMedia = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let socialMedia = this.state.socialMedia;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        socialMedia[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ socialMedia, fieldsWebsiteError });
    }

    handleInputPaytm = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let paytm = this.state.paytm;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        paytm[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ paytm, fieldsWebsiteError });
    }

    handleInputPhonePay = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let phonePay = this.state.phonePay;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        phonePay[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ phonePay, fieldsWebsiteError });
    }

    handleInputGooglePay = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let googlePay = this.state.googlePay;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        googlePay[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ googlePay, fieldsWebsiteError });
    }

    handleInputBhimUpi = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let bhimUpi = this.state.bhimUpi;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        bhimUpi[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ bhimUpi, fieldsWebsiteError });
    }

    handleInputBarcode = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let barcode = this.state.barcode;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        barcode[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ barcode, fieldsWebsiteError });
    }

    handleInputAccountDetails = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let account = this.state.account;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        account[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ account, fieldsWebsiteError });
    }

    handleInputThemeSetting = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let themeSetting = this.state.themeSetting;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        themeSetting[name] = value;
        fieldsWebsiteError[name] = "";
        this.setState({ themeSetting, fieldsWebsiteError });
    }

    handleChangeToggle = (e) => {
        e.preventDefault();
        let { id, value } = e.target;
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        fieldsWebsiteUpdate[id] = !fieldsWebsiteUpdate[id]
        this.setState({ fieldsWebsiteUpdate })
    }

    handleChangeSportsSettingToggle = (e) => {
        e.preventDefault();
        let { id, value } = e.target;
        let sportsSetting = this.state.sportsSetting;
        sportsSetting[id] = !sportsSetting[id]
        this.setState({ sportsSetting })
    }

    handleChangeDeleted = () => {
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        fieldsWebsiteUpdate["isDeleted"] = !fieldsWebsiteUpdate["isDeleted"]
        this.setState({ fieldsWebsiteUpdate })
    }

    handleSelectAgent = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        fieldsWebsiteUpdate[name] = value;
        fieldsWebsiteError[""] = "";
        this.setState({ fieldsWebsiteUpdate, fieldsWebsiteError });
    }

    handleUpdateSubmit = async () => {
        if (this.handleValidationWebsiteUpdate()) {
            let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
            let reqData = {
                "subownerId": JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.userId,
                "domainName": fieldsWebsiteUpdate.domainName,
                "domainUrl": fieldsWebsiteUpdate.domainUrl,
                "userNotification": fieldsWebsiteUpdate.userNotification,
                "clientNotification": fieldsWebsiteUpdate.clientNotification,
                // "logo": fieldsWebsiteUpdate.logo,
                // "favicon": fieldsWebsiteUpdate.favicon,
                // "title": fieldsWebsiteUpdate.title,
                "whatsappNumber": fieldsWebsiteUpdate.whatsappNumber,
                "helplineNumber": fieldsWebsiteUpdate.helplineNumber,
                // "assignSubownerId": fieldsWebsiteUpdate.assignSubownerId,
                "assignAgentId": fieldsWebsiteUpdate.assignAgentId,
                "registerBonus": fieldsWebsiteUpdate.registerBonus,
                "status": fieldsWebsiteUpdate.status,
                "isRegister": fieldsWebsiteUpdate.isRegister,
                "isAffilation": fieldsWebsiteUpdate.isAffilation,
                "talkTo": fieldsWebsiteUpdate.talkTo,
                "isDeleted": fieldsWebsiteUpdate.isDeleted,
                "aboutUs": fieldsWebsiteUpdate.aboutUs,
                "contactUs": fieldsWebsiteUpdate.contactUs,
                "themeSetting": this.state.themeSetting,
                "sportsSetting": this.state.sportsSetting,
                "socialMedia": this.state.socialMedia,
                "apiKey": {
                    "talkTo": fieldsWebsiteUpdate.talkTo,
                },
                "banner": this.state.banners,
                "account": this.state.account,
                "barcode": this.state.barcode,
                "upi": {
                    "paytm": this.state.paytm,
                    "googlePay": this.state.googlePay,
                    "phonePay": this.state.phonePay,
                    "bhimUpi": this.state.bhimUpi,
                },
            }
            this.setState({ isFetch: true })
            let updateWebsiteRes = await httpPost('website/updateDomain', reqData);
            if (updateWebsiteRes) {
                toast.success(updateWebsiteRes?.message);
                window.location.reload()
            }
            this.setState({ isFetch: false })
        }
    }
    handleValidationWebsiteUpdate = () => {
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        let errorsWebsiteUpdate = {};
        let formIsValid = true;

        // //title
        // if (!fieldsWebsiteUpdate["title"] || fieldsWebsiteUpdate["title"] === "") {
        //     formIsValid = false;
        //     errorsWebsiteUpdate["title"] = "Cannot be empty";
        // }

        this.setState({ errorsWebsiteUpdate: errorsWebsiteUpdate });
        return formIsValid;
    }

    handleClickLightTheme = () => {
        this.setState({ displayLightTheme: !this.state.displayLightTheme });
    };
    handleClickDarkTheme = () => {
        this.setState({ displayDarkTheme: !this.state.displayDarkTheme });
    };
    handleClickBackGround = () => {
        this.setState({ displayBackGround: !this.state.displayBackGround });
    };

    handleClose = () => {
        this.setState({
            displayLightTheme: false,
            displayBackGround: false,
            displayDarkTheme: false
        });
    };

    // handleChangeLightTheme = (color) => {
    //     let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
    //     let fieldsWebsiteError = this.state.fieldsWebsiteError;
    //     fieldsWebsiteUpdate.themeSetting["lightThemeColor"] = color.hex;
    //     fieldsWebsiteError["lightThemeColor"] = "";
    //     this.setState({ fieldsWebsiteUpdate, fieldsWebsiteError });
    // };

    handleChangeLightTheme = (color) => {
        let themeSetting = this.state.themeSetting;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        themeSetting["lightThemeColor"] = color.hex;
        fieldsWebsiteError["lightThemeColor"] = "";
        this.setState({ themeSetting, fieldsWebsiteError });
    };

    handleChangeDarkTheme = (color) => {
        let themeSetting = this.state.themeSetting;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        themeSetting["darkThemeColor"] = color.hex;
        fieldsWebsiteError["darkThemeColor"] = "";
        this.setState({ themeSetting, fieldsWebsiteError });
    };

    handleChangeBackGround = (color) => {
        let themeSetting = this.state.themeSetting;
        let fieldsWebsiteError = this.state.fieldsWebsiteError;
        themeSetting["backgroundColor"] = color.hex;
        fieldsWebsiteError["backgroundColor"] = "";
        this.setState({ themeSetting, fieldsWebsiteError });
    };


    rteChangeAboutUsUpdate = (content, delta, source, editor) => {
        let themeSetting = this.state.themeSetting;
        themeSetting["aboutUs"] = editor.getHTML();
        this.setState({ themeSetting });
    };

    rteChangeAboutUsTextUpdate = (e) => {
        e.preventDefault();
        let { value } = e.target;
        let themeSetting = this.state.themeSetting;
        themeSetting["aboutUs"] = value;
        this.setState({ themeSetting });
    };

    rteChangeContactUsUpdate = (content, delta, source, editor) => {
        let themeSetting = this.state.themeSetting;
        themeSetting["contactUs"] = editor.getHTML();
        this.setState({ themeSetting });
    };

    rteChangeContactUsTextUpdate = (e) => {
        e.preventDefault();
        let { value } = e.target;
        let themeSetting = this.state.themeSetting;
        themeSetting["contactUs"] = value;
        this.setState({ themeSetting });
    };

    handleFile = async (e) => {
        let fieldsWebsiteUpdate = this.state.fieldsWebsiteUpdate;
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                fieldsWebsiteUpdate[name] = fileData.data.imageName;
                this.setState({ fieldsWebsiteUpdate });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedBanners = [...this.state.banners];
        updatedBanners[index][name] = value;

        this.setState({
            banners: updatedBanners,
        });
    };

    handleAddBanner = () => {
        this.setState((prevState) => ({
            banners: [...prevState.banners, { name: '', priority: '', image: null }],
        }));
    };

    handleClearBanner = () => {
        this.setState(() => ({
            banners: [{ name: '', priority: '', image: null }],
        }));
    };

    handleRemoveBanner = (index) => {
        this.setState((prevState) => {
            const updatedBanners = [...prevState.banners];
            updatedBanners.splice(index, 1);
            return { banners: updatedBanners };
        });
    };

    handleImageChange = async (e, index) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const updatedBanners = this.state.banners;
                updatedBanners[index].image = `${this.state.fieldsWebsiteUpdate.imgBaseUrl}/${fileData.data.imageName}`;
                this.setState({ banners: updatedBanners, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handleBarcodeImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const barcode = this.state.barcode;
                barcode.image = `${this.state.fieldsWebsiteUpdate.imgBaseUrl}/${fileData.data.imageName}`;
                this.setState({ barcode: barcode, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handlePaytmImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const paytm = this.state.paytm;
                paytm.image = `${this.state.fieldsWebsiteUpdate.imgBaseUrl}/${fileData.data.imageName}`;
                this.setState({ paytm: paytm, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handlePhonePayImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const phonePay = this.state.phonePay;
                phonePay.image = `${this.state.fieldsWebsiteUpdate.imgBaseUrl}/${fileData.data.imageName}`;
                this.setState({ phonePay: phonePay, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handleGooglePayImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const googlePay = this.state.googlePay;
                googlePay.image = `${this.state.fieldsWebsiteUpdate.imgBaseUrl}/${fileData.data.imageName}`;
                this.setState({ googlePay: googlePay, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    handleBhimUpiImageChange = async (e) => {
        let { name } = e.target;
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                toast.success(fileData.message);
                const bhimUpi = this.state.bhimUpi;
                bhimUpi.image = `${this.state.fieldsWebsiteUpdate.imgBaseUrl}/${fileData.data.imageName}`;
                this.setState({ bhimUpi: bhimUpi, });
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            this.setState({ errorMessage });
        }
    }

    render() {


       
        let { fieldsWebsiteUpdate, fieldsWebsiteError, isFetch, agentList, banners, themeSetting, sportsSetting, socialMedia, account, barcode, paytm, phonePay, googlePay, bhimUpi } = this.state;

        return (

            <GlobalLayout icons={<FaTrophy />} title="Website Setting">
                

                <div className="flex flex-col flex-1 overflow-y-auto h-full">
                    <main className="relative flex-1">
                        <div className=" lg:px-1 sm:p-5 w-full">
                            <div className="mx-auto max-w-screen-3xl md:py-3 px-2">
                                <section className="flex-col col-span-6 bg-white">
                                    {/* <PageTitleBar title={`Update Website`} history={this.props.history} /> */}
                                    <div className='w-full py-2 px-3'>

                                        <div className='grid grid-col-1 lg:grid-cols-2 gap-4'>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Domain Name :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="domainName"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["domainName"] ? fieldsWebsiteUpdate["domainName"] : ""} onChange={this.handleInput} disabled />
                                                    {fieldsWebsiteError && fieldsWebsiteError["domainName"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["domainName"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Domain Url :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="domainUrl"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["domainUrl"] ? fieldsWebsiteUpdate["domainUrl"] : ""}
                                                        onChange={this.handleInput} disabled />
                                                    {fieldsWebsiteError && fieldsWebsiteError["domainUrl"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["domainUrl"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">User Notification :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <textarea rows={5} className="w-full p-2 text-sm text-black border focus:outline-none" type="text"
                                                        name="userNotification"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["userNotification"] ? fieldsWebsiteUpdate["userNotification"] : ""}
                                                        onChange={this.handleInput} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["userNotification"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["userNotification"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Client Notification :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <textarea rows={5} className="w-full p-2 text-sm text-black border focus:outline-none" type="text"
                                                        name="clientNotification"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["clientNotification"] ? fieldsWebsiteUpdate["clientNotification"] : ""}
                                                        onChange={this.handleInput} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["clientNotification"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["clientNotification"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Title :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="w-full p-2 text-sm text-black border focus:outline-none" type="text"
                                                        name="title"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["title"] ? fieldsWebsiteUpdate["title"] : ""}
                                                        onChange={this.handleInput} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["title"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["title"]}
                                                        </div> : null}
                                                </div>
                                            </div> */}
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">WhatsApp No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="w-full p-2 text-sm text-black border focus:outline-none" type="number"
                                                        name="whatsappNumber"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["whatsappNumber"] ? fieldsWebsiteUpdate["whatsappNumber"] : ""}
                                                        onChange={this.handleInput} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["whatsappNumber"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["whatsappNumber"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Helpline No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="w-full p-2 text-sm text-black border focus:outline-none" type="number"
                                                        name="helplineNumber"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["helplineNumber"] ? fieldsWebsiteUpdate["helplineNumber"] : ""}
                                                        onChange={this.handleInput} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["helplineNumber"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["helplineNumber"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Register Bonus :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="w-full p-2 text-sm text-black border focus:outline-none" type="text"
                                                        name="registerBonus"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["registerBonus"] ? fieldsWebsiteUpdate["registerBonus"] : ""}
                                                        onChange={this.handleInput} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["registerBonus"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["registerBonus"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Assign Agent :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <select className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer" id="assignAgentId" name="assignAgentId"
                                                        value={fieldsWebsiteUpdate && fieldsWebsiteUpdate["assignAgentId"] ? fieldsWebsiteUpdate["assignAgentId"] : ""}
                                                        onChange={this.handleSelectAgent} >
                                                        <option selected>Select Agent</option>
                                                        {agentList && agentList.length > 0 ?
                                                            agentList.map((element, index) => (
                                                                <option value={element && element.userId ? element.userId : "NA"}>{element && element.name ? element.name : "NA"}</option>
                                                            )) : null
                                                        }
                                                    </select>
                                                    {fieldsWebsiteError && fieldsWebsiteError["assignAgentId"] ?
                                                        <div className="text-xs text-red-500 pt-[3px]">
                                                            {fieldsWebsiteError["assignAgentId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Status :</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeToggle} isChecked={fieldsWebsiteUpdate["status"]} name={"status"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Register :</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeToggle} isChecked={fieldsWebsiteUpdate["isRegister"]} name={"isRegister"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Deleted :</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeToggle} isChecked={fieldsWebsiteUpdate["isDeleted"]} name={"isDeleted"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Affilation :</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeToggle} isChecked={fieldsWebsiteUpdate["isAffilation"]} name={"isAffilation"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Talk To :</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeToggle} isChecked={fieldsWebsiteUpdate["talkTo"]} name={"talkTo"} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-3xl text-black/80 capitalize leading-4'>Theme Setting</h2>
                                        </div>

                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize"> Site Name :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="siteName"
                                                        value={themeSetting && themeSetting["siteName"] ? themeSetting["siteName"] : ""}
                                                        onChange={this.handleInputThemeSetting} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["siteName"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["siteName"]}
                                                        </div> : null}
                                                </div>
                                            </div> */}
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Cache Url :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="cacheUrl"
                                                        value={themeSetting && themeSetting["cacheUrl"] ? themeSetting["cacheUrl"] : ""} onChange={this.handleInputThemeSetting} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["cacheUrl"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["cacheUrl"]}
                                                        </div> : null}
                                                </div>
                                            </div> */}
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Socket Url :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="socketUrl"
                                                        value={themeSetting && themeSetting["socketUrl"] ? themeSetting["socketUrl"] : ""} onChange={this.handleInputThemeSetting} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["socketUrl"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["socketUrl"]}
                                                        </div> : null}
                                                </div>
                                            </div> */}
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">TV Url :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="tvUrl"
                                                        value={themeSetting && themeSetting["tvUrl"] ? themeSetting["tvUrl"] : ""} onChange={this.handleInputThemeSetting} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["tvUrl"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["tvUrl"]}
                                                        </div> : null}
                                                </div>
                                            </div> */}
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Score Url :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="scoreUrl"
                                                        value={themeSetting && themeSetting["scoreUrl"] ? themeSetting["scoreUrl"] : ""} onChange={this.handleInputThemeSetting} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["scoreUrl"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["scoreUrl"]}
                                                        </div> : null}
                                                </div>
                                            </div> */}
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Background Color</label>
                                                </div>
                                                <div className="flex flex-col w-full relative">
                                                    <div className="w-full h-8 rounded cursor-pointer border border-gray-300"
                                                        style={{ backgroundColor: themeSetting && themeSetting["backgroundColor"] ? themeSetting["backgroundColor"] : "" }}
                                                        onClick={this.handleClickBackGround}
                                                    ></div>
                                                    {this.state.displayBackGround && (
                                                        <div className="absolute z-10 left-12 top-5">
                                                            <div className="fixed inset-0 bg-black opacity-25" onClick={this.handleClose}
                                                            ></div>
                                                            <SketchPicker
                                                                color={themeSetting && themeSetting["backgroundColor"] ? themeSetting["backgroundColor"] : ""}
                                                                onChange={this.handleChangeBackGround}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Dark theme Color</label>
                                                </div>
                                                <div className="flex flex-col w-full relative">
                                                    <div className="w-full h-8 rounded cursor-pointer border border-gray-300"
                                                        style={{ backgroundColor: themeSetting && themeSetting["darkThemeColor"] ? themeSetting["darkThemeColor"] : "" }}
                                                        onClick={this.handleClickDarkTheme}
                                                    ></div>
                                                    {this.state.displayDarkTheme && (
                                                        <div className="absolute z-10 left-12 top-5">
                                                            <div className="fixed inset-0 bg-black opacity-25" onClick={this.handleClose}
                                                            ></div>
                                                            <SketchPicker
                                                                // color={this.state.darkTheme}
                                                                color={themeSetting && themeSetting["darkThemeColor"] ? themeSetting["darkThemeColor"] : ""}
                                                                onChange={this.handleChangeDarkTheme}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">light Theme Color</label>
                                                </div>
                                                <div className="flex flex-col w-full relative">
                                                    <div className="w-full h-8 rounded cursor-pointer border border-gray-300"
                                                        style={{ backgroundColor: themeSetting && themeSetting["lightThemeColor"] ? themeSetting["lightThemeColor"] : "" }}
                                                        onClick={this.handleClickLightTheme}
                                                    ></div>
                                                    {this.state.displayLightTheme && (
                                                        <div className="absolute z-10 left-12 top-5">
                                                            <div className="fixed inset-0 bg-black opacity-25" onClick={this.handleClose}
                                                            ></div>
                                                            <SketchPicker
                                                                // color={this.state.lightTheme}
                                                                color={themeSetting && themeSetting["lightThemeColor"] ? themeSetting["lightThemeColor"] : ""}
                                                                onChange={this.handleChangeLightTheme}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Website Type</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <select id="websiteType" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer" name="websiteType" value={themeSetting && themeSetting["websiteType"] ? themeSetting["websiteType"] : ""} onChange={this.handleInputThemeSetting} >
                                                        <option selected>Select Website type</option>
                                                        <option value="b2b">B To B</option>
                                                        <option value="b2c">B To C</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Country :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="country"
                                                        value={themeSetting && themeSetting["country"] ? themeSetting["country"] : ""} onChange={this.handleInputThemeSetting} />
                                                </div>
                                            </div> */}
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Currency :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="currency"
                                                        value={themeSetting && themeSetting["currency"] ? themeSetting["currency"] : ""} onChange={this.handleInputThemeSetting} />
                                                </div>
                                            </div> */}
                                            {/* <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Language :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="language"
                                                        value={themeSetting && themeSetting["language"] ? themeSetting["language"] : ""} onChange={this.handleInputThemeSetting} />
                                                </div>
                                            </div> */}
                                            <div className='w-full'>
                                                <div className="w-full">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">About Us Text :</label>
                                                    <ReactQuill
                                                        modules={this.modules}
                                                        formats={this.formats}
                                                        onChange={this.rteChangeAboutUsUpdate}
                                                        value={themeSetting && themeSetting["aboutUs"]}
                                                    />
                                                </div>
                                                <div className='w-full'>
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">HTML as About Us: :</label>
                                                    <span className=''>
                                                        <textarea className='border border-gray-400' name='aboutUs'
                                                            onChange={this.rteChangeAboutUsTextUpdate}
                                                            value={themeSetting && themeSetting["aboutUs"]}
                                                            rows={5}
                                                            style={{ width: '100%' }} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='w-full'>
                                                <div className="w-full">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Contact Us Text :</label>
                                                    <ReactQuill
                                                        modules={this.modules}
                                                        formats={this.formats}
                                                        onChange={this.rteChangeContactUsUpdate}
                                                        value={themeSetting && themeSetting["contactUs"]}
                                                    />
                                                </div>
                                                <div className='w-full'>
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">HTML as Contact Us: :</label>
                                                    <span className=''>
                                                        <textarea className='border border-gray-400' name='contactUs'
                                                            onChange={this.rteChangeContactUsTextUpdate}
                                                            value={themeSetting && themeSetting["contactUs"]}
                                                            rows={5}
                                                            style={{ width: '100%' }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-3xl text-black/80 capitalize leading-4'>Sport Setting</h2>
                                        </div>

                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Cricket :</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isCricket"] ? sportsSetting["isCricket"] : false} name={"isCricket"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Soccer:</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isSoccer"] ? sportsSetting["isSoccer"] : false} name={"isSoccer"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Tennis:</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isTennis"] ? sportsSetting["isTennis"] : false} name={"isTennis"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">International Casino:</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isIntCasino"] ? sportsSetting["isIntCasino"] : false} name={"isIntCasino"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Diamond Casino:</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isDiamondCasino"] ? sportsSetting["isDiamondCasino"] : false} name={"isDiamondCasino"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Horse Racing:</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isHorseRacing"] ? sportsSetting["isHorseRacing"] : false} name={"isHorseRacing"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Grey Hound:</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isGreyHound"] ? sportsSetting["isGreyHound"] : false} name={"isGreyHound"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Kabaddi:</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isKabaddi"] ? sportsSetting["isKabaddi"] : false} name={"isKabaddi"} />
                                                </div>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Politics:</label>
                                                </div>
                                                <div className="flex flex-col">
                                                    <ToggleButton handleChange={this.handleChangeSportsSettingToggle} isChecked={sportsSetting && sportsSetting["isPolitics"] ? sportsSetting["isPolitics"] : false} name={"isPolitics"} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-3xl text-black/80 capitalize leading-4'>Social Media</h2>
                                        </div>

                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Facebook Link :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="facebookLink"
                                                        value={socialMedia && socialMedia["facebookLink"] ? socialMedia["facebookLink"] : ""} onChange={this.handleInputSocialMedia} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["facebookLink"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["facebookLink"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Instagram Link :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="instaLink"
                                                        value={socialMedia && socialMedia["instaLink"] ? socialMedia["instaLink"] : ""} onChange={this.handleInputSocialMedia} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["instaLink"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["instaLink"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">LinkedIn Link :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="linkedInLink"
                                                        value={socialMedia && socialMedia["linkedInLink"] ? socialMedia["linkedInLink"] : ""} onChange={this.handleInputSocialMedia} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["linkedInLink"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["linkedInLink"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Telegram Link :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="telegramLink"
                                                        value={socialMedia && socialMedia["telegramLink"] ? socialMedia["telegramLink"] : ""} onChange={this.handleInputSocialMedia} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["telegramLink"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["telegramLink"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-3xl text-black/80 capitalize leading-4'>Banner</h2>
                                        </div>
                                        <div className='space-y-2'>
                                            {banners.map((element, index) => (
                                                <div key={index} className='grid grid-cols-1 lg:grid-cols-4 gap-4'>
                                                    <div className="flex flex-col w-full">
                                                        <div className="w-full">
                                                            <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Banner Name :</label>
                                                        </div>
                                                        <input className="border p-2 focus:outline-none text-sm text-black w-full"
                                                            type="text"
                                                            name="name"
                                                            value={element.name}
                                                            onChange={(event) => this.handleInputChange(event, index)}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col w-full">
                                                        <div className="w-full">
                                                            <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Banner Priority :</label>
                                                        </div>
                                                        <input className="border p-2 focus:outline-none text-sm text-black w-full"
                                                            type="text"
                                                            name="priority"
                                                            value={element.priority}
                                                            onChange={(event) => this.handleInputChange(event, index)}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col w-full">
                                                        <div className="w-full">
                                                            <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Text :</label>
                                                        </div>
                                                        <textarea rows={2} className="border p-2 focus:outline-none text-sm text-black w-full"
                                                            type="text"
                                                            name="text"
                                                            value={element.text}
                                                            onChange={(event) => this.handleInputChange(event, index)}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col w-full">
                                                        <div className="w-full">
                                                            <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Choose Banner :</label>
                                                        </div>
                                                        <div className='flex space-x-2 items-start'>
                                                            <img src={element && element.image} alt='barcode' className='h-16 w-32 rounded' />
                                                            <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(event) => this.handleImageChange(event, index)}
                                                            />
                                                            {index === 0 ? null :
                                                                <button className="px-2 py-1 cursor-pointer bg-red-500 text-white" type="button" onClick={() => this.handleRemoveBanner(index)}>
                                                                    Remove
                                                                </button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {banners && banners[0] && banners[0].image ?
                                                <div className='flex space-x-2'>
                                                    <button className="rounded-none cursor-pointer btn btn-type" type="button" onClick={this.handleAddBanner}>
                                                        Add Banner
                                                    </button>
                                                    <button className="rounded-none cursor-pointer btn btn-type" type="button" onClick={this.handleClearBanner}>
                                                        Clear All Banners
                                                    </button>
                                                </div> : <button className="rounded-none cursor-pointer btn btn-type" type="button" >
                                                    Add Banner
                                                </button>}
                                        </div>

                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-3xl text-black/80 capitalize leading-4'>Account Details</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Account Holder Name :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="acHolderName"
                                                        value={account && account["acHolderName"] ? account["acHolderName"] : ""}
                                                        onChange={this.handleInputAccountDetails} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["acHolderName"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["acHolderName"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Account No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="accountNumber"
                                                        value={account && account["accountNumber"] ? account["accountNumber"] : ""}
                                                        onChange={this.handleInputAccountDetails} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["accountNumber"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["accountNumber"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Branch Name :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="branchName"
                                                        value={account && account["branchName"] ? account["branchName"] : ""}
                                                        onChange={this.handleInputAccountDetails} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["branchName"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["branchName"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">IFSC Code :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="ifscCode"
                                                        value={account && account["ifscCode"] ? account["ifscCode"] : ""}
                                                        onChange={this.handleInputAccountDetails} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["ifscCode"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["ifscCode"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-3xl text-black/80 capitalize leading-4'>UPI</h2>
                                        </div>
                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-xl text-center text-black/80 capitalize leading-4'>Paytm UPI</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handlePaytmImageChange(event)}
                                                    />
                                                    <img src={paytm && paytm["image"]} alt='barcode' className='h-24 w-24' />
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">UPI Id :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="upiId"
                                                        value={paytm && paytm["upiId"] ? paytm["upiId"] : ""}
                                                        onChange={this.handleInputPaytm} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["upiId"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["upiId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Mobile No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="mobNo"
                                                        value={paytm && paytm["mobNo"] ? paytm["mobNo"] : ""}
                                                        onChange={this.handleInputPaytm} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["mobNo"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["mobNo"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-xl text-center text-black/80 capitalize leading-4'>PhonePay UPI</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handlePhonePayImageChange(event)}
                                                    />
                                                    <img src={phonePay && phonePay["image"]} alt='barcode' className='h-24 w-24' />
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">UPI Id :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="upiId"
                                                        value={phonePay && phonePay["upiId"] ? phonePay["upiId"] : ""}
                                                        onChange={this.handleInputPhonePay} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["upiId"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["upiId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Mobile No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="mobNo"
                                                        value={phonePay && phonePay["mobNo"] ? phonePay["mobNo"] : ""}
                                                        onChange={this.handleInputPhonePay} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["mobNo"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["mobNo"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-xl text-center text-black/80 capitalize leading-4'>GooglePay UPI</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handleGooglePayImageChange(event)}
                                                    />
                                                    <img src={googlePay && googlePay["image"]} alt='barcode' className='h-24 w-24' />
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">UPI Id :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="upiId"
                                                        value={googlePay && googlePay["upiId"] ? googlePay["upiId"] : ""}
                                                        onChange={this.handleInputGooglePay} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["upiId"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["upiId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Mobile No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="mobNo"
                                                        value={googlePay && googlePay["mobNo"] ? googlePay["mobNo"] : ""}
                                                        onChange={this.handleInputGooglePay} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["mobNo"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["mobNo"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="py-3">
                                            <h2 className='text-[23px] lg:text-xl text-center text-black/80 capitalize leading-4'>Bhim UPI</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handleBhimUpiImageChange(event)}
                                                    />
                                                    <img src={bhimUpi && bhimUpi["image"]} alt='barcode' className='h-24 w-24' />
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">UPI Id :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="upiId"
                                                        value={bhimUpi && bhimUpi["upiId"] ? bhimUpi["upiId"] : ""}
                                                        onChange={this.handleInputBhimUpi} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["upiId"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["upiId"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Mobile No. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="mobNo"
                                                        value={bhimUpi && bhimUpi["mobNo"] ? bhimUpi["mobNo"] : ""}
                                                        onChange={this.handleInputBhimUpi} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["mobNo"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["mobNo"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="py-3">
                                            <h2 className='text-[23px] lg:text-3xl text-black/80 capitalize leading-4'>Barcode</h2>
                                        </div>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>

                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Barcode :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-1 focus:outline-none text-sm text-black w-full"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(event) => this.handleBarcodeImageChange(event)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">No.of Withdraw Req. :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="withdrawReq"
                                                        value={barcode && barcode["withdrawReq"] ? barcode["withdrawReq"] : ""}
                                                        onChange={this.handleInputBarcode} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["withdrawReq"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["withdrawReq"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                            <div className="md:flex items-center lg:space-x-2">
                                                <div className="w-2/5">
                                                    <label className="block text-[14px] font-medium text-black/70 md:text-left capitalize">Remark :</label>
                                                </div>
                                                <div className="flex flex-col w-full">
                                                    <input className="border p-2 focus:outline-none text-sm text-black w-full" type="text"
                                                        name="remark"
                                                        value={barcode && barcode["remark"] ? barcode["remark"] : ""}
                                                        onChange={this.handleInputBarcode} />
                                                    {fieldsWebsiteError && fieldsWebsiteError["remark"] ?
                                                        <div className="text-xs text-red pt-[3px]">
                                                            {fieldsWebsiteError["remark"]}
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='flex justify-end pt-3 pb-4 space-x-5'>
                                            <button className="rounded-none cursor-pointer btn btn-type bg-green-500 text-white font-bold" type="button" onClick={this.handleUpdateSubmit}>Submit</button>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </div>
            </GlobalLayout>
        );
    }
}
function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}
export default connect(mapStateToProps)(EditWebsite);
