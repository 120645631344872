import { useDispatch, useSelector } from "react-redux";
import Drawer from "../../../global_layouts/Drawer/Drawer";
import {
  getCoinsUpdate,
  getUserBalance,
  setUpdateUserListFalse,
} from "../../../redux/_reducers/_user_reducers";
import { useEffect, useState } from "react";
import { domainName } from "../../../constents/global";

const DepositWidrawal = (props) => {
  const [coins, setCoins] = useState("");
  const [reference, setReference] = useState("");
  const { isDrawerOpen, setIsDrawerOpen, propsData } = props;
  const isOpen = isDrawerOpen.depositWidralDrawer;
  const dispatch = useDispatch();
  const { updateUserList } = useSelector((state) => state.user);




  const handleCoinsChange = (e) => {
    setCoins(e.target.value);
  };

  const handleReferenceChange = (e) => {
    setReference(e.target.value);
  };

  const handleTransaction = (e, transactionType) => {
    e.preventDefault();

    if (!coins) {
      alert("Please enter the amount.");
      return;
    }

    const reqData = {
      userId: propsData.dipWidProps.userId,
      coins: transactionType === "deposit" ? parseInt(coins) : -parseInt(coins),
      // reference: reference,
    };

    dispatch(getCoinsUpdate(reqData));
  };
  const userInfo = JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data;
  useEffect(() => {
    if (updateUserList === true) {
     
      dispatch(getUserBalance())

      setCoins("");
      setReference("");
      setIsDrawerOpen({ depositWidralDrawer: false });
    }
  }, [updateUserList]);
  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setIsDrawerOpen({ depositWidralDrawer: false })}
      direction="right"
      size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
      overlay={true}
    >
      <div className="py-2 px-2">
        <div className="border-b pb-2 text-white">
          {` A/C Chips In/Out for [${propsData?.dipWidProps.username}]`}
          {/* <br />
         {` ${Parent} Balance: 447,606,180.56`} */}
          <br />
          {`${propsData?.dipWidProps.username} [${propsData?.dipWidProps?.name}] Balance: ${propsData?.dipWidProps?.coins}`}
          <br />
        </div>

        {/* <form className="p-2.5"> */}
        <div className="grid gap-6 mb-2 mt-1 md:grid-cols-2">
          <div>
            <label
              htmlFor="depositWid"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
            >
              Chips
            </label>

            <input
              type="number"
              id="depositWid"
              name="depositWid"
              value={coins}
              onChange={handleCoinsChange}
              className="border border-gray-300 text-gray-900 text-sm block w-full p-1.5 bg-gray-300"
              placeholder="Enter amount"
            />
          </div>
          <div>
            <label
              htmlFor="reference"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
            >
              Reference
            </label>
            <input
              type="text"
              id="reference"
              name="reference"
              value={reference}
              onChange={handleReferenceChange}
              className="border border-gray-300 text-gray-900 text-sm block w-full p-1.5 bg-gray-300"
              placeholder="Enter reference"
            />
          </div>
        </div>

        <div className="flex items-start mt-2 gap-1 w-full">
          <button
            type="button"
            onClick={(e) => handleTransaction(e, "deposit")}
            className="text-white w-1/2 uppercase bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none font-normal rounded-sm text-md w-100 sm:w-auto px-5 py-1.5 text-center"
          >
            Deposit
          </button>
          <button
            type="button"
            onClick={(e) => handleTransaction(e, "withdraw")}
            className="text-white w-1/2 uppercase bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none font-normal rounded-sm text-md w-100 sm:w-auto px-5 py-1.5 text-center"
          >
            Withdrawal
          </button>
        </div>
        {/* </form> */}
      </div>
    </Drawer>
  );
};

export default DepositWidrawal;
