import React, { useState } from "react";
import { Card, DatePicker, Table, Button, Row, Col } from "antd";
import moment from "moment";
// import BackButton from "../../Hoc/BackButton";
import { useSelector } from "react-redux";

// import Loader from "../../../../components/loader";
import { Link } from "react-router-dom";
import Loader from "../../../component/loader/Loader";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import Triangle from "../../../global_layouts/Triangle/Triangle";
import dayjs from "dayjs";

const Basic = () => {
  const [dates, setDates] = useState("");

  const { loading } = useSelector((state) => state.user);

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };

    return obj;
  };

  const columns = [
    {
      title: "CODE",
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#3f3f46",
          paddingInline: "10px",
          paddingBlock: "8px",
          fontWeight: "bold",
          fontSize: "12px",
          color: "white",
        },
      }),
      dataIndex: "code",
      render: renderContent,
    },
    {
      title: "NAME",
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#3f3f46",
          paddingInline: "10px",
          paddingBlock: "8px",
          fontWeight: "bold",
          fontSize: "12px",
          color: "white",
        },
      }),
      dataIndex: "name",
      render: (value, row) => (
        <Link to={`/components/casino/casinoinplayview/${row.id}/${row.name}`}>
          <span className="gx-text-blue">{row.name}</span>
        </Link>
      ),
    },
    {
      title: "DATE & TIME",
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#3f3f46",
          paddingInline: "10px",
          paddingBlock: "8px",
          fontWeight: "bold",
          fontSize: "12px",
          color: "white",
        },
      }),
      dataIndex: "date",
      render: renderContent,
    },
    {
      title: "ACTION",
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: "#3f3f46",
          paddingInline: "10px",
          paddingBlock: "8px",
          fontWeight: "bold",
          fontSize: "12px",
          color: "white",
        },
      }),
      dataIndex: "action",
      render: (text, record) => (
        <div className="flex justify-end gap-1">
          <Button
  className="bg-blue-400 text-white hover:!text-white hover:!bg-blue-400 font-bold px-4 py-1"
  type="primary"
  onClick={() => {
    // Construct the URL and navigate to it
    window.location.href = `/admin/casinoinplayview/${record.id}/${record.name}`;
  }}
>
  <span className="leading-bold uppercase text-[12px]">
    View
  </span>
</Button>

          <Button className="px-4 py-1 leading-0 bg-orange-400 !border-0 text-white hover:!bg-orange-400 font-bold">
            <Link
              className="hover:!text-white uppercase text-[12px]"
              to={`/admin/inplaycasinodetails/${record.name}/${record.id}/`}
            >
              Display Games
            </Link>
          </Button>
        </div>
      ),
    },
  ];

  // Define the data source
  const dataSource = [
    {
      key: "1",
      name: "AmarAkbarAnthony",
      date: (
        <span className="gx-text-nowrap">
          {moment().utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss A")}
        </span>
      ),
      action: "",
      code: 1,
      id: 3056,
    },
    {
      key: "2",
      name: "Live Teen Patti One Day",
      date: (
        <span className="gx-text-nowrap">
          {moment().utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss A")}
        </span>
      ),
      action: "",
      code: 2,
      id: 3031,
    },
    {
      key: "3",
      name: "DragonTiger",
      date: (
        <span className="gx-text-nowrap">
          {moment().utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss A")}
        </span>
      ),
      action: "",
      code: 3,
      id: 3035,
    },
    {
      key: "4",
      name: "DragonTiger T20",
      date: (
        <span className="gx-text-nowrap">
          {moment().utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss A")}
        </span>
      ),
      action: "",
      code: 4,
      id: 3059,
    },
    {
      key: "5",
      name: "luck7b",
      date: (
        <span className="gx-text-nowrap">
          {moment().utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss A")}
        </span>
      ),
      action: "",
      code: 5,
      id: 3032,
    },
    {
      key: "6",
      name: "TeenpattiT20",
      date: (
        <span className="gx-text-nowrap">
          {moment().utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss A")}
        </span>
      ),
      action: "",
      code: 6,
      id: 3030,
    },
    {
      key: "7",
      name: "Warli Matka",
      date: (
        <span className="gx-text-nowrap">
          {moment().utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss A")}
        </span>
      ),
      action: "",
      code: 7,
      id: 3054,
    },
  ];

  const RangePicker = DatePicker.RangePicker;

  return (
    <>
      {loading ? (
        <Loader loaderTrue={true} />
      ) : (
        <GlobalLayout>
          <Card className="gx-card">
          <div className="bg-red-600 flex justify-between items-center ">
            <div className="text-white bg-red-700 relative font-bold px-4 py-2">
              {" "}
              Inplay Casino
              <Triangle direction="down" size={10} color="#B91C1C" />
            </div>
            <button
              onClick={() => window.location.reload()}
              className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
            >
              {" "}
              Refresh
            </button>
          </div>

          <div className="my-3">
            
          </div>
          <div>
            <Table
              className="table-responsive customTable"
              columns={columns}
              dataSource={dataSource}
              bordered
              pagination={false}
              size="small"
              rowClassName={(record, index) =>
                index % 2 === 0 ? "bg-gray-100 " : ""
              }
            />
          </div>
        </Card>
        </GlobalLayout>
      )}
    </>
  );
};

export default Basic;
