import React from "react";
import { Row, Col } from "antd";
import Basic from "./Basic";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";


const PlusminusCasinoDetails = () => {
  return (

      <Row justify={"center"}>
      <Col xs={24}>
        <Basic />
      </Col>
    </Row>

  );
};

export default PlusminusCasinoDetails;