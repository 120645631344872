import React from "react";
import CustomModal from "../../global_layouts/Modal/CustomModal";
import moment from "moment/moment";


const HistoryModal = ({ userLedgerData, isHistoryModalOpen, setIsHistoryModalOpen }) => {

    const totalAmount = userLedgerData?.ledgerData?.reduce((sum, data) => sum + data.amount, 0) || 0;
    let showAmount = 0;

    const ledgerDataFinal = userLedgerData?.ledgerData?.map((data) => {
        const adjustedAmount = data.userType === "client" ? -data.amount : data.amount;
        const userBalance = totalAmount - showAmount;
        showAmount += adjustedAmount;

        return {
            amount: adjustedAmount,
            userBalance: userBalance,
            createdAt: data.createdAt,
            remark: data.remark,
            ledgerType: data.ledgerType,
            eventName: data.eventName,
            userType: data.userType,
        };
    }) || [];

    return (
        <CustomModal
            isOpen={isHistoryModalOpen}
            onClose={() => setIsHistoryModalOpen(false)}
            header={<div className=" text-right px-2 w-100 h-10 py-1" onClick={() => setIsHistoryModalOpen(false)}>
                <span className="bg-red-700  top-0 right-2 px-2 py-1  text-white text-lg font-bold">
                    &times;
                </span>
            </div>}
            position={{ horizontal: 'center', vertical: 'top' }} // Custom position
            size={{ width: '60%', }} // Custom size
        >
            <table className="table table-bordered table-sm text-nowrap">
                <thead className="text-sm font-semibold uppercase">
                    <tr className="bg-gray-900 text-white">
                        <th >No</th>
                        <th>Desc</th>
                        <th>Type</th>
                        <th >Amount</th>
                        <th >Total</th>
                        <th >D/C</th>
                        <th >Note</th>
                        <th >Time&Date</th>
                    </tr>
                </thead>
                <tbody className="text-sm font-medium uppercase">
                    {ledgerDataFinal && ledgerDataFinal.length > 0 ? ledgerDataFinal?.map((element, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{element.eventName}</td>
                            <td>{element.ledgerType}</td>
                            <td>{Number.parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                            <td>{Number.parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                            <td>{element.amount >= 0 ? 'Credit' : element.amount < 0 ? 'Debit' : 'N/A'}</td>
                            <td>{element.remark}</td>
                            <td>{element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD MMM hh:mm:ss A") : ""}</td>
                        </tr>
                    )) : <tr>
                        <td colSpan={8} className="text-center">
                            No Data Found
                        </td></tr>}
                </tbody>
            </table>

            {/* <div>
                <table>
                    <thead>
                        <tr>
                            <th>Amount</th>
                            <th>User Balance</th>
                            <th>Created At</th>
                            <th>Remark</th>
                            <th>Ledger Type</th>
                            <th>Event Name</th>
                            <th>User Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ledgerDataFinal.map((item, index) => (
                            <tr key={index}>
                                <td>{item.amount}</td>
                                <td>{item.userBalance}</td>
                                <td>{new Date(item.createdAt).toLocaleString()}</td>
                                <td>{item.remark}</td>
                                <td>{item.ledgerType}</td>
                                <td>{item.eventName}</td>
                                <td>{item.userType}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div> */}
        </CustomModal>
    );
};

export default HistoryModal;
