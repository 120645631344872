import { TbLayoutDashboardFilled } from "react-icons/tb";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import Card from "../../global_layouts/Card/Card";
import Triangle from "../../global_layouts/Triangle/Triangle";
import { gameList } from "../../constents/JsonConstant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import { RiSettings4Fill } from "react-icons/ri";
import { PiChartLineBold } from "react-icons/pi";
import Loader from "../../component/loader/Loader";
import MatchDetails from "../details/MatchDeatils";
import MatchSetting from "../../component/matchSetting/MatchSetting";
import DetailsMarket from "../../component/detaileMarket/DeatilMarket";
import { encrypt } from "../../config/Encryption";
import { IoPlay } from "react-icons/io5";
import { getSportMatchList } from "../../redux/_reducers/_sports_reducers";
import { reportBlockMarket, reportsUserWiseBlockMarketList } from "../../redux/_reducers/_report_reducers";
import moment from "moment";




const Dashboard = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [activeSport, setActiveSport] = useState(4)
  const [activeSportsData, setActiveSportData] = useState([])
  const [isDrawerOpens, setIsDrawerOpens] = useState({
    matchSettingDrawer: false,
    detailsMarketDrawer: false
  })
  const dispatch = useDispatch()
  const { sportmatchlist, loading } = useSelector(state => state.sport)
  const { reportsUserWiseBlockMarketListData } = useSelector(state => state?.report)



  useEffect(() => {
    if (sportmatchlist && sportmatchlist?.data && sportmatchlist !== undefined) {
      const targetArray = [4, 3, 2, 1];
      if (JSON.stringify(activeSport) === JSON.stringify(targetArray)) {
        const filteredData = sportmatchlist?.data;
        setActiveSportData(filteredData);

      } else {
        const filteredData = sportmatchlist?.data?.filter((item) => item.sportId === activeSport);
        setActiveSportData(filteredData);
      }
    }

    // console.log(sportmatchlist?.data, "rrrrrrrrrrrr");
    // if (sportmatchlist?.data) {
    //   let sportmatchlistData= sportmatchlist?.data;
    //   sportmatchlistData.map((match, key) => {
    //     if(reportsUserWiseBlockMarketListData[match?.marketId]){
  
    //       // sportmatchlistData[key]?.matchBlock = true;
    //     }
    //     console.log(match?.marketId, "ttttttttttttt");
        
        
    //   })
    // }

    // if(sportmatchlist?.data && reportsUserWiseBlockMarketListData) {
    //   sportmatchlist?.data.map((match, key) => {
    //     if (reportsUserWiseBlockMarketListData[marketId]) {
    //       sportmatchlist[key]?.matchBlock = true
    //     } else {
    //       sportmatchlist[key]?.matchBlock = false
    //     }
    //   })
    // }

  }, [sportmatchlist, activeSport]);


  useEffect(() => {
    const reqData = {
      sportId: activeSport
    }


    dispatch(getSportMatchList(reqData))
    dispatch(reportsUserWiseBlockMarketList())
  }, [activeSport])


  const matchSettingModal = () => {
    setIsDrawerOpens({
      matchSettingDrawer: true,
      detailsMarketDrawer: false
    })
  }

  const detailsMarketModal = () => {
    setIsDrawerOpens({
      matchSettingDrawer: false,
      detailsMarketDrawer: true
    })
  }

  const handelMarketBlock = async (req) => {
    let reqData = {
      "marketId": req,
      "blockStatus": true
    }
    await dispatch(reportBlockMarket(reqData))


  }


  return (loading ?
    <Loader loaderTrue={true} /> :
    <>
      <GlobalLayout icons={<TbLayoutDashboardFilled />} title="Dashboard">
        <div className="flex flex-col gap-3">
          <div className="bg-red-600 flex justify-between items-center">
            <div className="text-white bg-red-700 relative font-bold px-4 py-2"> Betfair<Triangle direction="down" size={10} color="#B91C1C" /></div>
            <button className="bg-green-500 text-white py-1 mr-1 px-3 rounded" > Refresh</button>
          </div>

          <div className="bg-red-600 flex divide-x divide-gray-500">
            {gameList.map((game, index) => (
              <div key={index} onClick={() => setActiveSport(game.sportId)}
                className={`text-white ${activeSport === game.sportId ? 'bg-red-700' : 'bg-transparent'} capitalize flex items-center gap-1 relative font-bold px-3 py-2`}
              >
                {game.icon}
                <span className={`${activeSport === game.sportId && 'hover:text-black'}`}>{game.sportName}</span>
                {activeSport === game.sportId &&
                  <Triangle direction="down" size={10} color="#B91C1C" />}
              </div>
            ))
            }
          </div>

          {/* <div className="flex flex-col items-start justify-center gap-2 md:flex-row md:items-center md:justify-between space-x-4 p-2">
            <div className="flex gap-3 items-center">
              <input
                type="text"
                placeholder="Search"
                className="border border-gray-300 rounded p-1"
              />
              <select className="border border-gray-300 rounded p-1">
                <option value="25">Rows 25</option>
                <option value="50">Rows 50</option>
                <option value="100">Rows 100</option>
              </select>
            </div>

            <div className="flex items-center">
              <button className="border border-gray-300 px-3 py-1">&lt;</button>
              <span className="border border-gray-700 bg-gray-600 text-white p-1 px-2">1</span>
              <button className="border border-gray-300 px-3 py-1">&gt;</button>
            </div>
          </div> */}
        </div>
        <Card className="overflow-auto">
          <table className="table table-sm table-bordered">
            <thead>
              <tr className="bg-zinc-700 text-white">
                <th className="text-nowrap">Series Name</th>
                <th className="text-nowrap">Match Name</th>
                <th className="text-nowrap">Date</th>
                <th className="text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {activeSportsData && activeSportsData.length > 0 ? activeSportsData.sort(
            (a, b) => moment(a.matchDate, "DD-MM-YYYY HH:mm:ss").isBefore(moment(b.matchDate, "DD-MM-YYYY HH:mm:ss")) ? -1 : 1
          ).map((game, index) => (
                <tr key={index}>
                  <td className="text-nowrap">{game?.seriesName || "-"}</td>
                  <td className="text-nowrap">
                    <div className="flex items-center gap-2 cursor-pointer">
                      {
                        game?.status === 'INPLAY' ? <div className="text-white text-[12px] bg-green-500 w-[20px] h-[20px] rounded-sm flex items-center justify-center">A</div> : null
                      }
                      {game?.matchName || "-"}
                      {
                        game?.status === 'INPLAY' ? <span className="bg-[#43d17f] rounded-full w-[18px] h-[18px] flex items-center justify-center"><IoPlay className="text-white w-[10px] h-[10px]" /></span> : null
                      }
                    </div>
                  </td>
                  <td className="text-nowrap">{game?.matchDate || "-"}</td>
                  <td className="flex gap-2">
                    {/* <span className="badge bg-green-400 w-10 h-7 flex justify-center items-center cursor-pointer" onClick={() => handelMarketBlock(game?.marketId)}>
                      <FaCheck className="text-white" />
                    </span> */}
                    {/* <span className="badge bg-blue-400 w-10 h-7 flex justify-center items-center">
                      <FaCircleInfo className="text-white" />
                    </span> */}
                    {/* <span className="badge bg-green-400 w-10 h-7 flex justify-center items-center">
                      <FaCheck className="text-white" />
                    </span> */}
                    <a href={`viewmatch-details/${encrypt(game.marketId)}/${encrypt(game.eventId)}`} className="">
                      <span className="badge bg-orange-400 w-10 h-7 flex justify-center items-center">
                        <FaCircleInfo className="text-white" />
                      </span>
                    </a>
                    {/* <span className="badge bg-orange-400 w-10 h-7 flex justify-center items-center" onClick={matchSettingModal}>
                      <RiSettings4Fill className="text-white" />
                    </span>
                    <span className="badge bg-green-400 w-10 h-7 flex justify-center items-center" onClick={detailsMarketModal}>
                      <PiChartLineBold className="text-white" />
                    </span> */}
                  </td>
                </tr>
              )) : <tr className="text-center">
                <td colSpan={4}>No Data Found</td>
              </tr>}
            </tbody>
          </table>
        </Card>
      </GlobalLayout>
      {isDrawerOpens.matchSettingDrawer && <MatchSetting isDrawerOpen={isDrawerOpens} setIsDrawerOpen={setIsDrawerOpens} />}
      {isDrawerOpens.detailsMarketDrawer && <DetailsMarket isDrawerOpen={isDrawerOpens} setIsDrawerOpen={setIsDrawerOpens} />}


    </>
  );

};

export default Dashboard;
