import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { casinoDiamondBetList } from "../../../redux/_reducers/_user_reducers";
import Loader from "../../../component/loader/Loader";
import DemoResult from "../Demo/DemoResult";
import Triangle from "../../../global_layouts/Triangle/Triangle";
// import { casinoDiamondBetList } from "../../../../appRedux/actions/User";
// import Loader from "../../../../components/loader";
// import BackButton from "../../Hoc/BackButton";
// import DemoResult from "../../navigation/Demo/DemoResult";

const Basic = () => {
  const { gameId } = useParams();

  const [userLists, setUserLists] = useState([]);
  const [ShowUserLists, setShowUserLists] = useState([]);
  const [selectUser, setSelectUser] = useState();
  const [infoMenu, setInfoMenu] = useState({ visible: false, data: null });
  const dispatch = useDispatch();

  const { casinoBetList, loading } = useSelector((state) => state.user);

  useEffect(() => {
    casinoList();
  }, []);

  const casinoList = async () => {
    let reqData = {
      roundId: gameId,
    };
    dispatch(casinoDiamondBetList(reqData));
  };

  useEffect(() => {
    if (casinoBetList) {
      const filteredData = casinoBetList?.casinoBetData?.map((item, index) => ({
        key: `${item._id}-${index}`,
        _id: `${item._id}`,
        createdAt: moment(parseInt(item.createdAt))
          .utcOffset("+05:30")
          .format("DD MMM hh:mm A"),
        client: `${item.userInfo.username}`,
        roundId: item.roundId,
        playerName: item.playerName,
        showResult: item.showResult ? item.showResult : "Not Declear",
        isDeclare: item.isDeclare,
        amount: item.amount,
        profit: item.profit,
        loss: item.loss,
        profitLoss: item.profitLoss,
        resultDetails: item.resultDetails,
        odds: item.odds,
        betType: item.betType,
      }));
      setUserLists(filteredData);
      // const uniqueClientName = filteredData
      //     ? [...new Set(filteredData.map((item) => item.client))]
      //     : null;
      // setFinalUserList(uniqueClientName)
    }
  }, [casinoBetList?.casinoBetData]);

  useEffect(() => {
    const finalUserData = selectUser ? ShowUserLists : userLists;
    setShowUserLists(finalUserData);
  }, [selectUser, ShowUserLists, userLists]);

  const renderContent = (value, row) => {
    const obj = {
      children: value,
      props: {},
    };
    const profitLoss = row.profitLoss;

    if (profitLoss > 0) {
      obj.props.style = { backgroundColor: "#10BF35", color: "black" };
    } else if (profitLoss < 0) {
      obj.props.style = { backgroundColor: "#DE4D4D", color: "black" };
    }
    return obj;
  };

  const renderShowResult = (value, row, index) => {
    const obj = renderContent(value, row, index);
    if (row.showResult) {
      obj.props.onClick = (e) => handleInfo(e, row.resultDetails);
      obj.props.style = { ...obj.props.style, cursor: "pointer" }; // Change cursor to pointer to indicate it's clickable
    }
    return obj;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },
    {
      title: "Client",
      dataIndex: "client",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },

    {
      title: "RoundId",
      dataIndex: "roundId",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },

    {
      title: "Bet Type",
      dataIndex: "betType",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },

    {
      title: "Odds",
      dataIndex: "odds",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },

    {
      title: "Player",
      dataIndex: "playerName",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },

    {
      title: "Winner",
      dataIndex: "showResult",
      render: renderShowResult,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },

    {
      title: "Stake",
      dataIndex: "amount",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },

    {
      title: "PNL",
      dataIndex: "profitLoss",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color:"white",
        },
      }),
    },
  ];

  const handleInfo = (e, showResult) => {
    e.preventDefault();
    setInfoMenu({
      visible: true,
      data: showResult,
    });
  };

  const handleClose = () => {
    setInfoMenu({
      visible: false,
    });
  };

  return (
    <>
      {loading ? (
        <Loader loaderTrue={true} />
      ) : (
        <Card className="card">
          <div className="bg-red-600 flex justify-between items-center mb-3">
            <div className="text-white bg-red-700 relative font-bold sm:px-4 px-2 py-2">
              {`All Bets: ${gameId}`}
              <Triangle direction="down" size={10} color="#B91C1C" />
            </div>
            <button
              onClick={() => window.location.reload()}
              className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
            >
              Refresh
            </button>
          </div>
          <div className="">
            <Table
              className="table-responsive "
              columns={columns}
              dataSource={ShowUserLists}
              bordered
              pagination={false}
              size="small"
            />
          </div>
          <DemoResult
            data={infoMenu.data}
            visible={infoMenu.visible}
            handleClose={handleClose}
          />
        </Card>
      )}
    </>
  );
};

export default Basic;
