import React, { useState, useEffect, useRef } from 'react';
import { httpPost } from '../../config/http2';
import { domainName } from '../../constents/global';
import { Card } from 'antd';

const MatchBookModal = ({ minMaxOddsModal1, modaleData, odds, marketId, setIsBookModal }) => {





    const [positionData, setPositionData] = useState([]);
    const modalRef = useRef();

    // Effect to trigger when modal data or props change
    useEffect(() => {
        if (minMaxOddsModal1) {
            getOddsPositionForAllexch();
        }
    }, [minMaxOddsModal1, modaleData]);

    // Function to fetch odds position
    const getOddsPositionForAllexch = async (position) => {
        let userData = JSON.parse(localStorage.getItem(`user_info_${domainName}`) || 'null');
        let reqData = {
            "marketId": marketId,
            "oddsType": modaleData.type,
            "userId": position?.userId || userData?.data?.userId,
            "userType": position?.userType || userData?.data?.userType,
        };
        try {
            let response = await httpPost("reports/getOddsPositionForAllexch", reqData);
            if (response?.data) {
                setPositionData(response.data || []);
            }
            console.log(response, "responseresponseresponse");


        } catch (error) {
            console.error("Error fetching bet list:", error);
        }
    };



    // Create totalArray based on odds
    const totalArray = {};

    if (odds) {
        Object?.keys(odds)?.forEach(oddsElement => {
            totalArray[oddsElement] = 0;
        });
    }


    const finalMapData = positionData.map((pos) => {
        let userInfo = pos.userInfo;
        let position = pos.position;
        let finalPos = {};
        position.forEach((posItem) => {
            finalPos[posItem._id] = posItem.Position;
            totalArray[posItem._id] += posItem.Position;
        });

        return {
            username: userInfo.username,
            name: userInfo.name,
            userId: userInfo.userId,
            userType: userInfo.userType,
            position: finalPos,
        };
    }).filter(item => Object.keys(item.position).length > 0);

    const finalOdds = Object.keys(odds);

    useEffect(() => {
        if (minMaxOddsModal1) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [minMaxOddsModal1]);

    return (
        <>
            <div className="absolute  top-0 left-0 w-full h-screen z-50 flex justify-center items-center bg-black/30 px-3" onClick={() => setIsBookModal(false)} >
                <div ref={modalRef} className="bg-white lg:w-1/2 w-full mx-auto rounded-none" onClick={(e) => e.stopPropagation()} >
                    <Card className="overflow-auto">


                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr className="bg-zinc-700 text-white">

                                    <th>Username</th>
                                    {Object?.values(odds).map((element, key) => (
                                        <th key={key}>{element}</th>
                                    ))}

                                </tr>
                            </thead>

                            <tbody className="">
                                {finalMapData.map((position, index) => (
                                    <tr key={index}>
                                        {position.userType === "client" ?
                                            <td className=''>{position.username}</td> :
                                            <td className='curser text-blue-400 underline' onClick={() => getOddsPositionForAllexch(position)}>{position.username}</td>
                                        }
                                        {finalOdds.map((key, subIndex) => (
                                            <td key={subIndex} className={`${position.position[key] < 0 ? "text-green-600" : "text-red-600"}`}>
                                                {Number.parseFloat(-1 * position.position[key]).toFixed(2).replace(/\.?0+$/, '')}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            <tbody className="">
                                <tr>
                                    <td><b>Total</b></td>
                                    {finalOdds?.map((key, subIndex) => (
                                        <td key={subIndex} className={`${totalArray[key] < 0 ? "text-green-600" : "text-red-600"}`}>
                                            <b>{Number.parseFloat(-1 * totalArray[key]).toFixed(2).replace(/\.?0+$/, '')}</b>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                            <tfoot className="">
                                <tr>
                                    <td><b>Up-Line</b></td>
                                    {finalOdds?.map((key, subIndex) => (
                                        <td key={subIndex} className={`${totalArray[key] < 0 ? "text-green-600" : "text-red-600"}`}>
                                            <b>{Number.parseFloat((100 - JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.matchShare) * (-1 * totalArray[key]) / 100).toFixed(2).replace(/\.?0+$/, '')}</b>
                                        </td>
                                    ))}
                                </tr>
                            </tfoot>
                        </table>

                        <div className="px-3 w-16 h-10 rounded bg-red-600 flex justify-center  items-center text-white font-bold text-md " onClick={() => setIsBookModal(false)}>
                            Close
                        </div>


                    </Card>
                </div></div>

        </>
    );
};

export default MatchBookModal;
