function TableNoDataFound({size}) {
    return (
        <tr>
                        <td colSpan={size} className="text-center">
                            No Data Found
                        </td></tr>
    )
}

export default TableNoDataFound
