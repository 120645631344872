import React, { useEffect, useState } from 'react';
import Card from '../../global_layouts/Card/Card';
import GlobalLayout from '../../global_layouts/GlobalLayout/GlobalLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStatement } from '../../redux/_reducers/_user_reducers';
import moment from 'moment';
import Loader from '../../component/loader/Loader';
import { useParams } from 'react-router-dom';
import { domainName } from '../../constents/global';

const AccountStatment = () => {
    const [statementList, setStatementList] = useState([]);

    const dispatch = useDispatch();
    const { userId } = useParams()
    const { userStatementData, loading } = useSelector(state => state.user);
    const { data } = localStorage.getItem(`user_info_${domainName}`) ? JSON.parse(localStorage.getItem(`user_info_${domainName}`)) : null
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };
    useEffect(() => {
        accountStatement();
    }, []);

    useEffect(() => {
        if (userStatementData) {
            setStatementList(userStatementData);
        }
    }, [userStatementData]);

    const accountStatement = () => {
        const reqData = {
            userId: userId ? userId : data.userId,
            pageNo: 1,
            size: 50
        };
        dispatch(getUserStatement(reqData));
    };

    return (loading ? <Loader loaderTrue={loading} /> :
        <GlobalLayout>
            <Card>
                <div className="flex  flex-wrap  gap-1">


                    {/* From Date */}
                    <div className="flex flex-col w-full md:w-auto">
                        <input
                            type="date"
                            id="from-date"
                            className="px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full md:w-64"
                            value={fromDate}
                            onChange={handleFromDateChange}
                        />
                    </div>

                    {/* To Date */}
                    <div className="flex flex-col w-full md:w-auto">
                        <input
                            type="date"
                            id="to-date"
                            className="px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full md:w-64"
                            value={toDate}
                            onChange={handleToDateChange}
                        />

                    </div>


                    <div className="flex flex-col w-full md:w-auto">
                        <select name="statmentType" id="statmentType" className="px-4 py-2 border rounded-md shadow-sm w-full md:w-64" >
                            <option value>Select Type</option>
                            <option value={1}>Free Chips Cr/Dr</option>
                            <option value={2}>Match Profit/Loss</option>
                            <option value={3}>Match Commission</option>
                            <option value={4}>Session Profit/Loss</option>
                            <option value={5}>Session Commission</option>
                            <option value={6}>Settlement</option>
                        </select>
                    </div>
                    <div className="form-group  flex gap-0.5 flex-nowrap">
                        <button type="button" name="filter" id="filter" className="btn btn-primary">Filter</button><span />
                        <button type="button" name="refresh" id="refresh" className="btn btn-default">Refresh</button><span />
                        <a href="javascript:void(0);" className="btn btn-warning" data-type="excel" id="btn_export"><i className="fa fa-file px-0.5" />Excel</a><span />
                        <a href="javascript:void(0);" className="btn btn-warning" data-type="pdf" id="btn_export_pdf"><i className="fa fa-file-pdf px-0.5" />Pdf</a>
                    </div>
                </div>

                <table className="table table-bordered table-sm">
                    <thead>
                        <tr className='bg-gray-800 text-white'>
                            <th className='w-1/3 px-2'>Narration</th>
                            <th className='w-1/3  px-2'>Amount</th>
                            <th className='w-1/3  px-2'>Balance</th>
                        </tr>
                    </thead>
                    <tbody className='text-sm'>
                        {statementList?.statementData?.length > 0 ? (
                            statementList.statementData.map((statement, index) => (
                                <tr key={index}>
                                    <td className='px-2'>
                                        {statement.remark}
                                        <br />
                                        <span>{moment(statement.createdAt).format('MMM DD YYYY hh:mm:ss A')}</span>

                                    </td>
                                    <td className={`px-2 ${statement.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>{Math.abs(statement.amount)}</td>
                                    <td className='px-2'>{statement.newAmount} </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">No statements available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Card>
        </GlobalLayout>
    );
};

export default AccountStatment;
