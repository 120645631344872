import React, { useEffect, useState } from "react";
import { Card, DatePicker, Table, Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { getCasinoDayWise } from "../../../../appRedux/actions/User";
// import BackButton from "../../Hoc/BackButton";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../component/loader/Loader";
import { getCasinoDayWise } from "../../../redux/_reducers/_user_reducers";
import Triangle from "../../../global_layouts/Triangle/Triangle";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import { colors } from "@mui/material";
import dayjs from "dayjs";
import { BifontSize } from "react-icons/bi";

const Basic = () => {
  const [matchLedger, setMatchLedger] = useState([]);
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [dates, setDates] = useState([
    dayjs().subtract(1, 'month').startOf('day'),
    dayjs().endOf('day'),
  ]);
  const dispatch = useDispatch();
  const { casinoDayWise, loading } = useSelector((state) => state.user);
  const navigate = useNavigate()
  useEffect(() => {
    if (Array.isArray(dates) && dates.length > 0) {
      casinoRoundWiseList();
    }
  }, []);

  const casinoRoundWiseList = () => {
    const fromDate = dates[0].format('YYYY-MM-DD');
    const toDate = dates[1].format('YYYY-MM-DD');
    const reqData = {
      fromDate: fromDate,
      toDate: toDate,
      isCasino: 1,
    };
    dispatch(getCasinoDayWise(reqData));
  };

  useEffect(() => {
    if (casinoDayWise) {
      const groupedData = {};
      casinoDayWise?.forEach((item) => {
        const dateKey = dayjs(item._id.date).format("YYYY-MM-DD");
        if (item.eventId >= 3030 && item.eventId <= 3099) {
          if (!groupedData[dateKey]) {
            groupedData[dateKey] = [];
          }
          groupedData[dateKey].push({
            key: item.eventId,
            eventName: item.eventName,
            dateTime: dayjs(item.createdAt).format("DD-MM-YYYY"),
            profitLoss: Number(item.userNetProfitLoss.toFixed(2)),
            takeComm: -1 * Number(item.userOddsComm.toFixed(2)),
            giveComm: -1 * Number(item.clientOddsAmount.toFixed(2)),
            final: -1 * Number(item.clientNetAmount.toFixed(2)),
            dataKeyPalyload: dateKey,
          });
        } // Filter data Code
      });
      let totalProfitLoss = 0;
      let totalTakeComm = 0;
      let totalGiveComm = 0;
      let totalFinal = 0;

      Object.keys(groupedData).forEach((dateKey) => {
        groupedData[dateKey].forEach((entry) => {
          totalProfitLoss += entry.profitLoss;
          totalTakeComm += entry.takeComm;
          totalGiveComm += entry.giveComm;
          totalFinal += entry.final;
        });
      });

      const finalTotal = {
        eventName: "Total",
        date: "",
        profitLoss: (
          <span
            className={`${totalProfitLoss > 0 ? "gx-text-green-0" : "gx-text-red"
              }`}
          >
            {Number(totalProfitLoss).toFixed(2)}
          </span>
        ),
        takeComm: (
          <span
            className={`${totalTakeComm > 0 ? "gx-text-green-0" : "gx-text-red"
              }`}
          >
            {Number(totalTakeComm).toFixed(2)}
          </span>
        ),
        giveComm: (
          <span
            className={`${totalGiveComm > 0 ? "gx-text-green-0" : "gx-text-red"
              }`}
          >
            {Number(totalGiveComm).toFixed(2)}
          </span>
        ),
        final: (
          <span
            className={`${totalFinal > 0 ? "gx-text-green-0" : "gx-text-red"}`}
          >
            {Number(totalFinal).toFixed(2)}
          </span>
        ),
        type: "another",
      };

      const transformData = (data) => {
        const result = [finalTotal];
        for (const date in data) {
          const entries = data[date];

          const totals = entries.reduce(
            (acc, entry) => {
              acc.profitLoss += entry.profitLoss;
              acc.takeComm += entry.takeComm;
              acc.giveComm += entry.giveComm;
              acc.final += entry.final;
              return acc;
            },
            { profitLoss: 0, takeComm: 0, giveComm: 0, final: 0 }
          );

          result.push({
            eventName: <span className="gx-text-blue gx-pointer">{date}</span>,
            date: date,
            profitLoss: (
              <span
                className={`${totals.profitLoss > 0 ? "gx-text-green-0" : "gx-text-red"
                  }`}
              >
                {Number(totals.profitLoss).toFixed(2)}
              </span>
            ),
            takeComm: (
              <span
                className={`${totals.takeComm > 0 ? "gx-text-green-0" : "gx-text-red"
                  }`}
              >
                {Number(totals.takeComm).toFixed(2)}
              </span>
            ),
            giveComm: (
              <span
                className={`${totals.giveComm > 0 ? "gx-text-green-0" : "gx-text-red"
                  }`}
              >
                {Number(totals.giveComm).toFixed(2)}
              </span>
            ),
            final: (
              <span
                className={`${totals.final > 0 ? "gx-text-green-0" : "gx-text-red"
                  }`}
              >
                {Number(totals.final).toFixed(2)}
              </span>
            ),
            type: "dateTotal",
          });
          entries.forEach((entry, index) => {
            result.push({
              index: index + 1, // Adding index (1-based)
              eventName: (
                <span className="gx-text-blue gx-text-nowrap gx-pointer">
                  {entry.eventName}
                </span>
              ),
              date: (
                <span className="gx-text-nowrap">
                  {dayjs(entry.dataKeyPalyload).format("DD MMM hh:mm A")}
                </span>
              ), // Updated date format
              profitLoss: (
                <span
                  className={`${entry.profitLoss > 0 ? "gx-text-green-0" : "gx-text-red"
                    }`}
                >
                  {Number(entry.profitLoss).toFixed(2)}
                </span>
              ),
              takeComm: (
                <span
                  className={`${entry.takeComm > 0 ? "gx-text-green-0" : "gx-text-red"
                    }`}
                >
                  {Number(entry.takeComm).toFixed(2)}
                </span>
              ),
              giveComm: (
                <span
                  className={`${entry.giveComm > 0 ? "gx-text-green-0" : "gx-text-red"
                    }`}
                >
                  {Number(entry.giveComm).toFixed(2)}
                </span>
              ),
              final: (
                <span
                  className={`${entry.final > 0 ? "gx-text-green-0" : "gx-text-red"
                    }`}
                >
                  {Number(entry.final).toFixed(2)}
                </span>
              ),
              type: "dayWise",
              payloadDate: entry?.dateTime,
              key: Number(entry.key),
              eventNames: entry.eventName,
              dataKeyPalyloadData: entry?.dataKeyPalyload,
            });
          });

          // entries.forEach((entry) => {
          //   result.push({
          //     eventName: <Link to={''}>{entry.eventName}</Link>,
          //     date: moment(entry.dataTime).utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss A"),
          //     profitLoss: Number(entry.profitLoss).toFixed(2),
          //     takeComm: Number(entry.takeComm).toFixed(2),
          //     giveComm: Number(entry.giveComm).toFixed(2),
          //     final: Number(entry.final).toFixed(2),
          //     type: "dayWise",
          //   });
          // });
        }

        return result;
      };

      const transformedData = transformData(groupedData);
      setMatchLedger(transformedData);
    }
  }, [casinoDayWise]);

  const columns = [
    {
      key: "eventName",
      title: "Event Name",
      dataIndex: "eventName",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize: '12px',
          color: "white",
          fontWeight: 'bold'
        },
      })
    },
    {
      key: "dateTime",
      title: "Date & Time",
      dataIndex: "date",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize: '12px',
          color: "white",
          fontWeight: 'bold'
        },
      })
    },
    {
      title: "P/L",
      dataIndex: "profitLoss",
      key: "profitLoss",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize: '12px',
          color: "white",
          fontWeight: 'bold'
        },
      })
    },
    // {
    //   title: "Take comm",
    //   dataIndex: "takeComm",
    //   key: "takeComm",
    // },
    // {
    //   title: "Give comm",
    //   dataIndex: "giveComm",
    //   key: "giveComm",
    // },
    // {
    //   title: "Final",
    //   dataIndex: "final",
    //   key: "final",
    // },
    {
      title: "Action",
      key: "action",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize: '12px',
          color: "white",
          fontWeight: 'bold'
        },
      }),
      render: (text, record) => {
        if (record.type === "dayWise") {
          return (
            <div className="flex justify-end gap-2">
              <Button onClick={() => navigate(`/admin/casinoviewdetails/${record?.key}/${record?.dataKeyPalyloadData}`)} className="px-4 py-1 leading-0 bg-blue-400 !border-0 text-white hover:!bg-blue-400 font-bold">

                plusminus

              </Button>
              <Button onClick={() => navigate(`/admin/inplaycasinodetails/${record.eventNames}/${record.key}/${record?.dataKeyPalyloadData}`)} className="px-4 py-1 leading-0 bg-orange-400 !border-0 text-white hover:!bg-orange-400 font-bold">
                Display Games

              </Button>
            </div>
          );
        } else if (record.type === "dateTotal") {
          return (
            <div className="flex justify-end">
              <Button onClick={() => navigate(`/admin/plusminuscasinodeatils-data/${record?.date}`)} className="px-4 py-1 leading-0 bg-blue-400 !border-0 text-white hover:!bg-blue-400 font-bold">

                plusminus2
              </Button>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];
  if (!matchLedger) {
    return null;
  }



  const onChange = (dates) => {
    try {
      setDates(dates);
    } catch (error) {
      console.error("Error in onChange:", error);
    }
  };
  const handleDateChange = (date, dateString, type) => {
    const newDates = [...dates];
    newDates[type === 'start' ? 0 : 1] = date; // Update either start or end date

    setDates(newDates);
    onChange(newDates); // Pass new date range back to parent
  };
  const handleSubmit = () => {
    if (Array.isArray(dates) && dates.length > 0) {
      casinoRoundWiseList();
    }
  };

  return (
    <>
      {loading ? (
        <Loader loaderTrue={true} />
      ) : (
        <GlobalLayout>
          <Card className="uppercase">
            <div className="bg-red-600 flex justify-between items-center ">
              <div className="text-white bg-red-700 relative text-xs sm:!text-base font-bold px-2 sm:px-4 py-2">

                Casino PandL Detail
                <Triangle direction="down" size={10} color="#B91C1C" />
              </div>
              <button
                onClick={() => window.location.reload()}
                className="bg-green-500 ms-2 text-xs sm:!text-base text-white py-1 mr-1 px-3 rounded"
              >
                Refresh
              </button>
            </div>

            <div className="my-3">
              <Row style={{ gap: 16 }}>
                <Col>
                  <div className="flex space-x-4">
                    <DatePicker
                      size="large"
                      value={dates[0]}
                      onChange={(date) => handleDateChange(date, '', 'start')}
                      style={{ width: 250 }}
                    />
                    <DatePicker
                      size="large"
                      value={dates[1]}
                      onChange={(date) => handleDateChange(date, '', 'end')}
                      style={{ width: 250 }}
                    />
                  </div>
                </Col>

                <Col>
                  <Button size="large" onClick={() => handleSubmit()} className="px-4 h-[30px] sm:h-[40px] text-xs sm:!text-base flex items-center justify-center leading-0 bg-green-400 !border-0 text-white hover:!bg-green-400 font-bold">
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
            <div>
              <Table
                columns={columns}
                dataSource={matchLedger}
                pagination={false}
                size="small"
                bordered
                scroll={{ x: true }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "bg-gray-100 " : ""
                }
              />
            </div>
          </Card>
        </GlobalLayout>
      )}
    </>
  );
};

export default Basic;
