import { useEffect, useState } from "react";
import Drawer from "../../../global_layouts/Drawer/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { getUserStatement } from "../../../redux/_reducers/_user_reducers";
import moment from "moment";

const AccountSatement = (props) => {
    const [statementList, setStatementList] = useState([]);
    const { userStatementData, loading } = useSelector(state => state.user);

    useEffect(() => {
        accountStatement()
    }, [])
    useEffect(() => {
        if (userStatementData) {
            setStatementList(userStatementData);
        }
    }, [userStatementData]);
    const dispatch = useDispatch()
    const { isDrawerOpen, setIsDrawerOpen, propsData } = props;
    const isOpen = isDrawerOpen.accountStatementDrawer;

    const accountStatement = () => {
        const reqData = {
            userId: propsData.accProps.userId,
            pageNo: 1,
            size: 10
        };
        dispatch(getUserStatement(reqData));
    };


    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => setIsDrawerOpen({ accountStatementDrawer: false })}
            direction="right"
            size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
            overlay={true}

        >
            <div class=" bg-gradient-to-t from-[#27a6c3] to-[#05537e] p-2 border-b-1.5 border-black">

                <div class="flex justify-center uppercase text-sm font-bold items-center w-100  text-white ">
                    <div class="info-box-number">{`Account Statement [${propsData.accProps.username}]`}</div>
                </div>
            </div>
            <div className="p-2 h-[400px] overflow-auto">
                <table className="table table-bordered table-sm">
                    <thead>
                        <tr className='bg-gray-800 text-white'>
                            <th className='w-1/2 px-2'>Narration</th>
                            <th className='w-1/4  px-2'>Amount</th>
                            <th className='w-1/4  px-2'>Balance</th>
                        </tr>
                    </thead>
                    <tbody className='text-sm text-black bg-white'>
                        {statementList?.statementData?.length > 0 ? (
                            statementList.statementData.map((statement, index) => (
                                <tr key={index}>
                                    <td className='px-2 text-nowrap'>
                                        {statement.remark}
                                        <br />
                                        <span>{moment(statement.createdAt).format('MMM DD YYYY hh:mm:ss A')}</span>

                                    </td>
                                    <td className={`px-2 ${statement.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>{Math.abs(statement.amount)}</td>
                                    <td className='px-2'>{statement.newAmount} </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">No statements available</td>
                            </tr>
                        )}
                    </tbody>
                </table>


            </div>
            <div className="flex items-start mt-3 px-2 w-full " >
                <a href={`/admin/account-statement/${propsData.accProps.userId}`} className="w-full">
                    <button type="submit" className="text-white uppercase  bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none  font-normal rounded-sm text-md w-100 sm:w-auto px-5 py-1.5 text-center ">View More</button>
                </a>

            </div>
        </Drawer>
    );
};

export default AccountSatement;
