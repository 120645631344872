import axios from "axios";
import { domainName } from "../constents/global";

export const baseUrl = {
    BACKEND_URL: 'https://api.dollarbet999.com/v1/',
    // BACKEND_URL: 'https://api.dollarbet999.com/',
    // BACKEND_URL: 'https://api.cric365day1.com/v1/',
    SOCKET_URL: 'https://socket.trovetown.co/'

};

function authHeader() {
    const user = JSON.parse(localStorage.getItem(`user_info_${domainName}`) || 'null');
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

export const apiCall = async (method, path, payload) => {
    try {
        const response = await axios({
            method,
            url: baseUrl.BACKEND_URL + path,
            data: payload,
            headers: {
                'Content-Type': 'application/json',
                ...authHeader(),
            },
        });

        return response.data;
    } catch (error) {
        if (Number(error?.response?.data?.code) === 3 || Number(error?.response?.status) === 401) {
            localStorage.clear();
            window.location.href = '/login';
        } else if (error.response) {
            throw error.response;
        } else if (error.request) {
            throw new Error('No response received from the server');
        } else {
            console.error(error, "Error occurred during request setup");
            throw new Error(error.message);
        }
    }
};
