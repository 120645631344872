


import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Row,
  Table,
  Col,
  Typography
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../component/loader/Loader";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import { getSportMatchBetList } from "../../../redux/_reducers/_sports_reducers";
import Triangle from "../../../global_layouts/Triangle/Triangle";
import dayjs from "dayjs";
// import { casinoDiamondBetList, getSportMatchBetList } from "../../../../appRedux/actions/User";
// import Loader from "../../../../components/loader";
// import BackButton from "../../Hoc/BackButton";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const InternetionalCasinoBet = () => {
  const RangePicker = DatePicker.RangePicker;
  const history = useNavigate();
  const { _id, fromDate, toDate } = useParams();
  const { Text } = Typography;
  const [userLists, setUserLists] = useState([]);
  const [ShowUserLists, setShowUserLists] = useState([]);
  const [selectUser, setSelectUser] = useState();

  const [dates, setDates] = useState([
    dayjs(fromDate),
    dayjs(toDate),
  ]);
  const startDate = dates[0]
  const endDate = dates[1]
  const [infoMenu, setInfoMenu] = useState({ visible: false, data: null, });

  const dispatch = useDispatch()
  const {  loading, sportBetList } = useSelector((state) => state.sport);
  const [finalUserList, setFinalUserList] = useState();


  useEffect(() => {
    casinoList();
  }, [dates]);


  const casinoList = async () => {
    let reqData = {
      gameId: _id,
      toDate: endDate.format('YYYY-MM-DD'),
      fromDate: startDate.format('YYYY-MM-DD'),
      pageNo: 1,
      size: '50',
      casinoBet: true,
      // isDeleted: false,
      sortData: {
        "createdAt": 1
      }
    };
    dispatch(getSportMatchBetList(reqData));
  };

  useEffect(() => {
    if (sportBetList) {
      const filteredData = sportBetList?.casinoBetData?.map((item, index) => {
        const profit = item.isDeclare ? (item.creditAmount > 0 ? item.creditAmount - item.debitAmount : 0) : "0";
        const loss = item.isDeclare ? (item.creditAmount === 0 ? item.debitAmount : "0") : "0";

        const profitLoss = item.creditAmount - item.debitAmount + item.rollbackAmount;
        return {
          key: `${item._id}-${index}`,
          _id: `${item._id}`,
          createdAt: `${moment(item.createdAt).format("DD-MM-YYYY")}`,
          client: `${item.userInfo.name}(${item.userInfo.username})`,
          roundId: item.roundId,
          playerName: item.gameName,
          showResult: item.isDeclare ? 'Declear' : "Not Declear",
          isDeclare: item.isDeclare,
          amount: item.debitAmount,
          profit: profit,
          loss: loss,
          profitLoss: profitLoss,
          resultDetails: item.resultDetails,
          creditAmount: item.creditAmount,
          debitAmount: item.debitAmount

        }
      });
      setUserLists(filteredData);
      const uniqueClientName = filteredData
        ? [...new Set(filteredData?.map((item) => item.client))]
        : null;
      setFinalUserList(uniqueClientName)
    }

  }, [sportBetList?.casinoBetData]);

  useEffect(() => {
    const finalUserData = selectUser ? ShowUserLists : userLists;
    setShowUserLists(finalUserData);
  }, [selectUser, ShowUserLists, userLists]);

  const renderContent = (value, row) => {
    const obj = {
      children: value,
      props: {},
    };
    const profitLoss = row.profit;
    const showResult = row?.showResult

    if (profitLoss > 0 && showResult === 'Declear') {
      obj.props.style = { backgroundColor: "green", color: "White" };
    } else if (profitLoss <= 0 && showResult === 'Declear') {
      obj.props.style = { backgroundColor: "red", color: "White" };
    }
    return obj;
  };

  async function getCompleteCasinoList() {
    let reqData = {
      gameId: _id,
      toDate: endDate.format('YYYY-MM-DD'),
      fromDate: startDate.format('YYYY-MM-DD'),
      pageNo: 1,
      size: '20',
      casinoBet: true,
      isDeleted: false,
      sortData: {
        "createdAt": 1
      }
    };
    dispatch(getSportMatchBetList(reqData));
  }

  async function todayCompleteCasinoList() {
    setDates(
      [
        dayjs().endOf('day'),
        dayjs().endOf('day'),
      ]
    )
    let reqData = {
      gameId: _id,
      toDate: dayjs().endOf('day'),
      fromDate: dayjs().endOf('day'),
      pageNo: 1,
      size: '20',
      casinoBet: true,
      isDeleted: false,
      sortData: { "createdAt": 1 }
    };
    dispatch(getSportMatchBetList(reqData));
  }

  const renderShowResult = (value, row, index) => {
    const obj = renderContent(value, row, index);
    if (row.showResult) {
      obj.props.onClick = (e) => handleInfo(e, row.resultDetails);
      obj.props.style = { ...obj.props.style, cursor: "pointer" }; // Change cursor to pointer to indicate it's clickable
    }
    return obj;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color: "white",
        },
      }),
      // render: (date) => (
      //   <span>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</span>
      // ),
    },
    {
      title: "Client",
      dataIndex: "client",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color: "white",
        },
      }),
    },
    {
      title: "Player",
      dataIndex: "playerName",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color: "white",
        },
      }),
    },
    {
      title: "RoundId",
      dataIndex: "roundId",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color: "white",
        },
      }),
    },

    // {
    //   title: "Winner",
    //   dataIndex: "showResult",
    //   // render: renderShowResult,
    //   render: renderContent,
    // },

    {
      title: "Stake",
      dataIndex: "amount",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color: "white",
        },
      }),
    },

    // {
    //   title: "Profit",
    //   dataIndex: "profit",
    //   render: renderContent,
    // },

    // {
    //   title: "Loss",
    //   dataIndex: "loss",
    //   render: renderContent,
    // },

    // {
    //   title: "LossAmount",
    //   dataIndex: "loss",
    //   render: renderContent,
    // },
    // {
    //   title: "WinAmount",
    //   dataIndex: "profit",
    //   render: renderContent,
    // },

    {
      title: "PNL",
      dataIndex: "profitLoss",
      // render: renderShowResult,
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          color: "white",
        },
      }),
    },
  ];



  function onChange(dates, dateStrings) {
    setDates(dates);
  }


  const handleDateChange = (date, dateString, type) => {
    const newDates = [...dates];
    newDates[type === 'start' ? 0 : 1] = date; // Update either start or end date
    history(`/admin/internetionalcasino-bet/${_id}/${newDates[0].format('YYYY-MM-DD')}/${newDates[1].format('YYYY-MM-DD')}`);
    setDates(newDates);
    onChange(newDates); // Pass new date range back to parent
  };

  const handleInputChange = async (value) => {
    setSelectUser(value);
    if (value === "All") {
      setShowUserLists(userLists); // Show all users if "All" is selected
    } else {
      const filteredData = userLists.filter((item) => item.client === value);
      setShowUserLists(filteredData);
    }
  };

  const handleInfo = (e, showResult) => {
    e.preventDefault();
    setInfoMenu({
      visible: true,
      data: showResult,
    });
  };

  const handleClose = () => {
    setInfoMenu({
      visible: false,
    });
  };
  const Option = Select.Option;

  const components = {
    body: {
      row: ({ children, ...restProps }) => (
        <tr {...restProps} className="no-hover">
          {children}
        </tr>
      ),
    },
  };



  return (
    <>
      {loading ? <Loader loaderTrue={true} /> :
      <GlobalLayout>
         <Card className="gx-card">
          
          <div className="bg-red-600 flex justify-between items-center mb-3">
              <div className="text-white bg-red-700 relative font-bold px-2 sm:px-4 py-2">
              International Casino Details
                <Triangle direction="down" size={10} color="#B91C1C" />
              </div>
              <button
                onClick={() => window.location.reload()}
                className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
              >
                Refresh
              </button>
            </div>

          <div className="mb-2">
            <Row  className="" >
              <Col className="flex gap-3" >
              <DatePicker
            size="large"
            value={dates[0]}
            onChange={(date) => handleDateChange(date, '', 'start')}
            style={{ width: 250 }}
          />
          <DatePicker
            size="large"
            value={dates[1]}
            onChange={(date) => handleDateChange(date, '', 'end')}
            style={{ width: 250 }}
          />

                <Select style={{ width: 300 }} defaultValue="All" onChange={value => handleInputChange(value)}
                // getPopupContainer={trigger => trigger.parentElement}
                >
                  <Select.Option value="All"  >All</Select.Option>
                  {finalUserList && finalUserList?.map((item, index) => (
                    <Select.Option key={`${index}`} value={item}  >{item}</Select.Option>
                  ))}
                </Select>
              </Col>
              <div className="px-2">
                {/* <Button type="danger" onClick={() => getCompleteCasinoList()}>Apply</Button> */}
                <Button className=" py-2.5 leading-0 bg-blue-400 !border-0 text-white hover:!bg-blue-400 font-bold" onClick={() => todayCompleteCasinoList()} >Today P/L</Button>
              </div>
            </Row>
          </div>


          <div>
            <Table
              className="gx-table-responsive"
              columns={columns}
              dataSource={ShowUserLists}
              bordered
              pagination={false}
              size="small"
              components={components}
              summary={(ShowUserLists) => {
              
                let totalProfit = 0;
                let totalLoss = 0;
                let totalprofitloss = 0;
                ShowUserLists?.forEach((data, key) => {
                  totalprofitloss += data?.profitLoss
                  // if (data.creditAmount > 0 && data.isDeclare) {
                  //   totalProfit += (data.creditAmount - data.debitAmount);
                  // }
                  // if (data.creditAmount === 0 && data.isDeclare) {
                  //   totalLoss += data.debitAmount;
                  // }

                });

                return (
                  <Table.Summary.Row >
                    <Table.Summary.Cell index={0} className="gx-font-weight-bold "></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <Text></Text>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={2}>
                      <Text></Text>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={3}>
                      <Text className="gx-font-weight-bold gx-fs-lg">Total</Text>
                    </Table.Summary.Cell>



                    <Table.Summary.Cell index={6}>
                      <Text></Text>
                      {/* <Text className="gx-text-black gx-font-weight-bold gx-fs-lg">{totalLoss}</Text> */}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      <Text className="gx-text-black gx-font-weight-bold gx-fs-lg">{totalprofitloss}</Text>
                    </Table.Summary.Cell>


                  </Table.Summary.Row>
                )
              }}
            // footer={() => 
            //   <>
            // <div className="gx-w-75 gx-text-black gx-font-weight-semi-bold gx-fs-lg">Total</div>
            // <div>{totalProfit}</div>
            // </>
            // }


            />
          </div>


        </Card>
      </GlobalLayout>
       }
    </>
  );
};

export default InternetionalCasinoBet;

