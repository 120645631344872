// import { useEffect } from "react";
// import { encrypt } from "../../config/Encryption";
// import { domainName, userTypeInfo } from "../../constents/global";

// let userPriorityInfo = ''
// useEffect(() => {

//   userPriorityInfo = JSON.parse(localStorage.getItem(`user_info_${domainName}`))?.data?.userPriority
// }, [])

// export const sidebarMenu = [
//   {

//     title: "Dashboard",
//     icon: "fas fa-tachometer-alt",
//     path: "dashboard",
//     orderBy: 1,
//     visible: true,
//   },
//   {
//     title: "User",
//     icon: "fas fa-book",
//     orderBy: 2,
//     activeSubMenu: false,
//     visible: true,
//     submenu: userTypeInfo.filter((ele) => ele.priority < userPriorityInfo).map((ele, index) => ({
//       title: ele.userType,
//       icon: "far fa-circle",
//       path: `/admin/user-list/${encrypt(ele.userType)}/${encrypt(ele.userType)}/${encrypt(ele.priority)}/${encrypt(ele.priority)}`,
//       orderBy: index,
//       visible: true,
//     })),
//   },

//   {
//     title: "In Play",
//     icon: "fas fa-solid fa-trophy",
//     path: "inplay-matche",
//     orderBy: 3,
//     visible: true,
//   },

//   {
//     title: "Settalment",
//     icon: "far fa-regular fa-thumbs-up",
//     path: "settalment",
//     orderBy: 4,
//     visible: true,
//   },

//   {
//     title: "Matches History",
//     icon: "fas fa-book",
//     path: "matches-history",
//     orderBy: 5,
//     visible: true,
//   },




//   {
//     title: "Role",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 13,
//     visible: true,
//   },

//   {
//     title: "Manage Market",
//     icon: "fas fa-solid fa-trophy",
//     path: "roles",
//     orderBy: 7,
//     visible: true,
//   },


//   {
//     title: "My Market",
//     icon: "fas fa-solid fa-trophy",
//     path: "roles",
//     orderBy: 10,
//     visible: true,
//   },


//   {
//     title: "Fancy History",
//     icon: "fas fa-solid fa-trophy",
//     path: "fancy-history",
//     orderBy: 6,
//     visible: true,
//   },


//   {
//     title: "D/W Requests",
//     icon: "fas fa-solid fa-trophy",
//     path: "roles",
//     orderBy: 8,
//     visible: true,
//   },

//   {
//     title: "Add Matches",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 9,
//     visible: true,
//   },

//   {
//     title: "Setting",
//     icon: "fas fa-book",
//     path: "roles",
//     orderBy: 11,
//     visible: true,
//   },

//   {
//     title: "Sport",
//     icon: "fas fa-solid fa-gear",
//     path: "roles",
//     orderBy: 12,
//     visible: true,
//   },


//   {
//     title: "Role",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 14,
//     visible: true,
//   },
//   {
//     title: "Role",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 15,
//     visible: true,
//   },

//   {
//     title: "Role",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 16,
//     visible: true,
//   },
//   {
//     title: "Role",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 17,
//     visible: true,
//   },
//   {
//     title: "Role",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 18,
//     visible: true,
//   },
//   {
//     title: "Role",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 19,
//     visible: true,
//   },
//   {
//     title: "Role",
//     icon: "far fa-image",
//     path: "roles",
//     orderBy: 20,
//     visible: true,
//   },

// ];

import { useEffect, useState } from "react";
import { encrypt } from "../../config/Encryption";
import { domainName, userTypeInfo } from "../../constents/global";
import moment from "moment";

const useSidebarMenu = () => {
  const [userPriorityInfo, setUserPriorityInfo] = useState(null);
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const [userDetails, setUserDetails] = useState([])

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem(`user_info_${domainName}`));
    setUserDetails(userInfo)
    const priority = userInfo?.data?.userPriority;
    setUserPriorityInfo(priority);
  }, []);
  useEffect(() => {
    if (userPriorityInfo !== null) {
      const menu = [
        {
          title: "Dashboard",
          icon: "fas fa-tachometer-alt",
          path: "dashboard",
          orderBy: 1,
          visible: true,
        },
        {
          title: "User",
          icon: "fa-solid fa-user-group",
          orderBy: 2,
          activeSubMenu: false,
          visible: true,
          submenu: [
            ...userTypeInfo
              .filter((ele) => ele.priority < userPriorityInfo)
              .map((ele, index) => ({
                title: ele.userType,
                icon: "fa-solid fa-user-group",
                path: `/admin/user-list/${encrypt(ele.userType)}/${encrypt(ele.userType)}/${encrypt(ele.priority)}/${encrypt(ele.priority)}`,
                orderBy: index,
                visible: true,
              })),
            {
              title: "Closed Accounts",
              icon: "fa fa-times",
              path: `/admin/closed-accounts`,
              orderBy: userTypeInfo.length + 1,
              visible: true,
            }
          ]
        },
        // {
        //   title: "In Play",
        //   icon: "fas fa-solid fa-trophy",
        //   path: "inplay-matche",
        //   orderBy: 11,
        //   visible: true,
        // },
        {
          title: "Settalment",
          icon: "far fa-regular fa-thumbs-up",
          path: "settalment",
          orderBy: 6,
          visible: true,
        },

        {
          title: "Sports",
          icon: "fa-solid fa-baseball",
          path: "manage-market",
          orderBy: 3,
          visible: true,
        },
        {
          title: "Matches History",
          icon: "fas fa-book",
          path: "matches-history",
          orderBy: 7,
          visible: true,
        },
        // {
        //   title: "Fancy History",
        //   icon: "fas fa-solid fa-trophy",
        //   path: "fancy-history",
        //   orderBy: 8,
        //   visible: true,
        // },
        {
          title: "D/W Transaction",
          icon: "fa-solid fa-building-columns",
          orderBy: 9,
          activeSubMenu: false,
          visible: true,
          submenu: [
            {
              title: "Deposit",
              icon: "fa-solid fa-indian-rupee-sign",
              path: `deposit`,
              orderBy: 1,
              visible: true,
            },
            {
              title: "Withdrwal",
              icon: "fa-solid fa-indian-rupee-sign",
              path: `withdraw`,
              orderBy: 1,
              visible: true,
            }
          ]
        },
        userDetails?.data?.userType === 'subowner' && {
          title: "Website Setting",
          icon: "fas fa-solid fa-gear",
          path: `website-setting`,
          orderBy: 10,
          visible: true,
        },

        {
          title: "Cash Transaction",
          icon: "fa-solid fa-wallet",
          orderBy: 8,
          activeSubMenu: false,
          visible: true,
          submenu: [
            ...userTypeInfo
              .filter((ele) => ele.priority < userPriorityInfo)
              .map((ele, index) => ({
                title: `${ele.userType} Transaction`,
                icon: "fa-solid fa-sack-dollar",
                path: `/admin/cash-transaction/${encrypt(ele.userType)}`,
                orderBy: index,
                visible: true,
              })),

          ]

        },


























        userDetails?.data?.userType === 'agent' &&  {
          title: "Settings",
          icon: "fas fa-solid fa-gear",
          orderBy: 11,
          activeSubMenu: false,
          visible: true,
          submenu: [
           {
              title: "Update Account",
              icon: "fas fa-solid fa-gear",
              path: `account-update-setting`,
              orderBy: 1,
              visible: true,
            },

          ]
        },

        {
          title: "Report",
          icon: "fas fa-book",
          orderBy: 12,
          activeSubMenu: false,
          visible: true,
          submenu: [
            {
              title: "Total Deposit Withdrwal",
              icon: "fa-solid fa-sack-dollar",
              path: `total-deposit-withdrwal`,
              orderBy: 1,
              visible: true,
            },

          ]
        },

        {
          title: "Casino",
          icon: "fa-solid fa-chess-queen",
          orderBy: 4,
          activeSubMenu: false,
          visible: true,
          submenu: [
            {
              title: "Inplay Casino",
              icon: "fa-solid fa-cube",
              path: `inplaycasino`,
              orderBy: 1,
              visible: true,
            },
            {
              title: "Completed Casino",
              icon: "fa-solid fa-cube",
              path: `completedcasino`,
              orderBy: 1,
              visible: true,
            },
            {
              title: "Casino Details",
              icon: "fa-solid fa-cube",
              path: `diamond-casino-details`,
              orderBy: 1,
              visible: true,
            },
            {
              title: "International Casino",
              icon: "fa-solid fa-cube",
              path: `internetionalcasinodeatils/${moment().format("YYYY-MM-DD")}/${moment().format("YYYY-MM-DD")}`,
              orderBy: 1,
              visible: true,
            },
          ]
        },

        {
          title: "Matka",
          icon: "fa-solid fa-m",
          orderBy: 5,
          activeSubMenu: false,
          visible: true,
          submenu: [
            {
              title: "Inplay Matka",
              icon: "fa-solid fa-diamond ",
              path: `inplay-matka`,
              orderBy: 1,
              visible: true,
            },

          ]
        },
      ];

      setSidebarMenu(menu);
    }
  }, [userPriorityInfo]);

  return sidebarMenu;
};

export default useSidebarMenu;
















// {
//   title: "Role",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 13,
//   visible: true,
// },

// {
//   title: "Manage Market",
//   icon: "fas fa-solid fa-trophy",
//   path: "roles",
//   orderBy: 7,
//   visible: true,
// },


// {
//   title: "My Market",
//   icon: "fas fa-solid fa-trophy",
//   path: "roles",
//   orderBy: 10,
//   visible: true,
// },
// {
//   title: "D/W Requests",
//   icon: "fas fa-solid fa-trophy",
//   path: "roles",
//   orderBy: 8,
//   visible: true,
// },
// {
//   title: "Add Matches",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 9,
//   visible: true,
// },

// {
//   title: "Setting",
//   icon: "fas fa-book",
//   path: "roles",
//   orderBy: 11,
//   visible: true,
// },

// {
//   title: "Sport",
//   icon: "fas fa-solid fa-gear",
//   path: "roles",
//   orderBy: 12,
//   visible: true,
// },


// {
//   title: "Role",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 14,
//   visible: true,
// },
// {
//   title: "Role",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 15,
//   visible: true,
// },

// {
//   title: "Role",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 16,
//   visible: true,
// },
// {
//   title: "Role",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 17,
//   visible: true,
// },
// {
//   title: "Role",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 18,
//   visible: true,
// },
// {
//   title: "Role",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 19,
//   visible: true,
// },
// {
//   title: "Role",
//   icon: "far fa-image",
//   path: "roles",
//   orderBy: 20,
//   visible: true,
// },
