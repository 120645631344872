import { Controller, useForm } from "react-hook-form";
import Drawer from "../../../global_layouts/Drawer/Drawer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IoIosArrowDown } from "react-icons/io";
import { getOneDownlineUserData } from "../../../constents/global";
import { apiCall } from "../../../config/Http";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userUpdate } from "../../../redux/_reducers/_user_reducers";
import Select from 'react-select';

const EDITUSER = (props) => {

  const { isDrawerOpen, setIsDrawerOpen, propsData } = props;
  const isOpen = isDrawerOpen.editUserDrawer;
  const { editUserProps } = propsData;
  const { userId } = editUserProps
  const [userDetails, setUserDetails] = useState({});
  const [parentDetails, setParentDetails] = useState({});
  const [toggleStatus, setToggleStatus] = useState(false);
  const [toggleStatusIntCasino, setToggleStatusIntCasino] = useState(false);
  const [toggleStatusMatka, setToggleStatusMatka] = useState(false);
  const [fieldsUser, setFieldsUser] = useState({
    commissionChangeType: '',
    selectDomain: [],
    userCommissionType: 'BetByBet'
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control
  } = useForm();

  const dispatch = useDispatch()
  const { updateUserList } = useSelector((state) => state.user);


  useEffect(() => {
    setValue("userId", userDetails.username);
    setValue("username", userDetails.name);
    setValue("contact", userDetails.mobile);
    setValue("password", userDetails.passwordShow);
    setValue("remark", userDetails.reference);
    setValue("userMatchShare", userDetails.userMatchShare);
    setValue("userMatchCommission", userDetails.userMatchCommission);
    setValue("userSessionCommission", userDetails.userSessionCommission);
    setValue("userCasinoShare", userDetails.userCasinoShare);
    setValue("userCasinoCommission", userDetails.userCasinoCommission);
    setValue("intCasinoShare", userDetails.intCasinoShare);
    setValue("intCasinoStatus", userDetails.intCasinoStatus);
    setValue("matkaStatus", userDetails.matkaStatus);
    setValue("userMatkaShare", userDetails.userMatkaShare);
    setValue("userMatkaCommission", userDetails.userMatkaCommission);
  }, [userDetails, setValue]);





  const getParentData = async (creatorId) => {
    try {
      let parentReqBody = { userId: creatorId };
      let response = await apiCall("POST", "user/userDetails", parentReqBody);
      if (response) {
        setParentDetails(response.data);

      }
    } catch (error) {
      console.error("Error fetching parent details:", error);
    }
  };

  const getUserDetails = async () => {
    let reqData = { userId };
    try {
      let response = await apiCall("POST", "user/userDetails", reqData);
      if (response) {
        setUserDetails(response.data);
        getParentData(response?.data?.creatorId);
        setFieldsUser(prevFields => ({
          ...prevFields,
          selectDomain: response?.data?.allowedDomains
        }));

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getUserDetails()
  }, [])

  const onFinish = async (values) => {

    let userUpdateData = {
      userId: userDetails?.userId,
      parentId: parentDetails?.userId,
      mobile: values.contact,
      name: values.username,
      userType: userDetails.userType,
      status: values.status === "active" ? 1 : 0,
      matchShare: values.userMatchShare,
      casinoShare: values.userCasinoShare,
      matkaShare: values.userMatkaShare,
      intCasinoStatus: values.intCasinoStatus,
      intCasinoShare: values.intCasinoShare ? values.intCasinoShare : userDetails.intCasinoShare,
      intCasinoExpoLimit: values.intCasinoExpoLimit ? values.intCasinoExpoLimit : userDetails.intCasinoExpoLimit,
      intCasinoMultiply: values.intCasinoMultiply ? values.intCasinoMultiply : userDetails.intCasinoMultiply,
      matchCommission: values.userMatchCommission,
      sessionCommission: values.userSessionCommission,
      commChangeType: values.commissionChangeType,
      commissionType: values.userCommissionType,
      casinoCommission: values.userCasinoCommission,
      matkaCommission: values.userMatkaCommission,
      coins: userDetails.coins,
      reference: values.remark,
      creditReference: 0,
      password: values.password,
      betStatus: userDetails.betStatus,
      matchStatus: userDetails.matchStatus,
      casinoStatus: toggleStatus,
      maxCommValue: values.commissionChangeType === "changed" ? values.maxCommValue : 0,
      matkaStatus: toggleStatusMatka,
      selfCuttingPermission: false,
      selfCuttingCoins: 0,
      transactionPassword: "1122",
      allowedDomains: values.selectDomain
    };
    await dispatch(userUpdate(userUpdateData))
    reset();
  };

  useEffect(() => {
    if (updateUserList === true) {
      setIsDrawerOpen({ editUserDrawer: false });
    }
  }, [updateUserList]);
  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setIsDrawerOpen({ editUserDrawer: false })}
      direction="right"
      size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
      overlay={true}
    >
      <div className="h-[95vh] pt-2 pb-20 md:pb-6">
        <div className="text-white text-[16px] m-1 font-bold border-b border-b-[#665d49] pb-2">
          Edit User For {userDetails?.name} [ {userDetails?.username} ]
        </div>
        <div className="px-3 py-2 h-full overflow-y-auto">

          <form onSubmit={handleSubmit(onFinish)}>
            <div className="grid grid-cols-2 gap-x-4 mt-2">
              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Username:
                </label>
                <input
                  className="w-full h-[34px] px-[12px] py-[6px] text-[14px]"
                  value={userDetails?.username}
                  disabled
                  {...register("userId")}
                />
              </div>
              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Name:
                </label>
                <input
                  defaultValue={userDetails?.name}

                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${errors.username ? "border-red-500" : ""
                    }`}
                  type="text"
                  placeholder="User Name"
                  {...register("username", {
                    required: "User Name is required",
                  })}
                />
                {errors.username && (
                  <span className="text-red-500 text-sm">
                    {errors.username.message}
                  </span>
                )}
              </div>
            </div>







            <div className="grid grid-cols-2 gap-x-4 mt-4">
              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Contact No:
                </label>
                <input
                  defaultValue={userDetails?.mobile}

                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${errors.contact ? "border-red-500" : ""
                    }`}
                  type="text"
                  placeholder="Contact Number"
                  {...register("contact", {
                    required: "Contact Number is required",
                  })}
                />
                {errors.contact && (
                  <span className="text-red-500 text-sm">
                    {errors.contact.message}
                  </span>
                )}
              </div>
              <div>

                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Password:
                </label>
                <input
                  defaultValue={userDetails?.passwordShow}
                  // defaultValue={'**********'}
                  disabled
                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${errors.password ? "border-red-500" : ""
                    }`}
                  type="text"
                  placeholder="Password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                {errors.password && (
                  <span className="text-red-500 text-sm">
                    {errors.password.message}
                  </span>
                )}
              </div>
              {/* <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Coins{" "}
                  <span className="text-red-500 text-sm">
                    [{parentDetails?.coins}]
                  </span>
                  :
                </label>
                <input
                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${
                    errors.coins ? "border-red-500" : ""
                  }`}
                  type="number"
                  placeholder="Coins"
                  {...register("coins", {
                    required: "Coins are required",
                    max: {
                      value: parentDetails?.coins,
                      message: `Coins cannot be more than ${parentDetails?.coins}`,
                    },
                  })}
                />
                {errors.coins && (
                  <span className="text-red-500 text-sm">
                    {errors.coins.message}
                  </span>
                )}
              </div> */}
            </div>



            {/* {userType?.userType !== "subowner" ? null : 
            <div className="mt-4">
              {userType?.userType !== 'subowner' ? null : ( 
              <div className="mt-4">
                <label className="text-[13px] font-bold text-white mb-[5px]">Select Domain:</label>
                <Controller
                  name="selectDomain"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isMulti
                      options={userDetails?.allowedDomains?.map(domain => ({
                        value: domain,
                        // label: domain,

                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  )}
                />

              </div>
             )} 



            </div>
            }  */}






            {/* <div className="grid grid-cols-1 gap-x-4 mt-4">
              <div>

                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Password:
                </label>
                <input
                  defaultValue={userDetails?.passwordShow}
                  // defaultValue={'**********'}
                  disabled
                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${errors.password ? "border-red-500" : ""
                    }`}
                  type="password"
                  placeholder="Password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                {errors.password && (
                  <span className="text-red-500 text-sm">
                    {errors.password.message}
                  </span>
                )}
              </div>

              <div>
                <label className="text-[13px] font-bold text-white mb-[5px]">
                  Confirm Password:
                </label>
                <input
                  className={`w-full h-[34px] px-[12px] py-[6px] text-[14px] ${
                    errors.confirmPassword ? "border-red-500" : ""
                  }`}
                  type="password"
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === getValues("password") ||
                      "Passwords do not match",
                  })}
                />
                {errors.confirmPassword && (
                  <span className="text-red-500 text-sm">
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
            </div> */}

            <div className="mt-4">
              <label className="text-[13px] font-bold text-white mb-[5px]">
                Remark:
              </label>
              <textarea
                defaultValue={userDetails?.reference}
                className="w-full px-[5px] py-[6px]"
                placeholder="Remark"
                {...register("remark")}
              />
            </div>

            <div className="text-[13px] font-bold text-white mb-[5px] border-b border-b-[#665d49] pb-2 mt-4">
              Set comm setting
            </div>

            <div>
              <div
                style={{ backgroundImage: "linear-gradient(#05537e, #27a6c3)" }}
                className="text-white mt-3 uppercase font-bold h-[38px] flex items-center justify-center text-[14px] bg-[#27a6c3]"
              >
                Sports Settings
              </div>


            </div>
            <div>
              <Accordion className="rounded-0" defaultExpanded>
                <AccordionSummary
                  sx={{
                    margin: 0,
                    height: "18px",
                    padding: "10px",
                    borderBottom: "1px solid #e4e4e4",
                    borderRadius: "0",
                  }}
                  className="!rounded-0"
                  expandIcon={
                    <IoIosArrowDown size={14} className="text-[#337ab7]" />
                  }
                  id="panel1-header"
                >
                  <div className=" flex items-center gap-1">
                    {/* <input
                      type="checkbox"
                      className="w-[14px] h-[14px] text-blue-600 bg-gray-100 border-gray-300 rounded"
                    /> */}
                    <div className="text-[12px] font-semibold text-[#23527c] uppercase">
                      cricket
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-3 gap-3">
                    <div>
                      <label className="text-[13px] font-bold text-black">
                        Match <br /> Share{" "}
                        <span className="text-red-700">
                          [{parentDetails?.userMatchShare}]
                        </span>
                      </label>
                      <input
                        defaultValue={userDetails?.userMatchShare}
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px]"
                        type="number"
                        placeholder="Match Share"
                        {...register("userMatchShare", {
                          required: "Match share is required",
                          max: {
                            value: parentDetails?.userMatchShare,
                            message: `Value cannot exceed ${parentDetails?.userMatchShare}`,
                          },
                        })}
                      />
                      {errors.userMatchShare && (
                        <span className="text-red-500 text-sm">
                          {errors.userMatchShare.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label className="text-[13px] font-bold text-black">
                        Match <br /> Comm{" "}
                        <span className="text-red-700">
                          [{parentDetails?.userMatchCommission}]
                        </span>
                      </label>
                      <input
                        defaultValue={userDetails?.userMatchCommission}
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px]"
                        type="number"
                        placeholder="Match Commission"
                        {...register("userMatchCommission", {
                          required: "Match Commission is required",
                          max: {
                            value: parentDetails?.userMatchCommission,
                            message: `Value cannot exceed ${parentDetails?.userMatchCommission}`,
                          },
                        })}
                      />
                      {errors.userMatchCommission && (
                        <span className="text-red-500 text-sm">
                          {errors.userMatchCommission.message}
                        </span>
                      )}
                    </div>
                    <div>
                      <label className="text-[13px] font-bold text-black">
                        Session <br /> Comm{" "}
                        <span className="text-red-700">
                          [{parentDetails?.userSessionCommission}]
                        </span>
                      </label>
                      <input
                        defaultValue={userDetails?.userSessionCommission}
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px]"
                        type="number"
                        placeholder="Session Commission"
                        {...register("userSessionCommission", {
                          required: "Session Commission is required",
                          max: {
                            value: parentDetails?.userSessionCommission,
                            message: `Value cannot exceed ${parentDetails?.userSessionCommission}`,
                          },
                        })}
                      />
                      {errors.userSessionCommission && (
                        <span className="text-red-500 text-sm">
                          {errors.userSessionCommission.message}
                        </span>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div>
              <Accordion className="rounded-0" defaultExpanded>
                <AccordionSummary
                  sx={{
                    margin: 0,
                    height: "20px",
                    padding: "10px",
                    borderBottom: "1px solid #e4e4e4",
                    borderRadius: "0",
                  }}
                  className="!rounded-0"
                  aria-controls="panel1-content"
                  expandIcon={
                    <IoIosArrowDown size={14} className="text-[#337ab7]" />
                  }
                  id="panel1-header"
                >
                  <div className="flex items-center gap-1">
                    {/* <input
                      type="checkbox"
                      className="w-[14px] h-[14px] text-blue-600 bg-gray-100 border-gray-300 rounded"
                    /> */}
                    <div className="text-[12px] font-semibold text-[#23527c] uppercase">
                      Casino
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        Casino Share{" "}
                        <span className="text-red-700">
                          [{parentDetails?.userCasinoShare}]
                        </span>
                      </label>
                      <input
                        defaultValue={userDetails?.userCasinoShare}
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder="Casino Share"
                        {...register("userCasinoShare", {
                          required: "Casino share is required",
                          max: {
                            value: parentDetails?.userCasinoShare,
                            message: `Maximum value is ${parentDetails?.userCasinoShare}`,
                          },
                        })}
                      />
                      {errors.userCasinoShare && (
                        <p className="text-red-500 text-sm">
                          {errors.userCasinoShare.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        Casino Comm{" "}
                        <span className="text-red-700">
                          [{parentDetails?.userCasinoCommission}]
                        </span>
                      </label>
                      <input
                        defaultValue={userDetails?.userCasinoCommission}
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder="Casino Commission"
                        {...register("userCasinoCommission", {
                          required: "Casino commission is required",
                          max: {
                            value: parentDetails?.userCasinoCommission,
                            message: `Maximum value is ${parentDetails?.userCasinoCommission}`,
                          },
                        })}
                      />
                      {errors.userCasinoCommission && (
                        <p className="text-red-500 text-sm">
                          {errors.userCasinoCommission.message}
                        </p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div>
              <Accordion className="rounded-0" defaultExpanded>
                <AccordionSummary
                  sx={{
                    margin: 0,
                    height: "20px",
                    padding: "10px",
                    borderBottom: "1px solid #e4e4e4",
                    borderRadius: "0",
                  }}
                  className="!rounded-0"
                  aria-controls="panel2-content"
                  expandIcon={
                    <IoIosArrowDown size={14} className="text-[#337ab7]" />
                  }
                  id="panel2-header"
                >
                  <div className="flex items-center gap-1">
                    {/* <input
                      type="checkbox"
                      className="w-[14px] h-[14px] text-blue-600 bg-gray-100 border-gray-300 rounded"
                    /> */}
                    <div className="text-[12px] font-semibold text-[#23527c] uppercase">
                      International Casino
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <label className="switch mb-0">
                    <input

                      type="checkbox"
                      id="togBtn"
                      {...register("intCasinoStatus", { required: false })}
                      defaultChecked={parentDetails?.intCasinoStatus}
                    />
                    <div className="slider round">
                      <span className="on">ON</span>
                      <span className="off">OFF</span>
                    </div>
                  </label>

                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        International Casino share{" "}
                        <span className="text-red-700">
                          [{parentDetails?.intCasinoShare}]
                        </span>
                      </label>
                      <input
                        defaultValue={userDetails?.intCasinoShare}
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder="Int Casino Share"
                        {...register("intCasinoShare", {
                          required: "International Casino share is required",
                          max: {
                            value: parentDetails?.intCasinoShare,
                            message: `Maximum value is ${parentDetails?.intCasinoShare}`,
                          },
                        })}
                      />
                      {errors.intCasinoShare && (
                        <p className="text-red-500 text-sm">
                          {errors.intCasinoShare.message}
                        </p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div>
              <Accordion className="rounded-0" defaultExpanded>
                <AccordionSummary
                  sx={{
                    margin: 0,
                    height: "20px",
                    padding: "10px",
                    borderBottom: "1px solid #e4e4e4",
                    borderRadius: "0",
                  }}
                  className="!rounded-0"
                  aria-controls="panel3-content"
                  expandIcon={
                    <IoIosArrowDown size={14} className="text-[#337ab7]" />
                  }
                  id="panel3-header"
                >
                  <div className="flex items-center gap-1">
                    {/* <input
                      type="checkbox"
                      className="w-[14px] h-[14px] text-blue-600 bg-gray-100 border-gray-300 rounded"
                    /> */}
                    <div className="text-[12px] font-semibold text-[#23527c] uppercase">
                      Matka
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <label className="switch mb-0">
                    <input
                      type="checkbox"
                      id="togBtn"
                      {...register("matkaStatus", { required: false })}
                      defaultChecked={userDetails?.matkaStatus}
                    />
                    <div className="slider round">
                      <span className="on">ON</span>
                      <span className="off">OFF</span>
                    </div>
                  </label>

                  <div className="grid grid-cols-2 gap-3">
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        Matka share{" "}
                        <span className="text-red-700">
                          [{parentDetails?.userMatkaShare}]
                        </span>
                      </label>
                      <input
                        defaultValue={userDetails?.userMatkaShare}
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder="Matka share"
                        {...register("userMatkaShare", {
                          required: "Matka share is required",
                          max: {
                            value: parentDetails?.userMatkaShare,
                            message: `Maximum value is ${parentDetails?.userMatkaShare}`,
                          },
                        })}
                      />
                      {errors.userMatkaShare && (
                        <p className="text-red-500 text-sm">
                          {errors.userMatkaShare.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        className="text-[13px] !font-bold text-black"
                        htmlFor=""
                      >
                        Matka Comm{" "}
                        <span className="text-red-700">
                          [{parentDetails?.userMatkaCommission}]
                        </span>
                      </label>
                      <input
                        defaultValue={userDetails?.userMatkaCommission}
                        className="w-full border border-[rgba(0, 0, 0, 0.25)] text-black px-[12px] py-[6px] h-[34px] focus:outline-none"
                        type="number"
                        placeholder=" Matka Commission"
                        {...register("userMatkaCommission", {
                          required: "Matka commission is required",
                          max: {
                            value: parentDetails?.userMatkaCommission,
                            message: `Maximum value is ${parentDetails?.userMatkaCommission}`,
                          },
                        })}
                      />
                      {errors.userMatkaCommission && (
                        <p className="text-red-500 text-sm">
                          {errors.userMatkaCommission.message}
                        </p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="flex justify-end mt-8">
              <button
                type="button"
                className="bg-[#3f5c61] text-white py-[6px] px-4 text-[13px] mr-2"
                onClick={() => setIsDrawerOpen({ addNewUserDrawer: false })}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#006e92] text-white py-[6px] px-4 text-[13px]"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Drawer>
  );
};

export default EDITUSER;
