import axios from "axios";
import { baseUrl } from "./Http";
import { domainName } from "../constents/global";


function authHeader() {
  const user = JSON.parse(localStorage.getItem(`user_info_${domainName}`) || 'null');
  if (user && user.token) {
      return { 'Authorization': 'Bearer ' + user.token };
  } else {
      return {};
  }
}


const httpGet = async (url, params, isNotify) => {
  try {
    let headers = {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin':'*',
      "Authorization": authHeader().Authorization
    };
    // params = { ...params };
    const result = await axios({
      method: "GET",
      url: baseUrl.BACKEND_URL + url,
      data: { ...params },
      headers: headers,
    });

    invalidToken(result);

    return result;
  } catch (err) {
    console.error(err);
    // toast.error(err.response.data.message);
    if (err.request.status) {
      invalidHeadres(err.request.status);
    }
  }
};


const httpPost = async (url, params, isNotify) => {
  try {
    let headers = {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin':'*',
      "Authorization": authHeader().Authorization
    };
    const result = await axios({
      method: "POST",
      url: baseUrl.BACKEND_URL + url,
      data: { ...params },
      headers: headers,
    });


    // await invalidToken(result);

    if (result.data) {
      if (result.data.error && isNotify) {
        // error(result.message)
        alert("h1")
      }
      else if (isNotify && !result.data.error) {
        alert("h3")

        // success(result.message)
        //alert(result.data.message)
      }
      return result.data
    } else {
      alert("h2")

      return false
    }
  } catch (err) {
    console.error(err);
    // toast.error(err.response.data.message);
    if (err.request.status) {
      invalidHeadres(err.request.status);
    }
  }
};

const httpPostFormData = async (url, data, isNotify) => {
  try {

    const result = await axios({
      method: "POST",
      url: baseUrl.BACKEND_URL + url,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });


    // await invalidToken(result);

    if (result.data) {
      if (result.data.error && isNotify) {
        // error(result.message)
        //alert(result.data.message)
      }
      else if (isNotify && !result.data.error) {
        // success(result.message)
        //alert(result.data.message)
      }
      return result.data
    } else {
      return false
    }
  } catch (err) {
    console.error(err);
    // toast.error(err.response.data.message);
    if (err.request.status) {
      invalidHeadres(err.request.status);
    }
  }
};

const httpPatch = async (url, params, isNotify) => {
  try {
    let headers = {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin':'*',
      "Authorization": authHeader().Authorization
    };
    const result = await axios({
      method: "PATCH",
      url: baseUrl.BACKEND_URL + url,
      data: { ...params },
      headers: headers,
    });

    // invalidToken(result);
    if (result.data) {
      if (result.data.error && isNotify) {
        // error(result.message)
        //alert(result.data.message)
      }
      else if (isNotify && !result.data.error) {
        // success(result.message)
        //alert(result.data.message)
      }
    } else {
      return false
    }

    return result.data
  } catch (err) {
    console.error(err);
    if (err.request.status) {
      invalidHeadres(err.request.status);
      //   toast.error(err.response.data.message);
    }
  }
};

const invalidToken = async (result) => {
  if (result.data.code === 3) {
    // localStorage.clear();
    // sessionStorage.clear();
    // window.location.href = "/login";
  }
};

const invalidHeadres = async (status = "") => {
  if (status === 401) {
    // localStorage.clear();
    // sessionStorage.clear();
    // window.location.href = "/login";
  }
};


export { httpGet, httpPost, httpPatch, httpPostFormData };
