import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import moment from "moment";
// import BackButton from "../../Hoc/BackButton";
import { useDispatch, useSelector } from "react-redux";
// import { casinoDiamondBetList, casinoRoundWise, getDiamondCasinoByEventId } from "../../../../appRedux/actions/User";
import { useParams } from "react-router-dom";
import CasinoIframeResult from "../CasinoComponent/CasinoIframeResult";
import TablePagination from "../TablePagination";
import { casinoDiamondBetList, casinoRoundWise, getDiamondCasinoByEventId } from "../../../redux/_reducers/_user_reducers";
import Triangle from "../../../global_layouts/Triangle/Triangle";
// import Auxiliary from "util/Auxiliary";
// import CasinoIframeResult from "components/dashboard/Casino/CasinoIframeResult";
// import TablePagination from "../../../../components/TablePagination";




const Basic = () => {
    const { gameName, eventId } = useParams();
    const [matchLedger, setMatchLedger] = useState([]);
    const [totalProfitLoss, setTotalProfitLoss] = useState(0);
    const [userLists, setUserLists] = useState([]);
    // const [finalUserList, setFinalUserList] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [infoMenu, setInfoMenu] = useState({ visible: false, data: null, });
    const [showDate, setShowDate] = useState({
        startDate: moment(),
        endDate: moment().subtract(7, 'days'),
    });


    const pageSize = 25;
    const handlePageChange = (page) => {
        setCurrentPage(page);

    };

    const dispatch = useDispatch()

    const { casinoRoundWiseData,  diamondcasinobyeventid, casinoBetList } = useSelector((state) => state.user);



    useEffect(() => {
        casinoRoundWiseList()
        casinoList()
        const intervalId = setInterval(() => {
            // casinoRoundWiseList();
            casinoList();
          }, 3000); 
          return () => {
            clearInterval(intervalId);
          };

    }, [currentPage])


    const casinoRoundWiseList = async () => {

        let reqData = {
            "eventId": eventId
        }
        dispatch(casinoRoundWise(reqData));
        dispatch(getDiamondCasinoByEventId(reqData));


    };

    const casinoList = async () => {
        let reqData = {
            eventId: eventId,
            toDate: moment(showDate?.startDate).format("YYYY-MM-DD"),
            fromDate: moment(showDate?.endDate).format("YYYY-MM-DD"),
            pageNo: currentPage,
            size: pageSize,
            isDeclare: 0
        };
        dispatch(casinoDiamondBetList(reqData));
    };

    useEffect(() => {
        if (casinoRoundWiseData) {
          
            const filteredData = casinoRoundWiseData?.map((item, index) => ({
                key: `${index + 1}`,
                createdAt: item.createdAt,
                gameId: item._id,
                profitLoss: -1 * item.profitLoss
            }));
            let total = 0;
            casinoRoundWiseData?.forEach(item => {
                total += -1 * item.profitLoss;
            });
            setTotalProfitLoss(total)
            setMatchLedger(filteredData);

        }

    }, [casinoRoundWiseData]);

    useEffect(() => {
        if (casinoBetList) {
            const filteredData = casinoBetList?.casinoBetData?.map((item, index) => ({
                key: `${item._id}-${index}`,
                _id: `${item._id}`,
                createdAt: `${item.createdAt}`,
                client: `${item.userInfo.name}(${item.userInfo.username})`,
                roundId: item.roundId,
                playerName: item.playerName,
                showResult: item.showResult ? item.showResult : "Not Declear",
                isDeclare: item.isDeclare,
                amount: item.amount,
                profit: item.profit,
                loss: item.loss,
                profitLoss: item.profitLoss * -1,
                resultDetails: item.resultDetails,
            }));
            setUserLists(filteredData);
            const uniqueClientName = filteredData
                ? [...new Set(filteredData?.map((item) => item.client))]
                : null;
            // setFinalUserList(uniqueClientName)
        }

    }, [casinoBetList?.casinoBetData]);


    const renderContent = (value, row, index) => {
        const obj = {
            children: value,
            props: {},
        };

        return obj;
    };

    // const handleClose = () => {
    //     setInfoMenu({
    //         visible: false,
    //     });
    // };

    const columns = [
        {
            title: 'Game ID',
            onHeaderCell: (column) => ({
                style: {
                    backgroundColor: '#27272a', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'normal', fontSize: '12px', borderRadius:'0', color: 'white'
                },
                }),
            dataIndex: 'gameId',
            render: renderContent,
        },
        {
            title: ' Started AT',
            onHeaderCell: (column) => ({
                style: {
                    backgroundColor: '#27272a', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'normal', fontSize: '12px', borderRadius:'0', color: 'white'
                },
                }),
            dataIndex: 'date',
            render: (createdAt) => <span className="gx-text-nowrap"> {moment(createdAt).format("DD MMM hh:mm A")}</span>,
        },
        // {
        //     title: 'Winner',
        //     dataIndex: 'action',
        //     render: () => <span onClick={() => alert(1)}>show</span>,

        // },
        {
            title: 'Plus/Minus',
            onHeaderCell: (column) => ({
            style: {
                backgroundColor: '#27272a', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'normal', fontSize: '12px', borderRadius:'0', color: 'white'
            },
            }),
            dataIndex: 'profitLoss',
            render: (value, row) => (
                <span className={`${row.profitLoss > 0 ? 'gx-text-green-0' : 'gx-text-red'}`}>
                    {row.profitLoss}
                </span>
            ),

        },
    ];

    const renderShowResult = (value, row, index) => {
        const obj = renderContent(value, row, index);
        if (row.showResult) {
            obj.props.onClick = (e) => handleInfo(e, row.resultDetails);
            obj.props.style = { ...obj.props.style, cursor: "pointer" }; // Change cursor to pointer to indicate it's clickable
        }
        return obj;
    };

    const handleInfo = (e, showResult) => {
        e.preventDefault();
        setInfoMenu({
            visible: true,
            data: showResult,
        });
    };

    const columns2 = [
        // {
        //     title: "Date",
        //     dataIndex: "createdAt",
        //     // render: renderContent,
        //     render: (date) => (
        //       <span>{moment(date).format('MMMM DD YYYY, h:mm:ss a')}</span>
        //     ),
        // },
        {
            title: <span style={{ backgroundColor: '#27272a', paddingInline: '10px', paddingBlock:'2px', fontWeight:'normal', fontSize:'14px', display: 'block' , color:'white'}}>Client</span>,
            dataIndex: "client",
            render: renderContent,
        },

        {
            title: <span style={{ backgroundColor: '#27272a', paddingInline: '10px', paddingBlock:'2px', fontWeight:'normal', fontSize:'14px', display: 'block' , color:'white'}}>RoundId</span>,
            dataIndex: "roundId",
            render: renderContent,
        },

        {
            title: <span style={{ backgroundColor: '#27272a', paddingInline: '10px', paddingBlock:'2px', fontWeight:'normal', fontSize:'14px', display: 'block' , color:'white'}}>Player</span>,
            dataIndex: "playerName",
            render: renderContent,
        },

        {
            title: <span style={{ backgroundColor: '#27272a', paddingInline: '10px', paddingBlock:'2px', fontWeight:'normal', fontSize:'14px', display: 'block' , color:'white'}}>Winner</span>,
            dataIndex: "showResult",
            render: renderShowResult,
        },

        {
            title: <span style={{ backgroundColor: '#27272a', paddingInline: '10px', paddingBlock:'2px', fontWeight:'normal', fontSize:'14px', display: 'block' , color:'white'}}>Stake</span>,
            dataIndex: "amount",
            render: renderContent,
        },

        {
            title: <span style={{ backgroundColor: '#27272a', paddingInline: '10px', paddingBlock:'2px', fontWeight:'normal', fontSize:'14px', display: 'block' , color:'white'}}>profit</span>,
            dataIndex: "profit",
            render: renderContent,
        },

        {
            title: <span style={{ backgroundColor: '#27272a', paddingInline: '10px', paddingBlock:'2px', fontWeight:'normal', fontSize:'14px', display: 'block' , color:'white'}}>Loss</span>,
            dataIndex: "loss",
            render: renderContent,
        },

        // {
        //     title: "PNL",
        //     dataIndex: "profitLoss",
        //     render: renderContent,
        // },
    ];

    // Define the data source


    

    return (
        <>
            {/* {loader ? <Loader props={loader} /> : */}
                <>
                    <Card className="gxcard">
                        {/* <div className="bg-gray-600 px-4 mb-4 flex py-3">
                            <span className="text-2xl font-normal text-white items-center pt-1 capitalize">{`${gameName}`}</span>
                            </div> */}
                            <div className="bg-red-600 flex justify-between items-center mb-4">
                                <div className="text-white bg-red-700 relative font-bold px-4 py-2">
                                {" "}
                                {`${gameName}`}
                                <Triangle direction="down" size={10} color="#B91C1C" />
                                </div>
                                <button
                                onClick={() => window.location.reload()}
                                className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
                                >
                                {" "}
                                Refresh
                                </button>
                            </div>
                            <div >
                            <CasinoIframeResult diamondcasinobyeventid={diamondcasinobyeventid} eventId={eventId} betLists={casinoBetList?.casinoBetData} />
                            <Card className="card">
                                <div className="bg-gray-600 border border-white flex align-items-center px-4 py-2">
                                    <span className="sm:text-2xl text-sm font-normal text-white items-center capitalize">{`NonDeclare Bets - [ ${casinoBetList?.totalCasinoCount} ]`}</span>
                                </div>
                                <div>
                                    <Table className="table-responsive customTable bg-white bordered" columns={columns2} dataSource={userLists} pagination={false} size="small" />
                                    <TablePagination currentPage={currentPage} totalItems={casinoBetList?.totalCasinoCount} pageSize={pageSize} onPageChange={handlePageChange} />

                                </div>
                            </Card>
                            <Card className="card">
                                <div className="bg-gray-600 flex items-center gap-2 justify-between px-4 py-2">
                                    <span className="sm:text-2xl text-sm font-normal text-white items-center capitalize">{`${gameName}`}</span>
                                    <div className="sm:text-lg text-sm "><span className="text-white">Total : </span><span className={`${totalProfitLoss > 0 ? 'text-green-600' : 'text-red-600'}`}> {totalProfitLoss ? totalProfitLoss.toFixed(2) : '0.00'}</span></div>
                                </div>
                                <div>
                                    <Table className="table-responsive  bg-white bordered" columns={columns} dataSource={matchLedger} bordered pagination={false} size="small" />
                                </div>

                            </Card>
                        </div>
                    </Card>

                </>
            {/* } */}

        </>
    );
};

export default Basic;
