// import React from "react";
// import { userLedgerCreditDebit } from "../../redux/_reducers/_user_reducers";

// const SettlementModal = (props) => {

//     const {fieldsUser, isModalOpen, modalData, paymentType} = props

//    const getUserSattlement = async () => {

//         let { modalData, fieldsUser } = this.state;
//         // if (parseFloat(fieldsUser.balance) < 0) {
//         //     toast.error("Balance amount must be a positive number.", {
//         //         position: toast.POSITION.TOP_CENTER
//         //     });
//         //     return;
//         // }

//         if (modalData.userType === "client") {
//             if (modalData.ledgerType === "lena") {
//                 fieldsUser.balance = -1 * Math.abs(fieldsUser.balance)
//             } else {
//                 fieldsUser.balance = Math.abs(fieldsUser.balance)
//             }
//         }

//         if (modalData.userType != "client") {
//             if (modalData.ledgerType === "lena") {
//                 fieldsUser.balance = Math.abs(fieldsUser.balance)
//             } else {
//                 fieldsUser.balance = -1 * Math.abs(fieldsUser.balance)
//             }
//         }
//         let reqData = {
//             "downlineUserId": modalData.userId,
//             "isDirect": true,
//             // "date": this.state.currentDate,
//             // "remark": this.state.note ? this.state.note : "settalment",
//             "amount": Number(fieldsUser.balance),
//         }


//         if (modalData.userType == "client" && (this.state.isChecked === true || modalData.ledgerType == "lena")) {
//             reqData["deposit"] = true;
//         }




//         this.setState({ isFetch: true })
//         dispatch(userLedgerCreditDebit(reqData))
//         // let saveLedger = await httpPost('user/ledgerCreditDebit', reqData);
//         if (saveLedger) {
//             this.setState({ saveLedger: saveLedger.data });
//             this.getLenaDenaList(this.state.downlineUserType);
//             this.getMyCash()
//             let userData = JSON.parse(localStorage.getItem('adminuser'));
//             let dataDeposit = {
//                 userId: userData.data.userId
//             }
//             this.props.dispatch(userActions.userBalance(dataDeposit));
//             this.setState({ showAlert: true, errorType: 0, resMessage: "Settlement Successfully " });
//         } else {
//             this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
//         }
//         this.setState({ isFetch: false, isChecked: false })
//         // }
//     }

//     return (
//         <div>
//             ffffffffffffff
//         </div>
//     )


// }

// export default SettlementModal;




import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userLedgerCreditDebit } from "../../redux/_reducers/_user_reducers";
import CustomModal from "../../global_layouts/Modal/CustomModal";

const SettlementModal = (props) => {
    const { fieldsUser, isModalOpen, modalData, paymentType, isChecked, handleCheckboxChange, getMyCash } = props;
    const dispatch = useDispatch();
    const [isFetch, setIsFetch] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [resMessage, setResMessage] = useState("");
    const [note, setNote] = useState("");
    const [balance, setBalance] = useState(fieldsUser.balance); // Use local state for balance

    useEffect(() => {
        setBalance(fieldsUser.balance); // Sync local balance state with props
    }, [fieldsUser.balance]);

    const getUserSettlement = async () => {
        let userBalance = parseFloat(balance);
        if (userBalance < 0) {
            setResMessage("Balance amount must be a positive number.");
            setShowAlert(true);
            return;
        }

        const finalBalance = modalData.userType === "client"
            ? (modalData.ledgerType === "lena" ? -Math.abs(userBalance) : Math.abs(userBalance))
            : (modalData.ledgerType === "lena" ? Math.abs(userBalance) : -Math.abs(userBalance));

        const reqData = {
            downlineUserId: modalData.userId,
            isDirect: true,
            amount: Number(finalBalance),
            deposit: modalData.userType === "client" && (isChecked || modalData.ledgerType === "lena"),
        };

        setIsFetch(true);

        try {
            
            const saveLedger = await dispatch(userLedgerCreditDebit(reqData));
            if (saveLedger) {
                setResMessage("Settlement Successfully");
                dispatch(getMyCash());
            } else {
                setResMessage("Something went wrong");
            }
        } catch (error) {
            console.error(error);
            setResMessage("Something went wrong");
        } finally {
            setIsFetch(false);
            setShowAlert(true);
        }
    };

    const handleChangeBalance = (e) => {
        setBalance(e.target.value); 
    };

    return (
        <CustomModal
            isOpen={isModalOpen}
            onClose={() => props.setIsModalOpen(false)}
            footer={
                <div className="text-right px-2 w-100 p-2">
                    <div className="space-x-2">
                        <button onClick={getUserSettlement} className="bg-[#22a7f0] text-white px-4 py-1.5 rounded-none">
                            Save
                        </button>
                        <button className="bg-red-800 text-white px-4 py-1.5 rounded-none" onClick={() => props.setIsModalOpen(false)}>
                            Close
                        </button>
                    </div>
                </div>
            }
            header={
                <div className="text-right px-2 w-100 p-2">
                    <p className="bg-[#000] text-white px-4 py-1 w-full rounded-none text-center font-bold">
                        SETTLEMENT FOR {modalData && modalData.username ? modalData.username : ""} {modalData.ledgerType === "dena" ? "(-)" : "(+)"}
                    </p>
                    <p className='lg:text-start text-center md:text-md text-sm uppercase py-2'>
                        Total Outstanding Amount : 
                        <span className="text-[#22a7f0] px-2">
                            {modalData && modalData.balance ? Number.parseFloat(Math.abs(modalData.balance)).toFixed(2) : "0.00"}
                        </span>
                    </p>
                </div>
            }
            position={{ horizontal: 'center', vertical: 'top' }}
            size={{ width: '50%' }}
        >
            <div className="px-3 py-2 mb-2 lg:space-x-2 space-x-0 lg:space-y-0 space-y-2 w-100 lg:flex justify-center">
                <input
                    className="md:text-sm text-xs border-2 lg:w-1/3 w-full focus:outline-none text-black placeholder:text-gray-500 lg:py-0 py-2 px-2"
                    name="balance"
                    type="number"
                    id="balance"
                    required
                    value={balance >= 0 ? parseFloat(balance).toFixed(2).replace(/\.?0+$/, '') : (balance * -1).toFixed(2).replace(/\.?0+$/, '')}
                    onChange={handleChangeBalance}
                />
                <input
                    className="md:text-sm text-xs px-2 border-2 lg:w-1/3 w-full focus:outline-none text-black lg:py-0 py-2 placeholder:text-gray-500"
                    type="text"
                    id="note"
                    required
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder="Comment"
                />
                {modalData.balance < 0 && modalData.userType === "client" && (
                    <div className='py-2'>
                        <label className='text-red-500'>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <span> Do you want to Deposit Chips (With Chips)?</span>
                        </label>
                    </div>
                )}
            </div>
        </CustomModal>
    );
};

export default SettlementModal;
