import { useEffect, useState } from "react";
import { domainName } from "../../constents/global";
import { useDispatch, useSelector } from "react-redux";
import { IoMenu } from "react-icons/io5";
import Triangle from "../../global_layouts/Triangle/Triangle";
import ChangePassword from "../changePassword/ChangePassword";
import { useBalance } from '../balanceContext/BalanceContext'
import { encrypt } from "../../config/Encryption";
import { TfiReload } from "react-icons/tfi";
import { getUserBalance } from "../../redux/_reducers/_user_reducers";


const Header = () => {
  const [userType, setUserType] = useState(null)
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpens, setIsDrawerOpens] = useState({
    changeDrawer: false,
    profileDrawer: false
  })
  const [userBalance, setUserBalance] = useState(null)
  // const [userBalanceData, setIsUserBalance] = useState(null)
  const { loading, refrecLoading } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const userData = localStorage.getItem(`user_info_${domainName}`) ? JSON.parse(localStorage.getItem(`user_info_${domainName}`)) : null

  useEffect(() => {
   
    if (userData) {
      setUserType(userData?.data?.userType);
      // setUserBalance(userData?.data?.balance)
    }
  }, [userData]);



  const userLogout = () => {
    localStorage.clear()
    window.location.href = '/login'
  }
  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const changePasswordModal = () => {
    setIsDrawerOpens({
      changeDrawer: true,
      profileDrawer: false
    })
  }
  const changeProfileModal = () => {
    setIsDrawerOpens({
      changeDrawer: false,
      profileDrawer: true
    })
  }



  const storedBalance = JSON.parse(localStorage.getItem('wallet-balance'));
  useEffect(() => {

    if (storedBalance) {
      setUserBalance(storedBalance);
    }
  }, [userBalance]);
  
  const handleUserBalance = () => {
    dispatch(getUserBalance()).then((newBalance) => {
      
      setUserBalance(newBalance?.payload.coins);
      localStorage.setItem('wallet-balance', JSON.stringify(newBalance?.payload.coins));
    });
  };
  


  return (
    <div className="main-header bg-dark ">
      <nav className=" d-flex navbar navbar-expand navbar-dark ">
        <ul className="navbar-nav 2xl:w-1/2 w-1/2">

          <li className="nav-item">
            <span className="nav-link" data-widget="pushmenu" role="button">
              <i><IoMenu size={28} /></i>
            </span>
          </li>

          <li className="nav-item d-none d-lg-block">

            <span className="nav-link"><i class="fa-solid fa-ship px-1 text-sm" />Dashboard</span>
          </li>

          <li className="nav-item d-block d-lg-none">
            <a href="/" className="">
              <img src="/assest/images/logo.png" alt="Logo" className="h-10 w-52" />
            </a>
          </li>

        </ul>
        <ul className="navbar-nav ml-auto 2xl:w-1/2 w-1/2 d-flex flex-row align-items-center xl:justify-between justify-end">

          <li className="nav-item d-md-flex d-none mr-2 justify-start  ">
            <span className="navbar-text text-white text-xs px-3 d-xl-flex d-none">
              Logged in as {userType} <br />
              {/* Last logged in: Oct 04 2024 03:00:43 PM */}
            </span>
            <span className=" bg-white px-2  py-0.5 mt-2 mr-2 d-none d-md-flex" >

              <span className="text-md px-3">Balance:</span>
              <span className="navbar-text text-white font-normal  text-sm bg-black rounded px-2 flex gap-x-2 items-center">
                {userBalance ? Number(userBalance).toFixed(2) : storedBalance} <TfiReload onClick={() => handleUserBalance()} className={loading ? 'animate-spin' : ''}/>
              </span>
            </span>
          </li>

          <li className="relative  bg-transparent hover:bg-gradient-to-r hover:from-transparent hover:to-[#D0000F] transition duration-300 justify-end">
            <button onClick={toggleDropdown} className="relative lg:px-3 block h-12  overflow-hidden focus:outline-none focus:border-white">
              <span className="flex justify-center   items-center gap-1">  <i className="fas fa-cog px-1"></i> Account
                {isOpen ? <i className="fas fa fa-chevron-up right px-1" /> : <i className="fas fa fa-chevron-down right px-1" />} </span>
            </button>

            <button
              className={isOpen ? 'cursor-default  fixed inset-0 w-full h-full' : 'hidden'}
              onClick={closeDropdown}
              tabIndex="-1"
            />
            <div className={isOpen ? 'absolute right-0 mt-1 w-60 bg-[#045662] rounded-lg shadow-xl' : 'hidden'} onClick={closeDropdown}>
              <Triangle direction="up" size={13} color="#045662" />
              <span className="block px-3 text-sm cursor-pointer font-medium py-2 border-b text-gray-300 hover:bg-gradient-to-l hover:from-transparent hover:to-[#D0000F] transition duration-300" onClick={changeProfileModal}>
                Profile
              </span>
              {/* <a href={`/admin/myBets`}>
                <span className="block px-3 text-sm cursor-pointer font-medium py-2 border-b text-gray-300 hover:bg-gradient-to-l hover:from-transparent hover:to-[#D0000F] transition duration-300">
                  My Bets
                </span>
              </a> */}
              <a href={`/admin/profit-loss/${encrypt(userData?.data?.userId)}/${encrypt(userData?.data?.userType)}`}>
                <span className="block px-3 text-sm cursor-pointer font-medium py-2 border-b text-gray-300 hover:bg-gradient-to-l hover:from-transparent hover:to-[#D0000F] transition duration-300">
                  Profit & Loss
                </span>
              </a>
              {/* <span className="block px-3 text-sm cursor-pointer font-medium py-2 border-b text-gray-300 hover:bg-gradient-to-l hover:from-transparent hover:to-[#D0000F] transition duration-300">
                Report
              </span> */}

              <a href="/admin/account-statement">
                <span className="block px-3 text-sm cursor-pointer font-medium py-2 border-b text-gray-300 hover:bg-gradient-to-l hover:from-transparent hover:to-[#D0000F] transition duration-300">
                  Account Statement
                </span>
              </a>
              {/* <span className="block px-3 text-sm cursor-pointer font-medium py-2 border-b text-gray-300 hover:bg-gradient-to-l hover:from-transparent hover:to-[#D0000F] transition duration-300">
                Casino Statement
              </span> */}

              <span className="block px-3 text-sm cursor-pointer font-medium py-2 border-b text-gray-300 hover:bg-gradient-to-l hover:from-transparent hover:to-[#D0000F] transition duration-300" onClick={() => changePasswordModal()}>
                Change Password
              </span>

              {/* <span className="block px-3 text-sm cursor-pointer font-medium py-2 border-b text-gray-300 hover:bg-gradient-to-l hover:from-transparent hover:to-[#D0000F] transition duration-300">
                Clear Casino matches
              </span> */}
              <span className=" px-4 py-2 mb-1 mx-1 bg-red-600 text-md cursor-pointer font-medium flex justify-center items-center rounded-sm gap-1" onClick={userLogout}>
                <span>
                  <i class="fa-solid fa-power-off" />
                </span>
                <span>Logout</span>
              </span>
            </div>
          </li>
        </ul>
        <span className="nav-link" data-widget="fullscreen" role="button">
          <i className="fas fa-expand-arrows-alt" />
        </span>
      </nav>
      <div className="d-block d-md-none text-sm px-2 text-center font-semibold uppercase bg-black py-1 d-flex justify-center  flex-1 gap-x-2 items-center">
        Balance : <span className="bg-white text-black rounded-sm px-2 py-0.5  text-nowrap flex gap-x-2 items-center">
          {userBalance ? Number(userBalance).toFixed(2) : storedBalance} <TfiReload onClick={() => handleUserBalance()} className={refrecLoading ? 'animate-spin' : ''} /></span>
      </div>

      {isDrawerOpens.changeDrawer && <ChangePassword isDrawerOpen={isDrawerOpens} setIsDrawerOpen={setIsDrawerOpens} />}
      {isDrawerOpens.profileDrawer && <ChangePassword isDrawerOpen={isDrawerOpens} setIsDrawerOpen={setIsDrawerOpens} />}
    </div >
  );
};

export default Header;








