import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Drawer = ({
  isOpen,
  onClose,
  direction = 'right', // 'left' or 'right'
  size = 'w-64', // Width of the drawer
  children,
  overlay = true, // Show or hide the overlay
}) => {
  const [isRendered, setIsRendered] = useState(false); // Track if drawer is in the DOM
  useEffect(() => {
    if (isOpen) {
      setIsRendered(true); // Render drawer when opening
      // Disable background scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Delay the unmount to allow the closing animation to finish
      const timer = setTimeout(() => setIsRendered(false), 300); // 300ms matches the transition duration
      return () => {
        clearTimeout(timer);
        // Re-enable background scrolling
        document.body.style.overflow = '';
      };
    }

    // Clean up to re-enable background scrolling when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);
  


  // useEffect(() => {
  //   if (isOpen) {
  //     setIsRendered(true); // Render drawer when opening
  //   } else {
  //     // Delay the unmount to allow the closing animation to finish
  //     const timer = setTimeout(() => setIsRendered(false), 300); // 300ms matches the transition duration
  //     return () => clearTimeout(timer);
  //   }
  // }, [isOpen]);

  // Create a portal root for the drawer if not available
  const drawerRoot = document.getElementById('root');
  if (!drawerRoot || (!isOpen && !isRendered)) return null;

  // Determine drawer position and sliding direction
  const directionClasses = direction === 'right' ? 'right-0' : 'left-0';
  const translateClass = direction === 'right' ? 'translate-x-full' : '-translate-x-full';

  // Handle drawer transitions
  const drawerClasses = `fixed top-0 ${directionClasses} h-full bg-[#023840] shadow-lg ${size} transform transition-transform duration-300 ease-in-out z-[1500] ${
    isOpen ? 'translate-x-0' : translateClass
  }`

  return createPortal(
    <>

      {overlay && isOpen && (
        <div
          className="fixed z-[1400] inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out"
          onClick={onClose}
        />
      )}

     
      <div className={drawerClasses}>
        <button
          onClick={onClose}
          className="absolute top-0 right-0 md:-left-8 p-0.5 w-8 text-white text-lg font-extrabold bg-red-600"
          aria-label="Close drawer"
        >
          &times;
        </button>
        <div className="">
          {children}</div>
      </div>
    </>,
    drawerRoot
  );
};

export default Drawer;
