import { useState, useEffect } from "react";

function BlinkingComponent({ price, size, color }) {
  const [bgColor, setBgColor] = useState(color);
  const [prevPrice, setPrevPrice] = useState(null);
  const [prevSize, setPrevSize] = useState(null);

  // Effect to trigger the blink when price or size changes
  useEffect(() => {
    if (prevPrice !== price || prevSize !== size) {
      setBgColor("bg-gray-50"); // Blink to gray
      setTimeout(() => {
        setBgColor(color); // Revert back to original color after 0.3s
      }, 300);
      setPrevPrice(price);
      setPrevSize(size);
    }
  }, [price, size, prevPrice, prevSize]);

  return (
    <div className={`${bgColor}  absolute top-0 left-0 w-100 h-100  flex flex-col justify-center items-center transition-all duration-300`}>
      {price && <div>{price}</div>}
      {size && <div className="text-xs font-medium">{size}</div>}
    </div>
  );
}

export default BlinkingComponent;
