
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import Card from "../../global_layouts/Card/Card";
import Triangle from "../../global_layouts/Triangle/Triangle";
import { gameList } from "../../constents/JsonConstant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RiSettings4Fill } from "react-icons/ri";

import Loader from "../../component/loader/Loader";
import { FaCheck, FaTrophy } from "react-icons/fa";
import { getSportMatchList } from "../../redux/_reducers/_sports_reducers";
import { encrypt } from "../../config/Encryption";
import { reportBlockMarket, reportsUserWiseBlockMarketList } from "../../redux/_reducers/_report_reducers";



const Inplay = () => {
  const [activeSport, setActiveSport] = useState(4)
  const [activeSportsData, setActiveSportData] = useState([])
  const { sportmatchlist, loading } = useSelector(state => state.sport)
  const dispatch = useDispatch()

  useEffect(() => {
    if (sportmatchlist && sportmatchlist?.data && sportmatchlist !== undefined) {
      const targetArray = [4, 3, 2, 1];
      if (JSON.stringify(activeSport) === JSON.stringify(targetArray)) {
        const filteredData = sportmatchlist?.data;
        setActiveSportData(filteredData);

      } else {
        const filteredData = sportmatchlist?.data?.filter((item) => item.sportId === activeSport);
        setActiveSportData(filteredData);
      }
    }
  }, [sportmatchlist, activeSport]);


  useEffect(() => {
    const reqData = {
      sportId: activeSport
    }

    dispatch(getSportMatchList(reqData))
    dispatch(reportsUserWiseBlockMarketList())
  }, [activeSport])

  const handelMarketBlock = async (req) => {
    let reqData = {
      "marketId": req,
      "blockStatus": true
    }
    await dispatch(reportBlockMarket(reqData))

  }


  return (loading ?
    <Loader loaderTrue={true} /> :
    <>
      <GlobalLayout icons={<FaTrophy />} title="Inplay">

        <div className="flex flex-col gap-3">

          <div className="bg-red-600 flex divide-x divide-gray-500 ">

            {
              gameList.map((game, index) => (
                <div
                  key={index}
                  onClick={() => setActiveSport(game.sportId)}
                  className={`text-white ${activeSport === game.sportId ? 'bg-red-700' : 'bg-transparent'} capitalize flex items-center gap-1 relative font-bold px-3 py-2`}
                >
                  {game.icon}
                  <span className={`${activeSport === game.sportId && 'hover:text-black'}`}>{game.sportName}</span>
                  {activeSport === game.sportId &&
                    <Triangle direction="down" size={10} color="#B91C1C" />}
                </div>
              ))
            }


          </div>

          {/*ff <div className="flex flex-col items-start justify-center gap-2 md:flex-row md:items-center md:justify-between space-x-4 p-2">
            <div className="flex gap-3 items-center">
              <input
                type="text"
                placeholder="Search"
                className="border border-gray-300 rounded p-1"
              />
              <select className="border border-gray-300 rounded p-1">
                <option value="25">Rows 25</option>
                <option value="50">Rows 50</option>
                <option value="100">Rows 100</option>
              </select>
            </div>

            <div className="flex items-center">
              <button className="border border-gray-300 px-3 py-1">&lt;</button>
              <span className="border border-gray-700 bg-gray-600 text-white p-1 px-2">1</span>
              <button className="border border-gray-300 px-3 py-1">&gt;</button>
            </div>
          </div> */}
        </div>
        <Card className="overflow-auto">
          <table className="table table-sm table-bordered">
            <thead>
              <tr className="bg-zinc-700 text-white">
                <th>Series Name</th>
                <th>Match Name</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {activeSportsData && activeSportsData.length > 0 ? activeSportsData.map((game, index) => (
                <tr key={index}>
                  <td className="text-nowrap">{game?.seriesName || "-"}</td>
                  <td className="text-nowrap">{game?.matchName || "-"}</td>
                  <td className="text-nowrap">{game?.matchDate || "-"}</td>
                  <td className="flex gap-2">
                    <a href={`viewmatch-details/${encrypt(game.marketId)}/${encrypt(game.eventId)}`} className="">
                      <span className="badge bg-orange-400 w-10 h-7 flex justify-center items-center">
                        <RiSettings4Fill className="text-white" />
                      </span>
                    </a>

                    <span className="badge bg-green-400 w-10 h-7 flex justify-center items-center cursor-pointer" onClick={() => handelMarketBlock(game?.marketId)}>
                      <FaCheck className="text-white" />
                    </span>

                    {/* <span className="badge bg-green-400 w-10 h-7 flex justify-center items-center text-sm text-gray-50 font-bold">B</span>

                    <span className="badge bg-green-400 w-10 h-7 flex justify-center items-center text-sm text-gray-50 font-bold">F</span> */}
                  </td>
                </tr>
              )) : <tr className="text-center">
                <td colSpan={4}> No Data Found </td>
              </tr>}
            </tbody>
          </table>
        </Card>

      </GlobalLayout>



    </>
  );

};

export default Inplay;
