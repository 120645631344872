import "./App.css";
import { Route, Routes, BrowserRouter, redirect, Navigate } from "react-router-dom";
import Layout from "./layout/Layout";
import PrivateRoute from "./routers/privateRoute";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSportMatchList } from "./redux/_reducers/_sports_reducers";
import Login from "./pages/login/Login";

function App() {
  const dispatch = useDispatch();
  useEffect(()=>{
   dispatch(getSportMatchList());
  },[])
  return (
    <div  className="wrapper">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Navigate to="/admin/dashboard" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin/*" element={<PrivateRoute element={<Layout />} />} />
        </Routes>
      </BrowserRouter>
      </div>
  );
}

export default App;
