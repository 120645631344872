import { useDispatch } from "react-redux";
import { domainName } from "../../constents/global";
import Drawer from "../../global_layouts/Drawer/Drawer";
import { userMemberInfo } from "../../global_layouts/UserLayerInfo/UserLayerInfo";
import { userUpdate } from "../../redux/_reducers/_user_reducers";
import { useState } from "react";


const ChangePassword = (props) => {
  const [userInfo, setUserInfo] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const { isDrawerOpen, setIsDrawerOpen } = props
  const isOpen = isDrawerOpen.changeDrawer || isDrawerOpen.profileDrawer;
  const { data } = localStorage.getItem(`user_info_${domainName}`) ? JSON.parse(localStorage.getItem(`user_info_${domainName}`)) : null
  const nextLowerPriorityData = getNextLowerPriorityData(userMemberInfo, data);
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const changePassword = (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = userInfo;
    let validationErrors = { oldPassword: '', newPassword: '', confirmPassword: '' };
    let hasError = false;

    if (!oldPassword) {
      validationErrors.oldPassword = 'Old password is required.';
      hasError = true;
    }
    if (!newPassword) {
      validationErrors.newPassword = 'New password is required.';
      hasError = true;
    }
    if (!confirmPassword) {
      validationErrors.confirmPassword = 'Please confirm new password.';
      hasError = true;
    } else if (newPassword !== confirmPassword) {
      validationErrors.confirmPassword = 'New password and confirm password do not match.';
      hasError = true;
    }

    if (hasError) {
      setErrors(validationErrors);
      return; // Do not submit if there are errors
    }

    let reqData = {
      oldPassword,
      password: newPassword,
      isTransaction: false
    };


    dispatch(userUpdate(reqData));
    setUserInfo({ oldPassword: '', newPassword: '', confirmPassword: '' });
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => setIsDrawerOpen({ changeDrawer: false, profileDrawer: false })}
      direction="right"
      size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
      overlay={true}>
      <div class=" bg-gradient-to-t from-[#27a6c3] to-[#05537e] p-2 border-b-1.5 border-black">

        <div class="flex justify-center uppercase text-sm font-bold items-center w-100  text-white ">
          <div class="info-box-number">My Profile</div>
        </div>
      </div>

      <form className="p-2.5 " onSubmit={changePassword}>
        <div className="grid gap-6 mb-2 md:grid-cols-2">
          <div>
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">user name</label>
            <input defaultValue={data.username} type="text" id="first_name" disabled className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 bg-gray-300" placeholder="John" required />
          </div>
          <div>
            <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">name</label>
            <input defaultValue={data.name} type="text" id="last_name" disabled className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 bg-gray-300" placeholder="Doe" required />
          </div>
        </div>

        <div className="mb-2">
          <label htmlFor="user_count" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">created user count</label>
          <input defaultValue={nextLowerPriorityData?.nextLowerPriorityCount} disabled type="text" id="usercount" className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 bg-gray-300" placeholder="•••••••••" />
        </div>

        <div className="mb-2">
          <label htmlFor="oldPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">Old Password</label>
          <input type="password" id="oldPassword" name="oldPassword" value={userInfo.oldPassword} onChange={handleChange} className="border border-gray-300 text-gray-900 text-sm block w-full p-1.5" placeholder="•••••••••" />
          {errors.oldPassword && <span className="text-red-500 text-sm font-medium">{errors.oldPassword}</span>}
        </div>


        <div className="grid gap-6 mb-2 md:grid-cols-2">
          <div>
            <label htmlFor="newPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">new password</label>
            <input type="text" id="newPassword" name="newPassword" value={userInfo.newPassword} onChange={handleChange} className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 bg-gray-300" placeholder="new password" />
            {errors.newPassword && <span className="text-red-500 text-sm font-medium">{errors.newPassword}</span>}
          </div>
          <div>
            <label htmlFor="confirmPassowrd" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">confirm password</label>
            <input type="text" id="confirmPassowrd" name="confirmPassword" className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 bg-gray-300" placeholder="confirm passowrd" value={userInfo.confirmPassword} onChange={handleChange} />
            {errors.confirmPassword && <span className="text-red-500 text-sm font-medium">{errors.confirmPassword}</span>}
          </div>
        </div>
        <div className="flex items-start mt-3  w-full " >
          <button type="submit" className="text-white uppercase  bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none  font-normal rounded-sm text-md w-100 sm:w-auto px-5 py-1.5 text-center ">update</button>

        </div>
      </form>

    </Drawer>
  );

};

export default ChangePassword;


const getNextLowerPriorityData = (userMemberInfo, data) => {
  if (!data) {
    return null;
  }

  const currentPriority = data.userPriority;

  const userCount = data.userCount?.[0];


  if (!currentPriority || !userCount) {
    return null;
  }

  const nextLowerPriorityInfo = userMemberInfo.find(user => user.priority === currentPriority - 1);


  if (!nextLowerPriorityInfo) {
    return null;
  }

  const nextLowerPriorityCount = userCount[nextLowerPriorityInfo.userType];

  if (nextLowerPriorityCount === undefined) {
    return null;
  }

  return {
    nextLowerPriorityType: nextLowerPriorityInfo.userType,
    nextLowerPriorityCount: nextLowerPriorityCount,
  };
};