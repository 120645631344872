import React, { useEffect, useState } from "react";
import { Button, Card, DatePicker, Select, Table,  Row, Col } from "antd";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getcasinoReportByUser } from "../../../redux/_reducers/_user_reducers";
import Loader from "../../../component/loader/Loader";
import Triangle from "../../../global_layouts/Triangle/Triangle";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import dayjs from "dayjs";

const Basic = () => {
  const dispatch = useDispatch();
  const RangePicker = DatePicker.RangePicker;
  const [userLists, setUserLists] = useState([]);
  const params = useParams();
  let { fromDate, toDate } = params;
  const history = useNavigate();
  const [totalClientProfitLoss, setTotalClientProfitLoss] = useState(0);
  const [totalSelfProfitLoss, setTotalSelfProfitLoss] = useState(0);
  const [totalExposer, setTotalExposer] = useState(0);
  const [dates, setDates] = useState([
    dayjs().endOf('day'),
    dayjs().endOf('day'),
  ]);
  const startDate = dates[0]
  const endDate = dates[1]
  const [matchLedger , setMatchLedger] = useState(null)
  const { loading, casinoReportListbyUser } = useSelector((state) => state.user);

  useEffect(() => {
    getDiamondCasinoReportFun();
  }, [dates]);

  useEffect(() => {
    const response = casinoReportListbyUser;
    if (response) {
      const filteredData = response?.map((item, index) => ({
        key: `${item._id}-${index}`,
        createdAt: item.createdAt,
        clientProfitLoss: item.clientProfitLoss,
        exposure: item.exposure,
        profitLoss: item.profitLoss,
        _id: item._id,
        gameName: item.gameName,
      }));
      setUserLists(filteredData);

      if (response && response?.data?.length > 0) {
        let selfProfitLossArray =
          response && response.data
            ? response.data.filter((items) => items.profitLoss)
            : [];
        let totalSelfProfitLoss = selfProfitLossArray.reduce(
          (acc, item) => acc + item.profitLoss,
          0
        );

        let clientProfitLossArray =
          response && response.data
            ? response.data.filter((items) => items.clientProfitLoss)
            : [];
        let totalClientProfitLoss = clientProfitLossArray.reduce(
          (acc, item) => acc + item.clientProfitLoss,
          0
        );

        let exposerArray =
          response && response.data
            ? response.data.filter((items) => items.exposure)
            : [];
        let totalExposer = exposerArray.reduce(
          (acc, item) => acc + item.exposure,
          0
        );
        setTotalClientProfitLoss(totalClientProfitLoss);
        setTotalSelfProfitLoss(totalSelfProfitLoss);
        setTotalExposer(totalExposer);
      }
    }
  }, [casinoReportListbyUser]);
useEffect(() => {
  if (casinoReportListbyUser ) {
    const casinoReportListbyUserSorted = casinoReportListbyUser 
  ? [...casinoReportListbyUser].sort((a, b) => {
      return new Date(a._id.date) - new Date(b._id.date);
    }) 
  : null;
    
    const groupedData = {};
    
    casinoReportListbyUserSorted?.forEach((item, index) => {
      const dateKey = moment(parseInt(item._id.date)).format("YYYY-MM-DD");

      if (!groupedData[dateKey]) {
        groupedData[dateKey] = [];
      }

      groupedData[dateKey].push({
        key: `${item._id.date}-${index}`,
        marketId: item._id.marketId,
        gameName: item.gameName,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        clientProfitLoss: -1 * Number(item.clientProfitLoss.toFixed(2)),
        exposure: -1 * Number(item.exposure.toFixed(2)),
        profitLoss: -1 * Number(item.profitLoss.toFixed(2)),
        dataKeyPayloadData: dateKey,
      });
    });


    let totalClientProfitLoss = 0;
    let totalExposure = 0;
    let totalProfitLoss = 0;

    Object.keys(groupedData).forEach((dateKey) => {
      groupedData[dateKey].forEach((entry) => {
        totalClientProfitLoss += entry.clientProfitLoss;
        totalExposure += entry.exposure;
        totalProfitLoss += entry.profitLoss;
      });
    });

    const finalTotal = {
      gameName: "Total",
      date: "",
      clientProfitLoss: totalClientProfitLoss,
      exposure:totalExposure ,
      profitLoss: totalProfitLoss,
      type: "another",
    };


    const transformData = (data) => {
      const result = [finalTotal];
 
      for (const date in data) {
        const entries = data[date];
        const totals = entries.reduce(
          (acc, entry) => {
            acc.clientProfitLoss += entry.clientProfitLoss;
            acc.exposure += entry.exposure;
            acc.profitLoss += entry.profitLoss;
            return acc;
          },
          { clientProfitLoss: 0, exposure: 0, profitLoss: 0 }
          
        );
       

        result.push({
          gameName: moment(date).format("DD-MM-YYYY"),
          date: date,
          clientProfitLoss: 
            Number(totals.clientProfitLoss).toFixed(2)
          ,
          exposure: 
            Number(totals.exposure).toFixed(2)
          ,
          profitLoss: Number(totals.profitLoss).toFixed(2),
          type: "dateTotal",
        });
      

        entries.forEach((entry, index) => {
          result.push({
            index: index + 1, 
            gameName: entry.gameName,
            date: moment(entry.dataKeyPayloadData),
            clientProfitLoss: Number(entry.clientProfitLoss).toFixed(2),
            exposure: Number(entry.exposure).toFixed(2),
            profitLoss: Number(entry.profitLoss).toFixed(2),
            type: "dayWise",
            marketId:entry.marketId,
            key: entry.key,
            datacheck: entry.dataKeyPayloadData
          });
        });
      }

      return result;
      
    };
    const transformedData = transformData(groupedData);

    setMatchLedger(transformedData);
  }
}, [casinoReportListbyUser]);



  const getDiamondCasinoReportFun = async () => {
    let reqData = {
      toDate: endDate.format('YYYY-MM-DD'),
      fromDate: startDate.format('YYYY-MM-DD'),
    
      "gameType": "internationalCasino"
    };
    dispatch(getcasinoReportByUser(reqData));
  };

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    return obj;
  };

  const columns = [
    {
      key: "eventName",
      title: "Event Name",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color: "white",
          paddingInline:'10px',
          paddingBlock:'8px'
        },
      }),
      // dataIndex: "gameName",
      render: (record) => (
        <span className="text-blue-500">
{record.gameName} {(record.date && record.type === "dayWise") ? `(${moment(record.date).format("DD-MM-YYYY")})` : ""}
        </span>
      ),
    },
    {
      title: "Date & Time",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color: "white",
          paddingInline:'10px',
          paddingBlock:'8px'
        },
      }),
      // dataIndex: "gameName",
      render: (record) => (
        <span className="">
 {(record.date && record.type === "dateTotal") ? `${moment(record.date).format("DD-MM-YYYY")}` : ""}
 {(record.date && record.type === "dayWise") ? `${moment(record.date).utcOffset("+05:30").format("DD MMM hh:mm A")}` : ""}
        </span>
      ),
    },
    {
      title: "Exposer",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color: "white",
          paddingInline:'10px',
          paddingBlock:'8px'
        },
      }),
      dataIndex: "exposure",
      render: (exposure) => (
        <span>
          {Number.parseFloat(Math.abs(exposure)).toFixed(2)}
        </span>
      ),
    },
    {
      title: "P/L",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color: "white",
          paddingInline:'10px',
          paddingBlock:'8px'
        },
      }),
      dataIndex: "profitLoss",
      render: (profitLoss) => (
        <span className={`${profitLoss > 0 ? "text-green-600" : "text-red-600"}`}>
          {Number.parseFloat(Math.abs(profitLoss)).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Action",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color: "white",
          paddingInline:'10px',
          paddingBlock:'8px'
        },
      }),
      // dataIndex: "_id",
      render: (record) => (
        // <Link
        //   // to={`/components/navigation/internetionalcasino-bet/${_id}?`}
        //   to={`/components/navigation/internetionalcasino-bet/${_id}/${fromDate}&${toDate}`}

        //   className="link-button"
        // >          <Tag bordered={false} style={{ borderRadius: '0' }} color="#108ee9">Show Bets</Tag>
        // </Link>
       record?.type === "dayWise" &&  
        <Link
          to={`/admin/internetionalcasino-bet/${record?.marketId}/${record?.datacheck}/${record?.datacheck}`}
        >
          <Button className="px-4 py-1 leading-0 bg-orange-400 !border-0 text-white hover:!bg-orange-400 font-bold">
         Show Bets
         </ Button >
        </Link>
       
      ),
    },
  ];
  function onChange(dates, dateStrings) {

    // Update the URL parameters with the new date range

    setDates(dates);
  }


  const handleDateChange = (date, dateString, type) => {
    const newDates = [...dates];
    newDates[type === 'start' ? 0 : 1] = date; // Update either start or end date
    history(`/admin/internetionalcasinodeatils/${newDates[1].format('YYYY-MM-DD')}/${newDates[0].format('YYYY-MM-DD')}`);
    setDates(newDates);
    onChange(newDates); // Pass new date range back to parent
  };

  async function getCompleteCasinoList() {
    let reqData = {
      toDate: endDate.format('YYYY-MM-DD'),
      fromDate: startDate.format('YYYY-MM-DD'),

      "gameType": "internationalCasino"
    };
    dispatch(getcasinoReportByUser(reqData));
  }

  // async function todayCompleteCasinoList() {
  //   let reqData = {
  //     toDate: moment().format('YYYY-MM-DD'),
  //     fromDate: moment().format('YYYY-MM-DD'),
  //     "gameType": "internationalCasino"
  //     // pageNo: 1,
  //     // size: 20,
  //   };
  //   dispatch(getcasinoReportByUser(reqData));
  // }
  async function todayCompleteCasinoList() {
    let fromDate = moment().format('YYYY-MM-DD');
    let toDate = moment().format('YYYY-MM-DD');

    let reqData = {
      fromDate,
      toDate,
      gameType: "internationalCasino"
      // pageNo: 1,
      // size: 20,
    };
    dispatch(getcasinoReportByUser(reqData));
    history(`/admin/internetionalcasinodeatils/${fromDate}/${toDate}`);
  setDates([
    dayjs().endOf('day'),
    dayjs().endOf('day'),
  ])
  }


  const { Option } = Select;
  return (
    <>
      {loading ? <Loader loaderTrue={true} /> :
       <GlobalLayout>
         <Card className="gx-card ">
          <div className="bg-red-600 flex justify-between items-center mb-3">
              <div className="text-white bg-red-700 relative text-xs sm:!text-base font-bold px-2 sm:px-4 py-2">
              International Casino Details
                <Triangle direction="down" size={10} color="#B91C1C" />
              </div>
              <button
                onClick={() => window.location.reload()}
                className="bg-green-500 ms-2 text-xs sm:!text-base text-white py-1 mr-1 px-3 rounded"
              >
                Refresh
              </button>
            </div>
         
       
          <Row gutter={20} className="flex justify-start items-center gap-3 pt-1 my-3 mx-1">
            <div className="flex gap-3 flex-wrap">
              <DatePicker
              size="large"
              value={dates[0]}
              onChange={(date) => handleDateChange(date, '', 'start')}
              style={{ width: 250 }}
              />
              <DatePicker
                size="large"
                value={dates[1]}
                onChange={(date) => handleDateChange(date, '', 'end')}
                style={{ width: 250 }}
              />
            </div>
            <Col className="px-0">
              <Button className="sm:h-[40px] h-[30px] leading-0 bg-blue-400 !border-0 text-white hover:!bg-blue-400 font-bold" onClick={todayCompleteCasinoList}>Today P/L</Button>
            </Col>
          </Row>
          <div>
            <Table
              className="table-responsive"
              columns={columns}
              dataSource={matchLedger}
              bordered
              pagination={false}
              size="medium"
    
              rowClassName={(record, index) =>
                index % 2 === 0 ? "bg-gray-100 " : ""
              }

            />
          </div>
        </Card>
       </GlobalLayout>
      }
    </>
  );
};

export default Basic;
