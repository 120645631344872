import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import moment from "moment";
// import BackButton from "../../Hoc/BackButton";
import { useDispatch, useSelector } from "react-redux";
// import Loader from "../../../../components/loader";
// import { getMatkaBetList } from "../../../../appRedux/actions/User";
import { useParams } from "react-router-dom";
import Loader from "../../../component/loader/Loader";
import { getMatkaBetList } from "../../../redux/_reducers/_user_reducers";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import Triangle from "../../../global_layouts/Triangle/Triangle";

const MatkaView = () => {
  const [matkaBetList, setMatkaBetList] = useState([]);

  const dispatch = useDispatch();
  const { eventIdMatka } = useParams();

  const { loading, matkaBetListReport } = useSelector((state) => state.user);

  useEffect(() => {
    let reqData = {
      matkaEventId: eventIdMatka,
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    };
    dispatch(getMatkaBetList(reqData));
  }, [dispatch]);

  useEffect(() => {
    if (matkaBetListReport) {
      // const sortedSessions = matkaBetListReport.sort((a, b) => a.priority - b.priority);
      const filteredData = matkaBetListReport.map((item, index) => ({
        key: `${index}`,
        isDeclare: item.isDeclare,
        isDeleted: item.isDeleted,
        ip: item.ip,
        matkaName: item.matkaName,
        createdAt: item.createdAt,
        amount: item.amount,
        loss: item.loss,
        profit: item.profit,
        gameType: item.gameType,
        betNumber: item.betNumber,
        // betStatus: item.betStatus ? 'Active' : 'InActive',
        betType: item.betType,
        data: item.date,
        profitLoss: item.profitLoss,
        result: item.result,
        matkaEventId: item.matkaEventId,
        oddEvenResult: item.oddEvenResult,
        userInfo: item?.userInfo,
        name: item?.userInfo?.name,
        username: item?.userInfo?.username,
      }));
      setMatkaBetList(filteredData);
    }
  }, [matkaBetListReport]);

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };

    return obj;
  };

  const columns = [
    {
      title: "Name",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "name",
      render: renderContent
    },
    {
      title: "Matka Name",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "matkaName",
      render: renderContent,
    },

    {
      title: "Bet Type",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "betType",
      render: renderContent,
    },

    {
      title: "Game Type",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "gameType",
      render: renderContent,
    },
    {
      title: "Amount",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "amount",
      render: renderContent,
    },

    {
      title: "Bet Number",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "betNumber",
      render: renderContent,
    },
    {
      title: "Date",
      onHeaderCell: (column) => ({
        style: {
            backgroundColor: '#343a40', paddingInline: '10px', paddingBlock: '8px', fontWeight: 'bold', textTransform:'uppercase', fontSize: '12px', color: 'white'
        },
        }),
      dataIndex: "createdAt",
      render: (row) => (
        <span>{moment(row?.createdAt).format("DD-MM-YYYY hh:mm A")}</span>
      ),
    },
    // {
    //     title: 'Action',
    //     dataIndex: 'action',
    //     render: (text, record) => (
    //         <div className="gx-bg-flex gx-justify-content-end">
    //             <Button type="primary">

    //                 <Link to={`/components/casino/casinoinplayview/${record.id}/${record.name}`}>
    //                     View
    //                 </Link>
    //             </Button>

    //         </div>
    //     ),
    // },
  ];

  return (
    <>
      {loading ? (
        <Loader loaderTrue={true} />
      ) : (
        <GlobalLayout>
          <Card className="gx-card">
            {/* <div className="gx-bg-grey gx-px-5 gx-pt-2 gx-bg-flex gx-align-items-center">
              <span className="gx-fs-2xl gx-font-weight-normal gx-text-white gx-align-items-center gx-text-capitalize">{`Matka Bet List`}</span>
            </div> */}
            <div className="bg-red-600 flex justify-between items-center mb-4">
              <div className="text-white bg-red-700 relative font-bold px-4 py-2">
                {" "}
                {`Matka Bet List`}
                <Triangle direction="down" size={10} color="#B91C1C" />
              </div>
              <button
                onClick={() => window.location.reload()}
                className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
              >
                {" "}
                Refresh
              </button>
            </div>
            <div>
              <Table
                className="gx-table-responsive uppercase whitespace-nowrap"
                columns={columns}
                dataSource={matkaBetList}
                bordered
                pagination={false}
                size="small"
                scroll={{x: true}}
              />
            </div>
          </Card>
        </GlobalLayout>
      )}
    </>
  );
};

export default MatkaView;
