import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Select,
  Table,
  Tag,
  Row,
  Col,
  Typography
} from "antd";

import moment from "moment";
import { Link } from "react-router-dom";
// import BackButton from "../../Hoc/BackButton";
// import { getDiamondCasinoReport } from "../../../../appRedux/actions/User";
import { useDispatch, useSelector } from "react-redux";
import { getDiamondCasinoReport } from "../../../redux/_reducers/_user_reducers";
import Loader from "../../../component/loader/Loader";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import Triangle from "../../../global_layouts/Triangle/Triangle";
import dayjs from "dayjs";
// import Loader from "../../../../components/loader";

const NavigationTop = () => {
  const dispatch = useDispatch();
  const RangePicker = DatePicker.RangePicker;
  const [userLists, setUserLists] = useState([]);
  const [totalClientProfitLoss, setTotalClientProfitLoss] = useState(0);
  const [totalSelfProfitLoss, setTotalSelfProfitLoss] = useState(0);
  const [totalExposer, setTotalExposer] = useState(0);
  const [dates, setDates] = useState([
    dayjs().subtract(1, 'month').startOf('day'),
    dayjs().endOf('day'),
  ]);
  const startDate = dates[0]
  const endDate = dates[1]

  const { loading, diamondCasinoReport } = useSelector((state) => state.user);
  const { Text } = Typography;

  useEffect(() => {
    getDiamondCasinoReportFun();
  }, []);

  useEffect(() => {
    const response = diamondCasinoReport;
    if (response) {
      const filteredData = response?.map((item, index) => ({
        key: `${item._id}-${index}`,
        createdAt: item.createdAt,
        clientProfitLoss: item.clientProfitLoss,
        exposure: item.exposure,
        profitLoss: item.profitLoss,
        _id: item._id,
        gameName: item.gameName,
      }));
      setUserLists(filteredData);

      if (response && response?.length > 0) {
        let selfProfitLossArray =
          response && response.data
            ? response.data.filter((items) => items.profitLoss)
            : [];
        let totalSelfProfitLoss = selfProfitLossArray.reduce(
          (acc, item) => acc + item.profitLoss,
          0
        );

        let clientProfitLossArray =
          response && response.data
            ? response.data.filter((items) => items.clientProfitLoss)
            : [];
        let totalClientProfitLoss = clientProfitLossArray.reduce(
          (acc, item) => acc + item.clientProfitLoss,
          0
        );

        let exposerArray =
          response && response.data
            ? response.data.filter((items) => items.exposure)
            : [];
        let totalExposer = exposerArray.reduce(
          (acc, item) => acc + item.exposure,
          0
        );
        setTotalClientProfitLoss(totalClientProfitLoss);
        setTotalSelfProfitLoss(totalSelfProfitLoss);
        setTotalExposer(totalExposer);
      }
    }
  }, [diamondCasinoReport]);

  const getDiamondCasinoReportFun = async () => {
    let reqData = {
      toDate: endDate.format('YYYY-MM-DD'),
      fromDate: startDate.format('YYYY-MM-DD'),
      pageNo: 1,
      size: 20,
    };
    dispatch(getDiamondCasinoReport(reqData));
  };

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };
    return obj;
  };

  const columns = [
    {
      title: "Game Id",
      dataIndex: "_id",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color:"white",
        },
      }),
    },
    {
      title: "Type",
      dataIndex: "gameName",
      render: renderContent,
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color:"white",
        },
      }),
    },
    {
      title: "Exposer",
      dataIndex: "exposure",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color:"white",
        },
      }),
      render: (exposure) => (
        <span>
          {Number.parseFloat(Math.abs(exposure)).toFixed(2)}
        </span>
      ),
    },
    {
      title: "P/L",
      dataIndex: "profitLoss",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color:"white",
        },
      }),
      render: (profitLoss) => (
        <span>
          {Number.parseFloat(Math.abs(profitLoss)).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Client P/L",
      dataIndex: "clientProfitLoss",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color:"white",
        },
      }),
      render: (value) => (
        <span className={`${value > 0 ? "gx-text-green-0" : "gx-text-red"}`}>
          {Number.parseFloat(Math.abs(value)).toFixed(2)}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      onHeaderCell: (column) => ({
        style: {
          background: "#343a40",
          fontSize:'12px',
          fontWeight:'bold',
          color:"white",
        },
      }),
      render: (_id) => (
        <Link
          to={`/admin/Demo/${_id}?`}
          className="link-button"
        >
           <Button className="px-3 py-1 leading-0 bg-blue-400 !border-0 text-white hover:!bg-blue-400 font-bold" >Show Bets</Button>
        </Link>
      ),
    },
  ];



  // function onChange(dates, dateStrings) {
  //   setShowDate({
  //     startDate: dates[0],
  //     endDate: dates[1],
  //   });
  // }

  async function getCompleteCasinoList() {
    let reqData = {
      toDate: endDate.format('YYYY-MM-DD'),
      fromDate: startDate.format('YYYY-MM-DD'),
      pageNo: 1,
      size: 20,
    };
    dispatch(getDiamondCasinoReport(reqData));
  }

  async function todayCompleteCasinoList() {
    setDates(
      [
        dayjs().endOf('day'),
        dayjs().endOf('day'),
      ]
    )
    let reqData = {
      toDate: dayjs().endOf('day'),
      fromDate: dayjs().endOf('day'),
      pageNo: 1,
      size: 20,
    };
    dispatch(getDiamondCasinoReport(reqData));
  }

  const { Option } = Select;


  const onChange = (dates) => {
    try {
      setDates(dates);
    } catch (error) {
      console.error("Error in onChange:", error);
    }
  };
  const handleDateChange = (date, dateString, type) => {
    const newDates = [...dates];
    newDates[type === 'start' ? 0 : 1] = date; // Update either start or end date

    setDates(newDates);
    onChange(newDates); // Pass new date range back to parent
  };



  return (
    <>
      {loading ? <Loader loaderTrue={true} /> :
      <GlobalLayout>
          <Card className="gx-card gx-w-100">
          
          <div className="bg-red-600 flex justify-between items-center mb-3">
              <div className="text-white bg-red-700 relative text-xs sm:!text-base font-bold px-2 sm:px-4 py-2">
              Diamond Casino Details
                <Triangle direction="down" size={10} color="#B91C1C" />
              </div>
              <button
                onClick={() => window.location.reload()}
                className="bg-green-500 ms-2 text-xs sm:!text-base text-white py-1 mr-1 px-3 rounded"
              >
                Refresh
              </button>
            </div>

          <Row gutter={20} className="flex gap-3 justify-start items-center px-2 my-4">
            <div className="flex gap-3 flex-wrap">
              <DatePicker
                size="large"
                value={dates[0]}
                onChange={(date) => handleDateChange(date, '', 'start')}
                style={{ width: 250 }}
              />
              <DatePicker
                size="large"
                value={dates[1]}
                onChange={(date) => handleDateChange(date, '', 'end')}
                style={{ width: 250 }}
              />
            </div>

            <Button className="sm:h-[40px] h-[30px] leading-0 bg-blue-400 !border-0 text-white hover:!bg-blue-400 font-bold" onClick={getCompleteCasinoList}>Apply</Button>
            <Button className="sm:h-[40px] h-[30px] leading-0 bg-green-400 !border-0 text-white hover:!bg-green-400 font-bold" onClick={todayCompleteCasinoList}>Today P/L</Button>
      
              {/* <Col className="flex px-0 gap-3 items-center justify-center">
              </Col> */}
          </Row>

          <div>
            <Table
              className="max-w-screen overflow-auto"
              columns={columns}
              dataSource={userLists}
              // dataSource={[{}, ...userLists]}
              bordered
              pagination={false}
              size="small"

            />
          </div>
        </Card>
      </GlobalLayout>
      }
    </>
  );
};

export default NavigationTop;
