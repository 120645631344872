import { BiSolidCricketBall, BiSolidTennisBall } from "react-icons/bi";
import { IoFootballSharp } from "react-icons/io5";
import { MdOutlineSportsScore } from "react-icons/md";
import { GrSelection } from "react-icons/gr";


export const gameList = [
    // {
    //     sportId:[4,3,2,1],
    //     icon:<MdOutlineSportsScore  className="text-white "  />,
    //     sportName:"All"
    // },
    {
        sportId:4,
        icon:<BiSolidCricketBall className="text-white "  />,
        sportName:"cricket"
    },
    {
        sportId:2,
        icon:<BiSolidTennisBall className="text-white "  />,
        sportName:"Tennis"
    },
    {
        sportId:1,
        icon:<IoFootballSharp className="text-white " />,
        sportName:"Soccer"
    },
    {
        sportId:999,
        icon:<GrSelection className="text-white " />,
        sportName:"Election"
    },
]

export const marketList = [
    {
        name:'market',
        icon:<BiSolidCricketBall className="text-white "  />,
        sportName:"Market Bets"
    },
    {
        name:'fancy',
        icon:<BiSolidTennisBall className="text-white "  />,
        sportName:"Fancy Bets",

    }
]