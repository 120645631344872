

const Roles = () => {
    return (
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Roles</h1>
                </div>
      
  
              </div>
            </div>
          </div>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  Welcome To Roles 
                </div>
              </div>
              
            </div>
          </section>
        </div>
      </div>
    );
  };
  
  export default Roles;
  