
import { TbLayoutDashboardFilled } from "react-icons/tb";
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import Card from "../../global_layouts/Card/Card";
import Triangle from "../../global_layouts/Triangle/Triangle";
import { gameList } from "../../constents/JsonConstant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import Loader from "../../component/loader/Loader";
import { encrypt } from "../../config/Encryption";
import { IoPlay } from "react-icons/io5";
import { getCompletedSportList, getSportMatchList } from "../../redux/_reducers/_sports_reducers";
import { useLocation, useNavigate } from "react-router-dom";




const FinisedManageMarket = () => {
  const [activeSport, setActiveSport] = useState(4)
  const [activeSportsData, setActiveSportData] = useState([])
  const [activeMarket, setActiveMarket] = useState(1)
  const { sportmatchlist, loading, getCompletedSportListData } = useSelector(state => state.sport)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/admin/manage-market") {
      setActiveMarket(1); // Active Market
    } else if (location.pathname === "/admin/complete-manage-market") {
      setActiveMarket(2); // Finished Market
    }
  }, [location.pathname]);

  useEffect(() => {
    if ((sportmatchlist && sportmatchlist?.data && sportmatchlist !== undefined) || (getCompletedSportListData && getCompletedSportListData?.data && getCompletedSportListData !== undefined)) {
      if (activeMarket === 1) {

        const filteredData = sportmatchlist?.data?.filter((item) => item.sportId === activeSport)
        setActiveSportData(filteredData)
      }
      if (activeMarket === 2) {

        const filteredData = getCompletedSportListData?.matchData ? Object.values(getCompletedSportListData?.matchData)?.filter((item) => item.sportId === activeSport) : []
        setActiveSportData(filteredData)
      }
    }
  }, [sportmatchlist, activeSport, getCompletedSportListData, activeMarket])
  useEffect(() => {
    if (activeMarket === 1) {
      dispatch(getSportMatchList())
    } else if (activeMarket === 2) {
      const reqData = {

        "sportId": [4, 3,2,1],
        "isAccountDetails": true,
        "pageNo": 1,
        "size": 20

      }
      dispatch(getCompletedSportList(reqData))
    }
  }, [activeMarket])




  return (loading ?
    <Loader loaderTrue={true} /> :
    <>
      <GlobalLayout icons={<TbLayoutDashboardFilled />} title="Sports">
        <div className="flex flex-col gap-3">
          <div className="bg-red-600 flex justify-between items-center">
            <div className="text-white bg-red-700 relative font-bold px-4 py-2"> Betfair<Triangle direction="down" size={10} color="#B91C1C" /></div>
            <button className="bg-green-500 text-white py-1 mr-1 px-3 rounded" > Refresh</button>
          </div>

          <div className="bg-red-600 flex divide-x divide-gray-500">
            {gameList.map((game, index) => (
              <div key={index} onClick={() => setActiveSport(game.sportId)}
                className={`text-white ${activeSport === game.sportId ? 'bg-red-700' : 'bg-transparent'} capitalize flex items-center gap-1 relative font-bold px-3 py-2`}
              >
                {game.icon}
                <span className={`${activeSport === game.sportId && 'hover:text-black'}`}>{game.sportName}</span>
                {activeSport === game.sportId &&
                  <Triangle direction="down" size={10} color="#B91C1C" />}
              </div>
            ))
            }
          </div>
          <div className="bg-red-600 flex divide-x divide-gray-500 items-center">
          <div
            onClick={() => navigate("/admin/manage-market")}
            className={`text-white ${
              activeMarket === 1 || location.pathname === "/admin/manage-market"
                ? "bg-red-700"
                : "bg-transparent"
            } relative font-bold px-4 py-2`}
          >
            Active Market
            {(activeMarket === 1 || location.pathname === "/admin/manage-market") && (
              <Triangle direction="down" size={10} color="#B91C1C" />
            )}
          </div>
          <div
            onClick={() => navigate("/admin/complete-manage-market")}
            className={`text-white ${
              activeMarket === 2 || location.pathname === "/admin/complete-manage-market"
                ? "bg-red-700"
                : "bg-transparent"
            } relative font-bold px-4 py-2`}
          >
            Finished Market
            {(activeMarket === 2 || location.pathname === "/admin/complete-manage-market") && (
              <Triangle direction="down" size={10} color="#B91C1C" />
            )}
          </div>
            {/* <div onClick={() => navigate('/admin/manage-market')} className={`text-white ${activeMarket === 1 ? "bg-red-700" : "bg-transparent"} relative font-bold px-4 py-2`}>Active Market {activeMarket === 1 && <Triangle direction="down" size={10} color="#B91C1C" />}</div> */}
            {/* <div onClick={() => navigate('/admin/complete-manage-market')} className={`text-white ${activeMarket === 2 ? "bg-red-700" : "bg-transparent"}  relative font-bold px-4 py-2`}>Finished Market{activeMarket === 2 && <Triangle direction="down" size={10} color="#B91C1C" />}</div> */}

          </div>


        </div>
        <Card className="overflow-auto">
          <table className="table table-sm table-bordered">
            <thead>
              <tr className="bg-zinc-700 text-white">
                <th className="text-nowrap">Series Name</th>
                <th className="text-nowrap">Match Name</th>
                <th className="text-nowrap">Result</th>
                <th className="text-nowrap">P/L</th>
                <th className="text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {activeSportsData && activeSportsData.length > 0 ? activeSportsData.map((game, index) => (
                <tr key={index}>
                  <td className="text-nowrap">{activeMarket === 1 ? (game?.seriesName || "-") : (game?.matchName || "-")}</td>
                  <td className="text-nowrap">
                    <div className="flex items-center gap-2 cursor-pointer">
                      {
                        game?.status === 'INPLAY' ? <div className="text-white text-[12px] bg-green-500 w-[20px] h-[20px] rounded-sm flex items-center justify-center">A</div> : null
                      }
                      {game?.matchName || "-"}
                      {
                        game?.status === 'INPLAY' ? <span className="bg-[#43d17f] rounded-full w-[18px] h-[18px] flex items-center justify-center"><IoPlay className="text-white w-[10px] h-[10px]" /></span> : null
                      }
                    </div>
                  </td>
                  <td className="text-nowrap">{activeMarket === 1 ? "" : game?.wonTeamName ? game?.wonTeamName : "-"}</td>
                  <td className={`text-nowrap ${game?.totalProfit > 0 ? 'text-green-600' : game?.totalProfit < 0 ? 'text-red-600' : 'text-black'}`}>
                    {game?.totalProfit != null ? (Math.floor(game.totalProfit * 100) / 100).toFixed(2) : '0.00'}
                  </td>


                  <td className="flex gap-2 uppercase">
                    <span onClick={() => navigate(`/admin/matches-bet-history/${encrypt(game?.marketId)}`)} className=" bg-green-400 text-sm text-white font-bold cursor-pointer rounded-sm px-2 py-0.5  flex justify-center items-center">
                      Bet
                    </span>
                    {/* <span className=" bg-green-400 text-sm text-white font-bold cursor-pointer rounded-sm px-2 py-0.5  flex justify-center items-center">
                      Position
                    </span> */}
                  </td>
                </tr>
              )) : <tr className="text-center">
                <td colSpan={4}>No Data Found</td>
              </tr>}
            </tbody>
          </table>
        </Card>
      </GlobalLayout>



    </>
  );

};

export default FinisedManageMarket;