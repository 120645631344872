// NotificationContext.js
import React, { createContext, useContext, useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import SnackbarContent from '@mui/material/SnackbarContent';
import { RxCross1 } from "react-icons/rx";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const notify = (message, severity = 'success') => {
    setNotification({ open: true, message, severity });
  };

  const handleClose = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <NotificationContext.Provider value={notify}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={notification.open}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            backgroundColor:
              notification.severity === 'success' ? 'green' : 'red',
          }}
          message={notification.message}
          action={
            <Button color="inherit" onClick={handleClose}>
             <RxCross1  />
            </Button>
          }
        />
      </Snackbar>
    </NotificationContext.Provider>
  );
};
