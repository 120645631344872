
import { combineReducers } from '@reduxjs/toolkit';

import authenticationReducer from './_reducers/_auth_reducers';
import userReducer from './_reducers/_user_reducers';
import sportReducer from './_reducers/_sports_reducers';
import reportReducer from './_reducers/_report_reducers';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  sport: sportReducer,
  report: reportReducer,
});

export default rootReducer;
