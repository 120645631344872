import React, { useEffect, useState } from 'react'
import GlobalLayout from '../../global_layouts/GlobalLayout/GlobalLayout'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getSportMatchBetList } from "../../redux/_reducers/_sports_reducers"
import { useParams } from 'react-router-dom'
import { decrypt } from '../../config/Encryption'
import Loader from '../../component/loader/Loader'
import { marketList } from '../../constents/JsonConstant'
import Triangle from '../../global_layouts/Triangle/Triangle'

const MatchHistoryBet = () => {
    const [activeSport, setActiveSport] = useState('market')
    const [sportBetListData, setSportBetListData] = useState({
        oddsBets: [],
        fancyBets: []
    })

    useEffect(() => {
        fetchBetList()
    }, [])

    const dispatch = useDispatch()
    const { userNameEnc } = useParams()
    const marketId = decrypt(userNameEnc);

    const { sportBetList, loading } = useSelector(state => state.sport);

    useEffect(() => {
        if (sportBetList) {
            setSportBetListData({
                oddsBets: sportBetList?.oddsBetData,
                fancyBets: sportBetList?.fancyBetData
            })
        }
       
    }, [sportBetList]);

    const fetchBetList = () => {
        let reqData = {
            oddsBet: true,
            marketId: marketId,
        }
        dispatch(getSportMatchBetList(reqData));
    };



    const handleSportClick = (name) => {
        setActiveSport(name);
        let reqData = {
            marketId: marketId,
        }
        if (name === 'market') {
            reqData.oddsBet = true
        }
        if (name === 'fancy') {
            reqData.fancyBet = true
        }
        dispatch(getSportMatchBetList(reqData));
    };

    return (loading ? <Loader loaderTrue={loading} /> :
        <GlobalLayout >
            <div className="bg-red-600 flex divide-x my-3 divide-gray-500">
                {marketList.map((match, index) => (
                    <div key={index}
                        onClick={() => handleSportClick(match.name)}
                        className={`text-white
                                ${activeSport === match.name ? 'bg-red-700' : 'bg-transparent'} 
                                capitalize flex items-center gap-1 relative font-bold px-3 py-2`}
                    >
                        {match.icon}
                        <span className={`${activeSport === match.name && 'hover:text-black'}`}>{match.sportName}</span>
                        {activeSport === match.name &&
                            <Triangle direction="down" size={10} color="#B91C1C" />}
                    </div>
                ))
                }
            </div>
            <Card className="overflow-auto">
                <table class="min-w-full bg-white border border-gray-300">
                {sportBetListData && (activeSport == 'market' ? sportBetListData?.oddsBets?.length : sportBetListData?.fancyBets?.length ) > 0 ? (
                    <thead class="bg-gray-100">
                        <tr className="text-uppercase">
                            <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">User</th>
                            <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">Market</th>
                            <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">Odds</th>
                            <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">Stack</th>
                            <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">P&L</th>
                            <th class="px-2 py-2 border border-gray-300 bg-zinc-800 text-xs text-white font-normal">IP Address</th>
                        </tr>
                    </thead> ) : null}
                    
                    <tbody>
                        {sportBetListData && (activeSport == 'market' ? sportBetListData?.oddsBets?.length : sportBetListData?.fancyBets?.length ) > 0 ? (
                          
                           (activeSport == 'market' ? sportBetListData?.oddsBets : sportBetListData?.fancyBets).map((bet) => (
                                <tr key={bet._id} className="hover:bg-gray-100">
                                    <td className="border border-gray-300 px-4 py-2">{bet?.userInfo?.clientName}</td>
                                    <td className="border border-gray-300 px-4 py-2">{bet?.marketId}</td>
                                    <td className="border border-gray-300 px-4 py-2">{((bet?.odds)*100).toFixed(0)}</td>
                                    <td className="border border-gray-300 px-4 py-2">{((bet?.odds)*100).toFixed(0)}</td>
                                    <td className="border border-gray-300 px-4 py-2">{(bet?.profit).toFixed(2)}</td>
                                    <td className="border border-gray-300 px-4 py-2">{bet?.ip}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={6} className="border border-gray-300 text-center">
                                    No Fancy Bet List
                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </Card>
        </GlobalLayout>
    )
}

export default MatchHistoryBet
