
import GlobalLayout from "../../global_layouts/GlobalLayout/GlobalLayout";
import Card from "../../global_layouts/Card/Card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompletedSportList } from "../../redux/_reducers/_sports_reducers";
import { gameList } from "../../constents/JsonConstant";
import Triangle from "../../global_layouts/Triangle/Triangle";
import { encrypt } from "../../config/Encryption";


const MatchesHistroy = () => {
    const [sportData, setSportData] = useState([])
    const [activeSport, setActiveSport] = useState(4)
    const dispatch = useDispatch()

    const { getCompletedSportListData, loading } = useSelector(state => state.sport);

    useEffect(() => {
        getMatchesHistory(activeSport)
    }, [])

    useEffect(() => {
        if (getCompletedSportListData) {
            setSportData(getCompletedSportListData);
        }
    }, [getCompletedSportListData]);

    const getMatchesHistory = (sportId) => {
        let reqData = {
            "sportId": sportId,
            "isAccountDetails": true,
            "pageNo": 1,
            "size": 20
        }
        dispatch(getCompletedSportList(reqData))
    };

    const handleSportClick = (sportId) => {
        setActiveSport(sportId);
        getMatchesHistory(sportId); 
    };

    return (
        <GlobalLayout >
            <div className="flex flex-col gap-3">
                <div className="bg-red-600 flex justify-between items-center">
                    <div className="text-white bg-red-700 relative font-bold px-4 py-2"> Betfair<Triangle direction="down" size={10} color="#B91C1C" /></div>
                </div>

                <div className="bg-red-600 flex divide-x divide-gray-500">
                    {gameList.map((game, index) => (
                        <div key={index} onClick={() => handleSportClick(game.sportId)}
                            className={`text-white ${activeSport === game.sportId ? 'bg-red-700' : 'bg-transparent'} capitalize flex items-center gap-1 relative font-bold px-3 py-2`}
                        >
                            {game.icon}
                            <span className={`${activeSport === game.sportId && 'hover:text-black'}`}>{game.sportName}</span>
                            {activeSport === game.sportId &&
                                <Triangle direction="down" size={10} color="#B91C1C" />}
                        </div>
                    ))
                    }
                </div>
            </div>
            <Card className="overflow-auto">
                <table class="min-w-full bg-white table-bordered">
                    <thead class="bg-gray-100">
                        <tr className="text-uppercase">
                            <th class="px-2 py-2 bg-zinc-800 text-xs text-white font-normal">Series Name</th>
                            <th class="px-2 py-2 bg-zinc-800 text-xs text-white font-normal">Results</th>
                            <th class="px-2 py-2 bg-zinc-800 text-xs text-white font-normal">Profit & Loss</th>
                            <th class="px-2 py-2 bg-zinc-800 text-xs text-white font-normal">Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {sportData && sportData.matchData && Object.entries(sportData.matchData).length > 0 ? Object.entries(sportData.matchData).map(([key, match], index) => (
                        <tr key={index} className="bg-white hover:bg-gray-100 text-sm font-light text-nowrap">
                        <td className="px-2 py-2">{match?.matchName}</td>
                        <td className="px-2 py-2"><div className="font-bold">{match?.wonTeamName}</div>{match?.matchDate}</td> 
                        <td className={`px-2 py-2 font-bold ${match?.totalProfit > 0 ? "text-green-500" : match?.totalProfit < 0 ? "text-red-500" : 'text-black'}`}>{match?.totalProfit ? (Math.floor(match?.totalProfit / 100) * 100).toFixed(2) : 0.00}</td> 
                        <td className={`font-bold `}>
                                {}
                                <div className="flex gap-2 px-2 py-2 h-full ">
                                    {/* <button className="bg-[#2ecc71] text-white text-[14px] font-[400] px-2 py-1 rounded-sm text-nowrap uppercase">Odds Rollback</button> */}
                                    <a href={`/admin/matches-bet-history/${encrypt(match.marketId)}`}>
                                    <button className="bg-[#2ecc71] text-white text-[14px] font-[400] px-2 py-1 rounded-sm text-nowrap uppercase">
                                        Bet</button>
                                    </a>
                                    <button className="bg-[#2ecc71] text-white text-[14px] font-[400] px-2 py-1 rounded-sm text-nowrap uppercase">Position</button>
                                </div>
                        </td> 
                        </tr>
                    )): <tr className="text-center">
                        <td colSpan={4}>No Data Found</td></tr>}
                    </tbody>
                </table>
            </Card>
        </GlobalLayout>
    );

};

export default MatchesHistroy;
