import React from 'react';
const getResponsiveClass = (responsive) => {
    if (responsive === "center") {
      return 'left-1/2 transform -translate-x-1/2'; 
    } else if (responsive === "left") {
      return 'left-[5%]'; 
    } else if (responsive === "right") {
      return 'left-[90%]'; 
    } else {
      return ''; 
    }
  };
  
const Triangle = ({ direction = 'down', size =15, color = 'red',responsive= "center" }) => {
  const baseSize = parseInt(size, 10);
const responsiveclass =  getResponsiveClass(responsive);
  const triangleStyles = {
    position:"absolute",
    width: 0,
    height: 0,
    borderLeft: `${baseSize}px solid transparent`,
    borderRight: `${baseSize}px solid transparent`,
    ...(direction === 'down'
      ? { borderTop: `${baseSize}px solid ${color}` }  
      : { borderBottom: `${baseSize}px solid ${color}` }), 
    ...(direction === 'down'
      ? { top : "100%" }  
      : { top: `${-size}px` }),
  };

  return (
    <div
      className={`absolute ${responsiveclass} transform -translate-x-1/2`}
      style={triangleStyles}
    />
  );
};

export default Triangle;
