import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Row,
  Table,
  Col
} from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Select } from "antd";
import DemoResult from "./DemoResult";
import { useDispatch, useSelector } from "react-redux";
import { casinoDiamondBetList } from "../../../redux/_reducers/_user_reducers";
import Loader from "../../../component/loader/Loader";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";
import dayjs from "dayjs";
import Triangle from "../../../global_layouts/Triangle/Triangle";
// import { casinoDiamondBetList } from "../../../../appRedux/actions/User";
// import Loader from "../../../../components/loader";
// import BackButton from "../../Hoc/BackButton";


const Demo = () => {
  const RangePicker = DatePicker.RangePicker;
  const { _id } = useParams();

  const [userLists, setUserLists] = useState([]);
  const [ShowUserLists, setShowUserLists] = useState([]);
  const [selectUser, setSelectUser] = useState();

  const [dates, setDates] = useState([
    dayjs().endOf('day'),
    dayjs().endOf('day'),
  ]);
  const [infoMenu, setInfoMenu] = useState({ visible: false, data: null, });

  const dispatch = useDispatch()
  const { casinoBetList, loading } = useSelector((state) => state.user);
  const [finalUserList, setFinalUserList] = useState();
  const startDate = dates[0]
  const endDate = dates[1]

  useEffect(() => {
    casinoList();
  }, []);


  const casinoList = async () => {
    let reqData = {
      eventId: _id,
      toDate: startDate.format('YYYY-MM-DD'),
      fromDate: endDate.format('YYYY-MM-DD'),
      pageNo: 1,
      size: '20',
    };
    dispatch(casinoDiamondBetList(reqData));
  };

  useEffect(() => {
    if (casinoBetList) {
      const filteredData = casinoBetList?.casinoBetData?.map((item, index) => ({
        key: `${item._id}-${index}`,
        _id: `${item._id}`,
        createdAt: `${item.createdAt}`,
        client: `${item.userInfo.name}(${item.userInfo.username})`,
        roundId: item.roundId,
        playerName: item.playerName,
        showResult: item.showResult ? item.showResult : "Not Declear",
        isDeclare: item.isDeclare,
        amount: item.amount,
        profit: item.profit,
        loss: item.loss,
        profitLoss: item.profitLoss * -1,
        resultDetails: item.resultDetails,
      }));
      setUserLists(filteredData);
      const uniqueClientName = filteredData
        ? [...new Set(filteredData?.map((item) => item.client))]
        : null;
      setFinalUserList(uniqueClientName)
    }

  }, [casinoBetList?.casinoBetData]);

  useEffect(() => {
    const finalUserData = selectUser ? ShowUserLists : userLists;
    setShowUserLists(finalUserData);
  }, [selectUser, ShowUserLists, userLists]);

  const renderContent = (value, row) => {
    const obj = {
      children: value,
      props: {},
    };
    const profitLoss = row.profitLoss;

    if (profitLoss > 0) {
      obj.props.style = { backgroundColor: "green", color: "White" };
    } else if (profitLoss < 0) {
      obj.props.style = { backgroundColor: "red", color: "White" };
    }
    return obj;
  };

  async function getCompleteCasinoList() {
    let reqData = {
      eventId: _id,
      toDate: startDate.format('YYYY-MM-DD'),
      fromDate: endDate.format('YYYY-MM-DD'),
      pageNo: 1,
      size: 20,
    };
    dispatch(casinoDiamondBetList(reqData));
  }

  async function todayCompleteCasinoList() {
    setDates(
      [
        dayjs().endOf('day'),
        dayjs().endOf('day'),
      ]
    )
    let reqData = {
      eventId: _id,
      toDate: dayjs().endOf('day').format('YYYY-MM-DD'),
      fromDate: dayjs().endOf('day').format('YYYY-MM-DD'),
      pageNo: 1,
      size: 20,
    };
    dispatch(casinoDiamondBetList(reqData));
  }

  const renderShowResult = (value, row, index) => {
    const obj = renderContent(value, row, index);
    if (row.showResult) {
      obj.props.onClick = (e) => handleInfo(e, row.resultDetails);
      obj.props.style = { ...obj.props.style, cursor: "pointer" }; // Change cursor to pointer to indicate it's clickable
    }
    return obj;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      render: renderContent,
      // render: (date) => (
      //   <span>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</span>
      // ),

    },
    {
      title: "Client",
      dataIndex: "client",
      render: renderContent,
    },

    {
      title: "RoundId",
      dataIndex: "roundId",
      render: renderContent,
    },

    {
      title: "Player",
      dataIndex: "playerName",
      render: renderContent,
    },

    {
      title: "Winner",
      dataIndex: "showResult",
      render: renderShowResult,
    },

    {
      title: "Stake",
      dataIndex: "amount",
      render: renderContent,
    },

    {
      title: "Profit",
      dataIndex: "profit",
      render: renderContent,
    },

    {
      title: "Loss",
      dataIndex: "loss",
      render: renderContent,
    },

    {
      title: "PNL",
      dataIndex: "profitLoss",
      render: renderContent,
    },
  ];

  const onChange = (dates) => {
    try {
      setDates(dates);
    } catch (error) {
      console.error("Error in onChange:", error);
    }
  };
  const handleDateChange = (date, dateString, type) => {
    const newDates = [...dates];
    newDates[type === 'start' ? 0 : 1] = date; // Update either start or end date

    setDates(newDates);
    onChange(newDates); // Pass new date range back to parent
  };

  const handleInputChange = async (value) => {
    setSelectUser(value);
    if (value === "All") {
      setShowUserLists(userLists); // Show all users if "All" is selected
    } else {
      const filteredData = userLists.filter((item) => item.client === value);
      setShowUserLists(filteredData);
    }
  };

  const handleInfo = (e, showResult) => {
    e.preventDefault();
    setInfoMenu({
      visible: true,
      data: showResult,
    });
  };

  const handleClose = () => {
    setInfoMenu({
      visible: false,
    });
  };
  const Option = Select.Option;

  const components = {
    body: {
      row: ({ children, ...restProps }) => (
        <tr {...restProps} className="no-hover">
          {children}
        </tr>
      ),
    },
  };
  
  

  return (
    <>
      {loading ? <Loader loaderTrue={true} /> :
      <GlobalLayout >

<Card className="gx-card">

          <div className="bg-red-600 flex justify-between items-center mb-3">
              <div className="text-white bg-red-700 relative font-bold px-4 py-2">
              Diamond Casino Details
                <Triangle direction="down" size={10} color="#B91C1C" />
              </div>
              <button
                onClick={() => window.location.reload()}
                className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
              >
                Refresh
              </button>
            </div>
          <div className="gx-mt-3">
            <Row style={{ gap: 16 }} className="gx-px-5" >
              <Col className="flex gap-3" >
              <DatePicker
            size="large"
            value={dates[0]}
            onChange={(date) => handleDateChange(date, '', 'start')}
            style={{ width: 250 }}
          />
          <DatePicker
            size="large"
            value={dates[1]}
            onChange={(date) => handleDateChange(date, '', 'end')}
            style={{ width: 250 }}
          />

                <Select style={{ width: 200 , height:"38px" }}  defaultValue="All"  onChange={value => handleInputChange(value)} 
                // getPopupContainer={trigger => trigger.parentElement}
                >
                  <Select.Option value="All"  >All</Select.Option>
                  {finalUserList && finalUserList?.map((item, index) => (
                    <Select.Option key={`${index}`} value={item}  >{item}</Select.Option>
                  ))}
                </Select>
              </Col>
              <div className="flex gap-2">
                <Button className="px-4 py-1 leading-0 bg-blue-400 !border-0 text-white hover:!bg-blue-400 font-bold" onClick={() => getCompleteCasinoList()}>Apply</Button>
                <Button className="px-4 py-1 leading-0 bg-green-400 !border-0 text-white hover:!bg-orange-400 font-bold" onClick={() => todayCompleteCasinoList()} >Today P/L</Button>
              </div>
            </Row>
            </div>
         

          <div>
            <Table
              className="gx-table-responsive"
              columns={columns}
              dataSource={ShowUserLists}
              bordered
              pagination={false}
              size="small"
              components={components}
            />
          </div>

          <DemoResult
            data={infoMenu.data}
            visible={infoMenu.visible}
            handleClose={handleClose}
          />
        </Card>
      </GlobalLayout>
       }
    </>
  );
};

export default Demo;

