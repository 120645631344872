// import React, { useEffect, useState } from 'react';
// import Card from '../../global_layouts/Card/Card';
// import GlobalLayout from '../../global_layouts/GlobalLayout/GlobalLayout';
// import { useParams } from 'react-router-dom';
// import { decrypt } from '../../config/Encryption';
// import { useDispatch, useSelector } from 'react-redux';
// import { reportUserProfitLoss } from '../../redux/_reducers/_report_reducers';
// import { FaTrophy } from 'react-icons/fa';

// const ProfitLoss = () => {
//     const [profitLossData, setProfitLossData] = useState([])
//     const [fromDate, setFromDate] = useState("");
//     const [toDate, setToDate] = useState("");


//     const { userIdEnc } = useParams();
//     const userId = decrypt(userIdEnc);
//     useEffect(() => {
//         getProfitLoss()
//     }, [])
//     const dispatch = useDispatch();
//     const { reportUserProfitLossData, loading } = useSelector(state => state.report);


//     useEffect(() => {
//         if (reportUserProfitLossData) {
//             const filterProfitLossData = reportUserProfitLossData.map(item => ({
//                 eventName: item.eventName,
//                 marketId: item.marketId,
//                 userLedgerAmt: item.userLedgerAmt,
//                 userNetProfitLoss: item.userNetProfitLoss,
//                 userOddsComm: item.userOddsComm
//             }));
//             setProfitLossData(filterProfitLossData)
//         }

//     }, [reportUserProfitLossData])

//     const getProfitLoss = () => {
//         let reqData = {
//             "fromDate": fromDate,
//             "toDate": toDate, //"2024-10-15",
//             "profitLossType": "all",
//             "status": 1,
//             "userId": userId

//         }
//         dispatch(reportUserProfitLoss(reqData))
//     }
//     const handleFromDateChange = (e) => {
//         setFromDate(e.target.value);
//     };

//     const handleToDateChange = (e) => {
//         setToDate(e.target.value);
//     };

//     const handlesubmit = () => {
//         let reqData = {
//             "fromDate": fromDate,
//             "toDate": toDate, //"2024-10-15",
//             "profitLossType": "all",
//             "status": 1,
//             "userId": userId

//         }
//         dispatch(reportUserProfitLoss(reqData))
//     }



//     return (
//         <GlobalLayout icons={<FaTrophy />} title="Profit Loss">
//             <div className="flex flex-wrap  gap-1 ">


//                 {/* From Date */}
//                 <div className="flex flex-col w-full md:w-auto">
//                     <input
//                         type="date"
//                         id="from-date"
//                         className="px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full md:w-64"
//                         value={fromDate}
//                         onChange={handleFromDateChange}
//                     />
//                 </div>

//                 {/* To Date */}
//                 <div className="flex flex-col w-full md:w-auto">
//                     <input
//                         type="date"
//                         id="to-date"
//                         className="px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full md:w-64"
//                         value={toDate}
//                         onChange={handleToDateChange}
//                     />

//                 </div>


//                 <div className="flex flex-col w-full md:w-auto">
//                     <select name="statmentType" id="statmentType" className="px-4 py-2 border rounded-md shadow-sm w-full md:w-64" >
//                         <option value>All</option>
//                         <option value={1}>Casino</option>
//                         <option value={2}>Cricket</option>
//                         <option value={3}>Tennis</option>

//                     </select>
//                 </div>
//                 <div className="form-group  flex  gap-0.5 flex-nowrap">
//                     <button type="button" name="filter" id="filter" className="btn btn-primary" onClick={() => handlesubmit()}>Filter</button>
//                     <button type="button" name="refresh" id="refresh" className="btn btn-default">Refresh</button>
//                     <button className="btn btn-warning" ><i className="fa fa-file px-0.5" />Excel</button>
//                     <button className="btn btn-warning" ><i className="fa fa-file-pdf px-0.5" />Pdf</button>
//                 </div>
//             </div>
//             <div className="lg:flex gap-4 w-100">
//                 <div className='flex-1 overflow-auto'>
//                     <table className="table table-bordered table-sm">
//                         <thead>
//                             <tr className='bg-gray-800 text-white'>
//                                 <th className=' px-2 w-20'></th>
//                                 <th className=' px-2'>Matches</th>
//                                 <th className=' px-2'>Stack</th>
//                                 <th className=' px-2'>Profit Loss</th>
//                                 <th className=' px-2'>Commission</th>
//                                 <th className='px-2 w-24'></th>
//                             </tr>
//                         </thead>
//                         <tbody className='text-sm text-black bg-white'>
//                             {profitLossData?.length > 0 ? (
//                                 profitLossData?.map((res, index) => (
//                                     <tr key={index}>
//                                         <td className='px-2 flex justify-center items-center'>
//                                             <span className='px-2 w-12 py-1  rounded-sm bg-orange-400 flex justify-center items-center text-md font-bold text-white'>+</span>
//                                         </td>
//                                         <td className='px-2'>{res.eventName}</td>
//                                         <td className='px-2'>0</td>
//                                         <td className='px-2'>{res.userOddsComm} </td>
//                                         <td className='px-2'>0</td>
//                                         <td className='px-2 flex justify-center items-center'>
//                                             <span className='px-2 py-1 rounded-sm  bg-green-600 text-white'>Bets</span>
//                                         </td>
//                                     </tr>
//                                 ))
//                             ) : (
//                                 <tr>
//                                     <td colSpan="3" className="text-center">No Profit Loss available</td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 </div>

//                 <div className='flex-1 text-center uppercase'>
//                     click BET to show your respective bets

//                     <div>
//                         {/* <table className="table table-bordered table-sm">
//                         <thead>
//                             <tr className='bg-yellow-200 text-black text-md font-bold'>
//                                 <th className='px-2'>Market</th>
//                                 <th className='px-2'>Odds</th>
//                                 <th className='px-2'>Stake</th>
//                                 <th className='px-2'>P&L</th>
//                                 <th className='px-2'>Status</th>
//                             </tr>
//                         </thead>

//                     </table> */}
//                     </div>
//                 </div>
//             </div>

//         </GlobalLayout>
//     );
// };

// export default ProfitLoss;


import React, { useEffect, useState } from 'react';
import Card from '../../global_layouts/Card/Card';
import GlobalLayout from '../../global_layouts/GlobalLayout/GlobalLayout';
import { useParams } from 'react-router-dom';
import { decrypt, encrypt } from '../../config/Encryption';
import { useDispatch, useSelector } from 'react-redux';
import { reportUserGroupByBetsForEventId, reportUserProfitLoss } from '../../redux/_reducers/_report_reducers';
import { FaTrophy } from 'react-icons/fa';
import moment from 'moment';

const ProfitLoss = () => {
    const [profitLossData, setProfitLossData] = useState([]);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [statmentType, setStatmentType] = useState("");

    const { userIdEnc, userTypeEnc } = useParams();
    const userId = decrypt(userIdEnc);
    const userType = decrypt(userTypeEnc);
    const dispatch = useDispatch();
    const { reportUserProfitLossData, loading, reportUserGroupByBetsForEventIdData } = useSelector(state => state.report);

    useEffect(() => {
        // Initial fetch with empty filters (if needed)
        getProfitLoss();
    }, []);

    useEffect(() => {
        if (reportUserProfitLossData) {
            const filterProfitLossData = reportUserProfitLossData.map(item => ({
                eventName: item.eventName,
                marketId: item.marketId,
                userLedgerAmt: item.userLedgerAmt,
                userNetProfitLoss: item.userNetProfitLoss,
                userOddsComm: item.userOddsComm,
                userSessionComm: item.userSessionComm
            }));
            setProfitLossData(filterProfitLossData);
        }
    }, [reportUserProfitLossData]);

    const getProfitLoss = () => {
        let reqData = {
            fromDate: fromDate,
            toDate: toDate,
            profitLossType: statmentType || "all",  // Handle the statement type
            status: 1,
            userId: userId,
            userType: userType
        };
        dispatch(reportUserProfitLoss(reqData));
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleStatementTypeChange = (e) => {
        setStatmentType(e.target.value);
    };

    const handleSubmit = () => {
        getProfitLoss(); // Call to fetch data after applying filters
    };

    const handlegroupByEventData = (req) => {
        let reqData = {
            marketId: req,
            userId: userId,
            userType: userType
        };
        dispatch(reportUserGroupByBetsForEventId(reqData));

    }

  

    const totalUserAmount = reportUserGroupByBetsForEventIdData?.reduce((accumulator, current) => {
        return accumulator + (current?.userAmount ? -current.userAmount : 0);
    }, 0);
    

    const totalUserAmountProfitLoss = profitLossData?.reduce((accumulator, current) => {
        return accumulator + (current?.userNetProfitLoss ? -current.userNetProfitLoss : 0);
    }, 0);
    

    // const totalUserAmount = reportUserGroupByBetsForEventIdData?.reduce((accumulator, current) => {
    //     return accumulator + current.userAmount;
    // }, 0);
    

    // const totalUserAmountProfitLoss = profitLossData?.reduce((accumulator, current) => {
    //     return accumulator + current.userNetProfitLoss;
    // }, 0);




    return (
        <GlobalLayout icons={<FaTrophy />} title="Profit Loss">
            <div className="flex flex-wrap gap-1">
                {/* From Date */}
                <div className="flex flex-col w-full md:w-auto">
                    <input
                        type="date"
                        id="from-date"
                        className="px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full md:w-64"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>

                {/* To Date */}
                <div className="flex flex-col w-full md:w-auto">
                    <input
                        type="date"
                        id="to-date"
                        className="px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full md:w-64"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>

                {/* Statement Type Filter */}
                <div className="flex flex-col w-full md:w-auto">
                    <select
                        name="statmentType"
                        id="statmentType"
                        className="px-4 py-2 border rounded-md shadow-sm w-full md:w-64"
                        value={statmentType}
                        onChange={handleStatementTypeChange}
                    >
                        <option value="">All</option>
                        <option value={1}>Casino</option>
                        <option value={2}>Cricket</option>
                        <option value={3}>Tennis</option>
                    </select>
                </div>

                <div className="form-group flex gap-0.5 flex-nowrap">
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>Filter</button>
                    <button type="button" className="btn btn-default">Refresh</button>
                    <button className="btn btn-warning"><i className="fa fa-file px-0.5" /> Excel</button>
                    <button className="btn btn-warning"><i className="fa fa-file-pdf px-0.5" /> PDF</button>
                </div>
            </div>

            <div className="lg:flex gap-4 w-100">
                <div className='flex-1 overflow-auto'>
                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr className='bg-gray-800 text-white'>
                                <th className='px-2 w-20'></th>
                                <th className='px-2'>Matches</th>
                                <th className='px-2'>Amount</th>
                                <th className='px-2'>Total</th>
                                {/* <th className='px-2'>Commission</th> */}
                                <th className='px-2 w-24'></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td colSpan={3}>Total</td>
                                <td colSpan={1} className={`px-2 text-md font-bold ${totalUserAmountProfitLoss > 0 ? 'text-green-800' : totalUserAmountProfitLoss < 0 ? 'text-red-800' : 'text-black'}`}>{(totalUserAmountProfitLoss).toFixed(2)}</td>
                                <td ></td>
                            </tr>
                        </tbody>
                        <tbody className='text-sm text-black bg-white'>
                            {profitLossData.length > 0 ? (profitLossData.map((res, index) => (
                                <tr key={index}>
                                    <td className='px-2 flex justify-center items-center'>
                                        <span className='px-2 w-12 py-1 rounded-sm bg-orange-400 flex justify-center items-center text-md font-bold text-white'>+</span>
                                    </td>
                                    <td className='px-2'>{res.eventName}</td>
                                    {/* <td className='px-2'>0</td> */}
                                    <td className='px-2'>{(-1 * res.userNetProfitLoss).toFixed(2)}</td>
                                    <td className={`px-2 text-sm font-bold ${-1 * res.userNetProfitLoss > 0 ? 'text-green-800' : -1 * res.userNetProfitLoss < 0 ? 'text-red-800' : 'text-black'}`}>{(-1 * res.userNetProfitLoss).toFixed(2)}</td>

                                    <td className='px-2 flex justify-center items-center' onClick={() => handlegroupByEventData(res.marketId)}>
                                        <span className='px-4 py-1 rounded-sm bg-orange-600 text-white'>D</span>
                                    </td>
                                </tr>
                            ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center">No Profit Loss available</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

                <div className='flex-1 text-start uppercase'>

                    {reportUserGroupByBetsForEventIdData?.length > 0 ? (
                        <>
                            {loading ? <div className="tab-loading flex justify-center items-center mt-12">
                                <div className='loader-img  translate-x-1/4'>
                                    <img
                                        className="img-fluid"
                                        src="/assest/images/loader.gif"
                                        alt="Loading..."
                                        width={100}
                                        height={100}
                                    />

                                </div>
                            </div> :

                                <table className="table table-bordered table-sm">
                                    <thead>
                                        <tr className='bg-gray-800 text-white'>
                                            <th className='px-2 w-20'></th>
                                            <th className='px-2'>Market</th>
                                            <th className='px-2'>Amount</th>
                                            <th className='px-2'>Total</th>
                                            <th className='px-2'>Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>

                                            <td colSpan={3}>Total</td>
                                            <td colSpan={1} className={`px-2 text-md font-bold ${totalUserAmount > 0 ? 'text-green-800' : totalUserAmount < 0 ? 'text-red-800' : 'text-black'}`}>{(totalUserAmount).toFixed(2)}</td>
                                            <td ></td>
                                        </tr>
                                    </tbody>
                                    <tbody className='text-sm text-black bg-white'>
                                        {reportUserGroupByBetsForEventIdData.map((res, index) => (
                                            <tr key={index}>
                                                <td className='px-2 '>{index + 1}</td>
                                                <td className='px-2'>{res._id.remarks}</td>
                                                <td className='px-2'>{(-1 * res.userAmount).toFixed(2)}</td>
                                                <td className={`px-2 text-md font-bold ${-1 * res.userAmount > 0 ? 'text-green-800' : -1 * res.userAmount < 0 ? 'text-red-800' : 'text-black'}`}>
                                                    {(-1 * res.userAmount).toFixed(2)}
                                                </td>

                                                <td className='px-2 flex justify-center items-center'>
                                                    <a href={`/admin/event-matches-bet-history/${encrypt(res?.marketId)}/${encrypt(res?.selectionId)}/${encrypt(res?.oddsType)}/${encrypt(userId)}/${encrypt(userType)}`}>
                                                        <span className='px-2 py-1 rounded-sm bg-green-600 text-white'>Bets</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                   
                                </table>
                            }
                        </>
                    ) :
                        <div> click D to show your respective bets</div>

                    }


                </div>

            </div>
        </GlobalLayout>
    );
};

export default ProfitLoss;
