function GlobalLayout({ icons, title, children }) {
  return (
    <div className="">
      <div className="content-wrapper">
        <div className={ ` ${(title || icons ) ? "content-header" : "py-1" } `}>
          <h1 className="flex lg:text-xl text-lg items-center gap-2"> {icons} {title}</h1>
        </div>
        <section className="content">
          {children}
        </section>
      </div>
    </div>
  )
}

export default GlobalLayout
