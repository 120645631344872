import { message } from "antd";
import { apiCall } from "../../config/Http";



async function userBalance(data) {
  try {
      const response = await apiCall("POST", "user/userBalance", data);
      if (response && response?.data) {
        localStorage.setItem('wallet-balance', JSON.stringify(response.data?.coins));
        localStorage.setItem('wallet-exposure', JSON.stringify(response.data.exposure));
          return response?.data;
      }
  } catch (error) {
    console.error("user Balance", error);
    return Promise.reject(error);
  }
}

async function userCreate(data) {

  
  try {
      const response = await apiCall("POST", "user/create", data);
      if (response && response?.data) {
          return response?.data;
      }
  } catch (error) {
    console.error("user Create", error);
    message.error(error?.data?.message)
    
    return Promise.reject(error);
  }
}

async function userUpdate(data) {
  try {
      const response = await apiCall("PATCH", "user/userUpdate", data);
      if (response ) {
          return response;
      }
  } catch (error) {
    console.error("user Balance", error);
    message.error(error?.data?.message)
    return Promise.reject(error);
  }
}

async function userLenaDena(data) {
  try {
      const response = await apiCall("POST", "user/lenaDena", data);
      if (response && response?.data) {
          return response?.data;
      }
  } catch (error) {
    console.error("user lenadena respons error", error);
    return Promise.reject(error);
  }
}

async function userLedger(data) {
  try {
      const response = await apiCall("POST", "user/userLedger", data);
      if (response && response?.data) {
          return response?.data;
      }
  } catch (error) {
    console.error("user lenadena respons error", error);
    return Promise.reject(error);
  }
}

async function userLedgerCreditDebit(data) {
  try {
      const response = await apiCall("POST", "user/ledgerCreditDebit", data);
      if (response && response?.data) {
          return response?.data;
      }
  } catch (error) {
    console.error("user ledgerCreditDebit respons error", error);
    return Promise.reject(error);
  }
}
async function userList(data) {
  try {
      const response = await apiCall("POST", "user/userList", data);
      if (response && response?.data) {
          return response?.data;
      }
  } catch (error) {
    console.error("user ledgerCreditDebit respons error", error);
    return Promise.reject(error);
  }
}


async function getuserStatement(data) {
  try {
      const response = await apiCall("POST", "user/userStatement", data);
      if (response && response?.data) {
          return response?.data;
      }
  } catch (error) {
    console.error("user getuserStatement respons error", error);
    return Promise.reject(error);
  }
}

async function getCoinsUpdate(data) {
  try {
      const response = await apiCall("PATCH", "user/updateCoins", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user coins respons error", error);
    return Promise.reject(error);
  }
}
async function getUserSearchResult(data) {
  try {
      const response = await apiCall("POST", "user/userSearch", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user search respons error", error);
    return Promise.reject(error);
  }
}
async function userLedgerList(data) {
  try {
      const response = await apiCall("POST", "user/userLedger", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function getCasinoDayWise(data) {
  try {
      const response = await apiCall("POST", "casino/dayWiseCasinoReport", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function getDiamondCasinoReport(data) {
  try {
      const response = await apiCall("POST", "casino/diamondCasinoReportByUser", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function getcasinoReportByUser(data) {
  try {
      const response = await apiCall("POST", "user/casinoReportByUser", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function casinoDiamondBetList(data) {
  try {
      const response = await apiCall("POST", "casino/diamondBetsList", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function casinoRoundWise(data) {
  try {
      const response = await apiCall("POST", "casino/roundWiseResult", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function getDiamondCasinoByEventId(data) {
  try {
      const response = await apiCall("POST", "casino/getDiamondCasinoByEventId", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function getProfitLossPos(data) {
  try {
      const response = await apiCall("POST", "casino/getProfitLossPos", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function getMatkaList(data) {
  try {
      const response = await apiCall("POST", "matka/getMatkaList", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}
async function getMatkaBetList(data) {
  try {
      const response = await apiCall("POST", "matka/matkaBetList", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}


async function getDomainList(data) {
  try {
      const response = await apiCall("POST", "user/domainList", data);
      if (response && response.data) {
        return response.data; // Return the response data
      }
  } catch (error) {
    console.error("user ledger respons error", error);
    return Promise.reject(error);
  }
}



export const userServices = {
  userBalance,
  userUpdate,
  userLenaDena,
  userLedger,
  userLedgerCreditDebit,
  getuserStatement,
  userList,
  getCoinsUpdate,
  getUserSearchResult,
  userLedgerList,
  getCasinoDayWise,
  getDiamondCasinoReport,
  getcasinoReportByUser,
  casinoDiamondBetList,
  casinoRoundWise,
  getDiamondCasinoByEventId,
  getProfitLossPos,
  getMatkaList,
  getMatkaBetList,
  userCreate,
  getDomainList
};