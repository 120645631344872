import React from 'react';

const Loader = ({ loaderTrue }) => {
  return (
    <div className="content-wrapper bg-white">
      <div className="content-header m-0">
        <div className="container-fluid">
          <div className="row mb-2 m-0 justify-content-center">
            {loaderTrue && (
              <div className="col-12 m-0 d-flex justify-center items-center min-h-[80vh]">
                <div className="tab-content m-0 flex items-center justify-center h-full w-full">
                  <div className="tab-loading">
                    <div className='loader-img  translate-x-1/4'>
                      <img
                        className="img-fluid w-50 "
                        src="/assest/images/loader.gif"
                        alt="Loading..."
                      />
                      {/* <h2 className="display-4 text-center">Tab is loading <i className="fa fa-sync fa-spin"></i></h2> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;



