import { useDispatch } from "react-redux";
import { domainName } from "../../constents/global";
import Drawer from "../../global_layouts/Drawer/Drawer";
import { userMemberInfo } from "../../global_layouts/UserLayerInfo/UserLayerInfo";
import { userUpdate } from "../../redux/_reducers/_user_reducers";
import { useState } from "react";


const CreditRefrence = (props) => {
  const [userInfo, setUserInfo] = useState({
    oldPassword: '',
    newPassword: '',
  });
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const { userId, creditReference } = props?.data



  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const changePassword = (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = userInfo;
    let validationErrors = { oldPassword: '', newPassword: '', confirmPassword: '' };
    let hasError = false;

    if (!oldPassword) {
      validationErrors.oldPassword = 'Transition password is required.';
      hasError = true;
    }
    if (!newPassword) {
      validationErrors.newPassword = 'Credit Reference Not Blank';
      hasError = true;
    }


    if (hasError) {
      setErrors(validationErrors);
      return; // Do not submit if there are errors
    }

    let reqData = {
      transactionPassword: oldPassword,
      creditReference: newPassword,
      userId: userId
    };
    // dispatch(userUpdate(reqData));
    dispatch(userUpdate(reqData)).then((output) => {
      if (!output.error) {
        props.setIsCreditData(false)
      }
    });
    setUserInfo({ oldPassword: '', newPassword: '' });
  };

  return (


    <div className="md:w-1/4 w-[360px] bg-[#343A40]" onClick={(e) => e.stopPropagation()}>

      <form className="p-2.5 " onSubmit={changePassword}>
        <div className="grid gap-6 mb-2">

          <div>
            <label htmlFor="last_name" className="block col-span-2 mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">Current Credit Refrence</label>
            <input defaultValue={creditReference} type="text" id="last_name" disabled className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 bg-gray-300" placeholder="Doe" required />
          </div>
        </div>

        <div className="mb-2">
          <div>
            <label htmlFor="newPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">Update Credit Refrence</label>
            <input type="text" id="newPassword" name="newPassword" value={userInfo.newPassword} onChange={handleChange} className=" border border-gray-300 text-gray-900 text-sm  block w-full p-1.5 " placeholder="Credit Refrence" />
            {errors.newPassword && <span className="text-red-500 text-sm font-medium">{errors.newPassword}</span>}
          </div>
        </div>

        <div className="mb-2">
          <label htmlFor="oldPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize">Transaction Password</label>
          <input type="text" id="oldPassword" name="oldPassword" value={userInfo.oldPassword} onChange={handleChange} className="border border-gray-300 text-gray-900 text-sm block w-full p-1.5" placeholder="Password" />
          {errors.oldPassword && <span className="text-red-500 text-sm font-medium">{errors.oldPassword}</span>}
        </div>



        <div className="flex items-start mt-3 space-x-2 w-full " >
          <button  onClick={()=> props.setIsCreditData(false)} className="text-white uppercase  bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none  font-normal rounded-sm text-md w-100 sm:w-auto px-5 py-1.5 text-center ">Close</button>
          <button type="submit" className="text-white uppercase  bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none  font-normal rounded-sm text-md w-100 sm:w-auto px-5 py-1.5 text-center ">update</button>
        </div>
      </form>
    </div>
  );

};

export default CreditRefrence;

