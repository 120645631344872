import React from "react";
import { Pagination } from '@mui/material';

const TablePagination = ({ currentPage, totalItems, pageSize, onPageChange,position="right", shape="rounded",color="primary" }) => {
  const handlePageChange = (event, page) => {
    onPageChange(page);
  };

  return (
    <div className={`flex my-1 ${position === "right" && "justify-end"} ${position === "left" && "justify-start"} ${position === "center" && "justify-center"}`} >
      <Pagination
        page={currentPage}
        count={Math.ceil(totalItems / pageSize)}  
        onChange={handlePageChange}
        size="small"
        shape={shape}
        color={color}
      />
    </div>
  );
};

export default TablePagination;
