import React from "react";
import { Row, Col } from "antd";
import Basic from "./Basic";


const CompleteCasino = () => {
  return (
    <Row justify={"center"} className="gx-px-2">
      <Col xs={24}>
        <Basic />
      </Col>
    </Row>
  );
};

export default CompleteCasino;
