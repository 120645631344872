import Drawer from "../../global_layouts/Drawer/Drawer";


const MatchSetting = (props) => {
    const {isDrawerOpen, setIsDrawerOpen} = props
    const isOpen = isDrawerOpen.matchSettingDrawer;
    
  return (
        <Drawer
          isOpen={isOpen}
          onClose={() => setIsDrawerOpen({ matchSettingDrawer: false})}   
          direction="right"
          size="lg:w-[35%] md:w-[40%] xl:w-[25%] w-[100%]"
          overlay={true}
        >
          <div className="text-white">

          <h2 className="text-2xl font-bold">MAtches Setting</h2>
          <p>Matches Setting Drawer api pending.</p>
          </div>
        </Drawer>


  );

};

export default MatchSetting;
