import React, { useEffect, useState } from "react";
import { Card, Table, Button, Row, Col } from "antd";
import { useParams } from "react-router-dom";
// import BackButton from "../../Hoc/BackButton";
import { Link } from "react-router-dom";
// import { getUserList } from "../../../../appRedux/actions/User";
import { useDispatch, useSelector } from "react-redux";
// import Loader from "../../../../components/loader";
import { gameList } from "./GameList";
import Loader from "../../../component/loader/Loader";
import { getUserList } from "../../../redux/_reducers/_user_reducers";
import { domainName } from "../../../constents/global";
import Triangle from "../../../global_layouts/Triangle/Triangle";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";

const getDownlineUserType = () => {
  let userInfo = JSON.parse(localStorage.getItem(`user_info_${domainName}`));
  let userDetails = userInfo?.data;
  let downlineUserPriority = userDetails.userPriority - 1;
  if (!downlineUserPriority) {
    return "";
  }
  let downlineUserType = "";
  switch (downlineUserPriority) {
    case 9:
      downlineUserType = "owner";
      break;
    case 8:
      downlineUserType = "subowner";
      break;
    case 7:
      downlineUserType = "superadmin";
      break;
    case 6:
      downlineUserType = "admin";
      break;
    case 5:
      downlineUserType = "subadmin";
      break;
    case 4:
      downlineUserType = "master";
      break;
    case 3:
      downlineUserType = "superagent";
      break;
    case 2:
      downlineUserType = "agent";
      break;
    case 1:
      downlineUserType = "client";
      break;
    default:
      break;
  }
  return downlineUserType;
};

const Basic = () => {
  const dispatch = useDispatch();
  const [sessionList, setSessionList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [sessionListDataFinal, setSessionListDataFinal] = useState([]);
  const [selectedSessionKeys, setSelectedSessionKeys] = useState([]);
  const [selectedClientKeys, setSelectedClientKeys] = useState([]);
  const [selectedClientKeys1, setSelectedClientKeys1] = useState([]);
  const [selectedSessionEventIdsData, setselectedSessionEventIdsData] =
    useState([]);
  const [selectedClientUserIdData, setselectedClientUserIdData] = useState([]);

  const { marketId, matchName, date } = useParams();
  const selectionType = "checkbox";
  const {  userListTotal, loading,userListData } = useSelector(
    (state) => state.user
  );
  const userListItems = userListData?.list

  useEffect(() => {
    const downlineUserType = getDownlineUserType();

    getUserListFun(downlineUserType);
  }, [marketId]);

  const oddsData = [{ type: "odds", key: "1" }];

  useEffect(() => {
    const allKeys = clientList?.map((item) => item.key);
    setSelectedClientKeys(allKeys);

    const allKeys1 = sessionListDataFinal?.map((item) => item.key);
    setSelectedSessionKeys(allKeys1);

    const allKeys2 = sessionListDataFinal?.map((item) => item.gameId);
    setselectedSessionEventIdsData(allKeys2);

    const allKeys3 = clientList?.map((item) => item.id);
    setselectedClientUserIdData(allKeys3);
  }, [clientList, sessionListDataFinal]);


  useEffect(() => {
    if (userListItems) {
      const filteredData = userListItems?.map((item, index) => ({
        key: `${item.userId}-${index}`,
        id: item.id,
        username: item.username,
        name: item.name,
      }));
      setClientList(filteredData);
    }
  }, [userListItems]);

  const getUserListFun = async (downlineUserType) => {
    let reqData = {
      specific: {
        username: 1,
        userId: 1,
        name: 1,
      },
      sortData: {
        createdAt: 1,
      },
      status: "both",
      downlineUserType: downlineUserType,
    };
    dispatch(getUserList(reqData));
  };

  const renderContent = (value, row, index) => {
    const obj = {
      children: value,
      props: {},
    };

    return obj;
  };


  const columns = [
    {
      title: "Match",
      dataIndex: "type",
      
      render: (text, record) => <span>{record.type}</span>,
    },
  ];
  const columns1 = [
    {
      title: "Game Name",
      dataIndex: "gameName",
      render: renderContent,
    },
    // {
    //     title: 'Declare',
    //     dataIndex: 'declare',
    //     render: renderContent,
    // },
  ];

  const columns2 = [
    {
      title: "user",
      dataIndex: "name",
      render: (value, row) => `${row.username} (${row.name})`,
    },
  ];

  const rowSelection1 = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSessionKeys(selectedRowKeys);
      const selectedSessionIds = selectedRows?.map((row) => row.key);
      const selectedSessionEventIds = selectedRows?.map((row) => row.gameId);
      setselectedSessionEventIdsData(selectedSessionEventIds);
    },

    selectedRowKeys: selectedSessionKeys,
  };

  const rowSelection3 = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedClientKeys(selectedRowKeys);
      const selectedClientIds = selectedRows?.map((row) => row.key);
      const selectedClientUserId = selectedRows?.map((row) => row.id);
      setselectedClientUserIdData(selectedClientUserId);
    },
    selectedRowKeys: selectedClientKeys,
  };

  useEffect(() => {
    if (gameList) {
      const filteredData = gameList?.map((item, index) => ({
        key: `${item.key}`,
        gameName: item.gameName,
        gameId: item.gameId,
      }));
      setSessionListDataFinal(filteredData);
    }
  }, [sessionList]);

  const downlineUserTypes = getDownlineUserType();

  return (
    <>
      {loading ? (
        <Loader loaderTrue={true} />
      ) : (
        <GlobalLayout>
          <Card className="gx-card capitalize  ">
            <div className="bg-red-600 flex justify-between items-center mb-5">
              <div className="text-white bg-red-700 relative font-bold px-4 py-2">
                {`Casino PandL Detail: (${date})`}
                <Triangle direction="down" size={10} color="#B91C1C" />
              </div>
              <button
                onClick={() => window.location.reload()}
                className="bg-green-500 text-white py-1 mr-1 px-3 rounded"
              >
                Refresh
              </button>
            </div>

            <Row>
              <Col className="px-2" md={12} sm={12} xs={24}>
                <Table
                  className="gx-table-responsive gx-pb-5"
                  dataSource={sessionListDataFinal}
                  columns={columns1}
                  bordered
                  pagination={false}
                  size="large"
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection1,
                  }}
                />
              </Col>
              <Col className="px-2" md={12} sm={12} xs={24}>
                <Table
                  className="table-responsive pb-5"
                  columns={columns2}
                  dataSource={clientList}
                  bordered
                  pagination={false}
                  size="large"
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection3,
                  }}
                />
              </Col>
            </Row>
          </Card>
        </GlobalLayout>
      )}
    </>
  );
};

export default Basic;
