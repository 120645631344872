import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSportMatchBetList } from "../../redux/_reducers/_sports_reducers";
import Card from "../../global_layouts/Card/Card";
import moment from "moment";

const ExposerModal = ({ isExposerOpens, setIsExposerOpens, exposerData }) => {

    const dispatch = useDispatch()
    const { loading, sportBetList } = useSelector((state) => state.sport);

    useEffect(() => {
        let reqData = {
            downlineUserId: exposerData,
            diamondBet: true,
            downlineUserType: "client",
            isClientExposure: true
        }
        dispatch(getSportMatchBetList(reqData))
    }, [])



    return (
        <div
            style={{ zIndex: 2500 }}
            className={`fixed inset-0 bg-black bg-opacity-50  flex justify-center items-center ${isExposerOpens ? "block" : "hidden"
                }`}
            onClick={() => setIsExposerOpens(false)} // Close on click outside
        >

            <div
                className="bg-white w-full  max-w-2xl  rounded-lg shadow-lg relative px-3 py-2"
                onClick={(e) => e.stopPropagation()} // Prevent close when clicking inside modal
            >
                <div className="text-right">
                    <span
                        onClick={() => setIsExposerOpens(false)}
                        className="cursor-pointer bg-red-700 px-2 py-1 text-white text-lg font-bold rounded-full"
                    >
                        &times;
                    </span>
                </div>
                <Card className="overflow-auto">
                    <table className="table table-sm table-bordered overflow-auto">
                        <thead>
                            <tr className="bg-zinc-700 text-white">
                                <th className="text-nowrap">Match</th>
                                <th className="text-nowrap">Stake</th>
                                <th className="text-nowrap">Rate</th>
                                <th className="text-nowrap">Type</th>
                                <th className="text-nowrap">Time</th>
                                <th className="text-nowrap">Market Type</th>
                            </tr>
                        </thead>

                        <tbody>
                            {sportBetList &&
                                sportBetList.length > 0 ? (
                                sportBetList.map((data, index) => (
                                    <tr key={index} className="uppercase">
                                        <td className="text-nowrap">{data?.market}</td>
                                        <td className="text-nowrap">{data?.amount}</td>
                                        <td className="text-nowrap">{data?.rate}</td>
                                        <td className="text-nowrap">{data?.type}</td>
                                        <td className="text-nowrap">{moment(data?.time).format("DD-MM-YYYY hh:mm A")}</td>
                                        <td className="text-nowrap">{data?.marketType}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="text-center">
                                    <td colSpan={6}>No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Card>


            </div>
        </div>
    )
}

export default ExposerModal;