import React from "react";

const Switch = ({
  isActive,
  onToggle,
  disabled = false,
  size = "small",
  text,
}) => {
  // Define sizes for the switch container and the knob, including responsiveness
  const sizes = {
    small: "w-14 h-7 sm:w-16 sm:h-7", // responsive for small switch
    medium: "w-20 h-10 sm:w-24 sm:h-10",
    large: "w-20 h-12 sm:w-20 sm:h-12",
  };

  const knobSizes = {
    small: "w-5 h-5 sm:w-5 sm:h-5",
    medium: "w-8 h-8 sm:w-8 sm:h-8",
    large: "w-10 h-10 sm:w-10 sm:h-10",
  };

  // Calculate the movement for the knob dynamically based on size
  const translateValues = {
    small: "translate-x-7 sm:translate-x-10",
    medium: "translate-x-10 sm:translate-x-12",
    large: "translate-x-12 sm:translate-x-14",
  };

  return (
    <div className="flex flex-col items-center">
      {text && <span className=" text-xs text-nowrap font-semibold">{text}</span>}
      <div
        className={`relative flex items-center rounded-full cursor-pointer transition-colors duration-300 ease-in-out ${
          sizes[size]
        } ${
          isActive ? "bg-green-500" : "bg-red-500"
        } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
        onClick={!disabled ? onToggle : null}
        role="switch"
        aria-checked={isActive}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !disabled) {
            onToggle();
          }
        }}
      >
        {/* ON and OFF Text inside the switch */}
        <span
          className={`absolute left-1 text-white text-xs font-bold transition-opacity duration-300 ease-in-out ${
            isActive ? "opacity-100" : "opacity-0"
          }`}
        >
          ON
        </span>
        <span
          className={`absolute right-1 text-white text-xs font-bold transition-opacity duration-300 ease-in-out ${
            !isActive ? "opacity-100" : "opacity-0"
          }`}
        >
          OFF
        </span>

        {/* The knob */}
        <div
          className={`bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
            knobSizes[size]
          } ${isActive ? translateValues[size] : "translate-x-1"}`}
        ></div>
      </div>
    </div>
  );
};

export default Switch;
