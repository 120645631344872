import React from "react";
import { Row, Col } from "antd";
import Basic from "./Basic";
import GlobalLayout from "../../../global_layouts/GlobalLayout/GlobalLayout";


const InplayCasinoDetails = () => {
  return (

     <Row justify={"center"}>
      <Col md={24} xl={24}>
        <Basic />
      </Col>
    </Row>

  );
};

export default InplayCasinoDetails;