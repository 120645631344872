import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import io from 'socket.io-client';
import { Col, Row, Table, Tag } from "antd";


const CasinoIframeResult = ({ diamondcasinobyeventid, eventId, betLists }) => {
  const [casinoData, setCasinoData] = useState({});
  const [isposArray, setIsposArray] = useState({})
  const socketRef = useRef(null);
  let userInfo = JSON.parse(localStorage.getItem('user_id'));
  


  useEffect(() => {
    casinoByEventIdList();


    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [eventId, diamondcasinobyeventid]);

  // useEffect(() => {
  //   casinoByEventIdList();
  // }, [diamondcasinobyeventid]); // Add diamondcasinobyeventid to dependency array if needed

  const casinoByEventIdList = () => {
    if (diamondcasinobyeventid?.fetchData === "socket") {
      callSocket(diamondcasinobyeventid?.socketURL, diamondcasinobyeventid?.shortName);
    } else {
      callCache(diamondcasinobyeventid?.cacheURL);
    }
  };

  const callSocket = (socketURL, shortName) => {
    if (socketRef.current && socketRef.current.connected) {
      socketRef.current.disconnect();
    }



    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });

    socket.emit('JoinRoom', shortName);

    socket.on(shortName, data => {
      setCasinoData(data);
      // alert(1)

      updatePos(data?.data?.t1[0]?.mid)
    });



    socketRef.current = socket;

  };

  useEffect(() => {
    updatePos(casinoData?.data?.t1[0]?.mid)
  }, [casinoData])

  const getMarketCacheUrl = (cacheURL) => {
    axios.get(cacheURL)
      .then((response) => {
        setCasinoData(response.data.data);
      })
      .catch((error) => {
        console.error('Cache data URL error:', error);
      });
  };

  const callCache = (cacheUrl) => {
    if (cacheUrl) {
      getMarketCacheUrl(cacheUrl);
      let cacheInterval = setInterval(() => {
        getMarketCacheUrl(cacheUrl);
      }, 1000);
      // Optional: Clear interval on component unmount
      return () => clearInterval(cacheInterval);
    } else {
      console.error('Cache URL is undefined or null');
    }
  };


  const updatePos = async (currentRoundId) => {
    if (betLists) {
      const filteredBets = betLists?.filter((element) =>  Number(element?.roundId) === Number(currentRoundId));
     
      let pushPos = {};
      filteredBets?.forEach((bet) => {
        const posArray = bet?.posArray;
        

        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value * (100 - userInfo?.data?.casinoShare));
        });
      });
    
      setIsposArray(pushPos)
    }
  }

  let t1 = casinoData?.data && casinoData?.data.t1 && casinoData?.data.t1[0] ? casinoData?.data.t1[0] : {};
  let t2 = casinoData?.data && casinoData?.data.t1 && casinoData?.data.t1[1] ? casinoData?.data.t1[1] : {};
  let Dragon = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[0] ? casinoData?.data.t2[0] : {};
  let Tiger = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[1] ? casinoData?.data.t2[1] : {};
  let Tie = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[2] ? casinoData?.data.t2[2] : {};
  // let Even = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[3] ? casinoData?.data.t2[3] : {};
  let Odd = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[4] ? casinoData?.data.t2[4] : {};
  let Red = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[5] ? casinoData?.data.t2[5] : {};
  let Black = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[6] ? casinoData?.data.t2[6] : {};
  let CardA = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[7] ? casinoData?.data.t2[7] : {};
  let Card2 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[8] ? casinoData?.data.t2[8] : {};
  let Card3 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[9] ? casinoData?.data.t2[9] : {};
  let Card4 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[10] ? casinoData?.data.t2[10] : {};
  let Card5 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[11] ? casinoData?.data.t2[11] : {};
  let Card6 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[12] ? casinoData?.data.t2[12] : {};
  let Card7 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[13] ? casinoData?.data.t2[13] : {};
  let Card8 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[14] ? casinoData?.data.t2[14] : {};
  let Card9 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[15] ? casinoData?.data.t2[15] : {};
  let Card10 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[16] ? casinoData?.data.t2[16] : {};
  let CardJ = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[17] ? casinoData?.data.t2[17] : {};
  let CardQ = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[18] ? casinoData?.data.t2[18] : {};
  let CardK = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[19] ? casinoData?.data.t2[19] : {};
  // let Under7 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[20] ? casinoData?.data.t2[20] : {};
  // let Over7 = casinoData?.data && casinoData?.data.t2 && casinoData?.data.t2[21] ? casinoData?.data.t2[21] : {};

  const columns = [
    {
      title:'Player Name',
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: '#4b5563', 
          color: 'white', 
          padding: '10px', 
          textAlign: 'start', 
         
          borderBottom: '2px solid #ccc', // Adding bottom border for separation
          fontSize:'11px',
          fontWeight:'normal',
        },
      }),
      dataIndex: 'name',
      render: (values, row) => <div>
        <div> {row?.name}</div>
        <div className={`text-md font--bold ${row?.pos > 0 ? 'text-green-600' : row?.pos < 0 ? 'text-red-600' : 'text-black'}`}>{row?.pos ? row?.pos : 0}</div>
      </div>
    },

    {
      title:  'Rate',
      onHeaderCell: (column) => ({
        style: {
          backgroundColor: '#4b5563', 
          color: 'white', 
          padding: '10px', 
          textAlign: 'start', 
          borderBottom: '2px solid #ccc', // Adding bottom border for separation,
   
          fontSize:'11px',
          fontWeight:'normal',
        },
      }),
      dataIndex: 'rate',
      render: (text) => {
        return <span className="">{text}</span>
      },
    }
  ];


  let data = [];
 


  if (Number(eventId) === 3056) {
    data = [
      { key: '1', rate: Dragon?.b1, name: Dragon.nat ? Dragon.nat : Dragon.nation },
      { key: '2', rate: Tiger?.b1, name: Tiger.nat ? Tiger.nat : Tiger.nation },
      { key: '3', name: Tie.nat, rate: Tie.b1 },
      // { key: '19', name: Even.nat, rate: Even.b1 },
      // { key: '20', name: Odd.nat, rate: Odd.b1 },
      { key: '21', name: Red.nat, rate: Red.b1 },
      { key: '5', name: Black.nat, rate: Black.b1 },
      { key: '6', name: <img src="/images/cards/1.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: CardA.b1 },
      { key: '7', name: <img src="/images/cards/2.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card2.b1 },
      { key: '8', name: <img src="/images/cards/3.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card3.b1 },
      { key: '9', name: <img src="/images/cards/4.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card4.b1 },
      { key: '10', name: <img src="/images/cards/5.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card5.b1 },
      { key: '11', name: <img src="/images/cards/6.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card6.b1 },
      { key: '12', name: <img src="/images/cards/7.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card7.b1 },
      { key: '13', name: <img src="/images/cards/8.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card8.b1 },
      { key: '14', name: <img src="/images/cards/9.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card9.b1 },
      { key: '15', name: <img src="/images/cards/10.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card10.b1 },
      { key: '16', name: <img src="/images/cards/11.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: CardJ.b1 },
      { key: '17', name: <img src="/images/cards/12.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: CardQ.b1 },
      { key: '18', name: <img src="/images/cards/13.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: CardK.b1 },
    ];
    data.forEach(item => {
      if (isposArray.hasOwnProperty(item.key)) {
          item.pos = isposArray[item.key];
      }
  });
  } else if (Number(eventId) === 3035) {
    data = [
      { key: '1', rate: Dragon?.rate, name: Dragon.nat ? Dragon.nat : Dragon.nation },
      { key: '3', name: Tie.nat ? Tie.nat : Tie.nation, rate: Tie.rate },
      { key: '2', rate: Tiger?.rate, name: Tiger.nat ? Tiger.nat : Tiger.nation },

    ];
    data.forEach(item => {
      if (isposArray.hasOwnProperty(item.key)) {
          item.pos = isposArray[item.key];
      }
  });

  }
  else if (Number(eventId) === 3031) {
    data = [
      { key: '1', rate: t1?.b1, name: t1.nat ? t1.nat : t1.nation },
      { key: '2', name: t2.nat ? t2.nat : t2.nation, rate: t2.b1 },

    ];
    data.forEach(item => {
      if (isposArray.hasOwnProperty(item.key)) {
          item.pos = isposArray[item.key];
      }
  });

  }
  else if (Number(eventId) === 3059) {
    data = [
      { key: '1', rate: Dragon?.rate, name: Dragon.nat ? Dragon.nat : Dragon.nation },
      { key: '3', name: Tie.nat ? Tie.nat : Tie.nation, rate: Tie.rate },
      { key: '2', rate: Tiger?.rate, name: Tiger.nat ? Tiger.nat : Tiger.nation },

    ];
    data.forEach(item => {
      if (isposArray.hasOwnProperty(item.key)) {
          item.pos = isposArray[item.key];
      }
  });

  }
  else if (Number(eventId) === 3032) {
    data = [
      { key: '1', rate: Dragon?.rate, name: Dragon.nat },
      // { key: '3', name: Tie.nat, rate: Tie.rate },
      { key: '2', rate: Tiger?.rate, name: Tiger.nat ? Tiger.nat : Tiger.nation },
      { key: '20', name: Odd.nat ? Odd.nat : Odd.nation, rate: Odd.rate },
      { key: '21', name: Red.nat ? Red.nat : Red.nation, rate: Red.rate },
      { key: '6', name: <img src="/images/cards/1.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: CardA.rate },
      { key: '7', name: <img src="/images/cards/2.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card2.rate },
      { key: '8', name: <img src="/images/cards/3.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card3.rate },
      { key: '9', name: <img src="/images/cards/4.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card4.rate },
      { key: '10', name: <img src="/images/cards/5.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card5.rate },
      { key: '11', name: <img src="/images/cards/6.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card6.rate },
      { key: '12', name: <img src="/images/cards/7.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card7.rate },
      { key: '13', name: <img src="/images/cards/8.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card8.rate },
      { key: '14', name: <img src="/images/cards/9.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card9.rate },
      { key: '15', name: <img src="/images/cards/10.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: Card10.rate },
      { key: '16', name: <img src="/images/cards/11.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: CardJ.rate },
      { key: '17', name: <img src="/images/cards/12.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: CardQ.rate },
      { key: '18', name: <img src="/images/cards/13.jpg" alt="card" className="w-[30px] h-[40px]"/>, rate: CardK.rate },
      // { key: '3', name: Tie.nat, rate: Tie.rate },

    ];
    data.forEach(item => {
      if (isposArray.hasOwnProperty(item.key)) {
          item.pos = isposArray[item.key];
      }
  });

  }
  else if (Number(eventId) === 3030) {
    
    
    data = [
      { key: '1', rate: Dragon?.rate, name: Dragon.nat ? Dragon.nat : Dragon.nation },
      { key: '3', name: Tie.nat ? Tie.nat : Tie.nation, rate: Tie.rate },
    ];

    data.forEach(item => {
      if (isposArray.hasOwnProperty(item.key)) {
          item.pos = isposArray[item.key];
      }
  });
   

  }
  else if (Number(eventId) === 3054) {
    data = [
      { key: '1', rate: Dragon?.rate, name: Dragon.nat ? Dragon.nat : Dragon.nation },
      { key: '3', name: Tie.nat ? Tie.nat : Tie.nation, rate: Tie.rate },
      { key: '2', rate: Tiger?.rate, name: Tiger.nat ? Tiger.nat : Tiger.nation },

    ];
    data.forEach(item => {
      if (isposArray.hasOwnProperty(item.key)) {
          item.pos = isposArray[item.key];
      }
  });

  }


  const videoUrlType = diamondcasinobyeventid?.videoUrlType;
  // const videoUrlMap = {
  //   "1": diamondcasinobyeventid?.videoUrl1,
  //   "2": diamondcasinobyeventid?.videoUrl2,
  //   "3": diamondcasinobyeventid?.videoUrl3
  // };
  // const selectedVideoUrl = videoUrlMap[videoUrlType] || "";
  const selectedVideoUrl = diamondcasinobyeventid?.[`videoUrl${videoUrlType}`]


  return (


      <Row className="flex">
        <Col xl={14} lg={24} md={24} sm={24} xs={24} className="pr-2">
          <div className="gx-news-itemgnn">
            <div className="gx-news-contenttt">
              <div className="flex justify-between bg-gray-600 rounded-tl-xl rounded-tr-xl px-2 py-2">
                <span className="text-white">{`Match ID: ${t1?.mid}`}</span>
                <span className="text-white">{t1?.autotime}</span>
              </div>
              <div className="flex">
                <iframe src={selectedVideoUrl} title=" " style={{ height: 300, position: 'relative' }} className="reletive  w-full" />

                {Number(eventId) === 3056 && (
                  <div className="top-[38px] left-0 absolute flex">
                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                  </div>
                )}

                {Number(eventId) === 3035 && (
                  <div className="top-[38px] left-0 absolute flex">
                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="" height={45} width={35} />
                  </div>
                )}

                {Number(eventId) === 3031 && (
                  <>
                  <div className="top-[38px] left-0 absolute flex">
                    {/* <span className="gx-text-blue"> Player A</span> */}
                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="" height={45} width={35} />
                  </div>
                  <div className="top-[95px] left-0 absolute flex">
                    <img src={`/cards/${t2 && t2.C1 ? t2.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t2 && t2.C2 ? t2.C2 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t2 && t2.C3 ? t2.C3 : 1}.png`} alt="card" className="" height={45} width={35} />
                  </div>
                  </>
                )}

                {Number(eventId) === 3030 && (
                  <>
                  <div className="top-[38px] left-0 absolute flex">
                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="" height={45} width={35} />
                  </div>
                  <div className="top-[95px] left-0 absolute flex">
                    <img src={`/cards/${t2 && t2.C1 ? t2.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t2 && t2.C2 ? t2.C2 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t2 && t2.C3 ? t2.C3 : 1}.png`} alt="card" className="" height={45} width={35} />
                  </div>
                  </>
                )}

                {Number(eventId) === 3054 && (
                  <div className="top-[38px] left-0 absolute flex">
                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="" height={45} width={35} />

                  </div>
                )}
                {Number(eventId) === 3059 && (
                  <div className="top-[38px] left-0 absolute flex">
                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                    <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="" height={45} width={35} />
                  </div>
                )}
                {Number(eventId) === 3032 && (
                  <div className="top-[38px] left-0 absolute flex">
                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="" height={45} width={35} />
                    {/* <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="" height={45} width={35} /> */}
                  </div>
                )}

              </div>

              <Row className="mb-3 pt-4 font-semibold capitalize  fex flex-wrap gap-1" >
                <div className="pr-2">Last 10 winners:</div>
                {Number(eventId) === 3032 && (
                  <>
                    {casinoData?.result && casinoData?.result.length > 0 ? (
                      casinoData?.result.map((element, index) => (
                        <div key={index}>
                          <Tag  color="#343a40">
                            {element.result === '1' ? "L" : element.result === '2' ? "H" : "-"}
                          </Tag>
                        </div>
                      ))
                    ) : null}
                  </>
                )}
                {Number(eventId) === 3031 && (
                  <>
                    {casinoData?.result && casinoData?.result.length > 0 ? (
                      casinoData?.result.map((element, index) => (
                        <div key={index}>
                          <Tag  color="#343a40" className={`${element && element.result && element.result === "1" ? "text-[#F75500]" : element && element.result && element.result === "2" ? "text-[#FFF523]" : "text-[#33c6ff]"} font-[700] text-[14px]`}>
                            {element && element.result && element.result === "1" ? "A" : element && element.result && element.result === "2" ? "B" : element && element.result && element.result === "3" ? "C" : "-"}
                            {/* <Tag  color="#343a40">
                            {element.result === '1' ? "dragon" : element.result === '2' ? "tiger" : "-"} */}
                          </Tag>
                        </div>
                      ))
                    ) : null}
                  </>
                )}
                {Number(eventId) === 3030 && (
                  <>
                    {casinoData?.result && casinoData?.result.length > 0 ? (
                      casinoData?.result.map((element, index) => (
                        <div key={index}>
                          <Tag  color="#343a40">
                            {element.result === '1' ? "A" : element.result === '3' ? "B" : "T"}
                          </Tag>
                        </div>
                      ))
                    ) : null}
                  </>
                )}
                {Number(eventId) === 3059 && (
                  <>
                    {casinoData?.result && casinoData?.result.length > 0 ? (
                      casinoData?.result.map((element, index) => (
                        <div key={index}>
                          <Tag  color="#343a40">
                            {element.result === '1' ? "dragon" : element.result === '2' ? "tiger" : "-"}
                          </Tag>
                        </div>
                      ))
                    ) : null}
                  </>
                )}
                {Number(eventId) === 3056 && (
                  <>
                    {casinoData?.result && casinoData?.result.length > 0 ? (
                      casinoData?.result.map((element, index) => (
                        <div key={index}>
                          <Tag  color="#343a40" className={`${element && element.result && element.result === "1" ? "text-[#F75500]" : element && element.result && element.result === "2" ? "text-[#FFF523]" : "text-[#33c6ff]"} font-[700] text-[14px]`}>
                            {element && element.result && element.result === "1" ? "A" : element && element.result && element.result === "2" ? "B" : element && element.result && element.result === "3" ? "C" : "-"}
                            {/* <Tag  color="#343a40">
                            {element.result === '1' ? "dragon" : element.result === '2' ? "tiger" : "-"} */}
                          </Tag>
                        </div>
                      ))
                    ) : null}
                  </>
                )}
                {Number(eventId) === 3035 && (
                  <>
                    {casinoData?.result && casinoData?.result.length > 0 ? (
                      casinoData?.result.map((element, index) => (
                        <div key={index}>
                          <Tag  color="#343a40">
                            {element.result === '1' ? "dragon" : element.result === '2' ? "tiger" : "-"}
                          </Tag>
                        </div>
                      ))
                    ) : null}
                  </>
                )}
                {Number(eventId) === 3054 && (
                  <>

                    {casinoData?.result && casinoData?.result.length > 0 ? (
                      casinoData?.result.map((element, index) => (
                        <div key={index}>
                          <Tag  color="#343a40">
                            {element && element.result && element.result === "1" ? "R" : element && element.result && element.result === "0" ? "R" : element && element.result && element.result === "2" ? "R" : "R"}
                          </Tag>
                        </div>
                      ))
                    ) : null}
                  </>
                )}
              </Row>
            </div>
          </div>
        </Col>

        <Col xl={10} lg={24} md={24} sm={24} xs={24} className="pl-2">
          <div className="table-responsive">
            <Table className="bordered" columns={columns} dataSource={data} pagination={false} bordered={true}
              size="small" />
          </div>
        </Col>
      </Row>

  );
};

export default CasinoIframeResult;

